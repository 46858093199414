import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Container,
  Row,
  Table,
  CardHeader,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Input,
  Button,
  FormFeedback,
} from "reactstrap";
import { Link } from "react-router-dom";
import classnames from "classnames";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { useDispatch, useSelector } from "react-redux";
import {
  addcurrencyAsync,
  deletecurrency,
  editcurrencyAsync,
  getcurrencyAsync,
} from "../../slices/currency";
import { toast } from "react-toastify";
import DeleteModal from "../../Components/Common/DeleteModal";

import { TranslationList } from "../../slices/settings/listtranslation";
import LoaderContent from "../../Components/Spinner";
import Paginations from "../Tables/DataTables/Pagination";

export default function Currency() {
  const [modal_varying1, setmodal_varying1] = useState(false);
  const [nameUZ, setNameUZ] = useState();
  const [nameRU, setNameRU] = useState();
  const [nameEN, setNameEN] = useState();
  const [symbol, setSymbol] = useState();
  const [error, setError] = useState();
  const [current, setCuttent] = useState();
  //   const [code, setCode] = useState();
  const [deleteModal, setDeleteModal] = useState(false);

  const dispatch = useDispatch();
  const currencys = useSelector((state) => state.currency?.data);

  const perpage = localStorage.getItem("perpage") ?? 10;
  const [perPage, setPerpage] = useState(perpage);
  const lang = useSelector((state) => state.lang.data);
  useEffect(() => {
    dispatch(getcurrencyAsync(`currency/?page=${1}&limit=${perPage}`));
  }, [lang]);
  const handleSearch = (e) => {
    dispatch(getcurrencyAsync(`currency/?search=${e}`));
  };
  function tog_varying1() {
    setmodal_varying1(!modal_varying1);
    setCuttent();
    setError();
  }
  const handleAdd = async () => {
    const dataDocument = {
      name_uz: nameUZ,
      name_ru: nameRU,
      name_en: nameEN,
      symbol: symbol,
    };
    const response = await addcurrencyAsync("currency/", dataDocument);
    if (response?.errorCode) {
      setError(response?.message);
      toast.error(TranslationList("An error has occurred!"));
    } else {
      toast.success(TranslationList("Done successfully!"));
      dispatch(getcurrencyAsync(`currency/?page=${1}&limit=${perPage}`));
      setmodal_varying1(false);
    }
  };
  const onClickEdite = (item) => {
    setmodal_varying1(true);
    setCuttent(item);
  };
  const handleEdite = async () => {
    const dataDocument = {
      name_uz: document.getElementById("name_uz").value,
      name_ru: document.getElementById("name_ru").value,
      name_en: document.getElementById("name_en").value,
      symbol: document.getElementById("symbol").value,
    };

    const response = await editcurrencyAsync(
      `currency/${current?.id}`,
      dataDocument
    );
    if (response?.errorCode) {
      setmodal_varying1(false);
      toast.error(TranslationList("An error has occurred!"));
    } else {
      toast.success(TranslationList("Done successfully!"));
      dispatch(getcurrencyAsync(`currency/?limit=100`));
      setmodal_varying1(false);
    }
  };
  const onClickTodoDelete = (item) => {
    setCuttent(item);
    setDeleteModal(true);
  };
  const handleDelete = async () => {
    const response = await deletecurrency(`currency/${current?.id}`);
    if (response?.errorCode) {
      toast.error(TranslationList("An error has occurred!"));
    } else {
      toast.success(TranslationList("Done successfully!"));
      dispatch(getcurrencyAsync(`currency/`));
    }
    setDeleteModal(false);
  };

  //Pagination begin

  const handleCurrentPage = (e) => {
    dispatch(getcurrencyAsync(`currency/?page=${e}&limit=${perPage}`));
  };
  const handlePerPageChange = (e) => {
    setPerpage(e);
    dispatch(getcurrencyAsync(`currency/?limit=${e}`));
  };
  //Pagination end
  document.title = `INVESTCONTROL | ${TranslationList("Currency")}`;

  return (
    <div className="page-content">
      <DeleteModal
        show={deleteModal}
        data={current?.name}
        onDeleteClick={handleDelete}
        onCloseClick={() => setDeleteModal(false)}
      />
      <Modal
        isOpen={modal_varying1}
        toggle={() => {
          tog_varying1();
        }}
        id="exampleModal"
      >
        <ModalHeader
          toggle={() => {
            tog_varying1();
          }}
        >
          {current ? TranslationList("Edit") : TranslationList("Add")}{" "}
        </ModalHeader>
        <div className="modal-body">
          <form>
            <div className="mb-3">
              {" "}
              <label htmlFor="customer-name" className="col-form-label">
                {TranslationList("uz")}:
              </label>
              <Input
                id="name_uz"
                type="text"
                className="form-control"
                onChange={(e) => setNameUZ(e.target.value)}
                defaultValue={current?.name_uz}
                invalid={error?.name_uz ? true : false}
              />
              <FormFeedback type="invalid">{error?.name_uz}</FormFeedback>
            </div>
            <div className="mb-3">
              <label htmlFor="customer-name" className="col-form-label">
                {TranslationList("ru")}:
              </label>
              <Input
                type="text"
                id="name_ru"
                className="form-control"
                onChange={(e) => setNameRU(e.target.value)}
                defaultValue={current?.name_ru}
                invalid={error?.name_ru ? true : false}
              />
              <FormFeedback type="invalid">{error?.name_ru}</FormFeedback>
            </div>
            <div className="mb-3">
              <label htmlFor="customer-name" className="col-form-label">
                {TranslationList("eng")}:
              </label>
              <Input
                type="text"
                id="name_en"
                className="form-control"
                onChange={(e) => setNameEN(e.target.value)}
                defaultValue={current?.name_en}
                invalid={error?.name_en ? true : false}
              />
              <FormFeedback type="invalid">{error?.name_en}</FormFeedback>
            </div>
            <div className="mb-3">
              <label htmlFor="customer-name" className="col-form-label">
                {TranslationList("Symbol")}:
              </label>
              <Input
                type="text"
                id="symbol"
                className="form-control"
                onChange={(e) => setSymbol(e.target.value)}
                defaultValue={current?.symbol}
                invalid={error?.symbol ? true : false}
              />
              <FormFeedback type="invalid">{error?.symbol}</FormFeedback>
            </div>
          </form>
        </div>
        <div className="modal-footer">
          <Button
            color="light"
            onClick={() => {
              setmodal_varying1(false);
            }}
          >
            {TranslationList("Cancel")}
          </Button>
          {current ? (
            <Button color="primary" onClick={handleEdite}>
              {TranslationList("Save")}
            </Button>
          ) : (
            <Button color="primary" onClick={handleAdd}>
              {TranslationList("Create")}
            </Button>
          )}
        </div>
      </Modal>

      <Container fluid>
        <BreadCrumb
          title={TranslationList("Сurrency")}
          pageTitle={TranslationList("home")}
        />
        <Row>
          <Card>
            <CardHeader>
              <Row className="align-items-center gy-3">
                <div className="col-sm">
                  <h5 className="card-title mb-0">
                    {" "}
                    {TranslationList("Сurrency")}
                  </h5>
                </div>
                <div className="col-sm-auto d-flex justify-content-between">
                  <div className="px-2">
                    <div className="search-box">
                      <Input
                        type="text"
                        className="form-control search"
                        placeholder={TranslationList("List Search")}
                        onChange={(e) => handleSearch(e.target.value)}
                      />
                      <i className="ri-search-line search-icon"></i>
                    </div>
                  </div>
                  <div className="d-flex gap-1 flex-wrap">
                    <button
                      type="button"
                      className="btn btn-success add-btn"
                      id="create-btn"
                      onClick={() => tog_varying1()}
                    >
                      <i className="ri-add-line align-bottom me-1"></i>{" "}
                      {TranslationList("Add")}
                    </button>{" "}
                  </div>
                </div>
              </Row>
            </CardHeader>
            {currencys ? (
              <CardBody>
                <div className="live-preview p-3">
                  <div className="text-muted table-responsive">
                    <Table className="table-bordered align-middle table-nowrap mb-0">
                      <thead>
                        <tr>
                          <th scope="col" className="text-center">
                            {TranslationList("Number")}
                          </th>
                          <th scope="col" className="w-50  text-center">
                            {TranslationList("Name")}
                          </th>
                          <th scope="col" className="w-50  text-center">
                            {TranslationList("Symbol")}
                          </th>
                          <th scope="col " className="text-center">
                            {TranslationList("Actions")}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {currencys?.results?.map((res, i) => (
                          <tr key={res?.id}>
                            <td className="text-center">{i + 1}</td>
                            <td>{res?.name}</td>
                            <td>{res?.symbol}</td>
                            <td className="text-center">
                              <div className="hstack gap-2 justify-content-center">
                                <button
                                  className="btn btn-sm btn-soft-danger remove-list"
                                  onClick={() => onClickTodoDelete(res)}
                                >
                                  <i className="ri-delete-bin-5-fill align-bottom" />
                                </button>
                                <button
                                  className="btn btn-sm btn-soft-info edit-list"
                                  onClick={() => onClickEdite(res)}
                                >
                                  <i className="ri-pencil-fill align-bottom" />
                                </button>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </CardBody>
            ) : (
              <LoaderContent />
            )}
          </Card>
          <Paginations
            totalItems={currencys?.pagination?.total}
            perPageChange={(e) => handlePerPageChange(e)}
            currentPage={(e) => handleCurrentPage(e)}
          />
        </Row>
      </Container>
    </div>
  );
}
