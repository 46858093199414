import React from "react";
import { Navigate, Route, useSearchParams } from "react-router-dom";

const AuthProtected = (props) => {
  const [searchParams] = useSearchParams();
  if (searchParams.get("code") && searchParams.get("state") == "testState") {
    return <>{props.children}</>;
  } else {
    if (!sessionStorage.getItem("authUser")) {
      return <Navigate to={{ pathname: "/login" }} />;
    } else {
      return <>{props.children}</>;
    }
  }
};

const AccessRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        return (
          <>
            {" "}
            <Component {...props} />{" "}
          </>
        );
      }}
    />
  );
};

export { AuthProtected, AccessRoute };
