import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import React from "react";
import { useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Row,
} from "reactstrap";
import dateFormat from "dateformat";
import { TranslationList } from "../../slices/settings/listtranslation";
export default function ForPDF({ data }) {
  const [loader, setLoader] = useState(false);
  const downloadPDF = () => {
    const capture = document.querySelector(".scrin");
    setLoader(true);
    html2canvas(capture).then((canvas) => {
      const imgData = canvas.toDataURL("img/png");
      const pdf = new jsPDF("p", "mm", "a4");
      pdf.addImage(imgData, "PNG", 0, 0);
      setLoader(false);
      pdf.save(`report_${dateFormat(new Date(), "dd.mm.yyyy_HH:MM:ss")}.pdf`);
    });
  };
  return (
    <Card>
      <CardBody className="scrin" style={{ padding: 30 }}>
        <Row>
          <Col xl={8}></Col>
          <Col xl={4}>
            <div className="fs-6">
              Директору Международного центра стратегического развития и
              исследований в сфере продовольствия и сельского хозяйства А.Н.
              Шукурову
            </div>
          </Col>
        </Row>
        <div>
          <div className="fs-6">
            <span className="ms-5"></span>B соответствие с поручением от{" "}
            {data?.dateInput}., касательно изучения Расходной ведомости №30 от
            02.03.2023г. {data?.organizationName} по субпроекту{" "}
            {data?.information},{" "}
          </div>
        </div>
        <div className="ms-5">
          <div className="fs-6">{data?.goal}</div>
        </div>
        <div className="ms-5">
          <div className="fs-6">
            <strong>Местоположение:</strong>
            {data?.location}
          </div>
        </div>
        <div className="ms-5">
          <div className="fs-6">
            Стоимость суб-проекта: {data?.subProjectCost}
          </div>
        </div>
        <div className="ms-5">
          <div className="fs-6">
            <strong>Из них субзаем МФСР: </strong> {data?.subLoan}
          </div>
        </div>
        <div className="ms-5">
          <div className="fs-6">Собств.вклад: {data?.cost}</div>
        </div>
        <div className="ms-5">
          <div className="fs-6">
            Образовано: {data?.singleWindow}
            {data?.order}
          </div>
        </div>
        <div className="ms-5">
          <div className="fs-6">
            Текущая деятельность: {data?.currentActivity}
          </div>
        </div>
        <div className="ms-5">
          <div className="fs-6">Контактное лицо: {data?.appliciant}</div>
        </div>
        <div className="ms-5">
          <div className="fs-6">
            <strong>Информация о поставшиках:</strong>
          </div>
        </div>
        <div className="ms-5">
          <div className="fs-6">Поставшик(и): {data?.postavshik}</div>
        </div>
        <div>
          <div className="fs-6">
            <span className="ms-5"></span>Товары: {data?.product}
          </div>
        </div>
        <div className="ms-5">
          <div className="fs-6">Общая сумма контракта: {data?.contract}</div>
        </div>
        <div className="ms-5">
          <div className="fs-6">
            Дата и номер контракта: {data?.dateContract}
          </div>
        </div>
        <div className="ms-5">
          <div className="fs-6">
            <strong>Структура субзайма:</strong>
          </div>
        </div>
        <div className="ms-5">
          <div className="fs-6">{data?.subloanStructure}</div>
        </div>
        <div className="ms-5">
          <div className="fs-6">
            <strong>Реализация субпроекта:</strong>
          </div>
        </div>
        <div className="ms-5">
          <div className="fs-6">{data?.subprojectImplementation}</div>
        </div>
        <div className="ms-5">
          <div className="fs-6">
            Новые рабочие места: {data?.newWorkplaces}
            {data?.payback}
          </div>
        </div>
        <div>
          <div className="fs-6">
            <span className="ms-5"></span>
            {data?.members}
          </div>
        </div>
        <div className="ms-5">
          <div className="fs-6">
            Требуется ли предварительное одобрение МФСР: {data?.automated}
          </div>
        </div>
        <div>
          <div className="fs-6">
            <span className="ms-5"></span>На основании Положения о порядке
            использования кредитной линии МФСР и Соглашения о перекредитовании
            от {data?.datebring}, г, суб-проект {data?.information}{" "}
            соответствует критериям кредитной линии в рамках дополнительного
            финансирования проекта {data?.projectName}. В связи с чем, полагаем
            возможным финансировать данный суб-проект.
          </div>
        </div>
        <div className="ms-5">
          <div className="fs-6">
            <strong className="me-5">Специалист по кредитной линии </strong>{" "}
            {data?.spetsialist}
          </div>
        </div>
      </CardBody>
      <CardFooter className="d-flex justify-content-end">
        <Button onClick={downloadPDF} disabled={!(loader === false)}>
          {loader ? (
            <span>{TranslationList("Downloading")}</span>
          ) : (
            <span>{TranslationList("Download")}</span>
          )}
        </Button>
      </CardFooter>
    </Card>
  );
}
