import React, { useEffect, useState } from "react";
import {
  Table,
  Modal,
  ModalHeader,
  Input,
  Button,
  Spinner,
  Label,
  Container,
  Form,
} from "reactstrap";
import DeleteModal from "../../Components/Common/DeleteModal";
import { registerPlugin } from "react-filepond";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteattachmentation,
  editattachmentAsync,
  getattachmentAsync,
} from "../../slices/attachment";
import { toast } from "react-toastify";
import "filepond/dist/filepond.min.css";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import { useSearchParams } from "react-router-dom";
import { DataService } from "../../helpers/dataService/dataService";
import { TranslationList } from "../../slices/settings/listtranslation";
import Loader from "../../Components/Spinner";
import { currentapplicAsync } from "../../slices/application";
export default function Document() {
  const [current, setCurrent] = useState();
  const [deleteModal, setDeleteModal] = useState(false);
  const [modal_varying1, setmodal_varying1] = useState(false);
  const [files, setFiles] = useState();
  const [title, setTitle] = useState();
  const [data, setData] = useState();
  const [idFile, setIDFile] = useState();
  const [KeyFile, setKeyFile] = useState(1);
  const [notes, setNotes] = useState();
  const [historyDoc, setHistoryDoc] = useState();
  const profile = useSelector((state) => state.profile?.data);
  registerPlugin(
    FilePondPluginFileValidateSize,
    FilePondPluginFileValidateType
  );
  const [loading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const documents = useSelector((state) => state.document.data?.results);
  const application = useSelector((state) => state.applic.current);
  const dispatch = useDispatch();
  const getdata = async () => {
    if (searchParams.get("show_id")) {
      const response = await getattachmentAsync(
        `attachment?application=${searchParams.get("show_id")}`
      );
      setData(response);
      const res = await DataService.get(
        `attachment-history/?application=${searchParams.get("show_id")}`
      );
      setHistoryDoc(res);
    } else {
      const response = await getattachmentAsync(
        `attachment?application=${searchParams.get("id")}`
      );
      setData(response);
      const res = await DataService.get(
        `attachment-history/?application=${searchParams.get("id")}`
      );
      setHistoryDoc(res);
    }
  };
  useEffect(() => {
    getdata();
  }, []);
  function tog_varying1() {
    setFiles();
    setmodal_varying1(!modal_varying1);
  }
  const handleDelete = async () => {
    const response = await deleteattachmentation(`attachment/${current?.id}`);
    if (response?.errorCode) {
      toast.error(TranslationList("An error has occurred!"));
    } else {
      toast.success(TranslationList("Done successfully!"));
      if (searchParams.get("show_id")) {
        dispatch(
          getattachmentAsync(
            `attachment?application=${searchParams.get("show_id")}`
          )
        );
      } else {
        dispatch(
          getattachmentAsync(`attachment?application=${searchParams.get("id")}`)
        );
      }
    }
    setDeleteModal(false);
  };
  const handlesave = async (res) => {
    setLoading(true);
    const formdata = new FormData();
    formdata.append("file", files);
    formdata.append("documents", res?.documents);
    formdata.append("application", application?.id);
    formdata.append("stage", "stageapplicant");
    formdata.append("status", "confirmed");
    const response = await editattachmentAsync(
      `attachment/${res?.id}/upload`,
      formdata
    );
    if (response?.status != 400) {
      dispatch(
        currentapplicAsync(`application/${searchParams.get("show_id")}`)
      );
      setLoading(false);
      toast.success("Success!");
      setKeyFile(res?.id);
    } else {
      toast.error("Error!");
    }
    setFiles();
    getdata();
  };
  const handleCheckFile = async (res) => {
    switch (profile?.roles[0]?.name) {
      case "Specialist":
        {
          var data = {
            status: document.getElementsByName("results")[0].checked
              ? "center_confirmed"
              : document.getElementById("inlineRadio2").value,
            text: notes,
          };
        }
        break;

      case "District Bank Manager":
        {
          var data = {
            status: document.getElementsByName("results")[0].checked
              ? "center_confirmed"
              : document.getElementById("inlineRadio2").value,
            text: notes,
          };
        }
        break;
    }

    const response = await DataService.put(`attachment/${res?.id}`, data);
    if (response?.errorCode) {
      toast.error(TranslationList("An error has occurred!"));
    } else {
      toast.success(TranslationList("Done successfully!"));
      setLoading(false);
      getdata();
    }
    setmodal_varying1(false);
  };
  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        data={current?.documents_detail?.name}
        onDeleteClick={handleDelete}
        onCloseClick={() => setDeleteModal(false)}
      />
      <Modal
        isOpen={modal_varying1}
        toggle={() => {
          tog_varying1();
        }}
        id="exampleModal"
      >
        <ModalHeader
          toggle={() => {
            tog_varying1();
          }}
        >
          <h5> {title?.documents_detail[0]?.name}</h5>
        </ModalHeader>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            handleCheckFile(title);
          }}
        >
          <div className="modal-body">
            <Container fluid>
              <div className="mb-3  d-flex justify-content-between">
                <div className="form-check form-check-inline align-item-center">
                  <Input
                    required
                    className="form-check-input me-1 "
                    type="radio"
                    name="results"
                    style={{ width: 20, height: 20 }}
                    id="inlineRadio1"
                    value="confirmed"
                  />
                  <Label
                    className="form-check-label fs-18"
                    htmlFor="inlineRadio1"
                  >
                    {TranslationList("Corresponds")}
                  </Label>
                </div>
                <div className="form-check form-check-inline">
                  <Input
                    required
                    className="form-check-input"
                    type="radio"
                    name="results"
                    id="inlineRadio2"
                    value="not_confirmed"
                    style={{ width: 20, height: 20 }}
                  />
                  <Label
                    className="form-check-label fs-18"
                    htmlFor="inlineRadio2"
                  >
                    {TranslationList("does_not_fit")}
                  </Label>
                </div>
              </div>
              <div className="invalid-feedback">
                More example invalid feedback text
              </div>

              <div className="mb-3 ">
                <label htmlFor="notes" className="col-form-label">
                  {TranslationList("Notes")}
                </label>
                <Input
                  required
                  type="textarea"
                  id="notes"
                  name="notes"
                  className="form-control"
                  onChange={(e) => setNotes(e.target.value)}
                />
                <div className="invalid-feedback">
                  More example invalid feedback text
                </div>
              </div>
            </Container>
          </div>
          <div className="modal-footer">
            <Button
              color="light"
              onClick={() => {
                setmodal_varying1(false);
              }}
            >
              {TranslationList("Cancel")}
            </Button>

            <Button color="primary" type="submit" disabled={loading}>
              {TranslationList("Send")}
            </Button>
          </div>
        </Form>
      </Modal>

      {data ? (
        <div className="live-preview">
          <div className="text-muted table-responsive">
            <Table className="text-muted table-bordered align-middle  mb-2 ">
              <thead>
                <tr>
                  <th scope="col">№</th>
                  <th scope="col">{TranslationList("Document's name")}</th>
                  <th scope="col">{TranslationList("Type")}</th>

                  {profile?.roles[0]?.name != "User" && (
                    <th scope="col">{TranslationList("Creator")}</th>
                  )}
                  {<th scope="col">{TranslationList("File name")}</th>}

                  <th scope="col" className="text-center">
                    {TranslationList("Actions")}
                  </th>
                </tr>
              </thead>
              <tbody>
                {data?.results
                  ?.filter((item) => item.stage == "stageapplicant")
                  ?.map((res, i) => (
                    <tr key={res?.id}>
                      <td className="fw-medium">{i + 1}</td>
                      <td
                        className="col-md-4"
                        style={{ wordWrap: "break-word" }}
                      >
                        {res.documents_detail?.map((res) => (
                          <div style={{ wordWrap: "break-word" }}>
                            {res?.name}
                          </div>
                        ))}
                      </td>
                      <td>
                        {res.documents_detail?.map((res) => (
                          <div>{res?.type}</div>
                        ))}
                      </td>
                      {profile?.roles[0]?.name != "User" && (
                        <td>
                          {res?.created_by_info?.map((item) => (
                            <div key={item?.id}>
                              <span>{item?.last_name} </span>
                              <span>{item?.first_name}</span>
                            </div>
                          ))}
                        </td>
                      )}
                      {profile?.roles[0]?.name == "User" &&
                      application?.stage == "stagefile" &&
                      res?.status == "not_confirmed" ? (
                        <td className="col-md-4">
                          <div key={KeyFile}>
                            <Input
                              id="customFile"
                              type="file"
                              onChange={(e) => {
                                setFiles(e.target.files[0]);
                                setIDFile(res?.id);
                              }}
                            />
                          </div>
                        </td>
                      ) : (
                        <td>{res?.file_name}</td>
                      )}

                      <td>
                        <div className="hstack gap-2 d-flex justify-content-center">
                          {files && idFile == res?.id && (
                            <button
                              className="btn btn-primary"
                              type="button"
                              onClick={() => handlesave(res)}
                              disabled={loading}
                            >
                              {loading ? (
                                <Spinner size="sm" className="me-2">
                                  {" "}
                                  Loading...{" "}
                                </Spinner>
                              ) : null}
                              {TranslationList("Save")}
                            </button>
                          )}
                          {profile?.roles[0]?.name == "Specialist" &&
                            application?.stage_center == "in_progress" &&
                            res?.status == "confirmed" &&
                            res.documents_detail[0].type != "Bank" && (
                              <button
                                className="btn  btn-soft-success remove-list"
                                onClick={() => {
                                  setTitle(res);
                                  tog_varying1();
                                }}
                              >
                                {TranslationList("Confirmation")}
                              </button>
                            )}
                          {profile?.roles[0]?.name == "District Bank Manager" &&
                            application?.stage == "stagetumbank" &&
                            res?.status == "confirmed" &&
                            res.documents_detail[0].type != "ISCAD" && (
                              <button
                                className="btn  btn-soft-success remove-list"
                                onClick={() => {
                                  setTitle(res);
                                  tog_varying1();
                                }}
                              >
                                {TranslationList("Confirmation")}
                              </button>
                            )}

                          {res?.file && (
                            <a
                              className="btn  btn-soft-info edit-list"
                              href={res.file}
                              target="_blank"
                            >
                              <i className="ri-download-fill align-bottom" />
                            </a>
                          )}
                          {res?.status == "not_confirmed" && (
                            <div className="btn  btn-soft-danger show-list">
                              <i className="ri-close-circle-fill align-bottom" />
                            </div>
                          )}
                          {res?.status == "center_confirmed" && (
                            <div className="btn  btn-soft-success show-list">
                              <i className="ri-check-fill align-bottom" />
                            </div>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
            {data?.results?.filter((item) => item.stage != "stageapplicant")
              ?.length > 0 ? (
              <Table className="text-muted table-bordered align-middle  mb-2 ">
                <thead>
                  <tr>
                    <th scope="col">№</th>
                    <th scope="col">{TranslationList("Creator")}</th>
                    <th scope="col">{TranslationList("Notes")}</th>
                    <th scope="col">{TranslationList("File name")}</th>
                    <th scope="col" className="text-center">
                      {TranslationList("Actions")}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {data?.results
                    ?.filter((item) => item.stage != "stageapplicant")
                    ?.map((res, i) => (
                      <tr key={res?.id + "!stageapplicant"}>
                        <td className="fw-medium">{i + 1}</td>

                        <td>
                          {res?.created_by_info?.map((item) => (
                            <div key={item?.id}>
                              <span>{item?.last_name} </span>
                              <span>{item?.first_name}</span>
                            </div>
                          ))}
                        </td>

                        <td
                          className="col-md-4"
                          style={{ wordWrap: "break-word" }}
                        >
                          <div style={{ wordWrap: "break-word" }}>
                            {res?.text}
                          </div>
                        </td>

                        <td>{res?.file_name}</td>

                        <td>
                          <div className="hstack gap-2 d-flex justify-content-center">
                            {res?.file && (
                              <a
                                className="btn  btn-soft-info edit-list"
                                href={res.file}
                                target="_blank"
                              >
                                <i className="ri-download-fill align-bottom" />
                              </a>
                            )}
                            {res?.status == "not_confirmed" && (
                              <div className="btn  btn-soft-danger show-list">
                                <i className="ri-close-circle-fill align-bottom" />
                              </div>
                            )}
                            {res?.status == "center_confirmed" && (
                              <div className="btn  btn-soft-success show-list">
                                <i className="ri-check-fill align-bottom" />
                              </div>
                            )}
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            ) : (
              ""
            )}
            {historyDoc?.results?.length > 0 ? (
              <div>
                <h5>{TranslationList("Document history")}</h5>
                <Table className="text-muted table-bordered align-middle table-nowrap mb-0 ">
                  <thead>
                    <tr>
                      <th scope="col">№</th>
                      <th scope="col">{TranslationList("Document's name")}</th>
                      <th scope="col">
                        {TranslationList("Name of the organization")}
                      </th>

                      <th scope="col">{TranslationList("Creator")}</th>
                      <th scope="col">{TranslationList("Description")}</th>

                      <th scope="col" className="text-center">
                        {TranslationList("Actions")}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {historyDoc?.results?.map((res, i) => (
                      <tr key={res?.id + "historyDoc"}>
                        <td className="fw-medium">{i + 1}</td>
                        <td style={{ wordWrap: "break-word" }}>
                          <div style={{ wordWrap: "break-word" }}>
                            {res.document_detail?.name}
                          </div>
                        </td>
                        <td>{res?.organizationinfo?.name}</td>
                        <td>
                          <div>
                            <span>{res?.created_by_info?.last_name} </span>
                            <span>{res?.created_by_info?.first_name}</span>
                          </div>
                        </td>
                        <td>{res?.description}</td>
                        <td>
                          <div className="hstack gap-2 d-flex justify-content-center">
                            {res?.file && (
                              <a
                                className="btn  btn-soft-info edit-list"
                                href={res.file}
                                target="_blank"
                              >
                                <i className="ri-download-fill align-bottom" />
                              </a>
                            )}
                            {res?.status == "not_confirmed" && (
                              <div className="btn  btn-soft-danger show-list">
                                <i className="ri-close-circle-fill align-bottom" />
                              </div>
                            )}
                            {res?.status == "confirmed" && (
                              <div className="btn  btn-soft-success show-list">
                                <i className="ri-check-fill align-bottom" />
                              </div>
                            )}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </React.Fragment>
  );
}
