import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionItem,
  Collapse,
  Container,
  FormGroup,
  Row,
  Label,
  Table,
  Col,
} from "reactstrap";
import classnames from "classnames";
import PersonalDataShow from "./PersonalDataShow";
import Document from "./Document";
import { useDispatch, useSelector } from "react-redux";
import { TranslationList } from "../../slices/settings/listtranslation";
import { useSearchParams } from "react-router-dom";
import { getsignatoryAsync } from "../../slices/chronology";
import dateFormat from "dateformat";
import { getattachmentAsync } from "../../slices/attachment";
import { api } from "../../config";
const ApplicationStep = () => {
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const [borderCol1, setborderCol1] = useState(false);
  const [borderCol2, setborderCol2] = useState(false);
  const [borderCol3, setborderCol3] = useState(false);
  const [borderCol31, setborderCol31] = useState(false);
  const [borderCol32, setborderCol32] = useState(false);

  const [borderCol4, setborderCol4] = useState(false);
  const [borderCol21, setborderCol21] = useState(false);
  const application = useSelector((state) => state.applic.current);
  const signatory = useSelector((state) => state.chronology?.signatory);
  const [stateTum, setStateTum] = useState();
  const [stateRes, setStateRes] = useState();
  const [statecen, setStatesen] = useState();
  const [stateMar, setStateMar] = useState();

  const getdata = async () => {
    // const response = await getattachmentAsync(
    //   `attachment?application=${searchParams.get("show_id")}&stage=stage3`
    // );
    // setStateTum(response?.results);
    // const response2 = await getattachmentAsync(
    //   `attachment?application=${searchParams.get("show_id")}&stage=stage5`
    // );
    // setStateRes(response2?.results);
    // const response4 = await getattachmentAsync(
    //   `attachment?application=${searchParams.get(
    //     "show_id"
    //   )}&stage=center_stage3`
    // );
    // setStateMar(response4?.results);
  };
  useEffect(() => {
    getdata();
  }, []);
  useEffect(() => {
    if (searchParams.get("show_id")) {
      dispatch(
        getsignatoryAsync(
          `signatory/?application=${searchParams.get("show_id")}`
        )
      );
    } else {
      dispatch(
        getsignatoryAsync(`signatory/?application=${searchParams.get("id")}`)
      );
    }
  }, []);
  const t_borderCol1 = () => {
    setborderCol1(!borderCol1);
    setborderCol2(false);
    setborderCol21(false);
    setborderCol3(false);
    setborderCol31(false);
    setborderCol32(false);
    setborderCol4(false);
  };

  const t_borderCol2 = () => {
    setborderCol2(!borderCol2);
    setborderCol1(false);
    setborderCol31(false);
    setborderCol32(false);

    setborderCol3(false);
    setborderCol21(false);
    setborderCol4(false);
  };
  const t_borderCol21 = () => {
    setborderCol21(!borderCol21);
    setborderCol2(false);
    setborderCol31(false);
    setborderCol32(false);

    setborderCol1(false);
    setborderCol3(false);
    setborderCol4(false);
  };

  const t_borderCol3 = () => {
    setborderCol3(!borderCol3);
    setborderCol21(false);
    setborderCol31(false);
    setborderCol32(false);

    setborderCol1(false);
    setborderCol2(false);
    setborderCol4(false);
  };
  const t_borderCol31 = () => {
    setborderCol31(!borderCol31);
    setborderCol3(false);
    setborderCol32(false);
    setborderCol21(false);
    setborderCol1(false);
    setborderCol2(false);
    setborderCol4(false);
  };
  const t_borderCol32 = () => {
    setborderCol32(!borderCol32);
    setborderCol3(false);
    setborderCol31(false);
    setborderCol21(false);
    setborderCol1(false);
    setborderCol2(false);
    setborderCol4(false);
  };
  const t_borderCol4 = () => {
    setborderCol4(!borderCol4);
    setborderCol1(false);
    setborderCol2(false);
    setborderCol21(false);
    setborderCol3(false);
    setborderCol31(false);
    setborderCol32(false);
  };

  return (
    <Container fluid>
      <h6>{TranslationList("Application chranology map")}</h6>
      <Row>
        {/* <div className="live-preview mb-2">
          <Accordion
            className="custom-accordionwithicon custom-accordion-border accordion-border-box accordion-success "
            id="accordionBordered4"
          >
            <AccordionItem>
              <h2 className="accordion-header" id="accordionborderedExample4">
                <button
                  className={classnames("accordion-button fw-semibold", {
                    collapsed: !borderCol4,
                  })}
                  type="button"
                  onClick={t_borderCol4}
                  style={{ cursor: "pointer" }}
                >
                  {TranslationList("Stage of formation of the application")}{" "}
                </button>
              </h2>
              <Collapse
                isOpen={borderCol4}
                className="accordion-collapse"
                id="accor_borderedExamplecollapse3"
              >
                <div className="accordion-body">
                  <PersonalDataShow />
                  <Document />
                </div>
              </Collapse>
            </AccordionItem>
          </Accordion>
        </div> */}
        <div className="col-6">
          <div className="live-preview">
            <Accordion
              className={`custom-accordionwithicon custom-accordion-border accordion-border-box accordion-${
                application?.stage_center == "completed" ? "success" : "warning"
              } `}
              id="accordionBordered1"
            >
              <AccordionItem>
                <h2 className="accordion-header" id="accordionborderedExample1">
                  <button
                    className={classnames("accordion-button fw-semibold", {
                      collapsed: !borderCol1,
                    })}
                    type="button"
                    onClick={t_borderCol1}
                    style={{ cursor: "pointer" }}
                  >
                    {TranslationList(
                      "Screening stage by the reception department of the Center"
                    )}{" "}
                  </button>
                </h2>

                <Collapse
                  isOpen={borderCol1}
                  className="accordion-collapse"
                  id="accor_borderedExamplecollapse1"
                >
                  <div className="accordion-body">
                    <div>
                      {/* <h6>Подписант заявления</h6> */}

                      <div className="live-preview mt-3 mb-3">
                        <div className="text-muted table-responsive">
                          <Table className="text-muted table-bordered align-middle table-nowrap mb-0 ">
                            <thead>
                              <tr>
                                <th scope="col">№</th>
                                <th scope="col">{TranslationList("Result")}</th>
                                {/* <th scope="col">Примечания</th> */}
                                <th scope="col">{TranslationList("Term")}</th>
                                <th scope="col">
                                  {TranslationList("Executor")}
                                </th>
                                <th scope="col">
                                  {TranslationList("The action of time")}
                                </th>
                                <th scope="col">{TranslationList("File")}</th>

                                <th scope="col"></th>
                              </tr>
                            </thead>
                            <tbody>
                              {signatory?.results?.map((res, i) => (
                                <tr key={res?.id}>
                                  <td className="fw-medium">{i + 1}</td>
                                  <td>{TranslationList(res?.stage_center)}</td>
                                  {/* <td>{res?.notes}</td> */}
                                  <td>
                                    {dateFormat(res?.deadline, "dd.mm.yyyy")}
                                  </td>
                                  <td>
                                    {res?.user_detail?.first_name}{" "}
                                    {res?.user_detail?.last_name}
                                  </td>
                                  <td>
                                    {res?.time_viewed &&
                                      dateFormat(
                                        res?.deadline,
                                        "dd.mm.yyyy HH:MM"
                                      )}
                                  </td>
                                  <td>
                                    {res?.attachments?.map((item) => (
                                      <div key={item?.id}>
                                        <a
                                          href={api?.FILE_URL + item?.file}
                                          target="_blank"
                                        >
                                          {/* {item?.file_name} */}
                                          <i className="ri-download-fill align-bottom" />
                                        </a>
                                        <br />
                                      </div>
                                    ))}
                                  </td>
                                  <td>
                                    {res?.time_viewed ? (
                                      <i className=" ri-eye-line"></i>
                                    ) : (
                                      <i
                                        className=" ri-eye-off-line
                          "
                                      ></i>
                                    )}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        </div>
                      </div>
                    </div>
                  </div>
                </Collapse>
              </AccordionItem>
            </Accordion>
          </div>
        </div>
        <div className="col-6">
          <div className="live-preview">
            <Accordion
              className={`custom-accordionwithicon custom-accordion-border accordion-border-box accordion-${
                application?.status_stage_3 == ""
                  ? "warning"
                  : application?.status_stage_3 == "status4"
                  ? "danger"
                  : "success"
              } `}
              id="accordionBordered2"
            >
              <AccordionItem>
                <h2 className="accordion-header" id="accordionborderedExample2">
                  <button
                    className={classnames("accordion-button fw-semibold", {
                      collapsed: !borderCol2,
                    })}
                    type="button"
                    onClick={t_borderCol2}
                    style={{ cursor: "pointer" }}
                  >
                    {TranslationList("Review stage by the District Bank")}
                  </button>
                </h2>

                <Collapse
                  isOpen={borderCol2}
                  className="accordion-collapse"
                  id="accor_borderedExamplecollapse2"
                >
                  <div className="accordion-body">
                    {/* <Row>
                      <Col md="6">
                        <Label className="fw-bold">
                          {TranslationList("Status")}:
                        </Label>
                      </Col>
                      <Col md="6">
                        <p>{application?.status_stage_3}</p>
                      </Col>
                    </Row> */}
                    <Row>
                      <Col md="6">
                        <Label className="fw-bold">
                          {TranslationList("Result")}:
                        </Label>
                      </Col>
                      <Col md="6">
                        <p>{TranslationList(application?.results_stage_3)}</p>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        <FormGroup className="mb-3">
                          <Label className="fw-bold">
                            {TranslationList("Notes")}:
                          </Label>
                        </FormGroup>{" "}
                      </Col>
                      <Col md="6">
                        <p>{application?.notes_stage_3}</p>
                      </Col>
                    </Row>
                    {/* <Row>
                      <Col md="6">
                        <Label className="fw-bold">
                          {TranslationList("Type decision")}:
                        </Label>
                      </Col>
                      <Col md="6">
                        <p>{application?.type_decision_stage_3}</p>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        <Label className="fw-bold">
                          {TranslationList("Solution description")}:
                        </Label>
                      </Col>
                      <Col md="6">
                        <p>{application?.solution_description_stage_3}</p>
                      </Col>
                    </Row> */}
                    <Row>
                      <Col md="6">
                        <Label className="fw-bold">
                          {TranslationList("Dedline")}:
                        </Label>
                      </Col>
                      <Col md="6">
                        <p>
                          {dateFormat(
                            application?.deadline_stage_3,
                            "dd.mm.yyyy"
                          )}
                        </p>
                      </Col>
                    </Row>
                  </div>
                </Collapse>
              </AccordionItem>
            </Accordion>
            <Accordion
              className={`custom-accordionwithicon custom-accordion-border mt-2 accordion-border-box accordion-${
                application?.status_stage_5 == ""
                  ? "warning"
                  : application?.status_stage_5 == "status4"
                  ? "danger"
                  : "success"
              } `}
              id="accordionBordered21"
            >
              {" "}
              <AccordionItem>
                <h2 className="accordion-header" id="accordionborderedExample2">
                  <button
                    className={classnames("accordion-button fw-semibold", {
                      collapsed: !borderCol21,
                    })}
                    type="button"
                    onClick={t_borderCol21}
                    style={{ cursor: "pointer" }}
                  >
                    {TranslationList("Review stage by the Republican Bank")}{" "}
                  </button>
                </h2>

                <Collapse
                  isOpen={borderCol21}
                  className="accordion-collapse"
                  id="accor_borderedExamplecollapse2"
                >
                  <div className="accordion-body">
                    <Row>
                      <Col md="6">
                        <Label className="fw-bold">
                          {TranslationList("Result")}:
                        </Label>
                      </Col>
                      <Col md="6">
                        <p>{TranslationList(application?.results_stage_5)}</p>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        <FormGroup className="mb-3">
                          <Label className="fw-bold">
                            {TranslationList("Notes")}:
                          </Label>
                        </FormGroup>{" "}
                      </Col>
                      <Col md="6">
                        <p>{application?.notes_stage_5}</p>
                      </Col>
                    </Row>

                    <Row>
                      <Col md="6">
                        <Label className="fw-bold">
                          {TranslationList("Dedline")}:
                        </Label>
                      </Col>
                      <Col md="6">
                        <p>
                          {dateFormat(
                            application?.deadline_stage_5,
                            "dd.mm.yyyy"
                          )}
                        </p>
                      </Col>
                    </Row>
                  </div>
                </Collapse>
              </AccordionItem>
            </Accordion>
          </div>
        </div>
        <div className="live-preview mt-2">
          <Accordion
            className={`custom-accordionwithicon custom-accordion-border mt-2 accordion-border-box accordion-${
              application?.status_stage_cordinator == ""
                ? "warning"
                : application?.status_stage_cordinator == "status4"
                ? "danger"
                : "success"
            } `}
            id="accordionBordered3"
          >
            <AccordionItem>
              <h2 className="accordion-header" id="accordionborderedExample3">
                <button
                  className={classnames("accordion-button fw-semibold", {
                    collapsed: !borderCol31,
                  })}
                  type="button"
                  onClick={t_borderCol31}
                  style={{ cursor: "pointer" }}
                >
                  {TranslationList(
                    "View stage Coordinator by department of the Center"
                  )}{" "}
                </button>
              </h2>
              <Collapse
                isOpen={borderCol31}
                className="accordion-collapse"
                id="accor_borderedExamplecollapse3"
              >
                <div className="accordion-body">
                  <Row>
                    <Col md="6">
                      <Label className="fw-bold">
                        {TranslationList("Result")}:
                      </Label>
                    </Col>
                    <Col md="6">
                      <p>{application?.status_stage_cordinator}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6">
                      <FormGroup className="mb-3">
                        <Label className="fw-bold">
                          {TranslationList("Notes")}:
                        </Label>
                      </FormGroup>{" "}
                    </Col>
                    <Col md="6">
                      <p>{application?.description_cordinator}</p>
                    </Col>
                  </Row>

                  <Row>
                    <Col md="6">
                      <Label className="fw-bold">
                        {TranslationList("Dedline")}:
                      </Label>
                    </Col>
                    <Col md="6">
                      <p>
                        {dateFormat(
                          application?.deadline_direktor,
                          "dd.mm.yyyy"
                        )}
                      </p>
                    </Col>
                  </Row>
                </div>
              </Collapse>
            </AccordionItem>
          </Accordion>
        </div>
        <div className="live-preview mt-2">
          <Accordion
            className={`custom-accordionwithicon custom-accordion-border mt-2 accordion-border-box accordion-${
              application?.status_stage_direktor == ""
                ? "warning"
                : application?.status_stage_direktor == "status4"
                ? "danger"
                : "success"
            } `}
            id="accordionBordered32"
          >
            <AccordionItem>
              <h2 className="accordion-header" id="accordionborderedExample32">
                <button
                  className={classnames("accordion-button fw-semibold", {
                    collapsed: !borderCol32,
                  })}
                  type="button"
                  onClick={t_borderCol32}
                  style={{ cursor: "pointer" }}
                >
                  {TranslationList(
                    "View stage Director by department of the Center"
                  )}{" "}
                </button>
              </h2>
              <Collapse
                isOpen={borderCol32}
                className="accordion-collapse"
                id="accor_borderedExamplecollapse3"
              >
                <div className="accordion-body">
                  <Row>
                    <Col md="6">
                      <Label className="fw-bold">
                        {TranslationList("Result")}:
                      </Label>
                    </Col>
                    <Col md="6">
                      <p>{application?.status_stage_direktor}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6">
                      <FormGroup className="mb-3">
                        <Label className="fw-bold">
                          {TranslationList("Notes")}:
                        </Label>
                      </FormGroup>{" "}
                    </Col>
                    <Col md="6">
                      <p>{application?.description_direktor}</p>
                    </Col>
                  </Row>

                  <Row>
                    <Col md="6">
                      <Label className="fw-bold">
                        {TranslationList("Dedline")}:
                      </Label>
                    </Col>
                    <Col md="6">
                      <p>
                        {dateFormat(
                          application?.deadline_ekonomist,
                          "dd.mm.yyyy"
                        )}
                      </p>
                    </Col>
                  </Row>
                </div>
              </Collapse>
            </AccordionItem>
          </Accordion>
        </div>
        <div className="live-preview mt-2">
          <Accordion
            className={`custom-accordionwithicon custom-accordion-border mt-2 accordion-border-box accordion-${
              application?.status_stage_ekonomist == ""
                ? "warning"
                : application?.status_stage_ekonomist == "status4"
                ? "danger"
                : "success"
            } `}
            id="accordionBordered33"
          >
            <AccordionItem>
              <h2 className="accordion-header" id="accordionborderedExample33">
                <button
                  className={classnames("accordion-button fw-semibold", {
                    collapsed: !borderCol3,
                  })}
                  type="button"
                  onClick={t_borderCol3}
                  style={{ cursor: "pointer" }}
                >
                  {TranslationList(
                    "View stage Manage by department of the Center"
                  )}{" "}
                </button>
              </h2>
              <Collapse
                isOpen={borderCol3}
                className="accordion-collapse"
                id="accor_borderedExamplecollapse3"
              >
                <div className="accordion-body">
                  <Row>
                    <Col md="6">
                      <Label className="fw-bold">
                        {TranslationList("Result")}:
                      </Label>
                    </Col>
                    <Col md="6">
                      <p>{application?.status_stage_ekonomist}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6">
                      <FormGroup className="mb-3">
                        <Label className="fw-bold">
                          {TranslationList("Notes")}:
                        </Label>
                      </FormGroup>{" "}
                    </Col>
                    <Col md="6">
                      <p>{application?.description_ekonomist}</p>
                    </Col>
                  </Row>

                  {/* <Row>
                    <Col md="6">
                      <Label className="fw-bold">
                        {TranslationList("Dedline")}:
                      </Label>
                    </Col>
                    <Col md="6">
                      <p>
                        {dateFormat(
                          application?.deadline_center_stage_3,
                          "dd.mm.yyyy"
                        )}
                      </p>
                    </Col>
                  </Row> */}
                </div>
              </Collapse>
            </AccordionItem>
          </Accordion>
        </div>
      </Row>
    </Container>
  );
};

export default ApplicationStep;
