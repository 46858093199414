import React, { useEffect, useState } from "react";
import {
  CardBody,
  Col,
  Container,
  CardHeader,
  Card,
  Row,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  Input,
} from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { withTranslation } from "react-i18next";
import BasicTables from "../Tables/DataTables/Tables";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getapplicAsync } from "../../slices/application";
import { TranslationList } from "../../slices/settings/listtranslation";
import Select from "react-select";
import { getsectorAsync } from "../../slices/sector";
import LoaderContent from "../../Components/Spinner";
import { getprofileAsync } from "../../slices/auth/profile";
import { getnotificationAsync } from "../../slices/notification";

const Applications = ({ t }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [currentValue, setCurrentValue] = useState();
  const [modal_varying1, setmodal_varying1] = useState(false);
  const application = useSelector((state) => state.applic?.data);
  const lang = useSelector((state) => state.lang.data);
  const sector = useSelector((state) => state.sector?.data?.results);

  useEffect(() => {
    if (sessionStorage.getItem("authUser")) {
      dispatch(getapplicAsync(`application/?page=${1}&limit=${10}`));
      dispatch(getsectorAsync("sector/"));
      dispatch(getprofileAsync("user-view/"));
      dispatch(getnotificationAsync("notification/?is_read=0"));
    }
  }, [lang]);
  const profile = useSelector((state) => state.profile?.data);
  document.title = `INVESTCONTROL | ${TranslationList("Applications")}`;
  function tog_varying1() {
    setmodal_varying1(!modal_varying1);
  }

  return (
    <React.Fragment>
      <Modal
        size="lg"
        isOpen={modal_varying1}
        toggle={() => {
          tog_varying1();
        }}
        id="exampleModal"
      >
        <ModalHeader
          toggle={() => {
            tog_varying1();
          }}
        >
          <h5>{TranslationList("Select sector")} </h5>
        </ModalHeader>
        <div className="modal-body">
          <ol className="list-group list-group-numbered">
            {sector?.map((res) => (
              <li
                className="list-group-item cursor-pointer fs-16"
                onClick={() => navigate(`/projectslist?id=${res.id}`)}
                key={res?.id}
              >
                {res?.name}
              </li>
            ))}
          </ol>
        </div>
      </Modal>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            title={TranslationList("REGISTER OF APPLICATIONS")}
            pageTitle={TranslationList("Applications")}
          />
          <Row>
            <Col xs={12}>
              <Card>
                <CardHeader>
                  <Row className="align-items-center gy-3">
                    <div className="col-sm">
                      <h5 className="card-title mb-0">
                        {TranslationList("My statements")}
                      </h5>
                    </div>
                    <div className="col-sm-auto">
                      <div className="d-flex gap-1 flex-wrap">
                        {profile?.organization &&
                          profile?.roles[0]?.name == "User" && (
                            <button
                              type="button"
                              className="btn btn-success add-btn"
                              id="create-btn"
                              // onClick={tog_varying1}
                              onClick={() => navigate("/create-application")}
                            >
                              <i className="ri-add-line align-bottom me-1"></i>{" "}
                              {TranslationList("New statement")}
                            </button>
                          )}
                      </div>
                    </div>
                  </Row>
                </CardHeader>
                {application ? <BasicTables /> : <LoaderContent />}
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withTranslation()(Applications);
