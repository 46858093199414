import React, { useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import "./style.scss";
import { TranslationList } from "../../slices/settings/listtranslation";
import Projects from "./Projects";
import TopCreator from "./TopCreator ";
import { useDispatch, useSelector } from "react-redux";
import { getsectorListAsync } from "../../slices/sector";
import { getprojectAsync } from "../../slices/project";
import SendApplication from "./SendApplication";
import "aos/dist/aos.css";
const Home = () => {
  const lang = useSelector((state) => state.lang.data);
  window.onscroll = function () {
    scrollFunction();
  };
  const scrollFunction = () => {
    const element = document.getElementById("back-to-top");
    if (element) {
      if (
        document.body.scrollTop > 100 ||
        document.documentElement.scrollTop > 100
      ) {
        element.style.display = "block";
      } else {
        element.style.display = "none";
      }
    }
  };
  const dispatch = useDispatch();
  const toTop = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  useEffect(() => {
    dispatch(getsectorListAsync("sector-list/"));
    dispatch(getprojectAsync("projects/list"));
  }, [lang]);
  return (
    <React.Fragment>
      <section className="section nft-hero mmm" id="hero">
        <div className="bg-custom"></div>
        <Container>
          <Row className="justify-content-center">
            <Col lg={10} sm={10}>
              <div className="text-center">
                <h6 className=" mb-4 text-white myClass">
                  {TranslationList("homedesc")}
                </h6>
                <h6 className=" mb-4 text-white myClass">
                  {TranslationList("homedesc_info")}
                </h6>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {/* <Sectors data={sectors} /> */}
      <Projects />
      <TopCreator />
      <SendApplication />
    </React.Fragment>
  );
};

export default Home;
