import React, { useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import { TranslationList } from "../../slices/settings/listtranslation";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/pagination";
//import Images

import img01 from "../../assets/images/nft/creators/img-01.jpg";
import img02 from "../../assets/images/nft/creators/img-02.jpg";
import img03 from "../../assets/images/nft/creators/img-04.jpg";
import img04 from "../../assets/images/nft/gif/img-4.gif";
import img05 from "../../assets/images/nft/creators/img-05.jpg";
import img06 from "../../assets/images/nft/img-06.jpg";
import part from "../../assets/images/part.jpg";
import gifImg01 from "../../assets/images/nft/gif/img-1.gif";
import bank from "../../assets/images/landing/world-bank.png";
import kfw from "../../assets/images/landing/Logo_KfW_300dpi.png";
import { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import Aos from "aos";
const TopCreator = () => {
  useEffect(() => {
    Aos.init();
  }, []);
  const topCreatorData = [
    {
      id: 1,
      img: img01,
    },
    {
      id: 2,
      img: img02,
    },
    {
      id: 3,
      img: bank,
    },
    {
      id: 4,
      img: img05,
    },
    {
      id: 5,
      img: kfw,
    },
  ];
  return (
    <React.Fragment>
      <section className="section bg-light" id="creators">
        <Container>
          <Row className="justify-content-center">
            <Col lg={8}>
              <div className="text-center mb-5">
                <h2 className="mb-3 fw-bold lh-base">
                  {TranslationList("PARTNERS")}
                </h2>
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg={12}>
              <div className="text-center mt-5">
                <Swiper
                  slidesPerView={4}
                  spaceBetween={30}
                  pagination={{
                    clickable: false,
                  }}
                  breakpoints={{
                    576: {
                      slidesPerView: 2,
                    },
                    768: {
                      slidesPerView: 3,
                    },
                    1024: {
                      slidesPerView: 4,
                    },
                  }}
                  loop={true}
                  autoplay={{ delay: 3000, disableOnInteraction: false }}
                  modules={[Autoplay]}
                  className="mySwiper swiper trusted-client-slider mt-sm-5 mt-4 mb-sm-5 mb-4"
                >
                  {" "}
                  {topCreatorData.map((item, key) => (
                    <SwiperSlide key={key}>
                      <img
                        src={item.img}
                        alt="client-img"
                        width={200}
                        height={120}
                      />
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default TopCreator;
