import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import iscad from "../../assets/images/ISCAD-Logorb.png";
import { TranslationList } from "../../slices/settings/listtranslation";

const Footer = () => {
  const [LogoDark, setLogoDark] = useState();
  const [LogoLight, setLogoLight] = useState();
  useEffect(() => {
    const currentLanguage = localStorage.getItem("I18N_LANGUAGE_INVEST");
    if (currentLanguage == "ru") {
      setLogoLight(
        "https://www.agro.uz/wp-content/uploads/2021/10/logo-ru.svg"
      );
      setLogoDark(
        "https://www.agro.uz/wp-content/themes/seriouz-child/assets/img/logo-ru.svg"
      );
    } else {
      setLogoLight("https://www.agro.uz/wp-content/uploads/2021/10/logo1.svg");
      setLogoDark(
        "https://www.agro.uz/wp-content/themes/seriouz-child/assets/img/logo.svg"
      );
    }
  }, [localStorage.getItem("I18N_LANGUAGE_INVEST")]);
  return (
    <React.Fragment>
      <footer
        className="custom-footer bg-dark py-5 position-relative mt-auto bottom-0"
        style={{ bottom: 0, marginTop: "auto" }}
      >
        <Container>
          <Row>
            <Col lg={4} className="mt-4">
              <div>
                <div>
                  <img src={LogoLight} alt="logo light" width={250} />
                  <img src={iscad} alt="" width={250} height={80} />
                </div>
              </div>
            </Col>
            <Col lg={7} className="ms-lg-auto">
              <Row>
                {/* <Col sm={4} className="mt-4">
                  <h5 className="text-white mb-0">
                    {TranslationList("Company")}
                  </h5>
                  <div className="text-muted mt-3">
                    <ul className="list-unstyled ff-secondary footer-list">
                      <li>
                        <Link to="/pages-profile">
                          {TranslationList("About")}
                        </Link>
                      </li>
                      <li>
                        <Link to="/pages-gallery">
                          {TranslationList("Project")}
                        </Link>
                      </li>
                    </ul>
                  </div>
                </Col> */}
                {/* <Col sm={4} className="mt-4">
                  <h5 className="text-white mb-0">
                    {TranslationList("Apps Pages")}
                  </h5>
                  <div className="text-muted mt-3">
                    <ul className="list-unstyled ff-secondary footer-list">
                      <li>
                        <Link to="/pages-pricing">
                          {TranslationList("New")}
                        </Link>
                      </li>
                      <li>
                        <Link to="/apps-chat">{TranslationList("Chat")}</Link>
                      </li>
                    </ul>
                  </div>
                </Col>
                <Col sm={4} className="mt-4">
                  <h5 className="text-white mb-0">
                    {TranslationList("Support")}
                  </h5>
                  <div className="text-muted mt-3">
                    <ul className="list-unstyled ff-secondary footer-list">
                      <li>
                        <Link to="/pages-faqs">FAQ</Link>
                      </li>
                      <li>
                        <Link to="/pages-faqs">
                          {TranslationList("Contact")}
                        </Link>
                      </li>
                    </ul>
                  </div>
                </Col> */}
              </Row>
            </Col>
          </Row>

          <Row className="text-center text-sm-start align-items-center mt-5">
            <Col sm={6}>
              <div>
                <p className="copy-rights mb-0">
                  {new Date().getFullYear()} © Kibera Technalogy
                </p>
              </div>
            </Col>
            <Col sm={6}>
              <div className="text-sm-end mt-3 mt-sm-0">
                <ul className="list-inline mb-0 footer-social-link">
                  <li className="list-inline-item">
                    <Link to="#" className="avatar-xs d-block">
                      <div className="avatar-title rounded-circle">
                        <i className="ri-facebook-fill"></i>
                      </div>
                    </Link>
                  </li>

                  <li className="list-inline-item">
                    <Link to="#" className="avatar-xs d-block">
                      <div className="avatar-title rounded-circle">
                        <i className="ri-linkedin-fill"></i>
                      </div>
                    </Link>
                  </li>
                  <li className="list-inline-item">
                    <Link to="#" className="avatar-xs d-block">
                      <div className="avatar-title rounded-circle">
                        <i className="ri-google-fill"></i>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  );
};

export default Footer;
