import React, { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { DataService } from "../../helpers/dataService/dataService";
import "./style.css";

export default function PdfShow({ data, report, signers }) {
  const [economist, seteconomist] = useState();
  const [director, setDirector] = useState();
  const navigate = useNavigate();

  const getData = async () => {
    try {
      const response = await DataService.get(`user/?limit=1000`);
      let economist = response?.results?.filter((user) =>
        user?.roles[0]?.name?.includes("Finansist")
      );
      seteconomist(economist);
      let director = response?.results?.filter(
        (user) =>
          user?.roles[0]?.name?.includes("Director") ||
          user?.roles[0]?.name?.includes("Deputy Director")
      );
      setDirector(director);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getData();

    let sonElementlar = document.querySelectorAll('[id="son"]');
    sonElementlar.forEach(function (element) {
      let sonMatn = element.innerText;
      let formattedSon = sonMatn.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      element.innerText = formattedSon;
    });
  }, []);

  function downloadPDF() {
    const linkSource = `data:application/pdf;base64,${report?.file}`;
    const downloadLink = document.createElement("a");
    const fileName = "file.pdf";
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  return (
    <div className="justify-content-center">
      <iframe
        src={`data:application/pdf;base64,${report?.file}`}
        width="100%"
        frameborder="0"
        style={{ height: "68vh" }}
      >
        You can{" "}
        <a href={`data:application/pdf;base64,${report?.file}`}>
          download the PDF file
        </a>{" "}
        instead.
      </iframe>
    </div>
  );
}
