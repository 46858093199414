import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import withRouter from "../Components/Common/withRouter";

//import Components
import Header from "./Header";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import RightSidebar from "../Components/Common/RightSidebar";

//import actions
import {
  changeLayout,
  changeSidebarTheme,
  changeLayoutMode,
  changeLayoutWidth,
  changeLayoutPosition,
  changeTopbarTheme,
  changeLeftsidebarSizeType,
  changeLeftsidebarViewType,
  changeSidebarImageType,
  changeSidebarVisibility,
} from "../slices/thunks";

//redux
import { useSelector, useDispatch } from "react-redux";
import { getprofileAsync } from "../slices/auth/profile";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  getTokenOneId,
  getTokenOneIdForBack,
} from "../helpers/integration/oneId";
import { DataService } from "../helpers/dataService/dataService";
import { getnotificationAsync } from "../slices/notification";
import LoaderContent from "../Components/Spinner";
import { toast } from "react-toastify";
import { TranslationList } from "../slices/settings/listtranslation";

const Layout = (props) => {
  const [headerClass, setHeaderClass] = useState("");
  const dispatch = useDispatch();
  const {
    layoutType,
    leftSidebarType,
    layoutModeType,
    layoutWidthType,
    layoutPositionType,
    topbarThemeType,
    leftsidbarSizeType,
    leftSidebarViewType,
    leftSidebarImageType,
    sidebarVisibilitytype,
  } = useSelector((state) => ({
    layoutType: state.Layout.layoutType,
    leftSidebarType: state.Layout.leftSidebarType,
    layoutModeType: state.Layout.layoutModeType,
    layoutWidthType: state.Layout.layoutWidthType,
    layoutPositionType: state.Layout.layoutPositionType,
    topbarThemeType: state.Layout.topbarThemeType,
    leftsidbarSizeType: state.Layout.leftsidbarSizeType,
    leftSidebarViewType: state.Layout.leftSidebarViewType,
    leftSidebarImageType: state.Layout.leftSidebarImageType,
    sidebarVisibilitytype: state.Layout.sidebarVisibilitytype,
  }));

  /*
    layout settings
    */
  useEffect(() => {
    if (
      layoutType ||
      leftSidebarType ||
      layoutModeType ||
      layoutWidthType ||
      layoutPositionType ||
      topbarThemeType ||
      leftsidbarSizeType ||
      leftSidebarViewType ||
      leftSidebarImageType ||
      sidebarVisibilitytype
    ) {
      window.dispatchEvent(new Event("resize"));
      dispatch(changeLeftsidebarViewType(leftSidebarViewType));
      dispatch(changeLeftsidebarSizeType(leftsidbarSizeType));
      dispatch(changeSidebarTheme(leftSidebarType));
      dispatch(changeLayoutMode(layoutModeType));
      dispatch(changeLayoutWidth(layoutWidthType));
      dispatch(changeLayoutPosition(layoutPositionType));
      dispatch(changeTopbarTheme(topbarThemeType));
      dispatch(changeLayout(layoutType));
      dispatch(changeSidebarImageType(leftSidebarImageType));
      dispatch(changeSidebarVisibility(sidebarVisibilitytype));
    }
  }, [
    layoutType,
    leftSidebarType,
    layoutModeType,
    layoutWidthType,
    layoutPositionType,
    topbarThemeType,
    leftsidbarSizeType,
    leftSidebarViewType,
    leftSidebarImageType,
    sidebarVisibilitytype,
    dispatch,
  ]);
  const [searchParams] = useSearchParams();
  const onChangeLayoutMode = (value) => {
    if (changeLayoutMode) {
      dispatch(changeLayoutMode(value));
    }
  };
  const navigate = useNavigate();
  // class add remove in header
  useEffect(() => {
    window.addEventListener("scroll", scrollNavigation, true);
  });
  function scrollNavigation() {
    var scrollup = document.documentElement.scrollTop;
    if (scrollup > 50) {
      setHeaderClass("topbar-shadow");
    } else {
      setHeaderClass("");
    }
  }

  useEffect(() => {
    if (
      sidebarVisibilitytype === "show" ||
      layoutType === "vertical" ||
      layoutType === "twocolumn"
    ) {
      document.querySelector(".hamburger-icon")?.classList.remove("open");
    } else {
      document.querySelector(".hamburger-icon")?.classList?.add("open");
    }
  }, [sidebarVisibilitytype, layoutType]);

  useEffect(() => {
    if (searchParams.get("code") && searchParams.get("state") == "testState") {
      getTokenOneIdForBack(
        searchParams.get("code"),
        "https://fr-invest.samar.uz/subprojects"
      )
        .then(async (res) => {
          try {
            const data = await DataService.postNoAuth("auth/token/login", {
              grant_type: "one_access_token_identify",
              client_id: "invest_iscad_uz",
              client_secret: "JZWEM26e8Y4lT70wObP7gQRh",
              access_token: res?.access_token,
              scope: res?.scope,
              redirect_uri: "https://fr-invest.samar.uz/subprojects",
            });
            if (data) {
              sessionStorage.setItem("authUser", JSON.stringify(data));
              dispatch(getprofileAsync("user-view/"));
              dispatch(getnotificationAsync("notification/?is_read=0"));
              navigate(`/applications`);
              localStorage.removeItem("userInfo");
              localStorage.removeItem("applicationId");
            }
          } catch (error) {
            toast.error(TranslationList(error?.message));
            navigate(`/`);
          }
        })
        .catch((error) => {
          localStorage.removeItem("applicationId");
        });
    }
    if (sessionStorage.getItem("authUser")) {
      dispatch(getprofileAsync("user-view/"));
      dispatch(getnotificationAsync("notification/?is_read=0"));
    }
  }, [searchParams.get("code")]);
  if (sessionStorage.getItem("authUser"))
    return (
      <React.Fragment>
        <div id="layout-wrapper">
          <Header
            headerClass={headerClass}
            layoutModeType={layoutModeType}
            onChangeLayoutMode={onChangeLayoutMode}
          />
          <Sidebar layoutType={layoutType} />
          <div className="main-content">
            {props.children}
            <Footer />
          </div>
        </div>
        <RightSidebar />
      </React.Fragment>
    );
  else return <LoaderContent />;
};

Layout.propTypes = {
  children: PropTypes.object,
};

export default withRouter(Layout);
