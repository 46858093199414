import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Label,
  Input,
  FormFeedback,
  FormGroup,
  Table,
  Spinner,
  Modal,
  ModalBody,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";
import { DataService } from "../../../helpers/dataService/dataService";
import { currentapplicAsync } from "../../../slices/application";
import { TranslationList } from "../../../slices/settings/listtranslation";
import { getlandingCheck } from "../../../slices/landingApplication";
import MapChange from "./MapChange";
export default function Stage1({ nextStageSave, nextStagePut, loading }) {
  const dispatch = useDispatch();
  const [pfi, setPfi] = useState();
  const [searchParams, setSearchParams] = useSearchParams();
  const [pfibranch, setpfiBranch] = useState();
  const [region, setRegions] = useState();
  const [districtCurrent, setdistrictCurrent] = useState();
  const [organizationError, setOrganizationError] = useState(false);
  const [organizationBranchError, setOrganizationBranchError] = useState(false);
  const [regionsError, setregionsError] = useState(false);
  const [districtError, setdistrictError] = useState(false);
  const [data, setData] = useState();
  const application = searchParams.get("id");
  const applicationcheck = useSelector(
    (state) => state.landingApplication?.check
  );
  const coordinate = useSelector((state) => state.coordinate.data);

  const [check, setCheck] = useState(!applicationcheck);
  const [modal_varyingLarge, setmodal_varyingLarge] = useState(false);

  const SelectChange = async (id) => {
    try {
      const res = await DataService.getNoAuth(
        `district/list?region=${id}&limit=100`,
        true
      );
      setdistrictCurrent(res?.results);
    } catch (error) {
      console.log(error);
    }
  };
  const SelectChangeFilial = async (id) => {
    try {
      if (!check) {
        const res = await DataService.getNoAuth(
          `organization-branch/list?organization=${id}&limit=100&type=Bank&district=${
            document.getElementById("project_district").value
          }`,
          true
        );
        setpfiBranch(res?.results);
      } else {
        {
          const res = await DataService.getNoAuth(
            `organization-branch/list?organization=${id}&limit=100&type=Bank`,
            true
          );
          setpfiBranch(res?.results);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  const SelectDistrict = async (id) => {
    try {
      if (!check) {
        const res = await DataService.getNoAuth(
          `organization-branch/list?organization=${
            document.getElementById("pfi").value
          }&limit=100&type=Bank&district=${id}`,
          true
        );
        setpfiBranch(res?.results);
      } else {
        {
          const res = await DataService.getNoAuth(
            `organization-branch/list?organization=${
              document.getElementById("pfi").value
            }&limit=100&type=Bank`,
            true
          );
          setpfiBranch(res?.results);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  const SelectDistrictCheck = async (check, id) => {
    try {
      dispatch(getlandingCheck(check));

      if (!check) {
        const res = await DataService.getNoAuth(
          `organization-branch/list?organization=${
            document.getElementById("pfi").value
          }&limit=100&type=Bank&district=${id}`,
          true
        );
        setpfiBranch(res?.results);
      } else {
        {
          const res = await DataService.getNoAuth(
            `organization-branch/list?organization=${
              document.getElementById("pfi").value
            }&limit=100&type=Bank`,
            true
          );
          setpfiBranch(res?.results);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  const nextPageNumber = () => {
    if (
      !document.getElementById("pfi").value ||
      !document.getElementById("pfi_branch").value ||
      !document.getElementById("project_region").value ||
      !document.getElementById("project_district").value
    ) {
      if (!document.getElementById("pfi").value) setOrganizationError(true);
      if (!document.getElementById("pfi_branch").value)
        setOrganizationBranchError(true);
      if (!document.getElementById("project_region").value)
        setregionsError(true);
      if (!document.getElementById("project_district").value)
        setdistrictError(true);
    } else {
      const data = {
        pfi: parseInt(document.getElementById("pfi").value) ?? 0,
        pfi_branch: parseInt(document.getElementById("pfi_branch").value) ?? 0,
        project_region:
          parseInt(document.getElementById("project_region").value) ?? 0,
        project_district:
          parseInt(document.getElementById("project_district").value) ?? 0,
        coordinate_x: coordinate ? coordinate[0] : 0,
        coordinate_y: coordinate ? coordinate[1] : 0,
      };
      setOrganizationError(false);
      setOrganizationBranchError(false);
      setregionsError(false);
      setdistrictError(false);
      if (application) {
        nextStagePut(1, data);
      } else {
        nextStageSave(1, data);
      }
    }
  };
  const getData = async () => {
    try {
      const reg = await DataService.getNoAuth(`region/list?limit=100`);
      setRegions(reg?.results);
      const res1 = await DataService.getNoAuth(
        `organization/list?limit=100&type=Bank`
      );
      setPfi(res1?.results);
    } catch (error) {
      console.log(error);
    }

    if (application) {
      const response = await DataService.getNoAuth(
        `application/${application}/step1`
      );
      setData(response);
      // dispatch(currentapplicAsync(`application/${application}/step`, true));
      try {
        const res = await DataService.getNoAuth(
          `organization-branch/list?organization=${response?.pfi}&limit=100&type=Bank`
        );
        setpfiBranch(res?.results);
        const res1 = await DataService.getNoAuth(
          `district/list?region=${response?.project_region}&limit=100`
        );
        setdistrictCurrent(res1?.results);
      } catch (error) {
        console.log(error);
      }
    }
  };
  useEffect(() => {
    getData();
  }, []);
  function tog_varyingLarge() {
    setmodal_varyingLarge(!modal_varyingLarge);
  }
  return (
    <div>
      <Modal
        className="modal-xl"
        scrollable={true}
        isOpen={modal_varyingLarge}
        toggle={() => {
          tog_varyingLarge();
        }}
        id="exampleModal"
      >
        <ModalBody>
          <MapChange />
        </ModalBody>
      </Modal>
      <h6>{TranslationList("Credit")}</h6>
      <Row>
        <Col md="6">
          <FormGroup className="mb-3">
            <Label htmlFor="region">
              {TranslationList("Area where the project will be implemented")}
            </Label>
            <Input
              key={data?.project_region}
              type="select"
              className="form-select mb-3"
              aria-label="Default select example"
              id="project_region"
              onChange={(e) => SelectChange(e.target.value)}
              invalid={regionsError}
              defaultValue={data?.project_region}
            >
              <option></option>

              {region?.map((res) => (
                <option key={res.id} value={res.id}>
                  {res.name}
                </option>
              ))}
            </Input>
            <FormFeedback type="invalid">
              {TranslationList("Field is required")}
            </FormFeedback>
          </FormGroup>
        </Col>
        <Col md="6">
          <FormGroup className="mb-3">
            <Label htmlFor="pfi">{TranslationList("Participating Bank")}</Label>
            <Input
              key={data?.pfi}
              type="select"
              className="form-select mb-3"
              aria-label="Default select example"
              id="pfi"
              onChange={(e) => {
                SelectChangeFilial(e.target.value);
              }}
              invalid={organizationError}
              defaultValue={data?.pfi}
            >
              <option></option>

              {pfi?.map((res) => (
                <option key={res.id} value={res.id}>
                  {res.name}
                </option>
              ))}
            </Input>
            <FormFeedback type="invalid">
              {TranslationList("Field is required")}
            </FormFeedback>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md="6">
          <FormGroup className="mb-3">
            <Label htmlFor="statusapp">
              {TranslationList(
                "The area where the project will be implemented"
              )}
            </Label>
            <Input
              key={data?.project_district + districtCurrent?.length}
              type="select"
              className="form-select mb-3"
              aria-label="Default select example"
              id="project_district"
              defaultValue={data?.project_district}
              invalid={districtError}
              onChange={(e) => SelectDistrict(e.target.value)}
            >
              <option></option>
              {districtCurrent?.map((res) => (
                <option key={res.id} value={res.id}>
                  {res.name}
                </option>
              ))}
            </Input>
            <FormFeedback type="invalid">
              {TranslationList("Field is required")}
            </FormFeedback>
          </FormGroup>
        </Col>
        <Col md="6">
          <FormGroup className="mb-3">
            <Label htmlFor="pfi_branch">
              {TranslationList("Participating bank branch")}
            </Label>
            <Input
              key={data?.pfi_branch + pfibranch?.length}
              type="select"
              className="form-select mb-3"
              aria-label="Default select example"
              id="pfi_branch"
              invalid={organizationBranchError}
              defaultValue={data?.pfi_branch}
            >
              <option></option>
              {pfibranch?.map((res) => (
                <option key={res.id} value={res.id}>
                  {res.name}
                </option>
              ))}
            </Input>
            <FormFeedback type="invalid">
              {TranslationList("Field is required")}
            </FormFeedback>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md="6">
          <div className="form-check form-switch form-switch-lg" dir="ltr">
            <Input
              type="checkbox"
              className="form-check-input"
              id="customSwitchsizelg"
              onChange={(e) => {
                setCheck(e.target.checked);

                SelectDistrictCheck(
                  e.target.checked,
                  document.getElementById("project_district").value
                );
              }}
              defaultChecked={check}
            />
            <Label className="form-check-label" htmlFor="customSwitchsizelg">
              {TranslationList(
                "Pick up from bank branches in the same district"
              )}
            </Label>
          </div>
        </Col>
        <Col md="3">
          <FormGroup className="mb-3">
            <Label htmlFor="statusapp">
              x: {coordinate ? coordinate[0].toString().slice(0, 10) : ""}
            </Label>

            <FormFeedback type="invalid">
              {TranslationList("Field is required")}
            </FormFeedback>
          </FormGroup>
        </Col>
        <Col md="3">
          <FormGroup className="mb-3">
            <Label htmlFor="pfi_branch">
              y: {coordinate ? coordinate[1].toString().slice(0, 10) : ""}
            </Label>

            <FormFeedback type="invalid">
              {TranslationList("Field is required")}
            </FormFeedback>
          </FormGroup>
        </Col>
        {/* <Col md="1">
          <button
            className="btn btn-primary"
            type="button"
            onClick={tog_varyingLarge}
          >
            {TranslationList("Map")}
          </button>
        </Col> */}
      </Row>
      <MapChange />

      <div className=" d-flex justify-content-end mt-3">
        {loading ? (
          <button className="btn btn-primary" type="button" disabled>
            <Spinner size="sm" className="flex-shrink-0">
              Loading...
            </Spinner>
          </button>
        ) : (
          <button
            className="btn btn-primary"
            type="button"
            onClick={() => nextPageNumber("2")}
          >
            {TranslationList("Next")}
          </button>
        )}
      </div>
    </div>
  );
}
