import React, { useState, useEffect, useMemo } from "react";
import DataTable from "react-data-table-component";
import { Input, Table } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import DeleteModal from "../../../Components/Common/DeleteModal";
import ExportCSVModal from "../../../Components/Common/ExportCSVModal";
import ColumnModal from "../../../Components/Common/ColumnModal";
import { columnAsync } from "../../../slices/application/reducer";
import {
  deleteApplication,
  deleteApplicationAll,
  getapplicAsync,
} from "../../../slices/application";
import Paginations from "./Pagination";
import { ToastContainer, toast } from "react-toastify";
import dateFormat from "dateformat";
import { TranslationList } from "../../../slices/settings/listtranslation";
import LoaderContent from "../../../Components/Spinner";
import { getprofileAsync } from "../../../slices/auth/profile";
import { DataService } from "../../../helpers/dataService/dataService";
import endpoints from "../../../endpoints";
export default function BasicTables() {
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.profile?.data);
  const lan = useSelector((state) => state.lang.data);
  const [apiData, setApiData] = useState();
  const navigate = useNavigate();
  const [multiSelect, setMultiSelect] = useState();
  const columnsData = useSelector((state) => state.columnTable);
  const [isExportCSV, setIsExportCSV] = useState(false);
  const [isColumn, setIsColumn] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [currentItem, setCurrentItem] = useState();
  const [checkSelect, setCheckSelect] = useState(true);

  const fetchData = async (params) => {
    const response = await DataService.get(endpoints.application, {
      ...params,
    });
    console.log("response", response?.results);
    setApiData(response);
  };
  const handleSearch = (e) => {
    fetchData({ search: e, page: 1, limit: perPage });
  };
  const handleDelete = async () => {
    if (multiSelect) {
      const data = {
        ids: multiSelect?.map((res) => res.id),
      };
      const response = await deleteApplicationAll(
        `application/multidelete/`,
        data
      );
      if (response?.errorCode) {
        toast.error(TranslationList("An error has occurred!"));
      } else {
        toast.success(TranslationList("Done successfully!"));
        fetchData({ page: 1, limit: perPage });
        setMultiSelect();
      }
    } else {
      const response = await deleteApplication(
        `application/${currentItem?.id}`
      );

      if (response?.errorCode) {
        toast.error(TranslationList("An error has occurred!"));
      } else {
        toast.success("Success delete !");
        fetchData({ page: 1, limit: perPage });
      }
    }
    setDeleteModal(false);
  };
  const onClickTodoDelete = (todo) => {
    setMultiSelect();

    setDeleteModal(true);
    setCurrentItem(todo);
  };
  const handleSave = (data) => {
    console.log("data", data);
  };

  const toggleColumn = () => {
    setIsColumn(!isColumn);
  };

  const handleCheck = (e) => {
    if (e.target.checked) {
      setCheckSelect(false);
    } else {
      const elementsWithName = document.getElementsByName("check");
      const elementsArray = Array.from(elementsWithName);
      const checkedValues = elementsArray.filter((e) => e.checked);

      if (checkedValues.length < 1) {
        setCheckSelect(true);
        let checkall = document.getElementById("checkAll");
        checkall.checked = false;
      }
    }
  };

  const handleCheckAll = (e) => {
    if (e.target.checked) {
      setCheckSelect(false);
    } else setCheckSelect(true);
    let check = document.getElementsByClassName("checkItem");
    let checkall = document.getElementById("checkAll");
    {
      for (var i = 0; i < check.length; i++) {
        check[i].checked = checkall.checked;
      }
    }
  };
  const handleMultiDelete = () => {
    const elementsWithName = document.getElementsByName("check");
    const elementsArray = Array.from(elementsWithName);
    const checkedValues = elementsArray
      .filter((e) => e.checked) // Filter checked elements
      .map((e) => JSON.parse(e.value)); // Map the checked elements to their values
    console.log(checkedValues);
    setMultiSelect(checkedValues);
    setDeleteModal(true);
  };

  const handleStatus = (cell) => {
    switch (cell.status) {
      case "completed":
        return (
          <span className="fs-12 badge badge-soft-success">
            {TranslationList("Fulfilled")}
          </span>
        );
      case "draft":
        return (
          <span className="fs-12 badge badge-soft-warning">
            {TranslationList("Draft")}
          </span>
        );
      case "removed":
        return (
          <span className="fs-12 badge badge-soft-danger">
            {TranslationList("Accepted for processing")}
          </span>
        );
      case "in_progress":
        return (
          <span className="fs-12 badge badge-soft-warning">
            {TranslationList("In consideration")}
          </span>
        );
      case "under_consideration":
        return (
          <span className="fs-12 badge badge-soft-primary">
            {TranslationList("Under consideration")}
          </span>
        );
      case "new":
        return (
          <span className="fs-12 badge badge-soft-info">
            {TranslationList("New")}
          </span>
        );
      case "refusal":
        return (
          <span className="fs-12 badge badge-soft-danger">
            {TranslationList("refusal")}
          </span>
        );
      default:
        return (
          <span className="fs-12 badge badge-soft-success">
            {TranslationList("On completion")}
          </span>
        );
    }
  };
  const handleCenterStage = (cell) => {
    if (
      cell?.stage == "stagecordinator" ||
      cell?.stage == "stagedirektor" ||
      cell?.stage == "stageekonomist"
    ) {
      return "";
    } else {
      switch (cell?.stage_center) {
        case "in_progress":
          return (
            <span className="badge badge-soft-info">
              {TranslationList("Under review by ISCAD")}
            </span>
          );

        case "completed":
          return (
            <span className="badge badge-soft-success">
              {TranslationList("Approved by ISCAD")}
            </span>
          );
      }
    }
  };
  const handleStage = (cell) => {
    switch (cell?.stage) {
      case "stageanketa":
        return (
          <span className="fs-12 badge badge-soft-secondary">
            {TranslationList("Stage of formation of the application")}{" "}
          </span>
        );
      case "stagefile":
        return (
          <span className="fs-12 badge badge-soft-secondary">
            {TranslationList("The file is in the process of being attached")}{" "}
          </span>
        );
      case "stagecenter":
        return (
          <span className="fs-12 badge badge-soft-secondary">
            {TranslationList("Review stage by the Center")}{" "}
          </span>
        );
      case "stagetumbank":
        return (
          <span className="fs-12 badge badge-soft-warning">
            {TranslationList("Review stage by the District Bank")}
          </span>
        );
      case "stageresbank":
        if (cell?.status_stage_5 == "")
          return (
            <span className="fs-12 badge badge-soft-secondary">
              {TranslationList("Review stage by the Republican Bank")}{" "}
            </span>
          );

        if (cell?.status_stage_5 == "status3") {
          return (
            <span className="fs-12 badge badge-soft-info">
              {TranslationList("It was reviewed by the Bank")}
            </span>
          );
        }

      case "stagecordinator":
        return (
          <span className="fs-12 badge badge-soft-warning">
            {TranslationList("Review stage by the Coordinator")}
          </span>
        );
      case "stagedirektor":
        return (
          <span className="fs-12 badge badge-soft-secondary">
            {TranslationList("Review stage by the Director")}
          </span>
        );
      case "stageekonomist":
        return (
          <span className="fs-12 badge badge-soft-info">
            {TranslationList("Review stage by the Economist")}
          </span>
        );
      case "stagefinish":
        return (
          <span className="fs-12 badge badge-soft-success">
            {TranslationList("Successful done!")}
          </span>
        );
    }
  };

  useEffect(() => {
    dispatch(
      columnAsync([
        { name: TranslationList("Application number"), status: true },
        { name: TranslationList("Bank"), status: true },
        { name: TranslationList("Project description"), status: true },
        { name: TranslationList("Stage"), status: true },
        { name: TranslationList("Status"), status: true },
        { name: TranslationList("Last modified"), status: true },
      ])
    );
    dispatch(getprofileAsync("user-view/"));
    fetchData({ page: 1, limit: perPage });
  }, [lan]);

  //Pagination begin
  const perpage = localStorage.getItem("perpage") ?? 10;
  const [perPage, setPerpage] = useState(perpage);
  const handleCurrentPage = (e) => {
    fetchData({ page: e, limit: perPage });
  };
  const handlePerPageChange = (e) => {
    setPerpage(e);
    fetchData({ limit: e });
  };
  const exportData = apiData?.results?.map((res) => {
    let obj = {};
    (obj[TranslationList("Application number")] = res.nomer),
      (obj[TranslationList("Bank")] = res?.organizationinfo?.name);
    obj[TranslationList("Project description")] = res?.project_description;
    obj[TranslationList("Stage")] = Array.isArray(
      handleStage(res)?.props?.children
    )
      ? handleStage(res)?.props?.children[0]
      : handleStage(res)?.props?.children;
    obj[TranslationList("Status")] = Array.isArray(
      handleStatus(res)?.props?.children
    )
      ? handleStatus(res)?.props?.children[0]
      : handleStatus(res)?.props?.children;
    obj[TranslationList("Last modified")] = dateFormat(
      res?.updated_time,
      "dd.mm.yyyy HH:MM"
    );
    return obj;
  });
  const dedlineWarningTr = (row) => {
    switch (profile?.roles[0]?.name) {
      case "Manager of the Republic Bank":
        {
          if (
            row.deadline_stage_5 < new Date() &&
            !row.deadline_finish_stage_5
          ) {
            return "rgb(233 198 198)";
          }
        }
        break;
      case "Coordinator":
        {
          if (
            row.deadline_cordinator < new Date() &&
            !row.deadline_finish_cordinator
          ) {
            return "rgb(233 198 198)";
          }
        }
        break;
      case "Specialist":
        {
          if (
            new Date(row.deadline_center_stage_3) - new Date() < 0 &&
            !row.deadline_finish_center_stage_3
          ) {
            return "rgb(233 198 198)";
          }
        }
        break;
      case "District Bank Manager":
        {
          if (
            new Date(row.deadline_stage_3) - new Date() < 0 &&
            !row.deadline_finish_stage_3
          ) {
            return "rgb(233 198 198)";
          }
        }
        break;
      case "Director":
        {
          if (
            row.deadline_direktor < new Date() &&
            !row.deadline_finish_direktor
          ) {
            return "rgb(233 198 198)";
          }
        }
        break;

      case "Finansist":
        {
          if (
            row.deadline_ekonomist < new Date() &&
            !row.deadline_finish_ekonomist
          ) {
            return "rgb(233 198 198)";
          }
        }
        break;
    }
  };
  console.log(apiData?.results);
  return (
    <div className="border-0 shadow-none">
      <DeleteModal
        show={deleteModal}
        data={currentItem}
        multiSelect={multiSelect}
        onDeleteClick={handleDelete}
        onCloseClick={() => setDeleteModal(false)}
      />

      <ExportCSVModal
        show={isExportCSV}
        onCloseClick={() => setIsExportCSV(false)}
        data={exportData}
        fileName={`${TranslationList("Applications")}_${dateFormat(
          new Date(),
          "dd.mm.yyyy_HH:MM:ss"
        )}`}
      />

      <ColumnModal
        show={isColumn}
        handleSaveColumn={handleSave}
        onCloseClick={() => setIsColumn(false)}
        column={columnsData?.columns}
      />
      {columnsData?.columns ? (
        <React.Fragment>
          <div className="border-0 p-2">
            <div className="d-flex ">
              <div className="p-2">
                <button
                  type="button"
                  className="btn btn-info"
                  onClick={() => setIsExportCSV(true)}
                >
                  <i className="ri-file-download-line align-bottom me-1"></i>{" "}
                  {TranslationList("Export")}
                </button>{" "}
              </div>
              <div className="me-auto p-2">
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={() => handleMultiDelete()}
                  disabled={checkSelect}
                >
                  <i className="bx bx-trash  me-1"></i>{" "}
                  {TranslationList("Delete")}
                </button>{" "}
              </div>

              <div className="p-2">
                <button
                  type="button"
                  className="btn btn-warning"
                  onClick={toggleColumn}
                >
                  <i className=" ri-menu-add-line me-1 align-bottom"></i>{" "}
                  {TranslationList("Columns")}
                </button>{" "}
              </div>
              <div className="p-2">
                <div className="search-box">
                  <Input
                    type="text"
                    className="form-control search"
                    placeholder={TranslationList("List Search")}
                    onChange={(e) => handleSearch(e.target.value)}
                  />
                  <i className="ri-search-line search-icon"></i>
                </div>
              </div>
            </div>
          </div>
          {apiData ? (
            <React.Fragment>
              {profile?.roles[0]?.name == "User" ? (
                <div className="live-preview p-3">
                  <div className="text-muted table-responsive">
                    <Table className="table-bordered align-middle table-nowrap mb-0">
                      <thead>
                        <tr>
                          <th scope="col">
                            <Input
                              className="form-check-input fs-15"
                              type="checkbox"
                              id="checkAll"
                              onChange={handleCheckAll}
                            />
                          </th>
                          {columnsData?.columns[0]?.status && (
                            <th scope="col">
                              {TranslationList("Application number")}
                            </th>
                          )}
                          {columnsData?.columns[1]?.status && (
                            <th scope="col">{TranslationList("Bank")}</th>
                          )}
                          {columnsData?.columns[2]?.status && (
                            <th scope="col">
                              {TranslationList("Project description")}
                            </th>
                          )}
                          {columnsData?.columns[3]?.status && (
                            <th scope="col">{TranslationList("Stage")}</th>
                          )}
                          {columnsData?.columns[4]?.status && (
                            <th scope="col">{TranslationList("Status")}</th>
                          )}
                          {columnsData?.columns[5]?.status && (
                            <th scope="col">
                              {TranslationList("Last modified")}
                            </th>
                          )}

                          <th scope="col">{TranslationList("Actions")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {apiData?.results?.map((res) => (
                          <tr key={res?.id}>
                            <td className="fw-medium">
                              <input
                                className="checkItem form-check-input fs-15"
                                type="checkbox"
                                name="check"
                                value={JSON.stringify(res)}
                                onChange={handleCheck}
                              />
                            </td>
                            {columnsData?.columns[0]?.status && (
                              <td>
                                {" "}
                                <Link
                                  to={`/application-detail?show_id=${res.id}`}
                                >
                                  {res.nomer}
                                </Link>
                              </td>
                            )}
                            {columnsData?.columns[1]?.status && (
                              <td>{res?.pfi_info?.name}</td>
                            )}
                            {columnsData?.columns[2]?.status && (
                              <td>
                                <div className="d-flex flex-wrap">
                                  <div className="text-wrap">
                                    {res?.project_description}
                                  </div>
                                </div>
                              </td>
                            )}
                            {columnsData?.columns[3]?.status && (
                              <td>
                                <div className="fs-14">{handleStage(res)}</div>
                                <div className="fs-14">
                                  {handleCenterStage(res)}
                                </div>
                              </td>
                            )}
                            {columnsData?.columns[4]?.status && (
                              <td>{handleStatus(res)}</td>
                            )}
                            {columnsData?.columns[5]?.status && (
                              <td>
                                {dateFormat(
                                  res?.updated_time,
                                  "dd.mm.yyyy HH:MM"
                                )}
                              </td>
                            )}
                            <td>
                              <div className="hstack gap-2">
                                {profile?.organization &&
                                  profile?.roles[0]?.name == "User" && (
                                    <button
                                      className="btn btn-sm btn-soft-danger remove-list"
                                      onClick={() => onClickTodoDelete(res)}
                                    >
                                      <i className="ri-delete-bin-5-fill align-bottom" />
                                    </button>
                                  )}
                                {profile?.organization &&
                                  profile?.roles[0]?.name == "User" &&
                                  res?.status == "draft" && (
                                    <button
                                      className="btn btn-sm btn-soft-info edit-list"
                                      onClick={() =>
                                        navigate(
                                          `/create-application?id=${res.id}`
                                        )
                                      }
                                    >
                                      <i className="ri-pencil-fill align-bottom" />
                                    </button>
                                  )}
                                <button
                                  className="btn btn-sm btn-soft-success show-list"
                                  onClick={() =>
                                    navigate(
                                      `/application-detail?show_id=${res.id}`
                                    )
                                  }
                                >
                                  <i className="ri-eye-fill align-bottom" />
                                </button>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </div>
              ) : (
                <div className="live-preview p-3">
                  <div className="text-muted table-responsive">
                    <Table className="table-bordered align-middle table-nowrap mb-0">
                      <thead>
                        <tr>
                          <th scope="col">
                            <Input
                              className="form-check-input fs-15"
                              type="checkbox"
                              id="checkAll"
                              onChange={handleCheckAll}
                            />
                          </th>
                          {columnsData?.columns[0]?.status && (
                            <th scope="col">
                              {TranslationList("Application number")}
                            </th>
                          )}

                          <th scope="col">{TranslationList("Applicant")}</th>

                          {columnsData?.columns[1]?.status && (
                            <th scope="col">{TranslationList("Bank")}</th>
                          )}
                          {columnsData?.columns[2]?.status && (
                            <th scope="col">
                              {TranslationList("Project description")}
                            </th>
                          )}
                          {columnsData?.columns[3]?.status && (
                            <th scope="col">{TranslationList("Stage")}</th>
                          )}
                          {columnsData?.columns[4]?.status && (
                            <th scope="col">{TranslationList("Status")}</th>
                          )}
                          {columnsData?.columns[5]?.status && (
                            <th scope="col">
                              {TranslationList("Last modified")}
                            </th>
                          )}
                          <th scope="col">{TranslationList("Actions")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {apiData?.results?.map((res) => (
                          <tr
                            key={res?.id + "19089"}
                            style={{ background: dedlineWarningTr(res) }}
                          >
                            <td className="fw-medium">
                              <input
                                className="checkItem form-check-input fs-15"
                                type="checkbox"
                                name="check"
                                onChange={handleCheck}
                              />
                            </td>
                            {columnsData?.columns[0]?.status && (
                              <td>
                                {" "}
                                <Link
                                  to={`/application-detail?show_id=${res.id}`}
                                >
                                  {res.nomer}
                                </Link>
                              </td>
                            )}

                            <td>
                              {res?.created_by_info?.first_name}{" "}
                              {res?.created_by_info?.last_name}
                            </td>

                            {columnsData?.columns[1]?.status && (
                              <td>{res?.pfi_info?.name}</td>
                            )}
                            {columnsData?.columns[2]?.status && (
                              <td>
                                <div className="d-flex flex-wrap">
                                  <div className="text-wrap">
                                    {res?.project_description}
                                  </div>
                                </div>
                              </td>
                            )}
                            {columnsData?.columns[3]?.status && (
                              <td>
                                <div className="fs-14">{handleStage(res)}</div>
                                <div className="fs-14">
                                  {handleCenterStage(res)}
                                </div>
                              </td>
                            )}
                            {columnsData?.columns[4]?.status && (
                              <td>{handleStatus(res)}</td>
                            )}
                            {columnsData?.columns[5]?.status && (
                              <td>
                                {dateFormat(
                                  res?.updated_time,
                                  "dd.mm.yyyy HH:MM"
                                )}
                              </td>
                            )}
                            <td>
                              <div className="hstack gap-2">
                                {profile?.organization &&
                                  profile?.roles[0]?.name == "User" && (
                                    <button
                                      className="btn btn-sm btn-soft-danger remove-list"
                                      onClick={() => onClickTodoDelete(res)}
                                    >
                                      <i className="ri-delete-bin-5-fill align-bottom" />
                                    </button>
                                  )}
                                {profile?.organization &&
                                  profile?.roles[0]?.name == "User" &&
                                  res?.status == "draft" && (
                                    <button
                                      className="btn btn-sm btn-soft-info edit-list"
                                      onClick={() =>
                                        navigate(
                                          `/create-application?id=${res.id}`
                                        )
                                      }
                                    >
                                      <i className="ri-pencil-fill align-bottom" />
                                    </button>
                                  )}
                                <button
                                  className="btn btn-sm btn-soft-success show-list"
                                  onClick={() =>
                                    navigate(
                                      `/application-detail?show_id=${res.id}`
                                    )
                                  }
                                >
                                  <i className="ri-eye-fill align-bottom" />
                                </button>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </div>
              )}
            </React.Fragment>
          ) : (
            <LoaderContent />
          )}

          <Paginations
            totalItems={apiData?.pagination?.total}
            perPageChange={(e) => handlePerPageChange(e)}
            currentPage={(e) => handleCurrentPage(e)}
          />
        </React.Fragment>
      ) : (
        <LoaderContent />
      )}
    </div>
  );
}
