import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Label,
  Input,
  FormFeedback,
  FormGroup,
  Form,
  Button,
} from "reactstrap";
import { DataService } from "../../../helpers/dataService/dataService";
import { TranslationList } from "../../../slices/settings/listtranslation";
import { useSelector } from "react-redux";
import CurrencyInput from "react-currency-input-field";
import Cleave from "cleave.js/react";
import "cleave.js/dist/addons/cleave-phone.in";
export default function Stage2({ nextStagePut, nextPageNumber, loading }) {
  const application = useSelector((state) => state.landingApplication?.data);
  const [data, setData] = useState();
  const [sectors, setSectors] = useState();
  const [dataCost1, setDatacost1] = useState(0);
  const [dataCost2, setDatacost2] = useState(0);
  const [errInfo, setErrInfo] = useState();
  const [currency, setCurrensy] = useState();
  const [projectCost, setProjectCost] = useState(0);
  const [CurrentCurrency, setCurrentCurrency] = useState();
  const [foa, setFoa] = useState();
  const [state, setState] = useState({
    overall_jobs_workplace: "",
    women_workplace: "",
    period_kredit: "",
    procent_kredit: "",
  });
  const getData = async () => {
    if (localStorage.getItem("applicationId")) {
      const response = await DataService.getNoAuth(
        `application/${localStorage.getItem("applicationId")}/step2`
      );
      setData(response);
      setState({
        ...state,
        overall_jobs_workplace: response?.overall_jobs_workplace,
        women_workplace: response?.women_workplace,
        period_kredit: response?.period_kredit,
        procent_kredit: response?.procent_kredit,
      });
      if (response?.project_foa) {
        SelectFoa(response?.project_sector);
      }
    }
    const responsecurrency = await DataService.getNoAuth(`currency/list`);
    setCurrensy(responsecurrency?.results);
    const responseSector = await DataService.getNoAuth(`sector-list/`);
    setSectors(responseSector?.results);
  };
  const SelectFoa = async (id) => {
    const responsefoa = await DataService.getNoAuth(`foa/list?sector=${id}`);
    setFoa(responsefoa?.results);
  };
  useEffect(() => {
    setProjectCost(parseInt(dataCost1) + parseInt(dataCost2));
  }, [dataCost1, dataCost2]);
  useEffect(() => {
    getData();
  }, []);

  const handledraft = async () => {
    {
      const data = {
        status: "draft",
        project_foa: document.getElementById("project_foa").value,
        project_sector: document.getElementById("sector").value,
        overall_jobs_workplace: parseInt(state.overall_jobs_workplace) ?? 0,
        women_workplace: parseInt(state.women_workplace) ?? 0,
        project_cost: {
          number: projectCost ?? 0,
          currency: parseInt(
            document.getElementById("project_cost_currency").value
          ),
        },
        procent_kredit: parseInt(state.procent_kredit),
        period_kredit: parseInt(state.period_kredit),
        borrower_contribution: {
          number: dataCost2 ?? 0,
          currency: parseInt(
            document.getElementById("borrower_contribution_currency").value
          ),
        },
        loan_amount_to_receive: {
          number: dataCost1 ?? 0,
          currency: parseInt(
            document.getElementById("loan_amount_to_receive_currency").value
          ),
        },
        project_description: document.getElementById("project_description")
          .value,
      };
      if (localStorage.getItem("applicationId")) {
        nextStagePut(2, data);
      }
    }
  };

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        handledraft();
      }}
    >
      <Row>
        <Col md="6">
          <FormGroup className="mb-3">
            <Label htmlFor="loan_amount_to_receive">
              {TranslationList("Loan amount to receive")}
            </Label>
            <div className="d-flex w-100 justify-content-between">
              <CurrencyInput
                required
                key={
                  data?.loan_amount_to_receive?.number +
                  data?.loan_amount_to_receive?.currency
                }
                id="loan_amount_to_receive"
                defaultValue={data?.loan_amount_to_receive?.number}
                name="loan_amount_to_receive"
                className="form-control me-2 w-75"
                decimalsLimit={10}
                maxLength={10000000}
                onValueChange={(value) => setDatacost1(value)}
              />

              <select
                required
                key={
                  data?.loan_amount_to_receive?.currency +
                  "loan_amount_to_receive"
                }
                className="form-select w-25"
                aria-label="Default select example"
                id="loan_amount_to_receive_currency"
                defaultValue={data?.loan_amount_to_receive?.currency}
                onChange={(e) => setCurrentCurrency(e.target.value)}
              >
                {currency?.map((res) => (
                  <option key={res.id} value={res.id}>
                    {res.symbol}
                  </option>
                ))}
              </select>
            </div>
          </FormGroup>
        </Col>
        <Col md="6">
          <FormGroup className="mb-3">
            <Label htmlFor="project_cost">
              {TranslationList("Project cost")}
            </Label>
            <div className="d-flex w-100 justify-content-between">
              <CurrencyInput
                required
                key={data?.project_cost?.number}
                id="project_cost"
                defaultValue={data?.project_cost?.number}
                name="project_cost"
                className="form-control me-2 w-75"
                decimalsLimit={10}
                maxLength={10000000}
                value={projectCost}
                disabled
              />

              <Input
                key={data?.project_cost?.currency + CurrentCurrency}
                type="select"
                className="form-select w-25"
                aria-label="Default select example"
                id="project_cost_currency"
                defaultValue={CurrentCurrency}
                disabled
              >
                {currency?.map((res) => (
                  <option key={res.id} value={res.id}>
                    {res.symbol}
                  </option>
                ))}
              </Input>
            </div>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md="6">
          <FormGroup className="mb-3">
            <Label htmlFor="borrower_contribution">
              {TranslationList("Borrower's contribution")}
            </Label>
            <div className="d-flex w-100 justify-content-between">
              <CurrencyInput
                required
                key={data?.borrower_contribution?.number}
                id="borrower_contribution"
                defaultValue={data?.borrower_contribution?.number}
                name="borrower_contribution"
                className="form-control me-2 w-75"
                decimalsLimit={10}
                maxLength={10000000}
                onValueChange={(value) => {
                  if (value < dataCost1 * 0.3)
                    setErrInfo(TranslationList("This value is not valid"));
                  else {
                    setDatacost2(value);
                    setErrInfo();
                  }
                }}
              />
              <Input
                required
                key={data?.borrower_contribution?.currency + CurrentCurrency}
                type="select"
                className="form-select w-25"
                aria-label="Default select example"
                defaultValue={CurrentCurrency}
                disabled
                id="borrower_contribution_currency"
              >
                {currency?.map((res) => (
                  <option key={res.id} value={res.id}>
                    {res.symbol}
                  </option>
                ))}
              </Input>
            </div>
            <i style={{ color: "red" }}>{errInfo}</i>
          </FormGroup>
        </Col>
        <Col md={6}>
          <Label className="form-label ">
            {TranslationList("Number of jobs")}
          </Label>
          <Row>
            <Col md="6" className="d-flex align-items-center">
              <Label
                className="form-check-label me-3"
                htmlFor="overall_jobs_workplace"
              >
                {TranslationList("General")}
              </Label>
              <div>
                <Cleave
                  required
                  className="form-control"
                  id="overall_jobs_workplace"
                  options={{
                    numeral: true,
                    numeralThousandsGroupStyle: "thousand",
                  }}
                  value={state.overall_jobs_workplace}
                  onChange={(e) =>
                    setState({
                      ...state,
                      overall_jobs_workplace: e.target.rawValue,
                    })
                  }
                />

                <FormFeedback type="invalid">
                  {TranslationList("Field is required")}
                </FormFeedback>
              </div>
            </Col>
            <Col md="6" className="d-flex align-items-center">
              <Label
                className="form-check-label me-3"
                htmlFor="women_workplace"
              >
                {TranslationList("Female")}
              </Label>
              <div>
                <Cleave
                  required
                  className="form-control"
                  id="women_workplace"
                  options={{
                    numeral: true,
                    numeralThousandsGroupStyle: "thousand",
                  }}
                  value={state.women_workplace}
                  onChange={(e) =>
                    setState({
                      ...state,
                      women_workplace: e.target.rawValue,
                    })
                  }
                />

                <FormFeedback type="invalid">
                  {TranslationList("Field is required")}
                </FormFeedback>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col md="6">
          <FormGroup className="mb-3">
            <Label htmlFor="project_sector">{TranslationList("Sector")} </Label>
            <Input
              key={data?.project_sector + sectors?.length}
              type="select"
              required
              name="project_sector"
              className="form-select mb-3"
              aria-label="Default select example"
              id="sector"
              defaultValue={data?.project_sector}
              onChange={(e) => SelectFoa(e.target.value)}
            >
              <option></option>
              {sectors?.map((res) => (
                <option key={res.id} value={res.id}>
                  {res.name}
                </option>
              ))}
            </Input>
            <FormFeedback type="invalid">
              {TranslationList("Field is required")}
            </FormFeedback>
          </FormGroup>
        </Col>
        <Col md="6">
          <FormGroup className="mb-3">
            <Label htmlFor="project_foa">
              {TranslationList("Type of project activity")}
            </Label>
            <Input
              key={data?.project_foa + foa?.length}
              required
              type="select"
              name="project_foa"
              className="form-select mb-3"
              id="project_foa"
              defaultValue={data?.project_foa}
            >
              <option></option>
              {foa?.map((res) => (
                <option key={res.id} value={res.id}>
                  {res.name}
                </option>
              ))}
            </Input>
            <FormFeedback type="invalid">
              {TranslationList("Field is required")}
            </FormFeedback>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md="6">
          <FormGroup className="mb-3">
            <Label htmlFor="period_kredit">
              {TranslationList("period_kredit")}
            </Label>
            <Cleave
              required
              className="form-control"
              id="period_kredit"
              options={{
                numeral: true,
                numeralThousandsGroupStyle: "thousand",
              }}
              value={state.period_kredit}
              onChange={(e) =>
                setState({
                  ...state,
                  period_kredit: e.target.rawValue,
                })
              }
            />

            <FormFeedback type="invalid">
              {TranslationList("Field is required")}
            </FormFeedback>
          </FormGroup>
        </Col>
        <Col md="6">
          <FormGroup className="mb-3">
            <Label htmlFor="procent_kredit">
              {TranslationList("procent_kredit")}
            </Label>
            <Cleave
              required
              className="form-control"
              id="procent_kredit"
              options={{
                numeral: true,
                numeralThousandsGroupStyle: "thousand",
              }}
              value={state.procent_kredit}
              onChange={(e) =>
                setState({
                  ...state,
                  procent_kredit: e.target.rawValue,
                })
              }
            />

            <FormFeedback type="invalid">
              {TranslationList("Field is required")}
            </FormFeedback>
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <FormGroup className="mb-3 mt-3">
          <Label htmlFor="project_description">
            {TranslationList("Brief description of the project")}
          </Label>
          <Input
            name="project_description"
            type="textarea"
            className="form-control"
            id="project_description"
            defaultValue={data?.project_description}
          />
        </FormGroup>
      </Row>

      <div className=" d-flex justify-content-between mt-3">
        <Button color="primary" type="button" onClick={() => nextPageNumber(1)}>
          {TranslationList("Previous step")}
        </Button>
        <Button color="primary" type="submit" disabled={loading}>
          {TranslationList("Next")}
        </Button>
      </div>
    </Form>
  );
}
