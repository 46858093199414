import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Container,
  Row,
  Table,
  CardHeader,
  Modal,
  ModalHeader,
  Input,
  Button,
  FormFeedback,
  Label,
} from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import DeleteModal from "../../Components/Common/DeleteModal";
import { TranslationList } from "../../slices/settings/listtranslation";
import { DataService } from "../../helpers/dataService/dataService";
import LoaderContent from "../../Components/Spinner";
import Paginations from "../Tables/DataTables/Pagination";
import endpoints from "../../endpoints";

export default function Complaint() {
  const [modal_varying1, setmodal_varying1] = useState(false);
  const [current, setCuttent] = useState();
  const [deleteModal, setDeleteModal] = useState(false);
  const perpage = localStorage.getItem("perpage") ?? 10;
  const [perPage, setPerpage] = useState(perpage);
  const lang = useSelector((state) => state.lang.data);
  const [apiData, setApiData] = useState();
  const [organization, setOrganization] = useState();
  const [orgBranch, setOrgBranch] = useState();
  const [employee, setEmployee] = useState();
  const [projects, setProjects] = useState();
  const fetchData = async () => {
    const response = await DataService.get(endpoints.complaint);
    setApiData(response);
    const response2 = await DataService.get(endpoints.organization);
    setOrganization(response2?.results);
    const response3 = await DataService.get(endpoints.projects);
    setProjects(response3?.results);
  };
  const fetchDataParams = async (params) => {
    const response = await DataService.get(endpoints.complaint, params);
    setApiData(response);
  };
  useEffect(() => {
    fetchData();
  }, [lang]);
  const handleSearch = (e) => {
    fetchDataParams({ search: e });
  };

  const handleAdd = async (e) => {
    e.preventDefault();

    const data = {
      title: document.getElementById("title").value,
      organization: document.getElementById("organization").value,
      organization_branch: document.getElementById("organization_branch").value,
      employee: document.getElementById("employee").value,
      type: document.getElementById("type").value,
      phone: document.getElementById("phone").value,
      email: document.getElementById("email").value,
      text: document.getElementById("text").value,
      project: document.getElementById("project").value,
    };
    const response = await DataService.post(endpoints.complaint, data);
    if (response?.errorCode) {
      toast.error(TranslationList("An error has occurred!"));
    } else {
      toast.success(TranslationList("Done successfully!"));
      fetchData();
      setmodal_varying1(false);
    }
  };
  const onClickEdite = (item) => {
    setmodal_varying1(true);
    setCuttent(item);
  };
  const handleEdite = async (e) => {
    e.preventDefault();
    const data = {
      title: document.getElementById("title").value,
      organization: document.getElementById("organization").value,
      organization_branch: document.getElementById("organization_branch").value,
      employee: document.getElementById("employee").value,
      type: document.getElementById("type").value,
      phone: document.getElementById("phone").value,
      email: document.getElementById("email").value,
      text: document.getElementById("text").value,
      project: document.getElementById("project").value,
    };
    const response = await DataService.put(
      endpoints.complaintById(current?.id),
      data
    );
    if (response?.errorCode) {
      toast.error(TranslationList("An error has occurred!"));
    } else {
      toast.success(TranslationList("Done successfully!"));
      fetchData();
      setmodal_varying1(false);
    }
  };
  const onClickTodoDelete = (item) => {
    setCuttent(item);
    setDeleteModal(true);
  };
  const handleDelete = async () => {
    const response = await DataService.delete(
      endpoints.complaintById(current?.id)
    );
    if (response?.errorCode) {
      toast.error(TranslationList("An error has occurred!"));
    } else {
      toast.success(TranslationList("Done successfully!"));
      fetchData();
    }
    setDeleteModal(false);
  };
  //Pagination begin
  function tog_varying1() {
    setmodal_varying1(!modal_varying1);
    setCuttent();
  }
  const handleCurrentPage = (e) => {
    fetchDataParams({ page: e, limit: perPage });
  };
  const handlePerPageChange = (e) => {
    setPerpage(e);
    fetchDataParams({ limit: e });
  };
  const SelectChangeOrg = async (e) => {
    const response3 = await DataService.get(endpoints.organizationbranch, {
      organization: e,
    });
    setOrgBranch(response3?.results);
    const response = await DataService.get(endpoints.user, {
      organization: e,
    });
    setEmployee(response?.results);
  };
  const SelectChangeOrgBranch = async (e) => {
    const response3 = await DataService.get(endpoints.user, {
      organization_branch: e,
    });
    setEmployee(response3?.results);
  };
  //Pagination end
  document.title = `INVESTCONTROL | ${TranslationList("Complaint")}`;

  return (
    <div className="page-content">
      <DeleteModal
        show={deleteModal}
        data={current?.name}
        onDeleteClick={handleDelete}
        onCloseClick={() => setDeleteModal(false)}
      />
      <Modal
        isOpen={modal_varying1}
        toggle={() => {
          tog_varying1();
        }}
        id="exampleModal"
      >
        <ModalHeader
          toggle={() => {
            tog_varying1();
          }}
        >
          {current ? TranslationList("Edit") : TranslationList("Add")}
        </ModalHeader>
        <form onSubmit={current ? handleEdite : handleAdd}>
          <div className="modal-body">
            <div className="mb-3">
              <label htmlFor="title" className="col-form-label">
                {TranslationList("Title")}:
              </label>
              <Input
                id="title"
                name="title"
                required
                type="text"
                className="form-control"
                defaultValue={current?.title}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="project" className="col-form-label">
                {TranslationList("Projects")} :
              </label>
              <select
                className="form-select mb-3 col-6"
                aria-label="Default select example"
                id="project"
                required
                defaultValue={current?.project}
              >
                <option></option>
                {projects?.map((res) => (
                  <option key={res.id} value={res.id} className="w-100">
                    <p>{res.name}</p>
                  </option>
                ))}
              </select>
            </div>
            <div className="mb-3">
              <label htmlFor="department" className="col-form-label">
                {TranslationList("Organization")} :
              </label>
              <select
                className="form-select mb-3 col-6"
                aria-label="Default select example"
                id="organization"
                required
                defaultValue={current?.organization}
                onChange={(e) => SelectChangeOrg(e.target.value)}
              >
                <option></option>
                {organization?.map((res) => (
                  <option key={res.id} value={res.id} className="w-100">
                    <p>{res.name}</p>
                  </option>
                ))}
              </select>
            </div>
            <div className="mb-3">
              <label htmlFor="department" className="col-form-label">
                {TranslationList("Organization branch")} :
              </label>
              <select
                className="form-select mb-3 col-6"
                aria-label="Default select example"
                id="organization_branch"
                defaultValue={current?.organization_branch}
                onChange={(e) => SelectChangeOrgBranch(e.target.value)}
              >
                <option></option>
                {orgBranch?.map((res) => (
                  <option key={res.id} value={res.id} className="w-100">
                    <p>{res.name}</p>
                  </option>
                ))}
              </select>
            </div>
            <div className="mb-3">
              <label htmlFor="employee" className="col-form-label">
                {TranslationList("Users")} :
              </label>
              <select
                required
                key={current?.employee}
                className="form-select mb-3 col-6"
                aria-label="Default select example"
                id="employee"
                defaultValue={current?.employee}
              >
                <option></option>
                {employee?.map((res) => (
                  <option key={res.id} value={res.id} className="w-100">
                    <p>
                      {res.last_name} {res.first_name}
                    </p>
                  </option>
                ))}
              </select>
            </div>
            <div className="mb-3">
              <label htmlFor="type" className="col-form-label">
                {TranslationList("Type")} :
              </label>
              <select
                required
                className="form-select mb-3 col-6"
                aria-label="Default select example"
                id="type"
                defaultValue={current?.type}
              >
                <option value="DISBANK">
                  {TranslationList("For stage District bank")}
                </option>
                <option value="REPBANK">
                  {TranslationList("For stage Republic bank")}
                </option>
                <option value="SPECISCAD">
                  {TranslationList("For stage ISCAD Specialist")}
                </option>
                <option value="CORISCAD">
                  {TranslationList("For stage ISCAD Cordinator")}
                </option>
                <option value="DIRISCAD">
                  {TranslationList("For stage ISCAD Direktor")}
                </option>
                <option value="EKOISCAD">
                  {TranslationList("For stage ISCAD Finansist")}
                </option>
              </select>
            </div>
            <div className="mb-3">
              <label htmlFor="phone" className="col-form-label">
                {TranslationList("Phone")}:
              </label>
              <Input
                id="phone"
                required
                type="text"
                className="form-control"
                defaultValue={current?.phone}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="email" className="col-form-label">
                {TranslationList("Email")}:
              </label>
              <Input
                id="email"
                required
                type="email"
                className="form-control"
                defaultValue={current?.email}
              />
            </div>

            <div className="mb-3">
              <label htmlFor="text" className="col-form-label">
                {TranslationList("Commit")}:
              </label>
              <Input
                id="text"
                required
                type="textarea"
                className="form-control"
                defaultValue={current?.text}
              />
            </div>
          </div>
          <div className="modal-footer">
            <Button
              color="light"
              onClick={() => {
                setmodal_varying1(false);
              }}
            >
              {TranslationList("Cancel")}
            </Button>
            {current ? (
              <Button color="primary">{TranslationList("Save")}</Button>
            ) : (
              <Button color="primary">{TranslationList("Create")}</Button>
            )}
          </div>
        </form>
      </Modal>

      <Container fluid>
        <BreadCrumb
          title={TranslationList("Complaint")}
          pageTitle={TranslationList("home")}
        />
        <Row>
          <Card>
            <CardHeader>
              <Row className="align-items-center gy-3">
                <div className="col-sm">
                  <h5 className="card-title mb-0">
                    {TranslationList("Monitoring_Documents")}
                  </h5>
                </div>
                <div className="col-sm-auto d-flex justify-content-between">
                  <div className="px-2">
                    <div className="search-box">
                      <Input
                        type="text"
                        className="form-control search"
                        placeholder={TranslationList("List Search")}
                        onChange={(e) => handleSearch(e.target.value)}
                      />
                      <i className="ri-search-line search-icon"></i>
                    </div>
                  </div>
                  <div className="d-flex gap-1 flex-wrap">
                    <button
                      type="button"
                      className="btn btn-success add-btn"
                      id="create-btn"
                      onClick={() => tog_varying1()}
                    >
                      <i className="ri-add-line align-bottom me-1"></i>
                      {TranslationList("Add")}
                    </button>
                  </div>
                </div>
              </Row>
            </CardHeader>
            {apiData?.results ? (
              <CardBody>
                <div className="live-preview p-3">
                  <div className="text-muted table-responsive">
                    <Table className="table-bordered align-middle table-nowrap mb-0">
                      <thead>
                        <tr>
                          <th scope="col" className="text-center">
                            {TranslationList("Title")}
                          </th>
                          <th
                            scope="col"
                            className="w-25 text-wrap  text-center"
                          >
                            {TranslationList("Organization")}
                          </th>
                          <th scope="col" className=" text-wrap  text-center">
                            {TranslationList("Users")}
                          </th>
                          <th
                            scope="col"
                            className="w-25 text-wrap  text-center"
                          >
                            {TranslationList("Phone")}
                          </th>
                          <th
                            scope="col"
                            className="w-25 text-wrap  text-center"
                          >
                            {TranslationList("Email")}
                          </th>
                          <th
                            scope="col"
                            className="w-50 text-wrap  text-center"
                          >
                            {TranslationList("Projects")}
                          </th>
                          <th
                            scope="col"
                            className="w-25 text-wrap  text-center"
                          >
                            {TranslationList("Type")}
                          </th>
                          <th scope="col " className="text-center">
                            {TranslationList("Actions")}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {apiData?.results?.map((res, i) => (
                          <tr key={res?.id}>
                            <td className=" align-items-center">
                              {res?.title}
                            </td>
                            <td className="w-25 text-break text-wrap">
                              {res?.organization_detail?.name}
                            </td>
                            <td className=" text-break text-wrap">
                              {res?.employee_detail?.last_name}{" "}
                              {res?.employee_detail?.first_name}
                            </td>{" "}
                            <td className="w-25 text-break text-wrap">
                              {res?.phone}
                            </td>
                            <td className="w-25 text-break text-wrap">
                              {res?.email}
                            </td>
                            <td className="w-50 text-break text-wrap">
                              {res?.project_detail?.name}
                            </td>
                            <td className="w-25 ">{res?.type}</td>
                            <td className="text-center">
                              <div className="hstack gap-2 justify-content-center">
                                <button
                                  className="btn btn-sm btn-soft-danger remove-list"
                                  onClick={() => onClickTodoDelete(res)}
                                >
                                  <i className="ri-delete-bin-5-fill align-bottom" />
                                </button>
                                <button
                                  className="btn btn-sm btn-soft-info edit-list"
                                  onClick={() => onClickEdite(res)}
                                >
                                  <i className="ri-pencil-fill align-bottom" />
                                </button>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </CardBody>
            ) : (
              <LoaderContent />
            )}
          </Card>
          <Paginations
            totalItems={apiData?.pagination?.total}
            perPageChange={(e) => handlePerPageChange(e)}
            currentPage={(e) => handleCurrentPage(e)}
          />
        </Row>
      </Container>
    </div>
  );
}
