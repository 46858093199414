import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  Modal,
  ModalBody,
  ModalHeader,
  UncontrolledAlert,
} from "reactstrap";
import "cleave.js/dist/addons/cleave-phone.in";
import { Link, redirect, useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { DataService } from "../../../helpers/dataService/dataService";
import MultiStepLanding from "../../../Components/MultiStepLanding";
import Stage1 from "./Step1";
import Stage2 from "./Step2";
import {
  addapplicAsync,
  addapplicAsyncNoAuth,
  editeapplicationAsync,
} from "../../../slices/application";
import { TranslationList } from "../../../slices/settings/listtranslation";
import {
  getlandingApplication,
  getlandingStage,
} from "../../../slices/landingApplication";
import OneID from "../../../assets/images/oneid.svg";
import { getprofileAsync } from "../../../slices/auth/profile";
import { getnotificationAsync } from "../../../slices/notification";
import Stage3 from "./Step3";
import {
  getTokenOneId,
  getTokenOneIdForBack,
  spliteAge,
} from "../../../helpers/integration/oneId";
export default function PersonalDataLanding() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const application = useSelector((state) => state.landingApplication?.data);
  const applicationStage = useSelector(
    (state) => state.landingApplication?.stage
  );
  const [selectLegal, setSelectLegal] = useState();
  const [loading, setLoading] = useState(false);
  const [currentData, setcurrentData] = useState();
  const [modal_large, setmodal_large] = useState(false);
  const [page, setPage] = useState(1);
  const [currentStage, setcurrentStage] = useState(applicationStage);
  const [modal_loginModals, setmodal_loginModals] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [listLegal, setListLegal] = useState();
  function tog_loginModals() {
    setmodal_loginModals(!modal_loginModals);
    dispatch(getlandingApplication(null));
    dispatch(getlandingStage(1));
    nextPageNumber(1);
  }
  const tog_large = () => {
    setmodal_large(!modal_large);
  };
  const nextPageNumber = (pageNumber) => {
    setPage(pageNumber);
  };
  const nextStageSave1 = async (stage, data) => {
    setLoading(true);
    try {
      const response = await addapplicAsyncNoAuth("application/step1", {
        ...data,
        draft_stage: stage,
      });
      setLoading(false);
      if (response?.errorCode == 400) {
        toast.error(TranslationList("An error has occurred!"));
      } else {
        toast.success(TranslationList("Application successfully created!"));
        dispatch(getlandingApplication(response?.id));
        dispatch(getlandingStage(2));
        localStorage.setItem("applicationId", response?.id);
        // searchParams.set("id", response?.id);
        // setSearchParams(searchParams);
      }
    } catch (error) {
      setLoading(false);
      toast.error(TranslationList("An error has occurred!"));
    }
    nextPageNumber(stage + 1);
  };
  const nextStagePut1 = async (stage, data) => {
    setLoading(true);
    try {
      const response = await editeapplicationAsync(
        `application/${localStorage.getItem("applicationId")}/step1`,
        {
          ...data,
          draft_stage: stage,
        }
      );
      setLoading(false);

      if (response?.errorCode == 400) {
        toast.error(TranslationList("An error has occurred!"));
      } else {
        toast.success(TranslationList("Application successfully updated!"));
        // searchParams.set("id", response?.id);
        // setSearchParams(searchParams);
      }
    } catch (error) {
      toast.error(TranslationList("An error has occurred!"));
    }

    nextPageNumber(stage + 1);
  };

  const nextStagePut2 = async (stage, data) => {
    setLoading(true);
    try {
      const response = await editeapplicationAsync(
        `application/${localStorage.getItem("applicationId")}/step2`,
        {
          ...data,
          draft_stage: stage,
        }
      );
      setLoading(false);

      if (response?.errorCode == 400) {
        toast.error(TranslationList("An error has occurred!"));
      } else {
        toast.success(TranslationList("Application successfully updated!"));
        // searchParams.set("id", response?.id);
        // setSearchParams(searchParams);
      }
    } catch (error) {
      toast.error(TranslationList("An error has occurred!"));
    }

    nextPageNumber(stage + 1);
  };
  const saveApplication = async (data) => {
    setLoading(true);
    try {
      const response = await editeapplicationAsync(
        `application/${localStorage.getItem("applicationId")}/step3`,
        {
          ...data,
          draft_stage: 3,
        }
      );
      setLoading(false);
      if (response?.errorCode == 400) {
        toast.error(TranslationList("An error has occurred!"));
      } else {
        localStorage.setItem("applicationId", response?.id);
        tog_loginModals();
      }
    } catch (error) {}
  };
  const handleOneID = async (id) => {
    if (searchParams.get("code") && searchParams.get("state") == "testState")
      getTokenOneIdForBack(
        searchParams.get("code"),
        "https://fr-invest.samar.uz"
      )
        .then(async (res) => {
          const data = await DataService.postNoAuth("auth/token/oneid", {
            grant_type: "one_access_token_identify",
            client_id: "invest_iscad_uz",
            client_secret: "JZWEM26e8Y4lT70wObP7gQRh",
            access_token: res?.access_token,
            scope: res?.scope,
            redirect_uri: "https://fr-invest.samar.uz",
            tin: selectLegal ?? "not",
          });
          if (data) {
            sessionStorage.setItem("authUser", JSON.stringify(data));
            dispatch(getprofileAsync("user-view/"));
            dispatch(getnotificationAsync("notification/?is_read=0"));
            await DataService.put(
              `application/${localStorage.getItem("applicationId")}/step4`,
              {
                project: id,
                created_by: data?.result?.user_id,
                organization: data?.result?.organization_id,
                draft_stage: 4,
              }
            );
            navigate(
              `/application-detail?show_id=${localStorage.getItem(
                "applicationId"
              )}`
            );
            localStorage.removeItem("userInfo");
            localStorage.removeItem("applicationId");
          }
        })
        .catch((error) => {
          localStorage.removeItem("applicationId");
        });
  };
  useEffect(() => {
    if (searchParams.get("code") && searchParams.get("state") == "testState") {
      getTokenOneId(searchParams.get("code"), "https://fr-invest.samar.uz")
        .then((res) => {
          getProjectSelect(res?.birth_date);
          if (res?.legal_info?.length > 0) {
            setListLegal(res?.legal_info);
          }
        })
        .catch((error) => {
          console.error("Error fetching token or processing data:", error);
        });
    }
  }, [searchParams.get("code")]);
  const getProjectSelect = async (pin) => {
    try {
      const response = await DataService.getNoAuth(
        `projects/filter?aplication_id=${localStorage.getItem(
          "applicationId"
        )}&user_age=${spliteAge(pin)}`
      );
      if (response) {
        if (response?.errors) setcurrentData(response);
        else setcurrentData({ data: response });
        tog_large();
        if (response?.errors?.length > 0) {
          localStorage.removeItem("applicationId");
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <Modal
        size="lg"
        isOpen={modal_large}
        toggle={() => {
          tog_large();
        }}
      >
        {currentData?.errors ? (
          <div className="modal-body">
            {currentData?.errors?.map((res, i) => (
              <UncontrolledAlert
                key={i}
                color="danger"
                className="alert-label-icon rounded-label"
              >
                <i className="ri-error-warning-line label-icon"></i>
                <strong>{res}</strong>
              </UncontrolledAlert>
            ))}
          </div>
        ) : (
          <React.Fragment>
            <ModalHeader
              id="myLargeModalLabel"
              toggle={() => {
                tog_large();
              }}
            >
              {listLegal && (
                <div className="form-group col-md-12 my-2">
                  <label htmlFor="legal">
                    {TranslationList("Choose organization:")}
                  </label>
                  <div>
                    <select
                      className="form-control"
                      name="legals"
                      id="legal"
                      onChange={(e) => setSelectLegal(e.target.value)}
                    >
                      {listLegal?.map((item) => (
                        <option key={item?.tin} value={item?.tin}>
                          {item?.le_name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              )}
              <Link
                to="#"
                className="text-dark"
                onClick={() => tog_large(true)}
              >
                <h5 className="fw-bold">{currentData?.data[0]?.name}</h5>
              </Link>{" "}
            </ModalHeader>
            <div className="modal-body">
              <div className=" fs-14">
                {/* <p>Description</p> */}
                <div
                  className="text-wrap "
                  dangerouslySetInnerHTML={{
                    __html: currentData?.data[0]?.description,
                  }}
                />
              </div>
              <div className="py-3">
                <h5 className="mb-2 fs-14 fw-bold">
                  {TranslationList("Sectors")}:
                </h5>
                <div>
                  {currentData?.data[0]?.sectors_detail?.map((res) => (
                    <p className="" key={res?.id}>
                      {res?.name}
                    </p>
                  ))}
                </div>
              </div>
              <div className="py-3">
                <h5 className="mb-2 fs-14 fw-bold">
                  {TranslationList(
                    "Issued_through_the_following_commercial_banks"
                  )}
                  :
                </h5>
                <div>
                  {currentData?.data[0]?.organization_detail?.map((res) => (
                    <p className="" key={res?.id}>
                      {res?.name}
                    </p>
                  ))}
                </div>
              </div>
            </div>

            <div className="modal-footer">
              <button
                className="btn btn-success add-btn"
                disabled={listLegal ? (selectLegal ? false : true) : false}
                onClick={() => {
                  handleOneID(currentData?.data[0]?.id);
                }}
              >
                {TranslationList("Send_application")}
              </button>
              <Link
                to="#"
                className="btn btn-link link-success fw-medium"
                onClick={() => setmodal_large(false)}
              >
                <i className="ri-close-line me-1 align-middle"></i>{" "}
                {TranslationList("Close")}
              </Link>
            </div>
          </React.Fragment>
        )}
      </Modal>
      <Modal
        id="loginModals"
        tabIndex="-1"
        isOpen={modal_loginModals}
        toggle={() => {
          tog_loginModals();
        }}
        centered
      >
        <div className="modal-content border-0 overflow-hidden">
          <ModalBody className="login-modal p-5">
            <h5 className="text-white fs-20">
              {TranslationList(
                "The form has been successfully completed, confirm by going to your personal cabinet!"
              )}
            </h5>
          </ModalBody>
          <ModalBody className="p-5">
            <div className="vstack gap-2 justify-content-center mt-2">
              <button
                className="btn btn-primary"
                onClick={() => {
                  window.location.href =
                    "https://sso.egov.uz/sso/oauth/Authorization.do?response_type=one_code&client_id=invest_iscad_uz&redirect_uri=https://fr-invest.samar.uz&scope=myportal&state=testState";
                  tog_loginModals();
                }}
              >
                <img src={OneID} width={200} />
              </button>
            </div>
          </ModalBody>
        </div>
      </Modal>
      <div className="needs-validation">
        <MultiStepLanding
          page={page}
          onPageNumberClick={nextPageNumber}
          currentStage={currentStage}
        />
        {
          {
            1: (
              <Stage1
                nextStagePut={nextStagePut1}
                nextStageSave={nextStageSave1}
                loading={loading}
              />
            ),
            2: (
              <Stage2
                nextStagePut={nextStagePut2}
                nextPageNumber={nextPageNumber}
                loading={loading}
              />
            ),
            3: (
              <Stage3
                saveApplication={saveApplication}
                nextPageNumber={nextPageNumber}
                loading={loading}
              />
            ),
          }[page]
        }
      </div>
    </div>
  );
}
