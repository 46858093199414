import { createSlice } from "@reduxjs/toolkit";
import { APIClient } from "../helpers/api_helper";
import { DataService } from "../helpers/dataService/dataService";

export const projectSlide = createSlice({
  name: "project",
  initialState: {
    data: null,
    error: null,
    current: null,
    item: null,
    select: null,
  },
  reducers: {
    getproject: (state, action) => {
      state.data = action.payload;
    },
    editproject: (state, action) => {
      state.item = action.payload;
    },
    getprojectselect: (state, action) => {
      state.select = action.payload;
    },
  },
});
export const getselectProjectAsync = (id) => (dispatch) => {
  dispatch(getprojectselect(id));
};
export const addprojectAsync = async (url, data) => {
  try {
    const datas = await DataService.post(url, data);
    return datas;
  } catch (error) {
    return error;
  }
};
export const getprojectAsync = (data) => async (dispatch) => {
  try {
    const datas = await DataService.getNoAuth(data);
    dispatch(getproject(datas));
  } catch (error) {
    // //throw new Error(err);
  }
};
export const getprojectAsyncAuth = (data) => async (dispatch) => {
  try {
    const datas = await DataService.get(data);
    dispatch(getproject(datas));
  } catch (error) {
    // //throw new Error(err);
  }
};
export const getprojectFilterAsync = async (data) => {
  try {
    const datas = await DataService.getNoAuth(data);
    return datas;
  } catch (error) {
    return error;
  }
};
export const editprojectAsync = async (url, data) => {
  try {
    console.log("datas", data);
    const datas = await DataService.put(url, data);

    return datas;
  } catch (error) {
    return error;
  }
};
export const getCurrentProject = async (data) => {
  try {
    const datas = await DataService.getNoAuth(data);
    return datas;
  } catch (error) {
    return error;
  }
};
export const deleteproject = async (data) => {
  try {
    const datas = await DataService.delete(data);
    return datas;
  } catch (error) {
    return error;
  }
};
export const deleteprojectAll = async (url, data) => {
  try {
    const datas = await DataService.post(url, data);
    return datas;
  } catch (error) {
    return error;
  }
};

export const { getproject, editproject, getprojectselect } =
  projectSlide.actions;
export default projectSlide.reducer;
