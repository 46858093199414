import { createSlice } from "@reduxjs/toolkit";

export const toggleLeftCanvasSlide = createSlice({
  name: "toggleLeftCanvas",
  initialState: {
    open: false,
  },
  reducers: {
    gettoggleLeftCanvas: (state, action) => {
      state.open = !state.open;
    },
  },
});

export const { gettoggleLeftCanvas } = toggleLeftCanvasSlide.actions;
export default toggleLeftCanvasSlide.reducer;
