import { createSlice } from "@reduxjs/toolkit";

const columnUserSlice = createSlice({
  name: "column",
  initialState: {
    columns: null,
    error: {},
  },
  reducers: {
    columnUserReducer: (state, action) => {
      state.columns = action.payload;
    },
  },
});
export const columnUserAsync = (data) => async (dispatch) => {
  try {
    dispatch(columnUserReducer(data));
  } catch (error) {
    // //throw new Error(err);
  }
};
export const { columnUserReducer } = columnUserSlice.actions;

export default columnUserSlice.reducer;
