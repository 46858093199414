import L from "leaflet";
import { get, isEqual } from "lodash";
import markerIconSVG from "../../assets/images/map/pin.svg";
export const backControl = L.control({
  position: "topleft",
});
backControl.onAdd = function (map) {
  this._div = L.DomUtil.create("div", "customMap--control-back ");
  this._div.style.backgroundImage = `url('${require("../../assets/images/map/undo.svg")}')`;

  return this._div;
};
backControl.onClick = function (cb) {
  this._div.onclick = cb;
};

export const info = L.control();

info.onAdd = function (map) {
  this._div = L.DomUtil.create("div", "customMap--info");
  this.update();
  return this._div;
};

info.update = function (props) {
  if (props) {
    this._div.innerHTML = `
    <div className="customMap--info-container">
       <div>${props.name}</div>
    </div>`;
  } else {
    this._div.innerHTML = "";
  }
};

export const CENTER = {
  lat: 41.3216775,
  lng: 69.1910553,
};

export const COLORS = {
  1: "#536c79",
  2: "#364d58",
  3: "#617988",
  4: "#9fb2bd",
  5: "#869fac",
  6: "#364d58",
  7: "#c2cfd6",
  8: "#4f6673",
  9: "#364d58",
  10: "#364d58",
  11: "#c2cfd6",
  12: "#869fac",
  13: "#869fac",
  14: "#c2cfd6",
};

export const styleRegion = (feature) => ({
  weight: 2,
  color: "white",
  fillOpacity: 0.4,
  fillColor: get(COLORS, feature.properties.id, "#364d58"),
});

export const styleDistrict = (name) => ({
  weight: 2,
  color: "white",
  fillOpacity: 0.4,
  fillColor: get(COLORS, name, "#364d58"),
});

export const highlightFeature = (e) => {
  const layer = e.target;

  layer.setStyle({
    weight: 2,
    // fillColor: '#00CC33',
    // fillOpacity: 0.4,
    color: "#00CC33",
  });

  if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) {
    layer.bringToFront();
  }

  info.update(layer.feature.properties);
};

export const markerIcon = new L.icon({
  iconUrl: markerIconSVG,
  iconSize: [30, 30],
});

export const VIEW_MODE_REGIONS = "REGIONS";
export const VIEW_MODE_DISTRICTS = "DISTRICTS";
export function isEqualChild(a, b, ...keys) {
  const path = [...keys].filter((x) => x != null);
  const aChild = get(a, path);
  const bChild = get(b, path);
  return isEqual(aChild, bChild);
}

export function isNotEqualChild(a, b, ...keys) {
  return !isEqualChild(a, b, ...keys);
}
export const tryParseJson = (string) => {
  try {
    return JSON.parse(string);
  } catch (e) {
    return null;
  }
};
