import { createSlice } from "@reduxjs/toolkit";
import { APIClient } from "../helpers/api_helper";
import { DataService } from "../helpers/dataService/dataService";

export const pfiSlide = createSlice({
  name: "pfi",
  initialState: {
    data: null,
    error: null,
    current: null,
    item: [],
  },
  reducers: {
    getpfi: (state, action) => {
      state.data = action.payload;
    },
    currentfilial: (state, action) => {
      state.current = action.payload;
    },
  },
});
export const addpfiAsync = async (url, data) => {
  try {
    const datas = await DataService.post(url, data);
    return datas;
  } catch (error) {
    return error;
  }
};
export const getpfiAsync = (data) => async (dispatch) => {
  try {
    const datas = await DataService.get(data);
    dispatch(getpfi(datas));
  } catch (error) {
    // //throw new Error(err);
  }
};
export const getpfifilialAsync = (data) => async (dispatch) => {
  try {
    const datas = await DataService.get(data);
    dispatch(currentfilial(datas));
  } catch (error) {
    // //throw new Error(err);
  }
};
export const editpfiAsync = async (url, data) => {
  try {
    const datas = await DataService.put(url, data);
    return datas;
  } catch (error) {
    return error;
  }
};
export const deletepfi = async (data) => {
  try {
    const datas = await DataService.delete(data);
    return datas;
  } catch (error) {
    return error;
  }
};

export const { getpfi, currentfilial } = pfiSlide.actions;
export default pfiSlide.reducer;
