import React from "react";
import { Spinner } from "reactstrap";

export default function PageLoader() {
  return (
    <div className="vh-100 w-100 d-flex justify-content-center align-items-center">
      <Spinner
        style={{ width: "5rem", height: "5rem" }}
        color="success"
        type="grow"
      >
        Loading...
      </Spinner>
    </div>
  );
}
