import React from "react";
import LoadIcon from "../assets/loadicon.json";
export default function LoaderContent() {
  return (
    <div
      className="w-100  d-flex justify-content-center align-items-center"
      style={{ height: 400 }}
    >
      <div>
        <lord-icon
          src="https://cdn.lordicon.com/msoeawqm.json"
          trigger="loop"
          colors="primary:#405189,secondary:#0ab39c"
          style={{ width: "72px", height: "72px" }}
        ></lord-icon>
      </div>
    </div>
  );
}
