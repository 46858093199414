import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import SimpleBar from "simplebar-react";

//Import Components
import VerticalLayout from "./VerticalLayouts/index";
import TwoColumnLayout from "./TwoColumnLayout";
import { Container } from "reactstrap";
import HorizontalLayout from "./HorizontalLayout";
import { useTranslation, withTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { gettoggleLeftCanvas } from "../slices/toggleLeftCanvas";

const Sidebar = ({ layoutType }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  useEffect(() => {
    var verticalOverlay = document.getElementsByClassName("vertical-overlay");
    if (verticalOverlay) {
      verticalOverlay[0].addEventListener("click", function () {
        document.body.classList.remove("vertical-sidebar-enable");
      });
    }
  });

  const addEventListenerOnSmHoverMenu = () => {
    // add listener Sidebar Hover icon on change layout from setting
    if (
      document.documentElement.getAttribute("data-sidebar-size") === "sm-hover"
    ) {
      document.documentElement.setAttribute(
        "data-sidebar-size",
        "sm-hover-active"
      );
    } else if (
      document.documentElement.getAttribute("data-sidebar-size") ===
      "sm-hover-active"
    ) {
      document.documentElement.setAttribute("data-sidebar-size", "sm-hover");
    } else {
      document.documentElement.setAttribute("data-sidebar-size", "sm-hover");
    }
  };
  const handleOpenSettings = () => {
    dispatch(gettoggleLeftCanvas());
  };
  return (
    <React.Fragment>
      <div className="app-menu navbar-menu">
        <div className="navbar-brand-box">
          <Link
            to="/"
            onClick={() => sessionStorage.removeItem("authUser")}
            className="logo logo-dark"
          >
            <span className="logo-sm">
              <div className="logo_text">{t("INVESTCONTROL")}</div>
              {/* <img src={logoSm} alt="" height="22" /> */}
            </span>
            <span className="logo-lg">
              <div className="logo_text">{t("INVESTCONTROL")}</div>

              {/* <img src={logoDark} alt="" height="17" /> */}
            </span>
          </Link>

          <Link
            to="/"
            onClick={() => sessionStorage.removeItem("authUser")}
            className="logo logo-light"
          >
            <span className="logo-sm">
              {/* <img src={logoSm} alt="" height="22" /> */}
              <div className="logo_text">{t("INVESTCONTROL")}</div>
            </span>
            <span className="logo-lg">
              {/* <img src={logoLight} alt="" height="17" /> */}
              <div className="logo_text">{t("INVESTCONTROL")}</div>
            </span>
          </Link>
          <button
            onClick={addEventListenerOnSmHoverMenu}
            type="button"
            className="btn btn-sm p-0 fs-20 header-item float-end btn-vertical-sm-hover"
            id="vertical-hover"
          >
            <i className="ri-record-circle-line"></i>
          </button>
        </div>
        {layoutType === "horizontal" ? (
          <div id="scrollbar">
            <Container fluid>
              <div id="two-column-menu"></div>
              <ul className="navbar-nav" id="navbar-nav">
                <HorizontalLayout />
              </ul>
            </Container>
          </div>
        ) : layoutType === "twocolumn" ? (
          <React.Fragment>
            <TwoColumnLayout />
            <div className="sidebar-background"></div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <SimpleBar id="scrollbar" className="h-100">
              <Container fluid>
                <div id="two-column-menu"></div>
                <ul className="navbar-nav" id="navbar-nav">
                  <VerticalLayout layoutType={layoutType} />
                </ul>
              </Container>
            </SimpleBar>
            <div className="sidebar-background"></div>
          </React.Fragment>
        )}
        <div className="customizer-setting d-none d-md-block">
          <div
            onClick={handleOpenSettings}
            className="btn-info btn-rounded shadow-lg btn btn-icon btn-lg p-2"
          >
            <i className="mdi mdi-spin mdi-cog-outline fs-22"></i>
          </div>
        </div>
      </div>
      <div className="vertical-overlay"></div>
    </React.Fragment>
  );
};

export default withTranslation()(Sidebar);
