import { createSlice } from "@reduxjs/toolkit";

export const langSlide = createSlice({
  name: "lang",
  initialState: {
    data: null,
  },
  reducers: {
    getlang: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const { getlang } = langSlide.actions;
export default langSlide.reducer;
