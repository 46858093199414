import React, { lazy, useEffect, useState } from "react";
import Signs from "./Signs";
import PdfShow from "./PdfShow";
import { useParams, useSearchParams } from "react-router-dom";
import { DataService } from "../../helpers/dataService/dataService";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Row,
} from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { TranslationList } from "../../slices/settings/listtranslation";
import LoaderContent from "../../Components/Spinner";
import { useSelector } from "react-redux";
const EImzoModal = lazy(() => import("../../Components/EImzo/EImzoModal"));

export default function ApplicationShow() {
  const [loader, setLoader] = useState(false);
  const [show, setShow] = useState(false);
  const profile = useSelector((state) => state.profile?.data);
  const [signer, setSigner] = useState();
  const [reports, setReports] = useState();
  const params = useParams();
  const [modal_varying, setmodal_varying] = useState(false);
  const tog_varying = () => {
    setmodal_varying(!modal_varying);
  };
  const [application, setApplication] = useState();
  const getData = async () => {
    if (params?.id) {
      const response = await DataService.get(`application/${params?.id}`);
      setApplication(response);

      const responseReport = await DataService.get(
        `reports/?application=${params?.id}`
      );
      const lastRes =
        responseReport?.results[responseReport?.results?.length - 1];
      setReports(lastRes);
      if (lastRes?.id) {
        const responseSign = await DataService.get(
          `subscriber/?report=${lastRes?.id}`
        );
        setSigner(responseSign?.results);
        const responseSign1 = await DataService.get(
          `subscriber/?report=${lastRes?.id}&employee=${profile?.id}`
        );
        if (responseSign1?.results?.length > 0) {
          setShow(false);
        } else {
          setShow(true);
        }
      }
    }
  };
  useEffect(() => {
    getData();
  }, []);
  document.title = `INVESTCONTROL | ${TranslationList("Show_application")}`;

  return (
    <div>
      {reports ? (
        <EImzoModal
          modal_varying={modal_varying}
          tog_varying={tog_varying}
          report={reports}
        />
      ) : (
        ""
      )}
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            pageTitle={TranslationList("Applications")}
            title={TranslationList("Show_application")}
          />
          <Row>
            <Col xs={12}>
              <Card>
                {reports ? (
                  <div className="d-flex justify-content-between w-100">
                    <div style={{ width: "70%" }}>
                      <PdfShow
                        data={application}
                        report={reports}
                        signers={signer}
                      />
                    </div>
                    <div style={{ width: "28%" }} className="border  p-2">
                      <div className="w-100 h-100 d-flex flex-column">
                        <Signs signers={signer} />
                        {show &&
                          (profile?.roles[0]?.name == "Coordinator" ||
                            profile?.roles[0]?.name == "Deputy Director" ||
                            profile?.roles[0]?.name == "Director" ||
                            profile?.roles[0]?.name == "Finansist") && (
                            <div className="  w-100 mt-auto">
                              <div className="d-flex justify-content-end">
                                <button
                                  disabled={!(loader === false)}
                                  className="btn btn-primary w-100 mb-3"
                                  onClick={() => setmodal_varying(true)}
                                >
                                  {loader ? (
                                    <span>
                                      {TranslationList("application_sign...")}
                                    </span>
                                  ) : (
                                    <span>
                                      {TranslationList("application_sign")}
                                    </span>
                                  )}
                                </button>
                              </div>
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                ) : (
                  <LoaderContent />
                )}
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}
