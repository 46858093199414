import React, { useState, useEffect } from "react";
import {
  Collapse,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  NavbarToggler,
  NavItem,
} from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import LanguageDropdown from "../../Components/Common/LanguageDropdown";
import iscad from "../../assets/images/ISCAD-Logorb.png";
import { TranslationList } from "../../slices/settings/listtranslation";
import "./style.scss";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { useDispatch, useSelector } from "react-redux";
import Scrollspy from "react-scrollspy";
import LightDark from "../../Components/Common/LightDark";
const Navbar2 = ({ onChangeLayoutMode, layoutModeType }) => {
  const [isOpenMenu, setisOpenMenu] = useState(false);
  const [navClass, setnavClass] = useState("");
  const [logo, setLogo] = useState();
  const [LogoDark, setLogoDark] = useState();
  const [LogoLight, setLogoLight] = useState();
  const [sectorOpen, setSectorOpen] = useState(false);
  const sectors = useSelector((state) => state.sector?.data?.results);
  const navigate = useNavigate();
  const toggle = () => setisOpenMenu(!isOpenMenu);
  const dispatch = useDispatch();
  console.log("asa", layoutModeType);
  useEffect(() => {
    window.addEventListener("scroll", scrollNavigation, true);
  });
  useEffect(() => {
    const currentLanguage = localStorage.getItem("I18N_LANGUAGE_INVEST");
    if (currentLanguage == "ru") {
      setLogoLight(
        "https://www.agro.uz/wp-content/uploads/2021/10/logo-ru.svg"
      );
      setLogoDark(
        "https://www.agro.uz/wp-content/themes/seriouz-child/assets/img/logo-ru.svg"
      );
    } else {
      setLogoLight("https://www.agro.uz/wp-content/uploads/2021/10/logo1.svg");
      setLogoDark(
        "https://www.agro.uz/wp-content/themes/seriouz-child/assets/img/logo.svg"
      );
    }
  }, [localStorage.getItem("I18N_LANGUAGE_INVEST")]);
  const scrollNavigation = () => {
    const currentLanguage = localStorage.getItem("I18N_LANGUAGE_INVEST");

    var scrollup = document.documentElement.scrollTop;
    if (scrollup > 50) {
      setnavClass("is-sticky");
      if (currentLanguage == "ru") {
        setLogoLight(
          "https://www.agro.uz/wp-content/uploads/2021/10/logo-ru.svg"
        );
        setLogoDark(
          "https://www.agro.uz/wp-content/themes/seriouz-child/assets/img/logo-ru.svg"
        );
      } else {
        setLogoLight(
          "https://www.agro.uz/wp-content/uploads/2021/10/logo1.svg"
        );
        setLogoDark(
          "https://www.agro.uz/wp-content/themes/seriouz-child/assets/img/logo.svg"
        );
      }
    } else {
      setnavClass("");
      if (currentLanguage == "ru") {
        setLogoLight(
          "https://www.agro.uz/wp-content/uploads/2021/10/logo-ru.svg"
        );
        setLogoDark(
          "https://www.agro.uz/wp-content/themes/seriouz-child/assets/img/logo-ru.svg"
        );
      } else {
        setLogoLight(
          "https://www.agro.uz/wp-content/uploads/2021/10/logo1.svg"
        );
        setLogoDark(
          "https://www.agro.uz/wp-content/themes/seriouz-child/assets/img/logo.svg"
        );
      }
    }
  };

  return (
    <React.Fragment>
      <nav
        className={
          "navbar navbar-expand-lg navbar-landing navbar-light fixed-top " +
          "is-sticky"
        }
        id="navbar"
      >
        <div className="w-100 d-flex justify-content-center align-items-center nav1">
          <Link
            className=" nav-link navbar-brand d-flex align-items-center"
            to="/"
          >
            <img
              src={LogoDark}
              className="card-logo card-logo-dark"
              alt="logo dark"
              width={200}
            />
            <img
              src={LogoLight}
              className="card-logo card-logo-light"
              alt="logo light"
              width={200}
            />
          </Link>
          <img src={iscad} alt="" width={150} />

          <NavbarToggler
            className="navbar-toggler py-0 fs-20 text-body"
            onClick={toggle}
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <i className="mdi mdi-menu"></i>
          </NavbarToggler>

          <Collapse className="navbar-collapse" id="navbarSupportedContent">
            <Scrollspy
              items={["hero", "sectors", "projects", "createapplication"]}
              currentClassName="active"
              className="navbar-nav mx-auto mt-2 mt-lg-0"
              id="navbar-example"
            >
              <li className="nav-item">
                <Link className=" nav-link fs-14 fw-semibold" to="/#hero">
                  {TranslationList("home")}
                </Link>
              </li>

              <li className="nav-item">
                <Link className=" nav-link fs-14 fw-semibold" to="/#projects">
                  {TranslationList("Projects")}
                </Link>
              </li>
              <li className="nav-item">
                <Dropdown
                  nav
                  onMouseLeave={() => setSectorOpen(false)}
                  onMouseOver={() => setSectorOpen(true)}
                  isOpen={sectorOpen}
                  toggle={() => setSectorOpen(!sectorOpen)}
                >
                  <DropdownToggle nav caret className="fs-14 fw-semibold">
                    {TranslationList("Sectors")}
                  </DropdownToggle>
                  <DropdownMenu>
                    {sectors?.map((sector, index) => (
                      <DropdownItem
                        key={index}
                        onClick={() =>
                          navigate(`/sectordetail?id=${sector.id}`)
                        }
                      >
                        <Link className="nav-link fs-14 fw-semibold">
                          {sector.name}
                        </Link>
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </Dropdown>
              </li>
              <li className="nav-item">
                <Link
                  className=" nav-link fs-14 fw-semibold"
                  to="/#createapplication"
                >
                  {TranslationList("Send_application")}
                </Link>
              </li>
            </Scrollspy>
            {/* <Link to="/send-application" width={100} className="btn btn-success add-btn">
              {TranslationList("Send_application")}
            </Link> */}
            <LightDark
              layoutMode={layoutModeType}
              onChangeLayoutMode={onChangeLayoutMode}
            />
            <div className="navbar-nav">
              <LanguageDropdown />
            </div>
          </Collapse>
          <div className="icon_login">
            <Link to="/login" width={100}>
              <FeatherIcon icon="log-in" />
            </Link>
          </div>
        </div>
      </nav>
    </React.Fragment>
  );
};

export default Navbar2;
