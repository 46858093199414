import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Container,
  Row,
  Table,
  CardHeader,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Input,
  Button,
} from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { useDispatch, useSelector } from "react-redux";

import { toast } from "react-toastify";
import DeleteModal from "../../Components/Common/DeleteModal";
import { TranslationList } from "../../slices/settings/listtranslation";
import LoaderContent from "../../Components/Spinner";
import Paginations from "../Tables/DataTables/Pagination";
import { deleteusers, getusersAsync } from "../../slices/users";
import { getregionAsync } from "../../slices/regions";
import {
  getdistrictAsync,
  getdistrictCurrentAsync,
} from "../../slices/district";
import { getroleAsync } from "../../slices/role";
import Select from "react-select";
import { columnUserAsync } from "../../slices/user/reducer";
import ColumnModal from "../../Components/Common/ColumnModal";
export default function Users() {
  const dispatch = useDispatch();
  const users = useSelector((state) => state.users?.data);
  const columnsData = useSelector((state) => state.columnsUserTable);
  const [current, setCuttent] = useState();
  const [isColumn, setIsColumn] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const perpage = localStorage.getItem("perpage") ?? 10;
  const [perPage, setPerpage] = useState(perpage);
  const lang = useSelector((state) => state.lang.data);
  const navigate = useNavigate();
  const toggleColumn = () => {
    setIsColumn(!isColumn);
  };
  useEffect(() => {
    dispatch(
      columnUserAsync([
        { name: TranslationList("Username"), status: true },
        { name: TranslationList("First name"), status: true },
        { name: TranslationList("Last name"), status: true },
        { name: TranslationList("Organization"), status: true },
        { name: TranslationList("Region"), status: true },
        { name: TranslationList("District"), status: false },
        { name: TranslationList("Address"), status: false },
        { name: TranslationList("Mahalla"), status: false },
        { name: TranslationList("PinFL"), status: false },
        { name: TranslationList("Description"), status: false },
        { name: TranslationList("Surname"), status: false },
        { name: TranslationList("Gender"), status: false },
        { name: TranslationList("Pasport Seria"), status: false },
        { name: TranslationList("Pasport Number"), status: false },
        { name: TranslationList("Inn"), status: false },
        { name: TranslationList("Birthday"), status: false },
        { name: TranslationList("Email"), status: false },
        { name: TranslationList("Roles"), status: false },
      ])
    );
    dispatch(getusersAsync(`user/?page=${1}&limit=${perPage}`));
  }, [lang]);
  const handleSearch = (e) => {
    dispatch(getusersAsync(`user/?search=${e}`));
  };
  const handleSave = (data) => {
    console.log("data", data);
  };
  const onClickTodoDelete = (item) => {
    setCuttent(item);
    setDeleteModal(true);
  };
  const handleDelete = async () => {
    const response = await deleteusers(`user/${current?.id}`);
    if (response?.errorCode) {
      toast.error(TranslationList("An error has occurred!"));
    } else {
      toast.success(TranslationList("Done successfully!"));
      dispatch(getusersAsync(`user/`));
    }
    setDeleteModal(false);
  };

  //Pagination begin

  const handleCurrentPage = (e) => {
    dispatch(getusersAsync(`user/?page=${e}&limit=${perPage}`));
  };
  const handlePerPageChange = (e) => {
    setPerpage(e);
    dispatch(getusersAsync(`user/?limit=${e}`));
  };
  //Pagination end
  document.title = `INVESTCONTROL | ${TranslationList("User")}`;

  return (
    <div className="page-content">
      <DeleteModal
        show={deleteModal}
        data={current?.name}
        onDeleteClick={handleDelete}
        onCloseClick={() => setDeleteModal(false)}
      />
      <ColumnModal
        show={isColumn}
        handleSaveColumn={handleSave}
        onCloseClick={() => setIsColumn(false)}
        column={columnsData?.columns}
      />

      {columnsData?.columns && users ? (
        <Container fluid>
          <BreadCrumb
            title={TranslationList("Users")}
            pageTitle={TranslationList("home")}
          />
          <Row>
            <Card>
              <CardHeader>
                <Row className="align-items-center gy-3">
                  <div className="col-sm">
                    <h5 className="card-title mb-0">
                      {" "}
                      {TranslationList("Users")}
                    </h5>
                  </div>
                  <div className="col-sm-auto d-flex justify-content-between">
                    <div className="px-2">
                      <div className="search-box">
                        <Input
                          type="text"
                          className="form-control search"
                          placeholder={TranslationList("List Search")}
                          onChange={(e) => handleSearch(e.target.value)}
                        />
                        <i className="ri-search-line search-icon"></i>
                      </div>
                    </div>
                    <div className="d-flex gap-1 flex-wrap">
                      <button
                        type="button"
                        className="btn btn-warning"
                        onClick={toggleColumn}
                      >
                        <i className=" ri-menu-add-line me-1 align-bottom"></i>{" "}
                        {TranslationList("Columns")}
                      </button>{" "}
                      <Link to="/profile">
                        <button
                          type="button"
                          className="btn btn-success add-btn"
                          id="create-btn"
                        >
                          <i className="ri-add-line align-bottom me-1"></i>{" "}
                          {TranslationList("Add")}
                        </button>{" "}
                      </Link>
                    </div>
                  </div>
                </Row>
              </CardHeader>

              <CardBody>
                <div className="live-preview p-3">
                  <div className="text-muted table-responsive">
                    <Table className="table-bordered align-middle table-nowrap mb-0">
                      <thead>
                        <tr>
                          <th scope="col" className="text-center">
                            {TranslationList("Number")}
                          </th>
                          {columnsData?.columns[0]?.status && (
                            <th scope="col" className="text-center">
                              {TranslationList("Username")}
                            </th>
                          )}
                          {columnsData?.columns[1]?.status && (
                            <th scope="col" className="text-center">
                              {TranslationList("First name")}
                            </th>
                          )}
                          {columnsData?.columns[2]?.status && (
                            <th scope="col" className="text-center">
                              {TranslationList("Last name")}
                            </th>
                          )}
                          {columnsData?.columns[3]?.status && (
                            <th scope="col" className="text-center">
                              {TranslationList("Organization")}
                            </th>
                          )}
                          {columnsData?.columns[4]?.status && (
                            <th scope="col" className="text-center">
                              {TranslationList("Region")}
                            </th>
                          )}
                          {columnsData?.columns[5]?.status && (
                            <th scope="col" className="text-center">
                              {TranslationList("District")}
                            </th>
                          )}
                          {columnsData?.columns[6]?.status && (
                            <th scope="col" className="text-center">
                              {TranslationList("Address")}
                            </th>
                          )}
                          {columnsData?.columns[7]?.status && (
                            <th scope="col" className="text-center">
                              {TranslationList("Mahalla")}
                            </th>
                          )}
                          {columnsData?.columns[8]?.status && (
                            <th scope="col" className="text-center">
                              {TranslationList("PinFL")}
                            </th>
                          )}
                          {columnsData?.columns[9]?.status && (
                            <th scope="col" className="text-center">
                              {TranslationList("Description")}
                            </th>
                          )}
                          {columnsData?.columns[10]?.status && (
                            <th scope="col" className="text-center">
                              {TranslationList("Surname")}
                            </th>
                          )}
                          {columnsData?.columns[11]?.status && (
                            <th scope="col" className="text-center">
                              {TranslationList("Gender")}
                            </th>
                          )}
                          {columnsData?.columns[12]?.status && (
                            <th scope="col" className="text-center">
                              {TranslationList("Pasport Seria")}
                            </th>
                          )}
                          {columnsData?.columns[13]?.status && (
                            <th scope="col" className="text-center">
                              {TranslationList("Pasport Number")}
                            </th>
                          )}
                          {columnsData?.columns[14]?.status && (
                            <th scope="col" className="text-center">
                              {TranslationList("Inn")}
                            </th>
                          )}
                          {columnsData?.columns[15]?.status && (
                            <th scope="col" className="text-center">
                              {TranslationList("Birthday")}
                            </th>
                          )}
                          {columnsData?.columns[16]?.status && (
                            <th scope="col" className="text-center">
                              {TranslationList("Email")}
                            </th>
                          )}
                          {columnsData?.columns[17]?.status && (
                            <th scope="col" className="text-center">
                              {TranslationList("Roles")}
                            </th>
                          )}
                          <th scope="col " className="text-center">
                            {TranslationList("Actions")}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {users?.results?.map((res, i) => (
                          <tr key={res?.id}>
                            <td className="text-center">{i + 1}</td>
                            {columnsData?.columns[0]?.status && (
                              <td>{res?.username}</td>
                            )}
                            {columnsData?.columns[1]?.status && (
                              <td>{res?.first_name}</td>
                            )}
                            {columnsData?.columns[2]?.status && (
                              <td>{res?.last_name}</td>
                            )}
                            {columnsData?.columns[3]?.status && (
                              <td>{res?.organizationinfo?.name}</td>
                            )}
                            {columnsData?.columns[4]?.status && (
                              <td>
                                {res?.organizationinfo?.region_detail?.name}
                              </td>
                            )}
                            {columnsData?.columns[5]?.status && (
                              <td>
                                {res?.organizationinfo?.district_detail?.name}
                              </td>
                            )}
                            {columnsData?.columns[6]?.status && (
                              <td>{res?.organizationinfo?.address}</td>
                            )}
                            {columnsData?.columns[7]?.status && (
                              <td>{res?.organizationinfo?.mahalla}</td>
                            )}
                            {columnsData?.columns[8]?.status && (
                              <td>{res?.organizationinfo?.pinfl}</td>
                            )}
                            {columnsData?.columns[9]?.status && (
                              <td>{res?.organizationinfo?.description}</td>
                            )}
                            {columnsData?.columns[10]?.status && (
                              <td>{res?.surname}</td>
                            )}
                            {columnsData?.columns[11]?.status && (
                              <td>{res?.gender}</td>
                            )}
                            {columnsData?.columns[12]?.status && (
                              <td>{res?.pasport_seria}</td>
                            )}
                            {columnsData?.columns[13]?.status && (
                              <td>{res?.pasport_number}</td>
                            )}
                            {columnsData?.columns[14]?.status && (
                              <td>{res?.inn}</td>
                            )}
                            {columnsData?.columns[15]?.status && (
                              <td>{res?.date_of_birthday}</td>
                            )}
                            {columnsData?.columns[16]?.status && (
                              <td>{res?.email}</td>
                            )}
                            {columnsData?.columns[17]?.status && (
                              <td>
                                {res?.roles?.map((rol, j) => (
                                  <p key={rol?.id}>{rol?.name}</p>
                                ))}
                              </td>
                            )}
                            <td className="text-center">
                              <div className="hstack gap-2 justify-content-center">
                                <button
                                  className="btn btn-sm btn-soft-danger remove-list"
                                  onClick={() => onClickTodoDelete(res)}
                                >
                                  <i className="ri-delete-bin-5-fill align-bottom" />
                                </button>
                                <button
                                  className="btn btn-sm btn-soft-info edit-list"
                                  onClick={() => navigate(`/users/${res.id}`)}
                                >
                                  <i className="ri-pencil-fill align-bottom" />
                                </button>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </CardBody>
            </Card>
            <Paginations
              totalItems={users?.pagination?.total}
              perPageChange={(e) => handlePerPageChange(e)}
              currentPage={(e) => handleCurrentPage(e)}
            />
          </Row>
        </Container>
      ) : (
        <LoaderContent />
      )}
    </div>
  );
}
