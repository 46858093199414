import { Card, CardBody, CardHeader, Container, Row } from "reactstrap";
import tableToExcel from "@linways/table-to-excel";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import React, { useEffect, useState } from "react";
import PivotTableUI from "react-pivottable/PivotTableUI";
import "react-pivottable/pivottable.css";
import TableRenderers from "react-pivottable/TableRenderers";
import Plot from "react-plotly.js";
import createPlotlyRenderers from "react-pivottable/PlotlyRenderers";
// import tips from "../data/tips";
import "./pivotTable.scss";
import { TranslationList } from "../../../slices/settings/listtranslation";
import { DataService } from "../../../helpers/dataService/dataService";
import endpoints from "../../../endpoints";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import LoaderContent from "../../../Components/Spinner";
import { sortAs } from "react-pivottable/Utilities";
import PivotTable from "react-pivottable/PivotTable";

const PlotlyRenderers = createPlotlyRenderers(Plot);

export default function ReportShow() {
  const params = useParams();
  const [tips, setTips] = useState();
  const [current, setCurrent] = useState();
  const [state, setState] = useState({});
  const lang = useSelector((state) => state.lang.data);

  useEffect(() => {
    fetchData();
  }, [lang, params]);
  const fetchData = async () => {
    if (params?.id) {
      const response = await DataService.get(
        endpoints.analyticReportById(params?.id)
      );
      const typeresponse = await DataService.get(
        endpoints.analyticReportById(response?.api_url)
      );
      const customData = typeresponse?.results?.map((data) => {
        let newObj = {};
        for (let key in data) {
          if (data.hasOwnProperty(key)) {
            let value = data[key];
            newObj[TranslationList(key)] = value;
          }
        }
        return newObj;
      });
      setTips(customData);
      if (response?.text_json) {
        const s = JSON.parse(response?.text_json);
        setState({
          aggregatorName: s.aggregatorName,
          rendererName: s.rendererName,
          rows: s.rows?.map((item) => TranslationList(item)),
          cols: s.cols?.map((item) => TranslationList(item)),
          vals: s.vals?.map((item) => TranslationList(item)),
        });
      } else {
        setState({
          aggregatorName: "Average",
          rendererName: "Table",
          rows: ["country", "subject"],
          cols: ["sex"],
          vals: ["age"],
        });
      }
      setCurrent(response);
    }
  };

  const handleExportClick = () => {
    var htmlTable = document.querySelector(".pvtTable")?.cloneNode(true);
    htmlTable.setAttribute("data-cols-width", "15,15,10");
    if (htmlTable) {
      const htmlTableHead = htmlTable.querySelector("thead");
      const htmlHeadRows = htmlTableHead.querySelectorAll("tr");
      htmlHeadRows.forEach((headRow) => {
        const htmlHeadCells = headRow.querySelectorAll("th");
        htmlHeadCells.forEach((htmlCell) => {
          const isAxisLabel = htmlCell.classList.contains("pvtAxisLabel");
          const isColLabel = htmlCell.classList.contains("pvtColLabel");
          const isTotalLabel = htmlCell.classList.contains("pvtTotalLabel");

          if (isAxisLabel) {
            htmlCell.setAttribute("data-a-h", "justify");
            htmlCell.setAttribute("data-a-v", "middle");
            htmlCell.setAttribute("data-f-bold", "true");
            htmlCell.setAttribute("data-b-a-c", "00000000");
            htmlCell.setAttribute("data-b-a-s", "thin");
          }
          if (isColLabel) {
            htmlCell.setAttribute("data-a-h", "center");
            htmlCell.setAttribute("data-a-v", "middle");
            htmlCell.setAttribute("data-b-a-c", "00000000");
            htmlCell.setAttribute("data-b-a-s", "thin");
            htmlCell.style.border = "thin"; // Add border style
          }
          if (isTotalLabel) {
            htmlCell.setAttribute("data-exclude", "true");
          }
        });
      });

      const htmlTableBody = htmlTable.querySelector("tbody");
      const htmlBodyRows = htmlTableBody.querySelectorAll("tr");
      htmlBodyRows.forEach((bodyRow) => {
        const htmlBodyCells = bodyRow.querySelectorAll("th, td");
        htmlBodyCells.forEach((htmlCell) => {
          const isRowLabel = htmlCell.classList.contains("pvtRowLabel");
          const isValue = htmlCell.classList.contains("pvtVal");
          const isTotal = htmlCell.classList.contains("pvtTotal");
          const isTotalLabel = htmlCell.classList.contains("pvtTotalLabel");
          const isGrandTotal = htmlCell.classList.contains("pvtGrandTotal");

          if (isRowLabel) {
            htmlCell.setAttribute("data-a-h", "justify");
            htmlCell.setAttribute("data-a-v", "middle");
            htmlCell.setAttribute("data-b-a-c", "00000000");
            htmlCell.setAttribute("data-b-a-s", "thin");
            htmlCell.style.border = "thin"; // Add border style
          }
          if (isValue) {
            htmlCell.setAttribute("data-a-h", "right");
            htmlCell.setAttribute("data-a-v", "middle");
            htmlCell.setAttribute("data-t", "n");
            htmlCell.setAttribute("data-b-a-c", "00000000");
            htmlCell.setAttribute("data-b-a-s", "thin");
            htmlCell.style.border = "thin"; // Add border style
          }
          if (isTotal || isTotalLabel || isGrandTotal) {
            htmlCell.setAttribute("data-exclude", "true");
          }
        });
      });

      tableToExcel.convert(htmlTable, { name: "report.xlsx" });
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            link="/analytics/reports"
            title="Reports"
            pageTitle="Analytics"
          />

          <Card>
            <CardHeader>
              <Row className="align-items-center gy-3">
                <div className="col-sm">
                  <h5 className="card-title mb-0">{current?.title}</h5>
                </div>
                <div className="col-sm-auto">
                  <div className="d-flex gap-1 flex-wrap">
                    {state?.rendererName ==
                      ("Table" ||
                        "Table Heatmap" ||
                        "Table Col Heatmap" ||
                        "Table Row Heatmap" ||
                        "Exportable TSV") && (
                      <button
                        type="button"
                        className="btn btn-info"
                        onClick={handleExportClick}
                      >
                        <i className="ri-file-download-line align-bottom me-1"></i>{" "}
                        {TranslationList("Export")}
                      </button>
                    )}
                  </div>
                </div>
              </Row>
            </CardHeader>

            <CardBody>
              <Row className="w-100 overflow-auto tableShow text-muted table">
                {tips ? (
                  <PivotTableUI
                    data={tips}
                    onChange={(s) => {
                      setState({ ...state, ...s });
                    }}
                    renderers={Object.assign(
                      {},
                      TableRenderers,
                      PlotlyRenderers
                    )}
                    {...state}
                  />
                ) : (
                  <LoaderContent />
                )}
              </Row>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
}
