import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  CardBody,
  Card,
  Modal,
  ModalHeader,
  Button,
} from "reactstrap";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { TranslationList } from "../../slices/settings/listtranslation";
import { useDispatch, useSelector } from "react-redux";
import {
  getprojectFilterAsync,
  getselectProjectAsync,
} from "../../slices/project";
import "./style.scss";
import { getselectProject } from "../../slices/selectProject";
import AOS from "aos";
import "aos/dist/aos.css";
import LoaderContent from "../../Components/Spinner";

const Projects = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const sectors = useSelector((state) => state.sector?.data?.results);
  const landingSector = useSelector((state) => state.landingSector.data);

  const [projects, setProject] = useState();
  const [nav, setNav] = useState(0);
  const [modal_large, setmodal_large] = useState(false);
  const [seachParams] = useSearchParams();
  function tog_large() {
    setmodal_large(!modal_large);
  }

  const handleStatus = (cell) => {
    switch (cell) {
      case "completed".toLocaleUpperCase():
        return <span>{TranslationList("Fulfilled")} </span>;
      case "draft".toLocaleUpperCase():
        return <span>{TranslationList("Draft")}</span>;
      case "removed".toLocaleUpperCase():
        return <span> {TranslationList("Accepted for processing")} </span>;
      case "in_progress".toLocaleUpperCase():
        return <span> {TranslationList("In consideration")} </span>;
      case "under_consideration".toLocaleUpperCase():
        return <span>{TranslationList("In consideration")} </span>;
      case "NEW":
        return <span> {TranslationList("New")} </span>;
      default:
        return <span> {TranslationList("On completion")}</span>;
    }
  };
  const hanleChange = async (item) => {
    setProject();
    setNav(item);
    if (item == 0) {
      const response = await getprojectFilterAsync(`projects/list`);
      setProject(response?.results);
    } else {
      const response = await getprojectFilterAsync(`projects/list?sectors=${item}`);
      setProject(response?.results);
    }
  };
  const [currentData, setCurrentData] = useState();
  const selectProjectData = (id) => {
    dispatch(getselectProject(id));
  };
  const getData = async () => {
    if (!landingSector) {
      const response = await getprojectFilterAsync(`projects/list`);
      setProject(response?.results);
    } else {
      setNav(landingSector);

      const response = await getprojectFilterAsync(
        `projects/list?sectors=${landingSector}`
      );
      setProject(response?.results);
    }
  };
  useEffect(() => {
    getData();
    console.log(landingSector);
  }, [landingSector]);

  return (
    <React.Fragment>
      <section className="section bg-light" id="projects">
        <Container>
          <Row className="justify-content-center">
            <Col lg={12}>
              <div className="text-center mb-5">
                <h2 className="mb-3 fw-bold lh-base">
                  {TranslationList("Projects")}
                </h2>
                <Nav
                  pills
                  className="nav-pills filter-btns justify-content-center"
                  role="tablist"
                >
                  <NavItem role="presentation">
                    <NavLink
                      type="button"
                      onClick={() => hanleChange(0)}
                      className={nav == 0 ? "fw-medium active" : "fw-medium"}
                    >
                      {TranslationList("All Projects")}
                    </NavLink>
                  </NavItem>
                  {sectors?.map((res) => (
                    <NavItem role="presentation" key={res?.id}>
                      <NavLink
                        type="button"
                        onClick={() => hanleChange(res?.id)}
                        className={
                          nav == res?.id ? "fw-medium active" : "fw-medium"
                        }
                      >
                        {res?.name}
                      </NavLink>
                    </NavItem>
                  ))}
                </Nav>
              </div>
            </Col>
          </Row>
          <div className="row">
            {projects ? (
              projects?.map((item, index) => (
                <React.Fragment key={index}>
                  <Col xxl={4} sm={6} className="project-card ">
                    <div
                      onClick={() => {
                        tog_large(true);
                        setCurrentData(item);
                      }}
                      className="cursor-pointer card border card-border-success cards-shadow ribbon-box right overflow-hidden"
                    >
                      <CardBody className=" p-4 h-50">
                        <div
                          className={`ribbon ribbon-success ribbon-shape trending-ribbon`}
                        >
                          <i className="ri-flashlight-fill text-white align-bottom"></i>{" "}
                          <span className="trending-ribbon-text">
                            {handleStatus(item?.status)}
                          </span>
                        </div>
                        <div className={`p-3 mt-n3 mx-n3   rounded-top `}>
                          <div className="d-flex align-items-center">
                            <div className="flex-grow-1"></div>
                          </div>
                        </div>
                        <Link
                          to="#"
                          className="text-dark"
                          onClick={() => tog_large(true)}
                        >
                          <h5 className="mb-0 fs-14 fw-bold mt-3 ">
                            {item?.name}
                          </h5>
                        </Link>
                        <div className="mt-3 fs-14">
                          {/* <p>Description</p> */}
                          <div className="mb-1">
                            <div
                              className="text-wrap text-truncate-multi-lines"
                              dangerouslySetInnerHTML={{
                                __html: item?.description,
                              }}
                            />
                          </div>
                        </div>

                        <div className="py-3">
                          <h5 className="mb-0 fs-14 fw-bold mt-3">
                            {TranslationList(
                              "Issued_through_the_following_commercial_banks"
                            )}
                            :
                          </h5>
                          <div>
                            {item?.organization_detail
                              ?.slice(1, 4)
                              .map((res) => (
                                <p className="" key={res?.id}>
                                  {res?.name}
                                </p>
                              ))}
                          </div>
                        </div>

                        {/* <div className="d-flex justify-content-center mt-3">
                            <button
                              className="btn btn-success add-btn"
                              onClick={() => {
                                selectProjectData(item?.id);
                                navigate("/login");
                              }}
                            >
                              {TranslationList("Send_application")}
                            </button>
                          </div> */}
                      </CardBody>
                    </div>
                  </Col>
                </React.Fragment>
              ))
            ) : (
              <LoaderContent />
            )}
          </div>
        </Container>
      </section>
      <Modal
        size="lg"
        isOpen={modal_large}
        toggle={() => {
          tog_large();
        }}
      >
        <ModalHeader
          id="myLargeModalLabel"
          toggle={() => {
            tog_large();
          }}
        >
          <Link to="#" className="text-dark" onClick={() => tog_large(true)}>
            <h5 className="fw-bold">{currentData?.name}</h5>
          </Link>{" "}
        </ModalHeader>
        <div className="modal-body">
          <div className=" fs-14">
            {/* <p>Description</p> */}
            <div
              className="text-wrap "
              dangerouslySetInnerHTML={{
                __html: currentData?.description,
              }}
            />
          </div>
          <div className="py-3">
            <h5 className="mb-2 fs-14 fw-bold">
              {TranslationList("Sectors")}
              :
            </h5>
            <div>
              {currentData?.sectors_detail?.map((res) => (
                <p className="" key={res?.id}>
                  {res?.name}
                </p>
              ))}
            </div>
          </div>
          <div className="py-3">
            <h5 className="mb-2 fs-14 fw-bold">
              {TranslationList("Issued_through_the_following_commercial_banks")}
              :
            </h5>
            <div>
              {currentData?.organization_detail?.map((res) => (
                <p className="" key={res?.id}>
                  {res?.name}
                </p>
              ))}
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <button
            className="btn btn-success add-btn"
            onClick={() => {
              selectProjectData(currentData?.id);
              navigate("/login");
            }}
          >
            {TranslationList("Send_application")}
          </button>
          <Link
            to="#"
            className="btn btn-link link-success fw-medium"
            onClick={() => setmodal_large(false)}
          >
            <i className="ri-close-line me-1 align-middle"></i>{" "}
            {TranslationList("Close")}
          </Link>
          {/* <Button color="primary">Save changes</Button> */}
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default Projects;
