import { createSlice } from "@reduxjs/toolkit";

export const selectProjectSlide = createSlice({
  name: "selectProject",
  initialState: {
    data: null,
  },
  reducers: {
    getselectProject: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const { getselectProject } = selectProjectSlide.actions;
export default selectProjectSlide.reducer;
