import { createSlice } from "@reduxjs/toolkit";
import { DataService } from "../helpers/dataService/dataService";

export const attachmentSlide = createSlice({
  name: "attachment",
  initialState: {
    data: null,
    error: null,
    current: null,
  },
  reducers: {
    getattachment: (state, action) => {
      state.data = action.payload;
    },
    currentattachment: (state, action) => {
      state.current = action.payload;
    },
  },
});
export const addattachmentAsync = async (url, data) => {
  try {
    const datas = await DataService.post(url, data);
    return datas;
  } catch (error) {
    return error;
  }
};
export const getattachmentAsync = async (data) => {
  try {
    const datas = await DataService.get(data);
    return datas;
  } catch (error) {
    return error;
  }
};
export const editattachmentAsync = async (url, data) => {
  try {
    const datas = await DataService.put(url, data);
    return datas;
  } catch (error) {
    return error;
  }
};
export const currentattachmentAsync = (data) => async (dispatch) => {
  try {
    const datas = await DataService.get(data);
    dispatch(currentattachment(datas));
  } catch (error) {
    // //throw new Error(err);
  }
};
export const deleteattachmentation = async (data) => {
  try {
    const datas = await DataService.delete(data);
    return datas;
  } catch (error) {
    return error;
  }
};

export const { getattachment, currentattachment } = attachmentSlide.actions;
export default attachmentSlide.reducer;
