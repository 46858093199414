import React, { useState } from "react";
import {
  Col,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import classnames from "classnames";

//import images
import avatar2 from "../../assets/images/users/avatar-2.jpg";
import avatar8 from "../../assets/images/users/avatar-8.jpg";
import avatar3 from "../../assets/images/users/avatar-3.jpg";
import avatar6 from "../../assets/images/users/avatar-6.jpg";
import bell from "../../assets/images/svg/bell.svg";

//SimpleBar
import SimpleBar from "simplebar-react";
import { useDispatch, useSelector } from "react-redux";
import { DataService } from "../../helpers/dataService/dataService";
import { getnotificationAsync } from "../../slices/notification";
import { TranslationList } from "../../slices/settings/listtranslation";

const NotificationDropdown = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  function datesubtraction(date) {
    const aa = new Date() - new Date(date);
    const a = Math.abs(aa);
    const diffDays = Math.ceil(a / (1000 * 60 * 60 * 24));
    const hour = Math.ceil(a / (1000 * 60 * 60));
    const minut = Math.ceil(a / (1000 * 60));
    if (diffDays > 1) {
      return diffDays + "  day ago";
    } else {
      if (hour > 1) {
        return hour + " hours ago";
      } else {
        return minut + " minut ago";
      }
    }
  }

  const notification = useSelector((state) => state.notification?.data);
  //Dropdown Toggle
  const [isNotificationDropdown, setIsNotificationDropdown] = useState(false);
  const toggleNotificationDropdown = () => {
    setIsNotificationDropdown(!isNotificationDropdown);
  };

  //Tab
  const [activeTab, setActiveTab] = useState("1");
  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };
  const handleChange = async (e) => {
    const data = { is_read: 1 };
    const response = await DataService.put(`notification/${e?.id}`, data);
    if (!response?.errorCode) {
      dispatch(getnotificationAsync("notification/?is_read=0"));
      toggleNotificationDropdown();
      navigate(`/application-detail?show_id=${e?.application}`);
    }
  };
  return (
    <React.Fragment>
      <Dropdown
        isOpen={isNotificationDropdown}
        toggle={toggleNotificationDropdown}
        className="topbar-head-dropdown ms-1 header-item"
      >
        <DropdownToggle
          type="button"
          tag="button"
          className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
        >
          <i className="bx bx-bell fs-22"></i>
          <span className="position-absolute topbar-badge fs-10 translate-middle badge rounded-pill bg-danger">
            {notification?.pagination?.total}
            <span className="visually-hidden">{TranslationList("Unread messages")}</span>
          </span>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-lg dropdown-menu-end p-0">
          <div className="dropdown-head bg-primary bg-pattern rounded-top">
            <div className="p-3">
              <Row className="align-items-center">
                <Col>
                  <h6 className="m-0 fs-16 fw-semibold text-white">
                    {" "}
                    {TranslationList("Notifications")}{" "}
                  </h6>
                </Col>
              </Row>
            </div>
          </div>
          {notification?.pagination?.total != 0 ? (
            <SimpleBar style={{ maxHeight: "300px" }} className="pe-2">
              {notification?.results?.map((res, i) => (
                <div
                  key={i}
                  className="text-reset notification-item d-block dropdown-item position-relative"
                  onClick={() => handleChange(res)}
                >
                  <div className="d-flex">
                    <div className="flex-1">
                      <Link to="#" className="stretched-link">
                        <h6 className="mt-0 mb-1 fs-13 fw-semibold">
                          {res?.userinfo.first_name} {res?.userinfo.last_name}{" "}
                        </h6>
                      </Link>
                      <div className="fs-13 text-muted">
                        <p className="mb-1">{res?.title}</p>
                      </div>
                      <p className="mb-0 fs-11 fw-medium text-uppercase text-muted">
                        <span>
                          <i className="mdi mdi-clock-outline"></i>{" "}
                          {datesubtraction(res.created_time)}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              ))}

              <div className="my-3 text-center">
                <button
                  type="button"
                  className="btn btn-soft-success waves-effect waves-light"
                >
                  {TranslationList("View all notifications")}{" "}
                  <i className="ri-arrow-right-line align-middle"></i>
                </button>
              </div>
            </SimpleBar>
          ) : (
            <div>
              <div className="w-25 w-sm-50 pt-3 mx-auto">
                <img src={bell} className="img-fluid" alt="user-pic" />
              </div>
              <div className="text-center pb-5 mt-2">
                <h6 className="fs-16 fw-semibold lh-base">
                  {TranslationList("Hello! You don't have notifications")}{" "}
                </h6>
              </div>
            </div>
          )}
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default NotificationDropdown;
