import { loginSuccess, logoutUserSuccess, apiError } from "./reducer";
import { getprofileAsync } from "../profile";
import { getnotificationAsync } from "../../notification";
import { DataService } from "../../../helpers/dataService/dataService";
export const loginUser = (user, history) => async (dispatch) => {
  try {
    const data = await DataService.postNoAuth("auth/token/", {
      username: user.username,
      password: user.password,
    });
    if (data) {
      sessionStorage.setItem("authUser", JSON.stringify(data));
      dispatch(loginSuccess(data));
      dispatch(getprofileAsync("user-view/"));
      dispatch(getnotificationAsync("notification/?is_read=0"));
      history("/applications");
    }
  } catch (error) {
    if (error?.message?.password) dispatch(apiError(error.message?.password));
    else {
      dispatch(apiError(error.message?.username));
    }
  }
};

export const logoutUser = () => async (dispatch) => {
  try {
    sessionStorage.removeItem("authUser");

    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response = fireBaseBackend.logout;
      dispatch(logoutUserSuccess(response));
    } else {
      dispatch(logoutUserSuccess(true));
    }
  } catch (error) {
    dispatch(apiError(error));
  }
};

