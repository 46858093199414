import { combineReducers } from "redux";

// Front
import LayoutReducer from "./layouts/reducer";

// Authentication
import LoginReducer from "./auth/login/reducer";
import AccountReducer from "./auth/register/reducer";
import ProfileReducer from "./auth/profile/reducer";

// API Key
import tableselectReducer from "./table";
import columnReducer from "./application/reducer";
import organizationSlide from "./organization";
import regionSlide from "./regions";
import districtSlide from "./district";
import sectorSlide from "./sector";
import pfiSlide from "./pfi";
import applicSlide from "./application";
import foaSlide from "./foa";
import currencySlide from "./currency";
import documentSlide from "./document";
import attachmentSlide from "./attachment";
import departmentSlide from "./department";
import positionSlide from "./position";
import translationsSlide from "./settings/translations";
import chronologySlide from "./chronology";
import profileSlide from "./auth/profile";
import notificationSlide from "./notification";
import activityTypeSlide from "./activityType";
import projectSlide from "./project";
import organizationBranchSlide from "./organizationBranch";
import entityTypeSlide from "./entityType";
import exchangeRatesSlide from "./exchangeRates";
import langSlide from "./lang";
import usersSlide from "./users";
import roleSlide from "./role";
import columnProjectReducer from "./project/reducer";
import columnOrganizationReducer from "./organizations/reducer";
import columnUserReducer from "./user/reducer";
import columnOrganizationBranchReducer from "./organizationBranch/reducer";
import selectProjectSlide from "./selectProject";
import landingApplicationSlide from "./landingApplication";
import landingSectorSlide from "./landing/sector";
import dataMapSlide from "./map";
import toggleLeftCanvasSlide from "./toggleLeftCanvas";

const rootReducer = combineReducers({
  toggleLeftCanvas: toggleLeftCanvasSlide,
  coordinate: dataMapSlide,
  landingSector: landingSectorSlide,
  landingApplication: landingApplicationSlide,
  selectProject: selectProjectSlide,
  roles: roleSlide,
  users: usersSlide,
  document: documentSlide,
  lang: langSlide,
  exchangeRates: exchangeRatesSlide,
  entityType: entityTypeSlide,
  organizationBranch: organizationBranchSlide,
  notification: notificationSlide,
  profile: profileSlide,
  organization: organizationSlide,
  activityType: activityTypeSlide,
  chronology: chronologySlide,
  currency: currencySlide,
  department: departmentSlide,
  position: positionSlide,
  oragnization: organizationSlide,
  translations: translationsSlide,
  foa: foaSlide,
  attachment: attachmentSlide,
  currency: currencySlide,
  applic: applicSlide,
  pfi: pfiSlide,
  project: projectSlide,
  sector: sectorSlide,
  region: regionSlide,
  district: districtSlide,
  columnTable: columnReducer,
  columnProjectTable: columnProjectReducer,
  columnsOrganizationTable: columnOrganizationReducer,
  columnsOrganizationBranchTable: columnOrganizationBranchReducer,
  columnsUserTable: columnUserReducer,
  TableSelect: tableselectReducer,
  Layout: LayoutReducer,
  Login: LoginReducer,
  Account: AccountReducer,
  Profile: ProfileReducer,
});

export default rootReducer;
