module.exports = {
  google: {
    API_KEY: "",
    CLIENT_ID: "",
    SECRET: "",
  },
  facebook: {
    APP_ID: "",
  },
  api: {
    BASE_URL: "https://bk-invest.samar.uz/api/v1/",
    FILE_URL: "https://bk-invest.samar.uz",
  },
};
