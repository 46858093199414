import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Container,
  Row,
  Table,
  CardHeader,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Input,
  Button,
  FormFeedback,
} from "reactstrap";
import { Link } from "react-router-dom";
import classnames from "classnames";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { useDispatch, useSelector } from "react-redux";
import {
  adddepartmentAsync,
  deletedepartment,
  editdepartmentAsync,
  getdepartmentAsync,
} from "../../slices/department";
import { toast } from "react-toastify";
import DeleteModal from "../../Components/Common/DeleteModal";
import {
  addpositionAsync,
  deleteposition,
  editpositionAsync,
  getpositionAsync,
  getfulldepartmentsAsync,
} from "../../slices/position";
import { TranslationList } from "../../slices/settings/listtranslation";
import LoaderContent from "../../Components/Spinner";
import Paginations from "../Tables/DataTables/Pagination";

export default function PositionAndDepartment() {
  const [customActiveTab, setcustomActiveTab] = useState("1");
  const [modal_varying1, setmodal_varying1] = useState(false);
  const [nameUZ, setNameUZ] = useState();
  const [nameRU, setNameRU] = useState();
  const [nameEN, setNameEN] = useState();
  const [error, setError] = useState();
  const [current, setCuttent] = useState();
  //   const [code, setCode] = useState();
  const [deleteModal, setDeleteModal] = useState(false);
  const positions = useSelector((state) => state.position.data);
  const departmentsfull = useSelector(
    (state) => state.position.department?.results
  );
  const perpage = localStorage.getItem("perpage") ?? 10;
  const [perPage, setPerpage] = useState(perpage);
  const dispatch = useDispatch();
  const departments = useSelector((state) => state.department.data);
  const toggleCustom = (tab) => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };
  const lang = useSelector((state) => state.lang.data);
  const [inputValue, setInputValue] = useState("");
  const [isRequiredFilled, setIsRequiredFilled] = useState(false);
  useEffect(() => {
    dispatch(getdepartmentAsync(`department/?page=${1}&limit=${perPage}`));
    dispatch(getfulldepartmentsAsync("department/?limit=100"));
    dispatch(getpositionAsync(`position/?page=${1}&limit=${perPage}`));
  }, [lang]);
  const handleSearch = (e) => {
    dispatch(getdepartmentAsync(`department/?search=${e}`));
    dispatch(getpositionAsync(`position/?search=${e}`));
  };
  function tog_varying1() {
    setmodal_varying1(!modal_varying1);
    setCuttent();
    setError();
  }
  const handleAdd = async () => {
    if (customActiveTab == "1") {
      const dataDocument = {
        name_uz: nameUZ,
        name_ru: nameRU,
        name_en: nameEN,
      };

      const response = await adddepartmentAsync("department/", dataDocument);
      if (response?.errorCode) {
        setError(response?.message);
        toast.error(TranslationList("An error has occurred!"));
      } else {
        toast.success(TranslationList("Done successfully!"));
        dispatch(getdepartmentAsync(`department/?page=${1}&limit=${perPage}`));
        setmodal_varying1(false);
      }
    } else {
      const dataDocument = {
        department: document.getElementById("department").value,
        name_uz: nameUZ,
        name_ru: nameRU,
        name_en: nameEN,
      };

      const response = await addpositionAsync("position/", dataDocument);
      if (response?.errorCode) {
        setError(response?.message);
        toast.error(TranslationList("An error has occurred!"));
      } else {
        toast.success(TranslationList("Done successfully!"));
        dispatch(getpositionAsync(`position/?page=${1}&limit=${perPage}`));
        setmodal_varying1(false);
      }
    }
  };
  const onClickEdite = (item) => {
    setmodal_varying1(true);
    setCuttent(item);
  };
  const handleEdite = async () => {
    if (customActiveTab == "1") {
      const dataDocument = {
        name_uz: document.getElementById("name_uz").value,
        name_ru: document.getElementById("name_ru").value,
        name_en: document.getElementById("name_en").value,
      };

      const response = await editdepartmentAsync(
        `department/${current?.id}`,
        dataDocument
      );
      if (response?.errorCode) {
        setError(response?.message);
        toast.error(TranslationList("An error has occurred!"));
      } else {
        toast.success(TranslationList("Done successfully!"));
        dispatch(getdepartmentAsync(`department/?limit=100`));
        setmodal_varying1(false);
      }
    } else {
      const dataDocument = {
        department: document.getElementById("department").value,
        name_uz: document.getElementById("name_uz").value,
        name_ru: document.getElementById("name_ru").value,
        name_en: document.getElementById("name_en").value,
      };

      const response = await editpositionAsync(
        `position/${current?.id}`,
        dataDocument
      );
      if (response?.errorCode) {
        setError(response?.message);
        toast.error(TranslationList("An error has occurred!"));
      } else {
        toast.success(TranslationList("Done successfully!"));
        dispatch(getpositionAsync(`position/?limit=100`));
        setmodal_varying1(false);
      }
    }
  };
  const onClickTodoDelete = (item) => {
    setCuttent(item);
    setDeleteModal(true);
  };
  const handleDelete = async () => {
    if (customActiveTab == "1") {
      const response = await deletedepartment(`department/${current?.id}`);
      if (response?.errorCode) {
        toast.error(TranslationList("An error has occurred!"));
      } else {
        toast.success(TranslationList("Done successfully!"));
        dispatch(getdepartmentAsync(`department/`));
      }
    } else {
      const response = await deleteposition(`position/${current?.id}`);
      if (response?.errorCode) {
        toast.error(TranslationList("An error has occurred!"));
      } else {
        toast.success(TranslationList("Done successfully!"));
        dispatch(getpositionAsync(`position/`));
      }
    }
    setDeleteModal(false);
  };
  const handleInputChange = (event) => {
    const value = event.target.value;
    setInputValue(value);
    setIsRequiredFilled(value !== ""); // Check if the input is not empty
  };
  const handleCurrentPageDepartment = (e) => {
    dispatch(getdepartmentAsync(`department/?page=${e}&limit=${perPage}`));
  };
  const handlePerPageChangeDepartment = (e) => {
    setPerpage(e);
    dispatch(getdepartmentAsync(`department/?limit=${e}`));
  };
  const handleCurrentPagePosition = (e) => {
    dispatch(getpositionAsync(`position/?page=${e}&limit=${perPage}`));
  };
  const handlePerPageChangePosition = (e) => {
    setPerpage(e);
    dispatch(getpositionAsync(`position/?limit=${e}`));
  };
  document.title = `INVESTCONTROL | ${TranslationList(
    "Department and Position"
  )}`;

  return (
    <div className="page-content">
      <DeleteModal
        show={deleteModal}
        data={current?.name}
        onDeleteClick={handleDelete}
        onCloseClick={() => setDeleteModal(false)}
      />
      <Modal
        isOpen={modal_varying1}
        toggle={() => {
          tog_varying1();
        }}
        id="exampleModal"
      >
        <ModalHeader
          toggle={() => {
            tog_varying1();
          }}
        >
          {current ? TranslationList("Edit") : TranslationList("Add")}{" "}
        </ModalHeader>

        <div className="modal-body">
          <form>
            {customActiveTab == "2" && (
              <div className="mb-3">
                <label htmlFor="department" className="col-form-label">
                  {TranslationList("Department")} :
                </label>
                <select
                  className="form-select mb-3"
                  aria-label="Default select example"
                  id="department"
                  defaultValue={current?.department?.id}
                >
                  {departmentsfull?.map((res) => (
                    <option key={res.id} value={res.id}>
                      {res.name}
                    </option>
                  ))}
                </select>
              </div>
            )}
            <div className="mb-3">
              {" "}
              <label htmlFor="customer-name" className="col-form-label">
                {TranslationList("uz")}:
              </label>
              <Input
                id="name_uz"
                type="text"
                className="form-control"
                onChange={(e) => setNameUZ(e.target.value)}
                defaultValue={current?.name_uz}
                invalid={error?.name_uz ? true : false}
              />
              <FormFeedback type="invalid">{error?.name_uz}</FormFeedback>
            </div>
            <div className="mb-3">
              <label htmlFor="customer-name" className="col-form-label">
                {TranslationList("ru")}:
              </label>
              <Input
                type="text"
                id="name_ru"
                className="form-control"
                onChange={(e) => setNameRU(e.target.value)}
                defaultValue={current?.name_ru}
                invalid={error?.name_ru ? true : false}
              />
              <FormFeedback type="invalid">{error?.name_ru}</FormFeedback>
            </div>
            <div className="mb-3">
              <label htmlFor="customer-name" className="col-form-label">
                {TranslationList("eng")}:
              </label>
              <Input
                type="text"
                id="name_en"
                className="form-control"
                onChange={(e) => setNameEN(e.target.value)}
                defaultValue={current?.name_en}
                invalid={error?.name_en ? true : false}
              />
              <FormFeedback type="invalid">{error?.name_en}</FormFeedback>
            </div>
          </form>
        </div>
        <div className="modal-footer">
          <Button
            color="light"
            onClick={() => {
              setmodal_varying1(false);
            }}
          >
            {TranslationList("Cancel")}
          </Button>
          {current ? (
            <Button color="primary" onClick={handleEdite}>
              {TranslationList("Save")}
            </Button>
          ) : (
            <Button color="primary" onClick={handleAdd}>
              {TranslationList("Create")}
            </Button>
          )}
        </div>
      </Modal>

      {positions?.results && departments?.results ? (
        <Container fluid>
          <BreadCrumb
            title={TranslationList("Position and Department")}
            pageTitle={TranslationList("home")}
          />
          <Row>
            <Card>
              <CardHeader>
                <Row className="align-items-center gy-3">
                  <div className="col-sm">
                    <h5 className="card-title mb-0">
                      {" "}
                      {customActiveTab == 1
                        ? TranslationList("Department")
                        : TranslationList("Position")}
                    </h5>
                  </div>
                  <div className="col-sm-auto d-flex justify-content-between">
                    <div className="px-2">
                      <div className="search-box">
                        <Input
                          type="text"
                          className="form-control search"
                          placeholder={TranslationList("List Search")}
                          onChange={(e) => handleSearch(e.target.value)}
                        />
                        <i className="ri-search-line search-icon"></i>
                      </div>
                    </div>
                    <div className="d-flex gap-1 flex-wrap">
                      <button
                        type="button"
                        className="btn btn-success add-btn"
                        id="create-btn"
                        onClick={() => tog_varying1()}
                      >
                        <i className="ri-add-line align-bottom me-1"></i>{" "}
                        {TranslationList("Add")}
                      </button>{" "}
                    </div>
                  </div>
                </Row>
              </CardHeader>
              <CardBody>
                <Nav
                  tabs
                  className="nav nav-tabs nav-tabs-custom nav-success  mb-3"
                >
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: customActiveTab === "1",
                      })}
                      onClick={() => {
                        toggleCustom("1");
                      }}
                    >
                      {TranslationList("Department")}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: customActiveTab === "2",
                      })}
                      onClick={() => {
                        toggleCustom("2");
                      }}
                    >
                      {TranslationList("Position")}
                    </NavLink>
                  </NavItem>
                </Nav>

                <TabContent activeTab={customActiveTab} className="text-muted">
                  <TabPane tabId="1" id="home1">
                    <div className="live-preview p-3">
                      <div className="text-muted table-responsive">
                        <Table className="table-bordered align-middle table-nowrap mb-0">
                          <thead>
                            <tr>
                              <th scope="col" className="text-center">
                                {TranslationList("Number")}
                              </th>
                              <th scope="col" className="w-50  text-center">
                                {TranslationList("Name")}
                              </th>
                              <th scope="col " className="text-center">
                                {TranslationList("Actions")}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {departments?.results?.map((res, i) => (
                              <tr key={res?.id}>
                                <td className="text-center">{i + 1}</td>
                                <td>{res?.name}</td>

                                <td className="text-center">
                                  <div className="hstack gap-2 justify-content-center">
                                    <button
                                      className="btn btn-sm btn-soft-danger remove-list"
                                      onClick={() => onClickTodoDelete(res)}
                                    >
                                      <i className="ri-delete-bin-5-fill align-bottom" />
                                    </button>
                                    <button
                                      className="btn btn-sm btn-soft-info edit-list"
                                      onClick={() => onClickEdite(res)}
                                    >
                                      <i className="ri-pencil-fill align-bottom" />
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </div>
                    </div>
                    <Paginations
                      totalItems={departments?.pagination?.total}
                      perPageChange={(e) => handlePerPageChangeDepartment(e)}
                      currentPage={(e) => handleCurrentPageDepartment(e)}
                    />
                  </TabPane>

                  <TabPane tabId="2">
                    <div className="live-preview p-3">
                      <div className="text-muted table-responsive">
                        <Table className="table-bordered align-middle table-nowrap mb-0">
                          <thead>
                            <tr>
                              <th scope="col" className="text-center">
                                {TranslationList("Number")}
                              </th>
                              <th scope="col" className="w-50  text-center">
                                {TranslationList("Name")}
                              </th>
                              <th scope="col" className="w-25  text-center">
                                {TranslationList("Department")}
                              </th>
                              <th scope="col " className="text-center">
                                {TranslationList("Actions")}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {positions?.results?.map((res, i) => (
                              <tr key={res?.id}>
                                <td className="text-center">{i + 1}</td>
                                <td>{res?.name}</td>
                                <td>{res?.department_detail?.name} </td>
                                <td className="text-center">
                                  <div className="hstack gap-2 justify-content-center">
                                    <button
                                      className="btn btn-sm btn-soft-danger remove-list"
                                      onClick={() => onClickTodoDelete(res)}
                                    >
                                      <i className="ri-delete-bin-5-fill align-bottom" />
                                    </button>
                                    <button
                                      className="btn btn-sm btn-soft-info edit-list"
                                      onClick={() => onClickEdite(res)}
                                    >
                                      <i className="ri-pencil-fill align-bottom" />
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </div>
                    </div>
                    <Paginations
                      totalItems={positions?.pagination?.total}
                      perPageChange={(e) => handlePerPageChangePosition(e)}
                      currentPage={(e) => handleCurrentPagePosition(e)}
                    />
                  </TabPane>
                </TabContent>
              </CardBody>
            </Card>
          </Row>
        </Container>
      ) : (
        <LoaderContent />
      )}
    </div>
  );
}
