import React from "react";
import "./MultiStepProgressBar.css";
import { ProgressBar, Step } from "react-step-progress-bar";

const MultiStepLanding = ({ currentStage, page, onPageNumberClick }) => {
  var stepPercentage = 0;
  if (page == 1) {
    stepPercentage = 1;
  } else if (page == 2) {
    stepPercentage = 50;
  } else if (page == 3) {
    stepPercentage = 100;
  } else {
    stepPercentage = 0;
  }
  const isStepDisabled = (step) => {
    if (currentStage < step) return true;
    else return false;
  };
  return (
    <ProgressBar percent={stepPercentage}>
      <Step>
        {({ accomplished, index }) => (
          <div
            className={`indexedStep ${accomplished ? "accomplished" : null}`}
            onClick={() => !isStepDisabled(index + 1) && onPageNumberClick("1")}
          >
            {index + 1}
          </div>
        )}
      </Step>
      <Step>
        {({ accomplished, index }) => (
          <div
            className={`indexedStep ${accomplished ? "accomplished" : null}`}
            onClick={() => !isStepDisabled(index + 1) && onPageNumberClick("2")}
          >
            {index + 1}
          </div>
        )}
      </Step>
      <Step>
        {({ accomplished, index }) => (
          <div
            className={`indexedStep ${accomplished ? "accomplished" : null}`}
            onClick={() => !isStepDisabled(index + 1) && onPageNumberClick("3")}
          >
            {index + 1}
          </div>
        )}
      </Step>
    </ProgressBar>
  );
};

export default MultiStepLanding;
