import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import React, { useEffect } from "react";
import { useState } from "react";
import { Button, Card, CardFooter, FormGroup, Label } from "reactstrap";
import { TranslationList } from "../../slices/settings/listtranslation";
import { DataService } from "../../helpers/dataService/dataService";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import endpoints from "../../endpoints";
export default function ForPDF({ data, report }) {
  const [loader, setLoader] = useState(false);
  const [economist, seteconomist] = useState();
  const [director, setDirector] = useState();
  const [beneficiary, setbeneficiary] = useState();
  const [component, setcomponent] = useState();
  const navigate = useNavigate();
  const getData = async () => {
    try {
      const responsecomponent = await DataService.get(endpoints.component);
      setcomponent(responsecomponent);
      const responsebeneficiary = await DataService.get(endpoints.beneficiary);
      setbeneficiary(responsebeneficiary);
      const response = await DataService.get(`user/?limit=1000`);
      let economist = response?.results?.filter((user) =>
        user?.roles[0]?.name?.includes("Finansist")
      );
      seteconomist(economist);
      let director = response?.results?.filter(
        (user) =>
          user?.roles[0]?.name?.includes("Director") ||
          user?.roles[0]?.name?.includes("Deputy Director")
      );
      setDirector(director);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getData();
  }, []);
  const handlesave = async () => {
    setLoader(true);
    const datas = {
      application: data?.id,
      instruction_date: document.getElementById("instruction_date").value ?? "",
      expense_sheet: document.getElementById("expense_sheet").value ?? "",
      pfi_name: document.getElementById("pfi_name").value ?? "",
      organization_name:
        document.getElementById("organization_name").value ?? "",
      goal_name: document.getElementById("goal_name").value ?? "",
      location: document.getElementById("location").value ?? "",
      subproject_cost: document.getElementById("subproject_cost").value ?? "",
      credit_cost: document.getElementById("credit_cost").value ?? "",
      contribution_cost:
        document.getElementById("contribution_cost").value ?? "",
      educated_date: document.getElementById("educated_date").value ?? "",
      type_of_ownership:
        document.getElementById("type_of_ownership").value ?? "",
      current_activity: document.getElementById("current_activity").value ?? "",
      contact_person: document.getElementById("contact_person").value ?? "",
      provider: document.getElementById("provider").value ?? "",
      goods: document.getElementById("goods").value ?? "",
      total_contract_amount:
        document.getElementById("total_contract_amount").value ?? "",
      contract_date_and_number:
        document.getElementById("contract_date_and_number").value ?? "",
      return_period: document.getElementById("return_period").value ?? "",
      grace_period: document.getElementById("grace_period").value ?? "",
      interest_rate: document.getElementById("interest_rate").value ?? "",
      one_time_commission:
        document.getElementById("one_time_commission").value ?? "",
      annual_production_volume:
        document.getElementById("annual_production_volume").value ?? "",
      cumulative_profit:
        document.getElementById("cumulative_profit").value ?? "",
      new_workplaces: document.getElementById("new_workplaces").value ?? "",
      payback: document.getElementById("payback").value ?? "",
      participants: document.getElementById("participants").value ?? "",
      approval_required:
        document.getElementById("approval_required").value ?? "",
      agreement_date: document.getElementById("agreement_date").value ?? "",
      project_name: document.getElementById("project_name").value ?? "",
      ekonomist: document.getElementById("ekonomist").value ?? "",
      direktor: document.getElementById("direktor").value ?? "",
    };
    const dataDocument = {
      ekonomist: document.getElementById("ekonomist").value ?? "",
      direktor: document.getElementById("direktor").value ?? "",
      status_stage_cordinator: "status3",
      description_cordinator: "Completed",
      component: document.getElementById("component").value ?? "",
      beneficiary: document.getElementById("beneficiary").value ?? "",
      project: data?.project,
    };
    await DataService.put(
      `application/${data?.id}/stagecordinator`,
      dataDocument
    );
    try {
      await DataService.post("reports/", datas);
      navigate("/applications");
    } catch (err) {
      toast.error(TranslationList(err.message));
    }
    setLoader(false);
  };
  const handleEdit = async () => {
    setLoader(true);
    const datas = {
      application: data?.id,
      instruction_date: document.getElementById("instruction_date").value ?? "",
      expense_sheet: document.getElementById("expense_sheet").value ?? "",
      pfi_name: document.getElementById("pfi_name").value ?? "",
      organization_name:
        document.getElementById("organization_name").value ?? "",
      goal_name: document.getElementById("goal_name").value ?? "",
      location: document.getElementById("location").value ?? "",
      subproject_cost: document.getElementById("subproject_cost").value ?? "",
      credit_cost: document.getElementById("credit_cost").value ?? "",
      contribution_cost:
        document.getElementById("contribution_cost").value ?? "",
      educated_date: document.getElementById("educated_date").value ?? "",
      type_of_ownership:
        document.getElementById("type_of_ownership").value ?? "",
      current_activity: document.getElementById("current_activity").value ?? "",
      contact_person: document.getElementById("contact_person").value ?? "",
      provider: document.getElementById("provider").value ?? "",
      goods: document.getElementById("goods").value ?? "",
      total_contract_amount:
        document.getElementById("total_contract_amount").value ?? "",
      contract_date_and_number:
        document.getElementById("contract_date_and_number").value ?? "",
      return_period: document.getElementById("return_period").value ?? "",
      grace_period: document.getElementById("grace_period").value ?? "",
      interest_rate: document.getElementById("interest_rate").value ?? "",
      one_time_commission:
        document.getElementById("one_time_commission").value ?? "",
      annual_production_volume:
        document.getElementById("annual_production_volume").value ?? "",
      cumulative_profit:
        document.getElementById("cumulative_profit").value ?? "",
      new_workplaces: document.getElementById("new_workplaces").value ?? "",
      payback: document.getElementById("payback").value ?? "",
      participants: document.getElementById("participants").value ?? "",
      approval_required:
        document.getElementById("approval_required").value ?? "",
      agreement_date: document.getElementById("agreement_date").value ?? "",
      project_name: document.getElementById("project_name").value ?? "",
      ekonomist: document.getElementById("ekonomist").value ?? "",
      direktor: document.getElementById("direktor").value ?? "",
      component: document.getElementById("component").value ?? "",
      beneficiary: document.getElementById("beneficiary").value ?? "",
    };
    try {
      await DataService.put(`reports/${report?.id}/`, datas);
      navigate("/applications");
    } catch (err) {
      toast.error(TranslationList(err.message));
    }
    setLoader(false);
  };

  return (
    <Card>
      <div
        // className="scrin"
        id="print"
        style={{
          fontFamily: "Times New Roman",
          fontSize: 16,
          padding: 15,
        }}
      >
        <div className="d-flex justify-content-end fw-bold w-100">
          <div style={{ width: "35%" }}>
            Директору Международного центра стратегического развития и
            исследований в сфере продовольствия и сельского хозяйства
            А.Н.Шукурову
          </div>
        </div>
        <br />
        <div className="ms-5">
          <div className="d-inline">
            <span className="ms-5 me-2">
              B соответствие с поручением Окупаемость
            </span>
            <input
              type="date"
              id="instruction_date"
              defaultValue={report?.instruction_date}
              className="d-inline me-2 fs-14 border border-0 border-bottom w-15 rounded-0"
              width={100}
            />
            , касательно изучения Расходной ведомости
            <input
              type="text"
              id="expense_sheet"
              defaultValue={report?.expense_sheet}
              className="fs-14 border border-0 border-bottom w-30 rounded-0"
            />{" "}
            .
            <input
              type="text"
              id="pfi_name"
              defaultValue={report?.pfi_name ?? data?.pfi_info?.name}
              className="fs-14 border border-0 border-bottom w-30 rounded-0"
            />{" "}
            по субпроекту{" "}
            <span className="fw-bold">
              <input
                type="text"
                id="organization_name"
                disabled
                defaultValue={
                  report?.organization_name ?? data?.organizationinfo?.name
                }
                className="fs-14 border border-0 border-bottom w-100  rounded-0"
              />
            </span>
            сообщаю следуюшее.
          </div>
        </div>
        <div className="ms-5">
          <span className="fw-bold"> Цель суб-проекта:</span>

          <span>
            <input
              type="text"
              id="goal_name"
              defaultValue={report?.goal_name ?? data?.project_description}
              className="fs-14 border border-0 border-bottom w-75 rounded-0"
            />
          </span>
        </div>
        <div className="ms-5">
          <span className="fw-bold">Местоположение: </span>
          <input
            type="text"
            size="sm"
            id="location"
            defaultValue={
              report?.location ??
              data?.project_region_info?.name +
                " , " +
                data?.project_district_info?.name
            }
            className="fs-14 border border-0 border-bottom w-50 rounded-0"
          />
        </div>
        <div className="ms-5">
          <div className="">
            Стоимость суб-проекта:
            <span>
              <span>
                <input
                  type="text"
                  id="subproject_cost"
                  defaultValue={
                    report?.subproject_cost ??
                    data?.project_cost?.number +
                      " " +
                      data?.project_cost?.currency_detail?.symbol
                  }
                  className="fs-14 border border-0 border-bottom w-50 rounded-0"
                />
              </span>
            </span>
          </div>
        </div>
        <div className="ms-5">
          <div className="fw-bold">
            <span>Из них субзаем МФСР: </span>{" "}
            <span>
              <input
                type="text"
                id="credit_cost"
                defaultValue={
                  report?.credit_cost ??
                  data?.loan_amount_to_receive?.number +
                    " " +
                    data?.loan_amount_to_receive?.currency_detail?.symbol
                }
                className="fs-14 border border-0 border-bottom w-50 rounded-0"
              />
            </span>
          </div>
        </div>
        <div className="ms-5">
          <div className="">
            Собств.вклад:
            <span>
              <input
                type="text"
                id="contribution_cost"
                defaultValue={
                  report?.contribution_cost ??
                  data?.borrower_contribution?.number +
                    " " +
                    data?.borrower_contribution?.currency_detail?.symbol
                }
                className="fs-14 border border-0 border-bottom w-50 rounded-0"
              />
            </span>
          </div>
        </div>
        <div className="ms-5">
          <div className="">
            Образовано:
            <input
              type="text"
              id="educated_date"
              defaultValue={report?.educated_date ?? data?.educated_date}
              className="fs-14 border border-0 border-bottom w-50 rounded-0"
            />
          </div>
        </div>
        <div className="ms-5">
          <div className="">
            Форма собственности:
            <input
              type="text"
              id="type_of_ownership"
              defaultValue={
                report?.type_of_ownership ?? data?.type_of_ownership
              }
              className="fs-14 border border-0 border-bottom w-50 rounded-0"
            />
          </div>
        </div>
        <div className="ms-5">
          <div className="">
            Текущая деятельность:{" "}
            <input
              type="text"
              id="current_activity"
              defaultValue={report?.current_activity ?? data?.current_activity}
              className="fs-14 border border-0 border-bottom w-50 rounded-0"
            />
          </div>
        </div>
        <div className="ms-5">
          <div className="">
            Контактное лицо:{" "}
            <input
              type="text"
              id="contact_person"
              defaultValue={
                report?.contact_person ?? data?.organizationinfo?.supervisor
              }
              className="fs-14 border border-0 border-bottom w-50 rounded-0"
            />
          </div>
        </div>
        <div className="ms-5">
          <div className="fw-bold">
            <span>Информация о поставшиках:</span>
          </div>
        </div>
        <div className="ms-5">
          <div className="">
            Поставшик(и):
            <input
              type="text"
              id="provider"
              defaultValue={report?.provider ?? data?.provider}
              className="fs-14 border border-0 border-bottom w-50 rounded-0"
            />
          </div>
        </div>
        <div>
          <div className="">
            <span className="ms-5"></span>Товары:
            <input
              type="text"
              id="goods"
              defaultValue={report?.goods ?? data?.goods}
              className="fs-14 border border-0 border-bottom w-50 rounded-0"
            />
          </div>
        </div>
        <div className="ms-5">
          <div className="">
            Общая сумма контракта:
            <input
              type="text"
              id="total_contract_amount"
              defaultValue={
                report?.total_contract_amount ?? data?.total_contract_amount
              }
              className="fs-14 border border-0 border-bottom w-50 rounded-0"
            />
          </div>
        </div>
        <div className="ms-5">
          <div className="">
            Дата и номер контракта:
            <input
              type="text"
              id="contract_date_and_number"
              defaultValue={
                report?.contract_date_and_number ??
                data?.contract_date_and_number
              }
              className="fs-14 border border-0 border-bottom w-50 rounded-0"
            />
          </div>
        </div>
        <div className="ms-5">
          <div className="fw-bold">
            <span>Структура субзайма:</span>
          </div>
          <div>
            <div>
              Срок возврата:
              <input
                type="text"
                id="return_period"
                defaultValue={report?.return_period ?? data?.return_period}
                className="fs-14 border border-0 border-bottom w-50 rounded-0"
              />
            </div>
            <div>
              Льготный период:
              <input
                type="text"
                id="grace_period"
                defaultValue={report?.grace_period ?? data?.grace_period}
                className="fs-14 border border-0 border-bottom w-50 rounded-0"
              />
            </div>
            <div>
              {" "}
              Процентная ставка:{" "}
              <input
                type="text"
                id="interest_rate"
                defaultValue={report?.interest_rate ?? data?.interest_rate}
                className="fs-14 border border-0 border-bottom w-50 rounded-0"
              />{" "}
            </div>
            <div>
              {" "}
              Разовая комиссия:{" "}
              <input
                type="text"
                id="one_time_commission"
                defaultValue={
                  report?.one_time_commission ?? data?.one_time_commission
                }
                className="fs-14 border border-0 border-bottom w-50 rounded-0"
              />
            </div>
          </div>
        </div>
        <div className="ms-5">
          <div className="fw-bold">
            <span>Реализация субпроекта:</span>
          </div>
          <div>
            <div>
              Годовой объем производства:
              <input
                type="text"
                id="annual_production_volume"
                defaultValue={
                  report?.annual_production_volume ??
                  data?.annual_production_volume
                }
                className="fs-14 border border-0 border-bottom w-50 rounded-0"
              />
            </div>
            <div>
              {" "}
              Кумулятивная прибыль:
              <input
                type="text"
                id="cumulative_profit"
                defaultValue={
                  report?.cumulative_profit ?? data?.cumulative_profit
                }
                className="fs-14 border border-0 border-bottom w-50 rounded-0"
              />
            </div>
          </div>
        </div>
        <div className="ms-5">
          <div className="">
            Новые рабочие места:{" "}
            <input
              type="text"
              id="new_workplaces"
              defaultValue={
                report?.new_workplaces ??
                data?.overall_jobs_workplace + " " + data?.women_workplace
              }
              className="fs-14 border border-0 border-bottom w-50 rounded-0"
            />
          </div>
          <div>
            Окупаемость.
            <input
              type="text"
              id="payback"
              defaultValue={report?.payback ?? data?.payback}
              className="fs-14 border border-0 border-bottom w-50 rounded-0"
            />{" "}
          </div>
        </div>
        <div className="ms-5">
          Участники ЦДС:
          <input
            type="text"
            id="participants"
            defaultValue={report?.participants ?? data?.participants}
            className="fs-14 border border-0 border-bottom w-50 rounded-0"
          />
        </div>
        <div className="ms-5">
          <div className="">
            Требуется ли предварительное одобрение МФСР:{" "}
            <input
              type="text"
              id="approval_required"
              defaultValue={
                report?.approval_required ?? data?.approval_required
              }
              className="fs-14 border border-0 border-bottom w-50 rounded-0"
            />
          </div>
        </div>
        <div className="ms-5">
          <div className="">
            <span className="ms-5"></span>На основании Положения о порядке
            использования кредитной линии МФСР и Соглашения о перекредитовании
            от{" "}
            <input
              type="date"
              id="agreement_date"
              defaultValue={report?.agreement_date ?? data?.agreement_date}
              className="fs-14 border border-0 border-bottom w-15 rounded-0"
            />{" "}
            , г, суб-проект{" "}
            <span className="fw-bold">{data?.organizationinfo?.name}</span>{" "}
            соответствует критериям кредитной линии в рамках дополнительного
            финансирования проекта
            <input
              type="text"
              id="project_name"
              defaultValue={report?.project_name ?? data?.projectinfo?.name}
              className="  fs-14 border border-0 border-bottom w-50 rounded-0"
            />
            . В связи с чем, полагаем возможным финансировать данный суб-проект.
          </div>
        </div>
        <div className="ms-5">
          <div className="d-flex justify-content-between">
            <span className="me-5 fw-bold">Специалист по кредитной линии </span>{" "}
            {/* <span>
              <input
                type="text"
                id="spetsialist"
                defaultValue={data?.spetsialist}
                className="fs-14 border border-0 border-bottom w-15 rounded-0"
              />{" "}
            </span> */}
          </div>
        </div>
        <div className="ms-5 d-flex justify-content-between w-75">
          <div>
            <FormGroup className="mb-3">
              <Label htmlFor="name" className="me-2">
                {TranslationList("Finansist")}{" "}
              </Label>
              <select
                key={report?.ekonomist + economist}
                className="fs-14  border border-0 border-bottom  rounded-0"
                size="sm"
                id="ekonomist"
                defaultValue={report?.ekonomist}
              >
                <option></option>
                {economist?.map((res, i) => (
                  <option key={i} value={res?.id}>
                    {res?.last_name} {res?.first_name}
                  </option>
                ))}
              </select>
            </FormGroup>
          </div>
          <div>
            <FormGroup className="mb-3">
              <Label htmlFor="legal_status" className="me-2">
                {TranslationList("Director")}{" "}
              </Label>
              <select
                key={report?.direktor + director}
                className="fs-14 border border-0 border-bottom  rounded-0"
                size="sm"
                id="direktor"
                defaultValue={report?.direktor}
              >
                <option></option>
                {director?.map((res, i) => (
                  <option key={i} value={res?.id}>
                    {res?.last_name} {res?.first_name}
                  </option>
                ))}
              </select>
            </FormGroup>
          </div>
        </div>
        <div className="ms-5 d-flex justify-content-between w-75">
          <div>
            <FormGroup className="mb-3">
              <Label htmlFor="name" className="me-2">
                {TranslationList("beneficiary")}{" "}
              </Label>
              <select
                key={report?.beneficiary + beneficiary}
                className="fs-14  border border-0 border-bottom  rounded-0"
                size="sm"
                id="beneficiary"
                defaultValue={report?.beneficiary}
              >
                <option></option>
                {beneficiary?.results?.map((res, i) => (
                  <option key={i} value={res?.id}>
                    {res?.name}
                  </option>
                ))}
              </select>
            </FormGroup>
          </div>
          <div>
            <FormGroup className="mb-3">
              <Label htmlFor="legal_status" className="me-2">
                {TranslationList("component")}{" "}
              </Label>
              <select
                key={report?.component + component}
                className="fs-14  border border-0 border-bottom  rounded-0"
                size="sm"
                id="component"
                defaultValue={report?.component}
              >
                <option></option>
                {component?.results?.map((res, i) => (
                  <option key={i} value={res?.id}>
                    {res?.name}
                  </option>
                ))}
              </select>
            </FormGroup>
          </div>
        </div>
      </div>
      <CardFooter className="d-flex justify-content-end">
        {report?.id ? (
          <Button onClick={handleEdit} disabled={!(loader === false)}>
            {loader ? (
              <span>{TranslationList("Saving...")}</span>
            ) : (
              <span>{TranslationList("Save")}</span>
            )}
          </Button>
        ) : (
          <Button onClick={handlesave} disabled={!(loader === false)}>
            {loader ? (
              <span>{TranslationList("Confirming")}</span>
            ) : (
              <span>{TranslationList("Confirm")}</span>
            )}
          </Button>
        )}
      </CardFooter>
    </Card>
  );
}
