import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Collapse,
  ListGroup,
  ListGroupItem,
} from "reactstrap";
import classnames from "classnames";
import { TranslationList } from "../../slices/settings/listtranslation";
import { useSelector } from "react-redux";
import dateFormat from "dateformat";
export default function Signs({ signers }) {
  const [open, setOpen] = useState("1");
  const toggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };
  return (
    <div>
      <h5 className="d-flex w-100 justify-content-center fs-16">
        {TranslationList("Signatories")}
      </h5>
      <Accordion
        open={open}
        toggle={toggle}
        className="custom-accordionwithicon custom-accordion-border accordion-border-box accordion-success"
      >
        {signers?.map((res, i) => (
          <AccordionItem key={res.id}>
            <AccordionHeader targetId={`${i + 1}`}>
              {res?.employee_info?.first_name} {res?.employee_info?.last_name}
            </AccordionHeader>
            <AccordionBody accordionId={`${i + 1}`}>
              <ListGroup flush>
                <ListGroupItem>
                  <div className="d-flex justify-content-between align-items-center">
                    <div>{TranslationList("Date")}</div>
                    <span>
                      {dateFormat(res?.createdDate, "dd.mm.yyyy HH:MM")}
                    </span>
                  </div>
                </ListGroupItem>
                <ListGroupItem>
                  <div className="d-flex justify-content-between align-items-center w-100">
                    <div>{TranslationList("Organization")}</div>
                    <span style={{ textAlign: "end" }}>
                      {res?.employee_info?.organizationinfo?.name}
                    </span>
                  </div>
                </ListGroupItem>
                <ListGroupItem>
                  <div className="d-flex justify-content-between align-items-center">
                    <div>{TranslationList("Username")}</div>
                    <span style={{ textAlign: "end" }}>
                      {res?.employee_info?.first_name}{" "}
                      {res?.employee_info?.last_name}
                    </span>
                  </div>
                </ListGroupItem>
                <ListGroupItem>
                  <div className="d-flex justify-content-between align-items-center">
                    <div>{TranslationList("Sign")}</div>
                    <span className="w-50 fs-12 flex-wrap">{res?.sign}</span>
                  </div>
                </ListGroupItem>
                <ListGroupItem>
                  <div className="d-flex justify-content-between align-items-center">
                    <div>{TranslationList("signer_serial_number")}</div>
                    <span className="w-50 fs-12 flex-wrap">
                      {res?.signer_serial_number}
                    </span>
                  </div>
                </ListGroupItem>
                <ListGroupItem>
                  <div className="d-flex justify-content-between align-items-center">
                    <div>{TranslationList("Confirmed")}</div>
                    <span>
                      {res?.status == "signed" ? (
                        <i className="  bx bx-check-circle text-success fs-24"></i>
                      ) : (
                        <i className=" bx bx-x-circle text-danger fs-24"></i>
                      )}
                    </span>{" "}
                  </div>
                </ListGroupItem>
              </ListGroup>
            </AccordionBody>
          </AccordionItem>
        ))}
      </Accordion>
    </div>
  );
}
