import React, { useEffect, useState } from "react";
import "./assets/scss/themes.scss";
import Route from "./Routes";
import { ToastContainer } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { gettranslationsListAsync } from "./slices/settings/translations";
import PageLoader from "./PageLoader";
import { loadAnimation } from "lottie-web";
import { defineElement } from "lord-icon-element";
defineElement(loadAnimation);
import "react-toastify/dist/ReactToastify.css";

function App() {
  const [load, setLoad] = useState();
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.lang.data);
  const translations = useSelector((state) => state.translations.list);
  useEffect(() => {
    dispatch(
      gettranslationsListAsync(
        `translations?lang=${localStorage.getItem("I18N_LANGUAGE_INVEST")}`
      )
    );

    setLoad(lang);
  }, [lang]);

  return (
    <React.Fragment>
      <ToastContainer />
      {translations ? <Route /> : <PageLoader />}
    </React.Fragment>
  );
}

export default App;
