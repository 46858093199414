import React, { useEffect, useState } from "react";
import { Row, Col, Table } from "reactstrap";
import { Link, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getchronologyAsync, getsignatoryAsync } from "../../slices/chronology";
import dateFormat from "dateformat";
import { TranslationList } from "../../slices/settings/listtranslation";
export default function Chronology() {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const chronology = useSelector((state) => state.chronology.data);
  const application = useSelector((state) => state.applic.current);
  const profile = useSelector((state) => state.profile?.data);
  const signatory = useSelector((state) => state.chronology?.signatory);
  useEffect(() => {
    if (searchParams.get("show_id")) {
      dispatch(
        getchronologyAsync(
          `chronology?loan_application=${searchParams.get("show_id")}`
        )
      );
      dispatch(
        getsignatoryAsync(
          `signatory/?application=${searchParams.get("show_id")}`
        )
      );
    } else {
      dispatch(
        getchronologyAsync(
          `chronology?loan_application=${searchParams.get("id")}`
        )
      );
      dispatch(
        getsignatoryAsync(`signatory/?application=${searchParams.get("id")}`)
      );
    }
  }, []);
  return (
    <div>
      <h6>{TranslationList("Timeline of the statement")}</h6>
      <div className="live-preview mt-3 mb-3">
        <div className="text-muted table-responsive">
          <Table className="text-muted table-bordered align-middle table-nowrap mb-0 ">
            <thead>
              <tr>
                <th scope="col">№</th>
                <th scope="col">{TranslationList("Organization")}</th>
                <th scope="col">{TranslationList("Organization branch")} </th>

                <th scope="col">{TranslationList("Actions")}</th>
                <th scope="col">{TranslationList("Time")}</th>
                <th scope="col">{TranslationList("Executor")}</th>
              </tr>
            </thead>
            <tbody>
              {chronology?.results?.map((res, i) => (
                <tr key={res?.id}>
                  <td className="fw-medium">{i + 1}</td>
                  <td>{res?.organization_detail?.name}</td>
                  <td>{res?.organization_branch_detail?.name}</td>

                  <td>{res?.action}</td>
                  <td>{dateFormat(res?.created_time, "dd.mm.yyyy HH:MM")}</td>
                  <td>
                    {res?.executer_detail?.first_name}{" "}
                    {res?.executer_detail?.last_name}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
      {profile?.roles[0]?.name == "Manager" &&
        profile?.organizationinfo?.inn == "987654321" && (
          <div>
            <h6>Результат Центра </h6>

            <div className="live-preview mt-3 mb-3">
              <div className="text-muted table-responsive">
                <Table className="text-muted table-bordered align-middle table-nowrap mb-0 ">
                  <thead>
                    <tr>
                      <th scope="col">№</th>
                      <th scope="col">Результат</th>
                      <th scope="col">Примечания</th>
                      <th scope="col">Срок</th>
                      <th scope="col">Исполнитель</th>
                      <th scope="col">Действие времени</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {signatory?.results?.map((res, i) => (
                      <tr key={res?.id}>
                        <td className="fw-medium">{i + 1}</td>
                        {/* <td>{TranslationList(res?.results)}</td>
                         */}
                        <td>Corresponds</td>
                        <td>{res?.notes}</td>
                        <td>{dateFormat(res?.deadline, "dd.mm.yyyy")}</td>
                        <td>
                          {res?.user_detail?.first_name}{" "}
                          {res?.user_detail?.last_name}
                        </td>
                        <td>
                          {res?.time_viewed &&
                            dateFormat(res?.deadline, "dd.mm.yyyy HH:MM")}
                        </td>
                        <td>
                          {res?.time_viewed ? (
                            <i className=" ri-eye-line"></i>
                          ) : (
                            <i
                              className=" ri-eye-off-line
                          "
                            ></i>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        )}
    </div>
  );
}
