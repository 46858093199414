import React, { useEffect, useState } from "react";
import MapImage from "../../assets/images/map.jpg";
import { Card, CardBody, Col, Container, Label, Row } from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import "leaflet/dist/leaflet.css";
import "leaflet.markercluster";

import Leaflet from "../../Components/Analytics/LafletMap";
import Select from "react-select";
import { TranslationList } from "../../slices/settings/listtranslation";
import { DataService } from "../../helpers/dataService/dataService";
import endpoints from "../../endpoints";
import { useSelector } from "react-redux";
import LoaderContent from "../../Components/Spinner";
import Map1 from "./Map1";
import { useCallback } from "react";
import MarkerCluster from "../../Components/Analytics/Clusters";
export default function Map() {
  const lang = useSelector((state) => state.lang.data);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedOptionDistrict, setSelectedOptionDistrict] = useState(null);
  const [currentRegion, setCurrentRegion] = useState("");
  const [apiDataRegion, setApiDataRegion] = useState();
  const [apiDataDistrict, setApiDataDistrict] = useState();
  const [geoDataDistrict, setGeoDataDistrict] = useState();
  const [filter, setFilter] = useState();
  const [coordinates, setCoordinates] = useState();

  const fetchData = async () => {
    const response = await DataService.get(endpoints.maps, {
      regions: "All",
      region_id: currentRegion,
    });
    setApiDataRegion(
      response?.region_dict?.map((item) => {
        return {
          name: item?.name_en,
          id: item?.id,
          geo_json: item?.geo_json,
        };
      })
    );
    const resSub = await DataService.get(endpoints.investSubproject);
    const dataFilter = resSub?.results?.filter(
      (x) =>
        x?.aplication_detail?.coordinate_x && x?.aplication_detail?.coordinate_y
    );

    setCoordinates(dataFilter);
  };

  useEffect(() => {
    fetchData();
  }, [lang]);
  const onChangeRegion = async () => {
    const response = await DataService.get(endpoints.maps, filter);
    setGeoDataDistrict(
      response?.district_dict?.map((item) => {
        return {
          name: item?.name_en,
          id: item?.id,
          geo_json: item?.geo_json,
        };
      })
    );
  };
  useEffect(() => {
    if (filter) onChangeRegion();
  }, [filter]);
  const MapCallback = useCallback(() => {
    return (
      <>
        {apiDataRegion && coordinates ? (
          <Map1
            setFilters={setFilter}
            filter={filter}
            subprojects={coordinates}
            regions={apiDataRegion}
            districts={geoDataDistrict}
          />
        ) : (
          <LoaderContent />
        )}
      </>
    );
  }, [apiDataRegion, geoDataDistrict, coordinates]);
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            title={TranslationList("Map")}
            pageTitle={TranslationList("Analytics")}
          />

          <Row className="w-100">
            <Col lg={8}>
              {/* {geoData ? (
                <Leaflet
                  districtJson={geoDataDistrict}
                  geojsonData={geoData}
                  onChangeRegion={onChangeRegion}
                />
              ) : (
                <LoaderContent />
              )} */}

              <MapCallback />
            </Col>

            <Col lg={4}>
              <Card>
                <CardBody>
                  <div className="mb-3">
                    <Label
                      htmlFor="choices-multiple-default"
                      className="form-label text-muted"
                    >
                      {TranslationList("regions")}
                    </Label>
                    <Select
                      defaultValue={selectedOption}
                      onChange={(e) => {
                        setSelectedOption(e.value);
                        setFilter({ regions: "", region_id: e.value });
                      }}
                      options={apiDataRegion?.map((item) => {
                        return { value: item.id, label: item.name };
                      })}
                    />
                  </div>
                  <div className="mb-3">
                    <Label
                      htmlFor="choices-multiple-default"
                      className="form-label text-muted"
                    >
                      {TranslationList("districts")}
                    </Label>
                    <Select
                      defaultValue={selectedOptionDistrict}
                      onChange={(e) => {
                        setSelectedOptionDistrict(e.value);
                      }}
                      options={geoDataDistrict?.map((item) => {
                        return { value: item.id, label: item.name };
                      })}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}
