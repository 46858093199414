import { createSlice } from "@reduxjs/toolkit";

export const dataMapSlide = createSlice({
  name: "dataMap",
  initialState: {
    data: null,
  },
  reducers: {
    getdataMap: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const { getdataMap } = dataMapSlide.actions;
export default dataMapSlide.reducer;
