import { createSlice } from "@reduxjs/toolkit";
import { APIClient } from "../helpers/api_helper";
import { DataService } from "../helpers/dataService/dataService";

export const documentSlide = createSlice({
  name: "document",
  initialState: {
    data: null,
    error: null,
    current: null,
    item: [],
  },
  reducers: {
    getdocument: (state, action) => {
      state.data = action.payload;
    },
    editdocument: (state, action) => {
      state.item = action.payload;
    },
    getcurrent:(state,action)=>{
      state.current=action.payload
    }
  },
});
export const adddocumentAsync = async (url, data) => {
  try {
    const datas = await DataService.post(url, data);
    return datas;
  } catch (error) {
    return error;
  }
};
export const getdocumentAsync = (data) => async (dispatch) => {
  try {
    const datas = await DataService.get(data);
    dispatch(getdocument(datas));
  } catch (error) {
    // //throw new Error(err);
  }
};
export const editdocumentAsync = async (url, data) => {
  try {
    const datas = await DataService.put(url, data);
    return datas;
  } catch (error) {
    return error;
  }
};
export const getorganizationCurrentAsync = (data) => async (dispatch) => {
  try {
    const datas = await DataService.get(data);
    dispatch(getcurrent(datas));
  } catch (error) {
    // //throw new Error(err);
  }
};
export const deletedocument = async (data) => {
  try {
    const datas = await DataService.delete(data);
    return datas;
  } catch (error) {
    return error;
  }
};

export const { getdocument, editdocument } = documentSlide.actions;
export default documentSlide.reducer;
