import React, { useState, useEffect } from "react";
import { TranslationList } from "../../slices/settings/listtranslation";
import data_64 from "./dataFile.json";
import { DataService } from "../../helpers/dataService/dataService";
export default function EImzoLogin() {
  const [accept, setAccept] = useState(false);
  const [eKeys, setEKeys] = useState("");
  const [eData, setEData] = useState({});
  const [eKeyId, setEKeyId] = useState("");
  const [ePKCS7, setEPKCS7] = useState("");
  const [eFullName, seteFullName] = useState("");
  const [keysList, setkeysList] = useState();
  const [error, setError] = useState();
  var EIMZO_MAJOR = 3;
  var EIMZO_MINOR = 37;
  var errorCAPIWS =
    "Ошибка соединения с E-IMZO. Возможно у вас не установлен модуль E-IMZO или Браузер E-IMZO.";
  var errorBrowserWS =
    "Браузер не поддерживает технологию WebSocket. Установите последнюю версию браузера.";
  var errorUpdateApp =
    'ВНИМАНИЕ !!! Установите новую версию приложения E-IMZO или Браузера E-IMZO.<br /><a href="https://e-imzo.uz/main/downloads/" role="button">Скачать ПО E-IMZO</a>';
  var errorWrongPassword = "Пароль неверный.";

  useEffect(() => {
    eImzo();
  }, []);
  function eImzo() {
    EIMZOClient.API_KEYS = [
      "localhost",
      "96D0C1491615C82B9A54D9989779DF825B690748224C2B04F500F370D51827CE2644D8D4A82C18184D73AB8530BB8ED537269603F61DB0D03D2104ABF789970B",
      "127.0.0.1",
      "A7BCFA5D490B351BE0754130DF03A068F855DB4333D43921125B9CF2670EF6A40370C646B90401955E1F7BC9CDBF59CE0B2C5467D820BE189C845D0B79CFC96F",
      // добавьте свой Домен и API-KEY сюда
      "kadastr.mwr.uz",
      "34A019FBCA3B4546136D389AF496DDFF92EBC52B239CB9868C39147993811D54935C2CF67EF1268F4841A257222F3E915AD167AE302697E6BC99B9834C466697",
    ];

    EIMZOClient.checkVersion(
      function (major, minor) {
        var newVersion = EIMZO_MAJOR * 100 + EIMZO_MINOR;
        var installedVersion = parseInt(major) * 100 + parseInt(minor);

        if (installedVersion < newVersion) {
          uiUpdateApp();
        } else {
          EIMZOClient.installApiKeys(
            function () {
              uiLoadKeys();
            },
            function (e, r) {
              if (r) {
                uiShowMessage(r);
                return r;
              } else {
                wsError(e);
                return e;
              }
            }
          );
        }
      },
      function (e, r) {
        if (r) {
          uiShowMessage(r);
          return r;
        } else {
          uiNotLoaded(e);
          return e;
        }
      }
    );
  }
  function uiLoadKeys() {
    uiClearCombo();
    EIMZOClient.listAllUserKeys(
      function (o, i) {
        var itemId = "itm-" + o.serialNumber + "-" + i;
        return itemId;
      },
      function (itemId, v) {
        return uiCreateItem(itemId, v);
      },
      function (items, firstId) {
        uiFillCombo(items);
        setkeysList(items);
        uiComboSelect(firstId);
      },
      function (e, r) {
        uiShowMessage(errorCAPIWS);
      }
    );
  }
  function uiFillCombo(items) {
    var combo = document.getElementById("eKeys");

    if (combo)
      for (var itm in items) {
        combo.append(items[itm]);
      }
  }

  function uiComboSelect(itm) {
    if (itm) {
      var id = document.getElementById(itm);
      id.setAttribute("selected", "true");
    }
  }

  function uiClearCombo() {
    var combo = document.getElementById("eKeys");
    if (combo) combo.length = 0;
  }

  function uiCreateItem(itmkey, vo) {
    var now = new Date();
    vo.expired = dates.compare(now, vo.validTo) > 0;
    var itm = document.createElement("option");
    itm.value = itmkey;
    itm.text = vo.CN;
    if (vo.expired) {
      itm.text = itm.text + " {{ __('muddati o'tkan') }}";
    }
    itm.setAttribute("vo", JSON.stringify(vo));
    itm.setAttribute("id", itmkey);
    return itm;
  }

  function uiShowMessage(message, icon, dangerModel = true) {
    console.log({
      title: message,
      icon: icon,
      dangerMode: dangerModel,
    });
  }

  function wsError(e) {
    if (e) {
      uiShowMessage(errorCAPIWS + " : " + e, "error");
    } else {
      uiShowMessage(errorBrowserWS, "error");
    }
  }

  function uiNotLoaded(e) {
    if (e) {
      wsError(e);
    } else {
      uiShowMessage(e, "error");
    }
  }

  function uiUpdateApp() {
    uiShowMessage(errorUpdateApp, "error");
  }

  function sign() {
    var itm = document.getElementById("eKeys").value;
    if (itm) {
      var id = document.getElementById(itm);
      var vo = JSON.parse(id.getAttribute("vo"));
      document.getElementById("eFull_name").value = vo["CN"];
      var keyId = document.getElementById("eKeyId").value;
      var ePKCS7 = document.getElementById("ePKCS7");
      if (keyId) {
        CAPIWS.callFunction(
          {
            plugin: "pkcs7",
            name: "create_pkcs7",
            arguments: [
              //Данные в кодировке BASE64 (будут предваритьльно декодированы, подписаны и вложены в документ)
              data_64.file,
              //Идентификатор ключа подписывающего лица (полученный из фукнции других плагинов)
              keyId,
              //Возможные значения: 'yes' - будет создан PKCS#7/CMS документ без вложения исходных данных, 'no' или '' - будет создан PKCS#7/CMS документ с вложением исходных данных
              "yes",
            ],
          },
          function (event, data) {
            console.log(data);
            if (data.success) {
              // Успешно
              var pkcs7 = data.pkcs7_64;
            } else {
              // Ошибка обработки в E-IMZO
              window.alert(data.reason);
            }
          },
          function (error) {
            // Ошибка WebSocket соединения
            window.alert(error);
          }
        );
        // EIMZOClient.createPkcs7(
        //   keyId,
        //   data_64.file,
        //   null,
        //   function (pkcs7) {
        //     ePKCS7.value = pkcs7;
        //     handleSubmit(pkcs7);
        //   },
        //   function (e, r) {
        //     if (r) {
        //       if (r.indexOf("BadPaddingException") !== -1) {
        //         uiShowMessage(errorWrongPassword, "error", true);
        //       } else {
        //         uiShowMessage(r);
        //       }
        //     } else {
        //       keyId = "";
        //       uiShowMessage(errorBrowserWS);
        //     }
        //     if (e) {
        //       wsError(e);
        //     }
        //   }
        // );
      } else {
        EIMZOClient.loadKey(
          vo,
          function (id) {
            document.getElementById("eKeyId").value = id;
            CAPIWS.callFunction(
              {
                plugin: "pkcs7",
                name: "create_pkcs7",
                arguments: [
                  //Данные в кодировке BASE64 (будут предваритьльно декодированы, подписаны и вложены в документ)
                  data_64.file,
                  //Идентификатор ключа подписывающего лица (полученный из фукнции других плагинов)
                  id,
                  //Возможные значения: 'yes' - будет создан PKCS#7/CMS документ без вложения исходных данных, 'no' или '' - будет создан PKCS#7/CMS документ с вложением исходных данных
                  "yes",
                ],
              },
              function (event, data) {
                console.log(data);
                if (data.success) {
                  // Успешно
                  var pkcs7 = data.pkcs7_64;
                } else {
                  // Ошибка обработки в E-IMZO
                  window.alert(data.reason);
                }
              },
              function (error) {
                // Ошибка WebSocket соединения
                window.alert(error);
              }
            );
            // EIMZOClient.createPkcs7(
            //   id,
            //   data_64.file,
            //   null,
            //   function (pkcs7) {
            //     ePKCS7.value = pkcs7;
            //     console.log(pkcs7);
            //     // handleSubmit(pkcs7);
            //   },
            //   function (e, r) {
            //     if (r) {
            //       if (r.indexOf("BadPaddingException") !== -1) {
            //         uiShowMessage(errorWrongPassword);
            //       } else {
            //         uiShowMessage(r);
            //       }
            //     } else {
            //       document.getElementById("keyId").innerHTML = "";
            //       uiShowMessage(errorBrowserWS);
            //     }
            //     if (e) wsError(e);
            //   }
            // );
          },
          function (e, r) {
            if (r) {
              if (r.indexOf("BadPaddingException") !== -1) {
                uiShowMessage(errorWrongPassword);
              } else {
                uiShowMessage(r);
              }
            } else {
              uiShowMessage(errorBrowserWS);
            }
            if (e) wsError(e);
          }
        );
      }
    }
  }
  const login = async () => {
    try {
      await DataService.post("/login", eData);
    } catch (e) {}
  };
  return (
    <div className="modal-body">
      <div className="form-group col-md-12 my-2">
        <label htmlFor="keys">{TranslationList("Choose key:")}</label>
        <div>
          <select
            className="form-control"
            name="keys"
            id="eKeys"
            value={eKeys}
            onChange={(e) => {
              const selectedOption = e.target.options[e.target.selectedIndex];
              setEKeys(e.target.value);
              setEData(JSON.parse(selectedOption.getAttribute("vo")));
            }}
          ></select>
        </div>
      </div>
      <button
        className="btn  w-100 h-25 color-white"
        style={{ background: "#e8c139", color: "white" }}
        onClick={sign}
      >
        E-Imzo
      </button>

      <i style={{ color: "red", fontSize: 12 }}>{error}</i>
      <input
        type="text"
        name="keyId"
        id="eKeyId"
        hidden
        value={eKeyId}
        onChange={(e) => setEKeyId(e.target.value)}
      />
      <input
        type="text"
        name="pkcs7"
        id="ePKCS7"
        hidden
        value={ePKCS7}
        onChange={(e) => setEPKCS7(e.target.value)}
      />
      <input
        type="text"
        id="eFull_name"
        name="full_name"
        hidden
        value={eFullName}
        onChange={(e) => seteFullName(e.target.value)}
      />
      <input
        type="hidden"
        name="_token"
        value="your_csrf_token_here"
        id="csrf"
      />
    </div>
  );
}
