import { createSlice } from "@reduxjs/toolkit";
import { APIClient } from "../helpers/api_helper";
import { DataService } from "../helpers/dataService/dataService";

export const departmentSlide = createSlice({
  name: "department",
  initialState: {
    data: null,
    error: null,
    current: null,
    item: [],
  },
  reducers: {
    getdepartment: (state, action) => {
      state.data = action.payload;
    },
    editdepartment: (state, action) => {
      state.item = action.payload;
    },
  },
});
export const adddepartmentAsync = async (url, data) => {
  try {
    const datas = await DataService.post(url, data);
    return datas;
  } catch (error) {
    return error;
  }
};
export const getdepartmentAsync = (data) => async (dispatch) => {
  try {
    const datas = await DataService.get(data);
    dispatch(getdepartment(datas));
  } catch (error) {
    // //throw new Error(err);
  }
};
export const editdepartmentAsync = async (url, data) => {
  try {
    const datas = await DataService.put(url, data);
    return datas;
  } catch (error) {
    return error;
  }
};
export const deletedepartment = async (data) => {
  try {
    const datas = await DataService.delete(data);
    return datas;
  } catch (error) {
    return error;
  }
};

export const { getdepartment, editdepartment } = departmentSlide.actions;
export default departmentSlide.reducer;
