import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Nav,
  NavItem,
  FormFeedback,
  NavLink,
  TabContent,
  TabPane,
  Container,
  Row,
  Table,
  CardHeader,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Input,
  Button,
} from "reactstrap";
import { Link } from "react-router-dom";
import classnames from "classnames";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { useDispatch, useSelector } from "react-redux";
import {
  addregionAsync,
  deleteregion,
  editregionAsync,
  getregionAsync,
} from "../../slices/regions";
import { toast } from "react-toastify";
import DeleteModal from "../../Components/Common/DeleteModal";
import {
  adddistrictAsync,
  deletedistrict,
  editdistrictAsync,
  getdistrictAsync,
  getfullregionsAsync,
} from "../../slices/district";
import { Translation } from "react-i18next";
import { TranslationList } from "../../slices/settings/listtranslation";
import LoaderContent from "../../Components/Spinner";
import Paginations from "../Tables/DataTables/Pagination";

export default function RegionsAndDistricts() {
  const [customActiveTab, setcustomActiveTab] = useState("1");
  const [modal_varying1, setmodal_varying1] = useState(false);
  const [nameUZ, setNameUZ] = useState();
  const [nameRU, setNameRU] = useState();
  const [nameEN, setNameEN] = useState();
  const [error, setError] = useState();
  const [current, setCuttent] = useState();
  const [code, setCode] = useState();
  const [deleteModal, setDeleteModal] = useState(false);
  const districts = useSelector((state) => state.district.data);
  const regionsfull = useSelector((state) => state.district.regions?.results);
  const perpage = localStorage.getItem("perpage") ?? 10;
  const [perPage, setPerpage] = useState(perpage);
  const dispatch = useDispatch();
  const regions = useSelector((state) => state.region.data);
  const toggleCustom = (tab) => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };
  const lang = useSelector((state) => state.lang.data);

  useEffect(() => {
    dispatch(getregionAsync(`region/?page=${1}&limit=${perPage}`));
    dispatch(getfullregionsAsync("region/?limit=100"));
    dispatch(getdistrictAsync(`district/?page=${1}&limit=${perPage}`));
  }, [lang]);
  const handleSearch = (e) => {
    dispatch(getregionAsync(`region/?search=${e}`));
    dispatch(getdistrictAsync(`district/?search=${e}`));
  };
  function tog_varying1() {
    setmodal_varying1(!modal_varying1);
    setCuttent();
    setError();
  }
  const handleAdd = async () => {
    if (customActiveTab == "1") {
      const dataDocument = {
        code: code,
        name_uz: nameUZ,
        name_ru: nameRU,
        name_en: nameEN,
      };

      const response = await addregionAsync("region/", dataDocument);
      if (response?.errorCode) {
        setError(response?.message);
        toast.error(TranslationList("An error has occurred!"));
      } else {
        toast.success(TranslationList("Done successfully!"));
        dispatch(getregionAsync(`region/?page=${1}&limit=${perPage}`));
        setmodal_varying1(false);
      }
    } else {
      const dataDocument = {
        region: document.getElementById("region").value,
        code: code,
        name_uz: nameUZ,
        name_ru: nameRU,
        name_en: nameEN,
      };

      const response = await adddistrictAsync("district/", dataDocument);
      if (response?.errorCode) {
        setError(response?.message);

        toast.error(TranslationList("An error has occurred!"));
      } else {
        toast.success(TranslationList("Done successfully!"));
        dispatch(getdistrictAsync(`district/?page=${1}&limit=${perPage}`));
        setmodal_varying1(false);
      }
    }
  };
  const onClickEdite = (item) => {
    setmodal_varying1(true);
    setCuttent(item);
  };
  const handleEdite = async () => {
    if (customActiveTab == "1") {
      const dataDocument = {
        code: document.getElementById("code").value,
        name_uz: document.getElementById("name_uz").value,
        name_ru: document.getElementById("name_ru").value,
        name_en: document.getElementById("name_en").value,
      };

      const response = await editregionAsync(
        `region/${current?.id}`,
        dataDocument
      );
      if (response?.errorCode) {
        toast.error(TranslationList("An error has occurred!"));
        setError(response?.message);
      } else {
        toast.success(TranslationList("Done successfully!"));
        dispatch(getregionAsync(`region/?limit=100`));
        setmodal_varying1(false);
      }
    } else {
      const dataDocument = {
        region: document.getElementById("region").value,
        code: document.getElementById("code").value,
        name_uz: document.getElementById("name_uz").value,
        name_ru: document.getElementById("name_ru").value,
        name_en: document.getElementById("name_en").value,
      };

      const response = await editdistrictAsync(
        `district/${current?.id}`,
        dataDocument
      );
      if (response?.errorCode) {
        toast.error(TranslationList("An error has occurred!"));
        setError(response?.message);
      } else {
        toast.success(TranslationList("Done successfully!"));
        setmodal_varying1(false);

        dispatch(getdistrictAsync(`district/?limit=100`));
      }
    }
  };
  const onClickTodoDelete = (item) => {
    setCuttent(item);
    setDeleteModal(true);
  };
  const handleDelete = async () => {
    if (customActiveTab == "1") {
      const response = await deleteregion(`region/${current?.id}`);
      if (response?.errorCode) {
        toast.error(TranslationList("An error has occurred!"));
      } else {
        toast.success(TranslationList("Done successfully!"));
        dispatch(getregionAsync(`region/`));
      }
    } else {
      const response = await deletedistrict(`district/${current?.id}`);
      if (response?.errorCode) {
        toast.error(TranslationList("An error has occurred!"));
      } else {
        toast.success(TranslationList("Done successfully!"));
        dispatch(getdistrictAsync(`district/`));
      }
    }
    setDeleteModal(false);
  };
  //Pagination begin

  const handleCurrentPageRegion = (e) => {
    dispatch(getregionAsync(`region/?page=${e}&limit=${perPage}`));
  };
  const handlePerPageChangeRegion = (e) => {
    setPerpage(e);
    dispatch(getregionAsync(`region/?limit=${e}`));
  };

  const handleCurrentPageDistrict = (e) => {
    dispatch(getdistrictAsync(`district/?page=${e}&limit=${perPage}`));
  };
  const handlePerPageChangeDistrict = (e) => {
    setPerpage(e);
    dispatch(getdistrictAsync(`district/?limit=${e}`));
  };
  //Pagination end
  document.title = `INVESTCONTROL | ${TranslationList("Region and District")}`;

  return (
    <div className="page-content">
      <DeleteModal
        show={deleteModal}
        data={current?.name}
        onDeleteClick={handleDelete}
        onCloseClick={() => setDeleteModal(false)}
      />
      <Modal
        isOpen={modal_varying1}
        toggle={() => {
          tog_varying1();
        }}
        id="exampleModal"
      >
        <ModalHeader
          toggle={() => {
            tog_varying1();
          }}
        >
          {current ? TranslationList("Edit") : TranslationList("Add")}{" "}
        </ModalHeader>

        <div className="modal-body">
          <form>
            {customActiveTab == "2" && (
              <div className="mb-3">
                <label htmlFor="region" className="col-form-label">
                  {TranslationList("Region")} :
                </label>
                <select
                  className="form-select mb-3"
                  aria-label="Default select example"
                  id="region"
                  defaultValue={current?.region?.id}
                >
                  {regionsfull?.map((res) => (
                    <option key={res.id} value={res.id}>
                      {res.name}
                    </option>
                  ))}
                </select>
              </div>
            )}
            <div className="mb-3">
              {" "}
              <label htmlFor="customer-name" className="col-form-label">
                {TranslationList("uz")}:
              </label>
              <Input
                id="name_uz"
                type="text"
                className="form-control"
                onChange={(e) => setNameUZ(e.target.value)}
                defaultValue={current?.name_uz}
                invalid={error?.name_uz ? true : false}
              />
              <FormFeedback type="invalid">{error?.name_uz}</FormFeedback>
            </div>
            <div className="mb-3">
              <label htmlFor="customer-name" className="col-form-label">
                {TranslationList("ru")}:
              </label>
              <Input
                type="text"
                id="name_ru"
                className="form-control"
                onChange={(e) => setNameRU(e.target.value)}
                defaultValue={current?.name_ru}
                invalid={error?.name_ru ? true : false}
              />
              <FormFeedback type="invalid">{error?.name_ru}</FormFeedback>
            </div>
            <div className="mb-3">
              <label htmlFor="customer-name" className="col-form-label">
                {TranslationList("eng")}:
              </label>
              <Input
                type="text"
                id="name_en"
                className="form-control"
                onChange={(e) => setNameEN(e.target.value)}
                defaultValue={current?.name_en}
                invalid={error?.name_en ? true : false}
              />
              <FormFeedback type="invalid">{error?.name_en}</FormFeedback>
            </div>

            <div className="mb-3">
              <label htmlFor="customer-name" className="col-form-label">
                {TranslationList("Code")}:
              </label>
              <Input
                type="text"
                className="form-control"
                id="code"
                onChange={(e) => setCode(e.target.value)}
                defaultValue={current?.code}
                invalid={error?.code ? true : false}
              />
              <FormFeedback type="invalid">{error?.code}</FormFeedback>
            </div>
          </form>
        </div>
        <div className="modal-footer">
          <Button
            color="light"
            onClick={() => {
              setmodal_varying1(false);
            }}
          >
            {TranslationList("Cancel")}
          </Button>
          {current ? (
            <Button color="primary" onClick={handleEdite}>
              {TranslationList("Save")}
            </Button>
          ) : (
            <Button color="primary" onClick={handleAdd}>
              {TranslationList("Create")}
            </Button>
          )}
        </div>
      </Modal>

      {regions?.results && districts?.results ? (
        <Container fluid>
          <BreadCrumb
            title={TranslationList("Regions and Districts")}
            pageTitle={TranslationList("home")}
          />
          <Row>
            <Card>
              <CardHeader>
                <Row className="align-items-center gy-3">
                  <div className="col-sm">
                    <h5 className="card-title mb-0">
                      {" "}
                      {customActiveTab == 1
                        ? TranslationList("Region")
                        : TranslationList("District")}
                    </h5>
                  </div>
                  <div className="col-sm-auto d-flex justify-content-between">
                    <div className="px-2">
                      <div className="search-box">
                        <Input
                          type="text"
                          className="form-control search"
                          placeholder={TranslationList("List Search")}
                          onChange={(e) => handleSearch(e.target.value)}
                        />
                        <i className="ri-search-line search-icon"></i>
                      </div>
                    </div>
                    <div className="d-flex gap-1 flex-wrap">
                      <button
                        type="button"
                        className="btn btn-success add-btn"
                        id="create-btn"
                        onClick={() => tog_varying1()}
                      >
                        <i className="ri-add-line align-bottom me-1"></i>{" "}
                        {TranslationList("Add")}
                      </button>{" "}
                    </div>
                  </div>
                </Row>
              </CardHeader>
              <CardBody>
                <Nav
                  tabs
                  className="nav nav-tabs nav-tabs-custom nav-success  mb-3"
                >
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: customActiveTab === "1",
                      })}
                      onClick={() => {
                        toggleCustom("1");
                      }}
                    >
                      {TranslationList("Region")}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: customActiveTab === "2",
                      })}
                      onClick={() => {
                        toggleCustom("2");
                      }}
                    >
                      {TranslationList("District")}
                    </NavLink>
                  </NavItem>
                </Nav>

                <TabContent activeTab={customActiveTab} className="text-muted">
                  <TabPane tabId="1" id="home1">
                    <div className="live-preview p-3">
                      <div className="text-muted table-responsive">
                        <Table className="table-bordered align-middle table-nowrap mb-0">
                          <thead>
                            <tr>
                              <th scope="col" className="text-center">
                                {TranslationList("Number")}
                              </th>
                              <th scope="col" className="w-50  text-center">
                                {TranslationList("Name")}
                              </th>
                              <th scope="col" className="text-center">
                                {TranslationList("Code")}
                              </th>
                              <th scope="col " className="text-center">
                                {TranslationList("Actions")}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {regions?.results?.map((res, i) => (
                              <tr key={res?.id}>
                                <td className="text-center">{i + 1}</td>
                                <td>{res?.name}</td>
                                <td className="text-center">{res?.code}</td>

                                <td className="text-center">
                                  <div className="hstack gap-2 justify-content-center">
                                    <button
                                      className="btn btn-sm btn-soft-danger remove-list"
                                      onClick={() => onClickTodoDelete(res)}
                                    >
                                      <i className="ri-delete-bin-5-fill align-bottom" />
                                    </button>
                                    <button
                                      className="btn btn-sm btn-soft-info edit-list"
                                      onClick={() => onClickEdite(res)}
                                    >
                                      <i className="ri-pencil-fill align-bottom" />
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </div>
                    </div>
                    <Paginations
                      totalItems={regions?.pagination?.total}
                      perPageChange={(e) => handlePerPageChangeRegion(e)}
                      currentPage={(e) => handleCurrentPageRegion(e)}
                    />
                  </TabPane>

                  <TabPane tabId="2">
                    <div className="live-preview p-3">
                      <div className="text-muted table-responsive">
                        <Table className="table-bordered align-middle table-nowrap mb-0">
                          <thead>
                            <tr>
                              <th scope="col" className="text-center">
                                {TranslationList("Number")}
                              </th>
                              <th scope="col" className="w-50  text-center">
                                {TranslationList("Name")}
                              </th>
                              <th scope="col" className="w-25  text-center">
                                {TranslationList("Region")}
                              </th>
                              <th scope="col" className="text-center">
                                {TranslationList("Code")}
                              </th>

                              <th scope="col " className="text-center">
                                {TranslationList("Actions")}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {districts?.results?.map((res, i) => (
                              <tr key={res?.id}>
                                <td className="text-center">{i + 1}</td>
                                <td>{res?.name}</td>
                                <td>{res?.region_detail?.name}</td>

                                <td className="text-center">{res?.code}</td>

                                <td className="text-center">
                                  <div className="hstack gap-2 justify-content-center">
                                    <button
                                      className="btn btn-sm btn-soft-danger remove-list"
                                      onClick={() => onClickTodoDelete(res)}
                                    >
                                      <i className="ri-delete-bin-5-fill align-bottom" />
                                    </button>
                                    <button
                                      className="btn btn-sm btn-soft-info edit-list"
                                      onClick={() => onClickEdite(res)}
                                    >
                                      <i className="ri-pencil-fill align-bottom" />
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </div>
                    </div>
                    <Paginations
                      totalItems={districts?.pagination?.total}
                      perPageChange={(e) => handlePerPageChangeDistrict(e)}
                      currentPage={(e) => handleCurrentPageDistrict(e)}
                    />
                  </TabPane>
                </TabContent>
              </CardBody>
            </Card>
          </Row>
        </Container>
      ) : (
        <LoaderContent />
      )}
    </div>
  );
}
