import React from "react";
import ForPDF from "../Directory/ForPDF";
import { Col, Container, Row } from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";

export default function ShowPdf() {
  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb title="Basic Elements" pageTitle="Forms" />{" "}
        <Row className="justify-content-center">
          <Col lg={6}>
            <ForPDF />
          </Col>
        </Row>
      </Container>
    </div>
  );
}
