import { createSlice } from "@reduxjs/toolkit";
import { DataService } from "../helpers/dataService/dataService";

export const applicSlide = createSlice({
  name: "applic",
  initialState: {
    data: null,
    error: null,
    current: null,
    load: false,
  },
  reducers: {
    getapplic: (state, action) => {
      state.data = action.payload;
    },
    getload: (state, action) => {
      state.load = action.payload;
    },
    currentapp: (state, action) => {
      state.current = action.payload;
    },
  },
});
export const addapplicAsync = async (url, data) => {
  try {
    const datas = await DataService.post(url, data);
    return datas;
  } catch (error) {
    return error;
  }
};
export const addapplicAsyncNoAuth = async (url, data) => {
  try {
    const datas = await DataService.postNoAuth(url, data);
    return datas;
  } catch (error) {
    return error;
  }
};
export const getapplicAsync = (data) => async (dispatch) => {
  try {
    dispatch(getload(true));
    const datas = await DataService.get(data);
    dispatch(getapplic(datas));
    dispatch(getload(false));
  } catch (error) {
    // //throw new Error(err);
  }
};
export const currentapplicAsync = (data) => async (dispatch) => {
  try {
    const datas = await DataService.get(data);
    dispatch(currentapp(datas));
  } catch (error) {
    // //throw new Error(err);
  }
};
export const deleteApplication = async (data) => {
  try {
    const datas = await DataService.delete(data);
    return datas;
  } catch (error) {
    return error;
  }
};
export const deleteApplicationAll = async (url, data) => {
  try {
    const datas = await DataService.post(url, data);
    return datas;
  } catch (error) {
    return error;
  }
};
export const editeapplicationAsync = async (url, data) => {
  try {
    const datas = await DataService.putNoAuth(url, data);
    return datas;
  } catch (error) {
    return error;
  }
};

export const { getapplic, currentapp, getload } = applicSlide.actions;
export default applicSlide.reducer;
