import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Container,
  Row,
  Table,
  CardHeader,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Input,
  Button,
  FormFeedback,
} from "reactstrap";
import { Link } from "react-router-dom";
import classnames from "classnames";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { useDispatch, useSelector } from "react-redux";
import {
  addorganizationAsync,
  deleteorganization,
  editorganizationAsync,
  getorganizationAsync,
} from "../../slices/organization";
import { toast } from "react-toastify";
import DeleteModal from "../../Components/Common/DeleteModal";

import { TranslationList } from "../../slices/settings/listtranslation";
import {
  getdistrictCurrentAsync,
  getfullregionsAsync,
} from "../../slices/district";
import LoaderContent from "../../Components/Spinner";
import Paginations from "../Tables/DataTables/Pagination";
import {
  addorganizationBranchAsync,
  getorganizationBranchAsync,
} from "../../slices/organizationBranch";
import { columnOrganizationAsync } from "../../slices/organizations/reducer";
import { columnOrganizationBranchAsync } from "../../slices/organizationBranch/reducer";
import ColumnModal from "../../Components/Common/ColumnModal";

export default function Organization() {
  const regionsfull = useSelector((state) => state.district.regions?.results);
  const [customActiveTab, setcustomActiveTab] = useState("1");
  const [modal_varying1, setmodal_varying1] = useState(false);
  const [inn, setInn] = useState();
  const [nameUZ, setNameUZ] = useState();
  const [nameRU, setNameRU] = useState();
  const [nameEN, setNameEN] = useState();
  const [isColumn, setIsColumn] = useState(false);
  const [isColumnBranch, setIsColumnBranch] = useState(false);
  const [error, setError] = useState();
  const [legalStatus, setLegalStatus] = useState();
  const [address, setAddresss] = useState();
  const [mahalla, setMahalla] = useState();
  const [supervisor, setSupervisor] = useState();
  const [supervisorPhone, setSupervisorPhone] = useState();
  const [supervisorGender, setSupervisorGender] = useState();
  const [pinfl, setPinfl] = useState();
  const [typ, setTyp] = useState();
  const [description, setDescription] = useState();
  const [current, setCuttent] = useState();
  //   const [code, setCode] = useState();
  const [deleteModal, setDeleteModal] = useState(false);
  const columnsOrganizationData = useSelector(
    (state) => state.columnsOrganizationTable
  );
  const columnsOrganizationBranchData = useSelector(
    (state) => state.columnsOrganizationBranchTable
  );

  const dispatch = useDispatch();
  const organizations = useSelector((state) => state.organization.data);
  const organizationbranchs = useSelector(
    (state) => state.organizationBranch.data
  );
  const districtCurrent = useSelector(
    (state) => state.district?.current?.results
  );
  const toggleCustom = (tab) => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };
  const lang = useSelector((state) => state.lang.data);
  useEffect(() => {
    dispatch(
      columnOrganizationAsync([
        { name: TranslationList("Inn"), status: true },
        { name: TranslationList("Name"), status: true },
        { name: TranslationList("Legal status"), status: true },
        { name: TranslationList("Region"), status: true },
        { name: TranslationList("District"), status: true },
        { name: TranslationList("Address"), status: false },
        { name: TranslationList("Mahalla"), status: false },
        { name: TranslationList("Supervisor"), status: false },
        { name: TranslationList("Supervisor Phone"), status: false },
        { name: TranslationList("Supervisor Gender"), status: false },
        { name: TranslationList("PinFL"), status: true },
        { name: TranslationList("Type"), status: true },
        { name: TranslationList("Description"), status: false },
      ])
    );
    dispatch(
      columnOrganizationBranchAsync([
        { name: TranslationList("Inn"), status: true },
        { name: TranslationList("Name"), status: true },
        { name: TranslationList("Legal status"), status: true },
        { name: TranslationList("Region"), status: true },
        { name: TranslationList("District"), status: true },
        { name: TranslationList("Address"), status: false },
        { name: TranslationList("Mahalla"), status: false },
        { name: TranslationList("Supervisor"), status: false },
        { name: TranslationList("Supervisor Phone"), status: false },
        { name: TranslationList("Supervisor Gender"), status: false },
        { name: TranslationList("PinFL"), status: true },
        { name: TranslationList("Type"), status: true },
        { name: TranslationList("Description"), status: false },
      ])
    );
    dispatch(getorganizationAsync("organization/"));
    dispatch(getfullregionsAsync("region/?limit=100"));
    dispatch(getorganizationBranchAsync("organization-branch/"));
    if (regionsfull)
      dispatch(
        getdistrictCurrentAsync(
          `district/?region=${regionsfull[0]?.id}&limit=100`
        )
      );
  }, [lang]);
  const handleSearch = (e) => {
    dispatch(getorganizationAsync(`organization/?search=${e}`));
    dispatch(getorganizationBranchAsync(`organization-branch/?search=${e}`));
  };
  const SelectChange = (id) => {
    dispatch(getdistrictCurrentAsync(`district/?region=${id}&limit=100`));
  };
  function tog_varying1() {
    setmodal_varying1(!modal_varying1);
    setCuttent();
    setError();
  }
  const toggleColumn = () => {
    setIsColumn(!isColumn);
  };
  const toggleColumnBranch = () => {
    setIsColumnBranch(!isColumnBranch);
  };
  const handleAdd = async () => {
    if (customActiveTab == "1") {
      const dataDocument = {
        region: document.getElementById("region").value,
        district: document.getElementById("district").value,
        inn: inn,
        name_uz: nameUZ,
        name_ru: nameRU,
        name_en: nameEN,
        legal_status: legalStatus,
        address: address,
        mahalla: mahalla,
        supervisor: supervisor,
        supervisor_phone: supervisorPhone,
        supervisor_gender: supervisorGender,
        pinfl: pinfl,
        typ: typ,
        description: description,
      };
      const response = await addorganizationAsync(
        "organization/",
        dataDocument
      );
      if (response?.errorCode) {
        setError(response?.message);
        toast.error(TranslationList("An error has occurred!"));
      } else {
        toast.success(TranslationList("Done successfully!"));
        dispatch(getorganizationAsync(`organization/`));
        setmodal_varying1(false);
      }
    } else {
      const dataDocument = {
        region: document.getElementById("region").value,
        district: document.getElementById("district").value,
        inn: inn,
        name_uz: nameUZ,
        name_ru: nameRU,
        name_en: nameEN,
        legal_status: legalStatus,
        address: address,
        mahalla: mahalla,
        supervisor: supervisor,
        supervisor_phone: supervisorPhone,
        supervisor_gender: supervisorGender,
        pinfl: pinfl,
        typ: typ,
        description: description,
      };
      const response = await addorganizationBranchAsync(
        "organization-branch/",
        dataDocument
      );
      if (response?.errorCode) {
        setError(response?.message);
        toast.error(TranslationList("An error has occurred!"));
      } else {
        toast.success(TranslationList("Done successfully!"));
        dispatch(getorganizationBranchAsync(`organization-branch/`));
        setmodal_varying1(false);
      }
    }
  };
  const onClickEdite = (item) => {
    setmodal_varying1(true);
    setCuttent(item);
  };
  const handleEdite = async () => {
    if (customActiveTab == "1") {
      const dataDocument = {
        inn: document.getElementById("inn").value,
        name_uz: document.getElementById("name_uz").value,
        name_ru: document.getElementById("name_ru").value,
        name_en: document.getElementById("name_en").value,
        legal_status: document.getElementById("legal_status").value,
        address: document.getElementById("address").value,
        mahalla: document.getElementById("mahalla").value,
        supervisor: document.getElementById("supervisor").value,
        supervisor_phone: document.getElementById("supervisor_phone").value,
        supervisor_gender: document.getElementById("supervisor_gender").value,
        pinfl: document.getElementById("pinfl").value,
        typ: document.getElementById("typ").value,
        description: document.getElementById("description").value,
      };

      const response = await editorganizationAsync(
        `organization/${current?.id}`,
        dataDocument
      );
      if (response?.errorCode) {
        setError(response?.message);
        toast.error(TranslationList("An error has occurred!"));
      } else {
        toast.success(TranslationList("Done successfully!"));
        dispatch(getorganizationAsync(`organization/`));
        setmodal_varying1(false);
      }
    } else {
      const dataDocument = {
        inn: document.getElementById("inn").value,
        name_uz: document.getElementById("name_uz").value,
        name_ru: document.getElementById("name_ru").value,
        name_en: document.getElementById("name_en").value,
        legal_status: document.getElementById("legal_status").value,
        address: document.getElementById("address").value,
        mahalla: document.getElementById("mahalla").value,
        supervisor: document.getElementById("supervisor").value,
        supervisor_phone: document.getElementById("supervisor_phone").value,
        supervisor_gender: document.getElementById("supervisor_gender").value,
        pinfl: document.getElementById("pinfl").value,
        typ: document.getElementById("typ").value,
        description: document.getElementById("description").value,
      };

      const response = await editorganizationBranchAsync(
        `organization-branch/${current?.id}`,
        dataDocument
      );
      if (response?.errorCode) {
        setError(response?.message);
        toast.error(TranslationList("An error has occurred!"));
      } else {
        toast.success(TranslationList("Done successfully!"));
        dispatch(getorganizationBranchAsync(`organization-branch/`));
        setmodal_varying1(false);
      }
    }
  };
  const onClickTodoDelete = (item) => {
    setCuttent(item);
    setDeleteModal(true);
  };
  const handleDelete = async () => {
    if (customActiveTab == "1") {
      const response = await deleteorganization(`organization/${current?.id}`);
      if (response?.errorCode) {
        toast.error(TranslationList("An error has occurred!"));
      } else {
        toast.success(TranslationList("Done successfully!"));
        dispatch(getorganizationAsync(`organization/`));
      }
      setDeleteModal(false);
    } else {
      const response = await deleteorganization(`organization/${current?.id}`);
      if (response?.errorCode) {
        toast.error(TranslationList("An error has occurred!"));
      } else {
        toast.success(TranslationList("Done successfully!"));
        dispatch(getorganizationAsync(`organization/`));
      }
      setDeleteModal(false);
    }
  };
  const handleSave = (data) => {
    console.log("data", data);
  };
  const perpage = localStorage.getItem("perpage") ?? 10;
  const [perPage, setPerpage] = useState(perpage);
  const handleCurrentPage = (e) => {
    dispatch(getorganizationAsync(`organization/?page=${e}&limit=${perPage}`));
  };
  const handlePerPageChange = (e) => {
    setPerpage(e);
    dispatch(getorganizationAsync(`organization/?limit=${e}`));
  };
  document.title = `INVESTCONTROL | ${TranslationList("Organizations")}`;

  return (
    <div className="page-content">
      <DeleteModal
        show={deleteModal}
        data={current?.name}
        onDeleteClick={handleDelete}
        onCloseClick={() => setDeleteModal(false)}
      />
      <ColumnModal
        show={customActiveTab == "1" ? isColumn : isColumnBranch}
        handleSaveColumn={handleSave}
        onCloseClick={() =>
          customActiveTab == "1" ? setIsColumn(false) : setIsColumnBranch(false)
        }
        column={
          (customActiveTab == "1"
            ? columnsOrganizationData
            : columnsOrganizationBranchData
          )?.columns
        }
      />
      <Modal
        size="lg"
        isOpen={modal_varying1}
        toggle={() => {
          tog_varying1();
        }}
        id="exampleModal"
      >
        <ModalHeader
          toggle={() => {
            tog_varying1();
          }}
        >
          {current ? TranslationList("Edit") : TranslationList("Add")}{" "}
        </ModalHeader>
        <div className="modal-body">
          <Container fluid>
            <div className="row">
              <div className="mb-3 col-6">
                <label htmlFor="customer-name" className="col-form-label">
                  {TranslationList("Inn")} :
                </label>
                <Input
                  type="number"
                  id="inn"
                  className="form-control"
                  onChange={(e) => setInn(e.target.value)}
                  defaultValue={current?.inn}
                />
              </div>
              <div className="mb-3 col-6">
                {" "}
                <label htmlFor="customer-name" className="col-form-label">
                  {TranslationList("uz")}:
                </label>
                <Input
                  id="name_uz"
                  type="text"
                  className="form-control"
                  onChange={(e) => setNameUZ(e.target.value)}
                  defaultValue={current?.name_uz}
                  invalid={error?.name_uz ? true : false}
                />
                <FormFeedback type="invalid">{error?.name_uz}</FormFeedback>
              </div>
            </div>
            <div className="row">
              <div className="mb-3 col-6">
                <label htmlFor="customer-name" className="col-form-label">
                  {TranslationList("ru")}:
                </label>
                <Input
                  type="text"
                  id="name_ru"
                  className="form-control"
                  onChange={(e) => setNameRU(e.target.value)}
                  defaultValue={current?.name_ru}
                  invalid={error?.name_ru ? true : false}
                />
                <FormFeedback type="invalid">{error?.name_ru}</FormFeedback>
              </div>
              <div className="mb-3 col-6">
                <label htmlFor="customer-name" className="col-form-label">
                  {TranslationList("eng")}:
                </label>
                <Input
                  type="text"
                  id="name_en"
                  className="form-control"
                  onChange={(e) => setNameEN(e.target.value)}
                  defaultValue={current?.name_en}
                  invalid={error?.name_en ? true : false}
                />
                <FormFeedback type="invalid">{error?.name_en}</FormFeedback>
              </div>
            </div>
            <div className="mb-3">
              <label htmlFor="customer-name" className="col-form-label">
                {TranslationList("Legal Status")} :
              </label>
              <Input
                type="text"
                id="legal_status"
                className="form-control"
                onChange={(e) => setLegalStatus(e.target.value)}
                defaultValue={current?.legal_status}
                invalid={error?.legal_status ? true : false}
              />
            </div>
            <div className="row">
              <div className="mb-3 col-6">
                <label htmlFor="department" className="col-form-label">
                  {TranslationList("Region")} :
                </label>
                <select
                  className="form-select mb-3 col-6"
                  aria-label="Default select example"
                  id="region"
                  defaultValue={current?.region?.id}
                  onChange={(e) => SelectChange(e.target.value)}
                >
                  {regionsfull?.map((res) => (
                    <option key={res.id} value={res.id}>
                      {res.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="mb-3 col-6">
                <label htmlFor="district" className="col-form-label">
                  {TranslationList("District")} :
                </label>
                <select
                  className="form-select mb-3 col-6"
                  aria-label="Default select example"
                  id="district"
                  defaultValue={current?.district?.id}
                >
                  {districtCurrent?.map((res) => (
                    <option key={res.id} value={res.id}>
                      {res.name}{" "}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="row">
              <div className="mb-3 col-6">
                {" "}
                <label htmlFor="customer-name" className="col-form-label">
                  {TranslationList("Address")} :
                </label>
                <Input
                  id="address"
                  type="text"
                  className="form-control"
                  onChange={(e) => setAddresss(e.target.value)}
                  defaultValue={current?.address}
                  invalid={error?.address ? true : false}
                />
                <FormFeedback type="invalid">{error?.address}</FormFeedback>
              </div>
              <div className="mb-3 col-6">
                {" "}
                <label htmlFor="customer-name" className="col-form-label">
                  {TranslationList("Mahalla")} :
                </label>
                <Input
                  id="mahalla"
                  type="text"
                  className="form-control"
                  onChange={(e) => setMahalla(e.target.value)}
                  defaultValue={current?.mahalla}
                  invalid={error?.mahalla ? true : false}
                />
                <FormFeedback type="invalid">{error?.mahalla}</FormFeedback>
              </div>
            </div>
            <div className="row">
              <div className="mb-3 col-6">
                {" "}
                <label htmlFor="customer-name" className="col-form-label">
                  {TranslationList("Supervisor")}:
                </label>
                <Input
                  id="supervisor"
                  type="text"
                  className="form-control"
                  onChange={(e) => setSupervisor(e.target.value)}
                  defaultValue={current?.supervisor}
                  invalid={error?.supervisor ? true : false}
                />
                <FormFeedback type="invalid">{error?.supervisor}</FormFeedback>
              </div>
              <div className="mb-3 col-6">
                <label htmlFor="customer-name" className="col-form-label">
                  {TranslationList("Supervisor phone")} :
                </label>
                <Input
                  type="number"
                  id="supervisor_phone"
                  className="form-control"
                  onChange={(e) => setSupervisorPhone(e.target.value)}
                  defaultValue={current?.supervisor_phone}
                  invalid={error?.supervisor_phone ? true : false}
                />
                <FormFeedback type="invalid">
                  {error?.supervisor_phone}
                </FormFeedback>
              </div>
            </div>
            <div className="row">
              <div className="mb-3 col-6">
                <label htmlFor="customer-name" className="col-form-label">
                  {TranslationList("Supervisor gender")} :
                </label>
                <select
                  className="form-select mb-3 col-6"
                  aria-label="Default select example"
                  id="supervisor_gender"
                  defaultValue={current?.region?.id}
                  onChange={(e) => SelectChange(e.target.value)}
                >
                  <option value="Male">{TranslationList("Male")}</option>
                  <option value="Female">{TranslationList("Female")}</option>
                </select>
              </div>
              <div className="mb-3 col-6">
                <label htmlFor="customer-name" className="col-form-label">
                  {TranslationList("PinFL")} :
                </label>
                <Input
                  type="number"
                  id="pinfl"
                  className="form-control"
                  onChange={(e) => setPinfl(e.target.value)}
                  defaultValue={current?.pinfl}
                  invalid={error?.pinfl ? true : false}
                />
                <FormFeedback type="invalid">{error?.pinfl}</FormFeedback>
              </div>
            </div>
            <div className="row">
              <div className="mb-3 col-6">
                <label htmlFor="customer-name" className="col-form-label">
                  {TranslationList("Type")} :
                </label>
                <select
                  className="form-select mb-3 col-6"
                  aria-label="Default select example"
                  id="typ"
                  defaultValue={current?.region?.id}
                  onChange={(e) => SelectChange(e.target.value)}
                >
                  <option value="Bank">{TranslationList("Bank")}</option>
                  <option value="Center">{TranslationList("Center")}</option>
                </select>
              </div>
              <div className="mb-3 col-6">
                <label htmlFor="customer-name" className="col-form-label">
                  {TranslationList("Description")} :
                </label>
                <Input
                  type="text"
                  id="description"
                  className="form-control"
                  onChange={(e) => setDescription(e.target.value)}
                  defaultValue={current?.description}
                  invalid={error?.description ? true : false}
                />
                <FormFeedback type="invalid">{error?.description}</FormFeedback>
              </div>
            </div>
          </Container>
        </div>
        <div className="modal-footer">
          <Button
            color="light"
            onClick={() => {
              setmodal_varying1(false);
            }}
          >
            {TranslationList("Cancel")}
          </Button>
          {current ? (
            <Button color="primary" onClick={handleEdite}>
              {TranslationList("Save")}
            </Button>
          ) : (
            <Button color="primary" onClick={handleAdd}>
              {TranslationList("Add")}
            </Button>
          )}
        </div>
      </Modal>

      {(customActiveTab == "1"
        ? columnsOrganizationData
        : columnsOrganizationBranchData
      )?.columns &&
      organizations &&
      organizationbranchs ? (
        <Container fluid>
          <BreadCrumb
            title={TranslationList("Organization and Organization Branch")}
            pageTitle={TranslationList("home")}
          />
          <Row>
            <Card>
              <CardHeader>
                <Row className="align-items-center gy-3">
                  <div className="col-sm">
                    <h5 className="card-title mb-0">
                      {customActiveTab == 1
                        ? TranslationList("Organization")
                        : TranslationList("Organization branch")}
                    </h5>
                  </div>
                  <div className="col-sm-auto d-flex justify-content-between">
                    <div className="px-2">
                      <div className="search-box">
                        <Input
                          type="text"
                          className="form-control search"
                          placeholder={TranslationList("List Search")}
                          onChange={(e) => handleSearch(e.target.value)}
                        />
                        <i className="ri-search-line search-icon"></i>
                      </div>
                    </div>
                    <div className="d-flex gap-1 flex-wrap">
                      <button
                        type="button"
                        className="btn btn-warning"
                        onClick={
                          customActiveTab == "1"
                            ? toggleColumn
                            : toggleColumnBranch
                        }
                      >
                        <i className=" ri-menu-add-line me-1 align-bottom"></i>{" "}
                        {TranslationList("Columns")}
                      </button>{" "}
                      <button
                        type="button"
                        className="btn btn-success add-btn"
                        id="create-btn"
                        onClick={() => tog_varying1()}
                      >
                        <i className="ri-add-line align-bottom me-1"></i>{" "}
                        {TranslationList("Add")}
                      </button>{" "}
                    </div>
                  </div>
                </Row>
              </CardHeader>
              <CardBody>
                <Nav
                  tabs
                  className="nav nav-tabs nav-tabs-custom nav-success  mb-3"
                >
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: customActiveTab === "1",
                      })}
                      onClick={() => {
                        toggleCustom("1");
                      }}
                    >
                      {TranslationList("Organization")}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: customActiveTab === "2",
                      })}
                      onClick={() => {
                        toggleCustom("2");
                      }}
                    >
                      {TranslationList("Organization branch")}
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent activeTab={customActiveTab} className="text-muted">
                  <TabPane tabId="1">
                    <div className="live-preview p-3">
                      <div className="text-muted table-responsive">
                        <Table className="table-bordered align-middle table-nowrap mb-0">
                          <thead>
                            <tr>
                              <th scope="col" className="text-center">
                                {TranslationList("Number")}
                              </th>
                              {columnsOrganizationData?.columns[0]?.status && (
                                <th scope="col" className="text-center">
                                  {TranslationList("Inn")}
                                </th>
                              )}
                              {columnsOrganizationData?.columns[1]?.status && (
                                <th scope="col" className="w-50  text-center">
                                  {TranslationList("Name")}
                                </th>
                              )}
                              {columnsOrganizationData?.columns[2]?.status && (
                                <th scope="col" className="w-50  text-center">
                                  {TranslationList("Legal status")}
                                </th>
                              )}
                              {columnsOrganizationData?.columns[3]?.status && (
                                <th scope="col" className="w-50  text-center">
                                  {TranslationList("Region")}
                                </th>
                              )}
                              {columnsOrganizationData?.columns[4]?.status && (
                                <th scope="col" className="w-50  text-center">
                                  {TranslationList("District")}
                                </th>
                              )}
                              {columnsOrganizationData?.columns[5]?.status && (
                                <th scope="col" className="w-50  text-center">
                                  {TranslationList("Address")}
                                </th>
                              )}
                              {columnsOrganizationData?.columns[6]?.status && (
                                <th scope="col" className="w-50  text-center">
                                  {TranslationList("Mahalla")}
                                </th>
                              )}
                              {columnsOrganizationData?.columns[7]?.status && (
                                <th scope="col" className="w-50  text-center">
                                  {TranslationList("Supervisor")}
                                </th>
                              )}
                              {columnsOrganizationData?.columns[8]?.status && (
                                <th scope="col" className="w-50  text-center">
                                  {TranslationList("Supervisor Phone")}
                                </th>
                              )}
                              {columnsOrganizationData?.columns[9]?.status && (
                                <th scope="col" className="w-50  text-center">
                                  {TranslationList("Supervisor Gender")}
                                </th>
                              )}
                              {columnsOrganizationData?.columns[10]?.status && (
                                <th scope="col" className="w-50  text-center">
                                  {TranslationList("PinFL")}
                                </th>
                              )}
                              {columnsOrganizationData?.columns[11]?.status && (
                                <th scope="col" className="w-50  text-center">
                                  {TranslationList("Type")}
                                </th>
                              )}
                              {columnsOrganizationData?.columns[12]?.status && (
                                <th scope="col" className="w-50  text-center">
                                  {TranslationList("Description")}
                                </th>
                              )}
                              <th scope="col " className="text-center">
                                {TranslationList("Actions")}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {organizations?.results?.map((res, i) => (
                              <tr key={res?.id}>
                                <td className="align-items-center">{i + 1}</td>
                                {columnsOrganizationData?.columns[0]
                                  ?.status && <td>{res?.inn}</td>}
                                {columnsOrganizationData?.columns[1]
                                  ?.status && <td>{res?.name}</td>}
                                {columnsOrganizationData?.columns[2]
                                  ?.status && <td>{res?.legal_status}</td>}
                                {columnsOrganizationData?.columns[3]
                                  ?.status && (
                                  <td>{res?.region_detail?.name}</td>
                                )}
                                {columnsOrganizationData?.columns[4]
                                  ?.status && (
                                  <td>{res?.district_detail?.name}</td>
                                )}
                                {columnsOrganizationData?.columns[5]
                                  ?.status && <td>{res?.address}</td>}
                                {columnsOrganizationData?.columns[6]
                                  ?.status && <td>{res?.mahalla}</td>}
                                {columnsOrganizationData?.columns[7]
                                  ?.status && <td>{res?.supervisor}</td>}
                                {columnsOrganizationData?.columns[8]
                                  ?.status && <td>{res?.supervisor_phone}</td>}
                                {columnsOrganizationData?.columns[9]
                                  ?.status && <td>{res?.supervisor_gender}</td>}
                                {columnsOrganizationData?.columns[10]
                                  ?.status && <td>{res?.pinfl}</td>}
                                {columnsOrganizationData?.columns[11]
                                  ?.status && <td>{res?.type}</td>}
                                {columnsOrganizationData?.columns[12]
                                  ?.status && <td>{res?.description}</td>}
                                <td className="text-center">
                                  <div className="hstack gap-2 justify-content-center">
                                    <button
                                      className="btn btn-sm btn-soft-danger remove-list"
                                      onClick={() => onClickTodoDelete(res)}
                                    >
                                      <i className="ri-delete-bin-5-fill align-bottom" />
                                    </button>
                                    <button
                                      className="btn btn-sm btn-soft-info edit-list"
                                      onClick={() => onClickEdite(res)}
                                    >
                                      <i className="ri-pencil-fill align-bottom" />
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </div>
                    </div>
                  </TabPane>

                  <TabPane tabId="2">
                    <div className="live-preview p-3">
                      <div className="text-muted table-responsive">
                        <Table className="table-bordered align-middle table-nowrap mb-0">
                          <thead>
                            <tr>
                              <th scope="col" className="text-center">
                                {TranslationList("Number")}
                              </th>
                              {columnsOrganizationBranchData?.columns[0]
                                ?.status && (
                                <th scope="col" className="text-center">
                                  {TranslationList("Inn")}
                                </th>
                              )}
                              {columnsOrganizationBranchData?.columns[1]
                                ?.status && (
                                <th scope="col" className="w-50  text-center">
                                  {TranslationList("Name")}
                                </th>
                              )}
                              {columnsOrganizationBranchData?.columns[2]
                                ?.status && (
                                <th scope="col" className="w-50  text-center">
                                  {TranslationList("Legal Status")}
                                </th>
                              )}
                              {columnsOrganizationBranchData?.columns[3]
                                ?.status && (
                                <th scope="col" className="w-50  text-center">
                                  {TranslationList("Region")}
                                </th>
                              )}
                              {columnsOrganizationBranchData?.columns[4]
                                ?.status && (
                                <th scope="col" className="w-50  text-center">
                                  {TranslationList("District")}
                                </th>
                              )}
                              {columnsOrganizationBranchData?.columns[5]
                                ?.status && (
                                <th scope="col" className="w-50  text-center">
                                  {TranslationList("Address")}
                                </th>
                              )}
                              {columnsOrganizationBranchData?.columns[6]
                                ?.status && (
                                <th scope="col" className="w-50  text-center">
                                  {TranslationList("Mahalla")}
                                </th>
                              )}
                              {columnsOrganizationBranchData?.columns[7]
                                ?.status && (
                                <th scope="col" className="w-50  text-center">
                                  {TranslationList("Supervisor")}
                                </th>
                              )}
                              {columnsOrganizationBranchData?.columns[8]
                                ?.status && (
                                <th scope="col" className="w-50  text-center">
                                  {TranslationList("Supervisor Phone")}
                                </th>
                              )}
                              {columnsOrganizationBranchData?.columns[9]
                                ?.status && (
                                <th scope="col" className="w-50  text-center">
                                  {TranslationList("Supervisor Gender")}
                                </th>
                              )}
                              {columnsOrganizationBranchData?.columns[10]
                                ?.status && (
                                <th scope="col" className="w-50  text-center">
                                  {TranslationList("PinFL")}
                                </th>
                              )}
                              {columnsOrganizationBranchData?.columns[11]
                                ?.status && (
                                <th scope="col" className="w-50  text-center">
                                  {TranslationList("Type")}
                                </th>
                              )}
                              {columnsOrganizationBranchData?.columns[12]
                                ?.status && (
                                <th scope="col" className="w-50  text-center">
                                  {TranslationList("Description")}
                                </th>
                              )}
                              <th scope="col " className="text-center">
                                {TranslationList("Actions")}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {organizationbranchs?.results?.map((res, i) => (
                              <tr key={res?.id}>
                                <td className="align-items-center">{i + 1}</td>
                                {columnsOrganizationBranchData?.columns[0]
                                  ?.status && <td>{res?.inn}</td>}
                                {columnsOrganizationBranchData?.columns[1]
                                  ?.status && <td>{res?.name}</td>}
                                {columnsOrganizationBranchData?.columns[2]
                                  ?.status && <td>{res?.legal_status}</td>}
                                {columnsOrganizationBranchData?.columns[3]
                                  ?.status && (
                                  <td>{res?.region_detail?.name}</td>
                                )}
                                {columnsOrganizationBranchData?.columns[4]
                                  ?.status && (
                                  <td>{res?.district_detail?.name}</td>
                                )}
                                {columnsOrganizationBranchData?.columns[5]
                                  ?.status && <td>{res?.address}</td>}
                                {columnsOrganizationBranchData?.columns[6]
                                  ?.status && <td>{res?.mahalla}</td>}
                                {columnsOrganizationBranchData?.columns[7]
                                  ?.status && <td>{res?.supervisor}</td>}
                                {columnsOrganizationBranchData?.columns[8]
                                  ?.status && <td>{res?.supervisor_phone}</td>}
                                {columnsOrganizationBranchData?.columns[9]
                                  ?.status && <td>{res?.supervisor_gender}</td>}
                                {columnsOrganizationBranchData?.columns[10]
                                  ?.status && <td>{res?.pinfl}</td>}
                                {columnsOrganizationBranchData?.columns[11]
                                  ?.status && <td>{res?.type}</td>}
                                {columnsOrganizationBranchData?.columns[12]
                                  ?.status && <td>{res?.description}</td>}
                                <td className="text-center">
                                  <div className="hstack gap-2 justify-content-center">
                                    <button
                                      className="btn btn-sm btn-soft-danger remove-list"
                                      onClick={() => onClickTodoDelete(res)}
                                    >
                                      <i className="ri-delete-bin-5-fill align-bottom" />
                                    </button>
                                    <button
                                      className="btn btn-sm btn-soft-info edit-list"
                                      onClick={() => onClickEdite(res)}
                                    >
                                      <i className="ri-pencil-fill align-bottom" />
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </div>
                    </div>
                  </TabPane>
                </TabContent>
              </CardBody>

              <Paginations
                totalItems={organizations?.pagination?.total}
                perPageChange={(e) => handlePerPageChange(e)}
                currentPage={(e) => handleCurrentPage(e)}
              />
            </Card>
          </Row>
        </Container>
      ) : (
        <LoaderContent />
      )}
    </div>
  );
}
