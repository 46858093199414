import React, { useState, useEffect } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { Card, CardBody, Col, Modal, ModalHeader } from "reactstrap";
import DeleteModal from "../../../Components/Common/DeleteModal";
import { ToastContainer } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import { getCurrentProject, getprojectAsync } from "../../../slices/project";
import { TranslationList } from "../../../slices/settings/listtranslation";
import { getCurrentsector } from "../../../slices/sector";
import LoaderContent from "../../../Components/Spinner";
import "./style.scss";
const List = () => {
  const dispatch = useDispatch();
  const [currentData, setCurrentData] = useState();
  const [modal_large, setmodal_large] = useState(false);
  const selectProject = useSelector((state) => state.selectProject?.data);
  function tog_large() {
    setmodal_large(!modal_large);
  }
  const [projects, setProjects] = useState();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const [project, setProject] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [data, setData] = useState();
  const getData = async () => {
    const res = await getCurrentsector(`sector/${searchParams.get("id")}`);
    setData(res);
  };
  const getDataPro = async () => {
    const res = await getCurrentProject(
      `projects/list/?sectors=${searchParams.get("id")}&limit=100`
    );
    console.log(res);
    setProjects(res);
  };
  useEffect(() => {
    dispatch(
      getprojectAsync(`projects/list/?sectors=${searchParams.get("id")}`)
    );
    getData();
    getDataPro();
  }, [dispatch]);

  const handleDeleteProjectList = () => {
    if (project) {
      setDeleteModal(false);
    }
  };

  const activebtn = (ele) => {
    if (ele.closest("button").classList.contains("active")) {
      ele.closest("button").classList.remove("active");
    } else {
      ele.closest("button").classList.add("active");
    }
  };
  const handleStatus = (cell) => {
    switch (cell) {
      case "completed".toLocaleUpperCase():
        return <span>{TranslationList("Fulfilled")} </span>;
      case "draft".toLocaleUpperCase():
        return <span>{TranslationList("Draft")}</span>;
      case "removed".toLocaleUpperCase():
        return <span> {TranslationList("Accepted for processing")} </span>;
      case "in_progress".toLocaleUpperCase():
        return <span> {TranslationList("In consideration")} </span>;
      case "under_consideration".toLocaleUpperCase():
        return <span>{TranslationList("In consideration")} </span>;
      case "NEW":
        return <span> {TranslationList("New")} </span>;
      default:
        return <span> {TranslationList("On completion")}</span>;
    }
  };
  return (
    <React.Fragment>
      {/* <ToastContainer closeButton={false} /> */}
      <DeleteModal
        show={deleteModal}
        onDeleteClick={() => handleDeleteProjectList()}
        onCloseClick={() => setDeleteModal(false)}
      />

      {projects ? (
        <div className="row">
          {projects?.results?.map((item, index) => (
            <React.Fragment key={index}>
              <Col xxl={4} sm={6} className="project-card ">
                <div
                  onClick={() => {
                    tog_large(true);
                    setCurrentData(item);
                  }}
                  className={
                    selectProject == item?.id
                      ? "card-border-warning cursor-pointer card border  cards-shadow ribbon-box right overflow-hidden"
                      : "card-border-success cursor-pointer card border  cards-shadow ribbon-box right overflow-hidden"
                  }
                >
                  <CardBody className=" p-4 h-50">
                    <div
                      className={`ribbon ribbon-success ribbon-shape trending-ribbon`}
                    >
                      <i className="ri-flashlight-fill text-white align-bottom"></i>{" "}
                      <span className="trending-ribbon-text">
                        {handleStatus(item?.status)}
                      </span>
                    </div>
                    <div className={`p-3 mt-n3 mx-n3   rounded-top `}>
                      <div className="d-flex align-items-center">
                        <div className="flex-grow-1"></div>
                      </div>
                    </div>
                    <h5 className="mb-0 fs-14 mt-3">
                      <Link to="#" className="text-dark">
                        <h5 className="mb-0 fs-14 fw-bold mt-3 ">
                          {item?.name}
                        </h5>{" "}
                      </Link>
                    </h5>
                    <div className="mt-3 fs-14">
                      {/* <p>Description</p> */}
                      <div className="mb-1">
                        <div
                          className="text-wrap text-truncate-multi-lines"
                          dangerouslySetInnerHTML={{
                            __html: item?.description,
                          }}
                        />
                      </div>
                    </div>

                    <div className="py-3">
                      <h5 className="mb-0 fs-14 fw-bold mt-3">
                        {TranslationList(
                          "Issued_through_the_following_commercial_banks"
                        )}
                        :
                      </h5>
                      <div>
                        {item?.organization_detail?.slice(1, 4).map((res) => (
                          <p className="" key={res?.id}>
                            {res?.name}
                          </p>
                        ))}
                      </div>
                    </div>

                    {/* <div className="d-flex justify-content-center mt-3 live-preview">
                      <button
                        className="btn btn-success add-btn"
                        onClick={() => navigate(`/send-application`)}
                      >
                        {TranslationList("New statement")}
                      </button>
                    </div> */}
                  </CardBody>
                </div>
              </Col>
            </React.Fragment>
          ))}
        </div>
      ) : (
        <LoaderContent />
      )}
      <Modal
        size="lg"
        isOpen={modal_large}
        toggle={() => {
          tog_large();
        }}
      >
        <ModalHeader
          id="myLargeModalLabel"
          toggle={() => {
            tog_large();
          }}
        >
          <Link to="#" className="text-dark " onClick={() => tog_large(true)}>
            <h5 className="mb-0 fs-14 fw-bold mt-3 ">{currentData?.name} </h5>
          </Link>{" "}
        </ModalHeader>
        <div className="modal-body">
          <div className=" fs-14">
            {/* <p>Description</p> */}
            <div
              className="text-wrap "
              dangerouslySetInnerHTML={{
                __html: currentData?.description,
              }}
            />
          </div>

          <div className="py-3">
            <h5 className="mb-2 fs-14  fw-bold">
              {TranslationList("Issued_through_the_following_commercial_banks")}
              :
            </h5>
            <div>
              {currentData?.organization_detail?.map((res) => (
                <p className="" key={res?.id}>
                  {res?.name}
                </p>
              ))}
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <button
            className="btn btn-success add-btn"
            onClick={() =>
              navigate(
                `/create-application?project=${
                  currentData.id
                }&sector=${searchParams.get("id")}`
              )
            }
          >
            {TranslationList("New statement")}
          </button>
          <Link
            to="#"
            className="btn btn-link link-success fw-medium"
            onClick={() => setmodal_large(false)}
          >
            <i className="ri-close-line me-1 align-middle"></i>{" "}
            {TranslationList("Close")}
          </Link>
          {/* <Button color="primary">Save changes</Button> */}
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default List;
