import React, { useEffect, useState } from "react";
import { Card, CardBody, Col, Label, Row } from "reactstrap";

import { TranslationList } from "../../slices/settings/listtranslation";
import Flatpickr from "react-flatpickr";
import { DataService } from "../../helpers/dataService/dataService";
import endpoints from "../../endpoints";
export default function StatisticsAll() {
  const [apiData, setApiData] = useState();
  const [state, setState] = useState({ dateFrom: null, dateTo: null });
  const fetchData = async () => {
    const response = await DataService.get(endpoints.aggregateStats, {
      dateFrom: state.dateFrom ?? "1021-01-01",
      dateTo: state.dateTo ?? new Date(),
    });
    setApiData(response);
  };

  useEffect(() => {
    fetchData();
  }, [state]);
  return (
    <div>
      <Row className="mb-3">
        <Col lg={3}>
          <div>
            <Label className="form-label mb-0">{TranslationList("From")}</Label>
            <Flatpickr
              onChange={(e) => setState({ ...state, dateFrom: e[0] })}
              className="form-control"
              options={{
                enableTime: true,
                dateFormat: "Y-m-d ",
              }}
            />
          </div>
        </Col>
        <Col lg={3}>
          <div>
            <Label className="form-label mb-0">{TranslationList("To")}</Label>
            <Flatpickr
              onChange={(e) => setState({ ...state, dateTo: e[0] })}
              className="form-control"
              options={{
                dateFormat: "Y-m-d",
              }}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <div className="d-flex flex-column h-100">
          <Row>
            {[
              { lable: "Projects", jobs: apiData?.projects_count },
              { lable: "Subproject", jobs: apiData?.subproject_count },
              { lable: "Components", jobs: apiData?.component_count },
              { lable: "Beneficiary", jobs: apiData?.beneficiary_count },
            ].map((widget, key) => (
              <Col xl={3} md={3} key={key}>
                <Card className="card-animate overflow-hidden">
                  <div
                    className="position-absolute start-0"
                    style={{ zIndex: "0" }}
                  >
                    <svg
                      version="1.2"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 200 120"
                      width="200"
                      height="120"
                    >
                      <path
                        id="Shape 8"
                        style={{ opacity: ".05", fill: "#878a99" }}
                        d="m189.5-25.8c0 0 20.1 46.2-26.7 71.4 0 0-60 15.4-62.3 65.3-2.2 49.8-50.6 59.3-57.8 61.5-7.2 2.3-60.8 0-60.8 0l-11.9-199.4z"
                      />
                    </svg>
                  </div>
                  <CardBody style={{ zIndex: "1" }}>
                    <div className="d-flex align-items-center">
                      <div className="flex-grow-1 overflow-hidden">
                        <p className="text-uppercase fw-semibold text-muted text-truncate mb-3">
                          {TranslationList(widget.lable)}
                        </p>
                        <h4 className="fs-22 fw-bold ff-secondary mb-0">
                          <span className="counter-value" data-target="36894">
                            {widget.jobs}
                          </span>
                        </h4>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row>
          <Row>
            {[
              { lable: "Applications", jobs: apiData?.application_dict },
              {
                lable: "In consideration",
                jobs: apiData?.application_completed_dict,
              },
              {
                lable: "Under consideration",
                jobs: apiData?.application_in_progress_dict,
              },
              {
                lable: "On completion",
                jobs: apiData?.application_refusal_dict,
              },
            ].map((widget, key) => (
              <Col xl={3} md={3} key={key}>
                <Card className="card-animate overflow-hidden">
                  <div
                    className="position-absolute start-0"
                    style={{ zIndex: "0" }}
                  >
                    <svg
                      version="1.2"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 200 120"
                      width="200"
                      height="120"
                    >
                      <path
                        id="Shape 8"
                        style={{ opacity: ".05", fill: "#878a99" }}
                        d="m189.5-25.8c0 0 20.1 46.2-26.7 71.4 0 0-60 15.4-62.3 65.3-2.2 49.8-50.6 59.3-57.8 61.5-7.2 2.3-60.8 0-60.8 0l-11.9-199.4z"
                      />
                    </svg>
                  </div>
                  <CardBody style={{ zIndex: "1" }}>
                    <div className="d-flex align-items-center">
                      <div className="flex-grow-1 overflow-hidden">
                        <p className="text-uppercase fw-semibold text-muted text-truncate mb-3">
                          {" "}
                          {TranslationList(widget.lable)}
                        </p>
                        <h4 className="fs-22 fw-bold ff-secondary mb-0">
                          <span className="counter-value" data-target="36894">
                            {widget.jobs}
                          </span>
                        </h4>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row>
          <Row>
            {[
              {
                lable: "sub_no_period_monitoring_dict",
                jobs: apiData?.sub_no_period_monitoring_dict,
              },
              {
                lable: "sub_monitoring_dict",
                jobs: apiData?.sub_monitoring_dict,
              },
              {
                lable: "sub_start_monitoring_dict",
                jobs: apiData?.sub_start_monitoring_dict,
              },
              {
                lable: "sub_finish_monitoring_dict",
                jobs: apiData?.sub_finish_monitoring_dict,
              },
            ].map((widget, key) => (
              <Col xl={3} md={3} key={key}>
                <Card className="card-animate overflow-hidden">
                  <div
                    className="position-absolute start-0"
                    style={{ zIndex: "0" }}
                  >
                    <svg
                      version="1.2"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 200 120"
                      width="200"
                      height="120"
                    >
                      <path
                        id="Shape 8"
                        style={{ opacity: ".05", fill: "#878a99" }}
                        d="m189.5-25.8c0 0 20.1 46.2-26.7 71.4 0 0-60 15.4-62.3 65.3-2.2 49.8-50.6 59.3-57.8 61.5-7.2 2.3-60.8 0-60.8 0l-11.9-199.4z"
                      />
                    </svg>
                  </div>
                  <CardBody style={{ zIndex: "1" }}>
                    <div className="d-flex align-items-center">
                      <div className="flex-grow-1 overflow-hidden">
                        <p className="text-uppercase fw-semibold text-muted text-truncate mb-3">
                          {TranslationList(widget.lable)}
                        </p>
                        <h4 className="fs-22 fw-bold ff-secondary mb-0">
                          <span className="counter-value" data-target="36894">
                            {widget.jobs}
                          </span>
                        </h4>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row>
          <Row>
            {[
              {
                lable: "application_stagetumbank_dict",
                jobs: apiData?.application_stagetumbank_dict,
              },
              {
                lable: "application_stageresbank_dict",
                jobs: apiData?.application_stageresbank_dict,
              },
              {
                lable: "application_stageiscad_dict",
                jobs: apiData?.application_stageiscad_dict,
              },
              {
                lable: "application_stagecordinator_dict",
                jobs: apiData?.application_stagecordinator_dict,
              },
              {
                lable: "application_stagedirektor_dict",
                jobs: apiData?.application_stagedirektor_dict,
              },
              {
                lable: "application_stageekonomist_dict",
                jobs: apiData?.application_stageekonomist_dict,
              },
            ].map((widget, key) => (
              <Col xl={2} md={2} key={key}>
                <Card className="card-animate overflow-hidden">
                  <div
                    className="position-absolute start-0"
                    style={{ zIndex: "0" }}
                  >
                    <svg
                      version="1.2"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 200 120"
                      width="200"
                      height="120"
                    >
                      <path
                        id="Shape 8"
                        style={{ opacity: ".05", fill: "#878a99" }}
                        d="m189.5-25.8c0 0 20.1 46.2-26.7 71.4 0 0-60 15.4-62.3 65.3-2.2 49.8-50.6 59.3-57.8 61.5-7.2 2.3-60.8 0-60.8 0l-11.9-199.4z"
                      />
                    </svg>
                  </div>
                  <CardBody style={{ zIndex: "1" }}>
                    <div className="d-flex align-items-center">
                      <div className="flex-grow-1 overflow-hidden">
                        <p className="text-uppercase fw-semibold text-muted text-truncate mb-3">
                          {TranslationList(widget.lable)}
                        </p>
                        <h4 className="fs-22 fw-bold ff-secondary mb-0">
                          <span className="counter-value" data-target="36894">
                            {widget.jobs}
                          </span>
                        </h4>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row>
        </div>
      </Row>
    </div>
  );
}
