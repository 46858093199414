const endpoints = {
  monitoringPeriod: "/manitoring-period/",
  monitoringPeriodById: (id) => `/manitoring-period/${id}`,
  monitoringDocument: "/manitoring-document/",
  monitoringDocumentById: (id) => `/manitoring-document/${id}`,
  investSubproject: "/invest-subproject/",
  investSubprojectById: (id) => `/invest-subproject/${id}`,
  region: "/region",
  district: "/district",
  attachment: "/attachment",
  beneficiary: "/beneficiary",
  component: "/component",
  uploadManitoring: (id) => `/attachment/${id}/upload-manitoring`,
  investSubprojectManitoring: "/invest-subproject-manitoring",
  investSubprojectManitoringById: (id) => `/invest-subproject-manitoring/${id}`,

  investSubprojectCount: "/invest-subproject-count",
  analyticReport: "/analytic-report/",
  analyticReportById: (id) => `/analytic-report/${id}`,
  maps: `/maps`,
  aggregateStats: "/aggregate-stats",
  organization: "/organization",
  organizationbranch: "/organization-branch",
  user: "/user",
  complaint: "/complaint/",
  complaintFilter: "/complaint-filter",
  complaintById: (id) => `/complaint/${id}`,
  projects: "/projects",
  application: "/application",
};

export default endpoints;
