import React from "react";
import { Container } from "reactstrap";
import List from "../Projects/ProjectList/List";
import { TranslationList } from "../../slices/settings/listtranslation";

const SectorDetail = () => {
  document.title = `${TranslationList("Projects")} | Invetcontrol`;

  return (
    <Container fluid className="pt-5">
      <div className="d-flex justify-content-center align-items-center w-100 px-5">
        <List />
      </div>
    </Container>
  );
};

export default SectorDetail;
