import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Container,
  Row,
  Label,
  Table,
  CardHeader,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Input,
  Button,
  FormFeedback,
  Col,
  CardFooter,
} from "reactstrap";
import { Link } from "react-router-dom";
import classnames from "classnames";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { useDispatch, useSelector } from "react-redux";
import {
  addcurrencyAsync,
  deletecurrency,
  editcurrencyAsync,
  getcurrencyAsync,
} from "../../slices/currency";
import { toast } from "react-toastify";
import DeleteModal from "../../Components/Common/DeleteModal";

import { TranslationList } from "../../slices/settings/listtranslation";
import LoaderContent from "../../Components/Spinner";
import Paginations from "../Tables/DataTables/Pagination";
import PreviewCardHeader from "../../Components/Common/PreviewCardHeader";
import ForPDF from "./ForPDF";

export default function Reports() {
  document.title = `INVESTCONTROL | ${TranslationList("Currency")}`;
  const [dateInput, setDateInput] = useState();
  const [organizationName, setOrganizationName] = useState();
  const [information, setInformation] = useState();
  const [goal, setGoal] = useState();
  const [location, setLocation] = useState();
  const [subProjectCost, setSubProjectCost] = useState();
  const [subLoan, setSubLoan] = useState();
  const [cost, setCost] = useState();
  const [singleWindow, setSingleWindow] = useState();
  const [order, setOrder] = useState();
  const [currentActivity, setCurrentActivity] = useState();
  const [appliciant, setAppliciant] = useState();
  const [postavshik, setPostavshik] = useState();
  const [product, setProduct] = useState();
  const [contract, setContract] = useState();
  const [dateContract, setDateContract] = useState();
  const [subloanStructure, setSubloanStructure] = useState();
  const [subprojectImplementation, setSubprojectImplementation] = useState();
  const [newWorkplaces, setNewWorkplaces] = useState();
  const [payback, setPayback] = useState();
  const [members, setMembers] = useState();
  const [automated, setAutomated] = useState();
  const [datebring, setDatebring] = useState();
  const [projectName, setProjectName] = useState();
  const [spetsialist, setSpetsialist] = useState();
  const [data, setData] = useState();
  const [modal_varying1, setmodal_varying1] = useState(false);
  function tog_varying1() {
    setmodal_varying1(!modal_varying1);
  }
  const handleSave = () => {
    const datas = {
      dateInput: dateInput,
      organizationName: organizationName,
      information: information,
      goal: goal,
      location: location,
      subProjectCost: subProjectCost,
      subLoan: subLoan,
      cost: cost,
      singleWindow: singleWindow,
      order: order,
      currentActivity: currentActivity,
      appliciant: appliciant,
      postavshik: postavshik,
      product: product,
      contract: contract,
      dateContract: dateContract,
      subloanStructure: subloanStructure,
      subprojectImplementation: subprojectImplementation,
      newWorkplaces: newWorkplaces,
      payback: payback,
      members: members,
      automated: automated,
      datebring: datebring,
      projectName: projectName,
      spetsialist: spetsialist,
    };
    setData(datas);
    tog_varying1();
  };
  return (
    <React.Fragment>
      <div className="page-content">
        {/* <Modal
          size="lg"
          isOpen={modal_varying1}
          toggle={() => {
            tog_varying1();
          }}
          id="exampleModal"
        >
          <ForPDF data={data} />
        </Modal> */}

        <Container fluid>
          <BreadCrumb title="Basic Elements" pageTitle="Forms" />

          {!modal_varying1 ? (
            <Row>
              <Col lg={12}>
                <Card>
                  <CardBody className="card-body">
                    <div className="live-preview">
                      <Row className="gy-4">
                        <Col xxl={3} md={6}>
                          <div>
                            <Label htmlFor="basiInput" className="form-label">
                              Қаердан олинади
                            </Label>
                            <Input
                              type="date"
                              className="form-control"
                              onChange={(e) => setDateInput(e.target.value)}
                            />
                          </div>
                        </Col>

                        <Col xxl={3} md={6}>
                          <div>
                            <Label htmlFor="labelInput" className="form-label">
                              {TranslationList("Name of the bank")}
                            </Label>
                            <Input
                              type="text"
                              className="form-control"
                              onChange={(e) =>
                                setOrganizationName(e.target.value)
                              }
                            />
                          </div>
                        </Col>
                        <Col xxl={3} md={6}>
                          <div>
                            <Label htmlFor="labelInput" className="form-label">
                              {TranslationList("Applicant information")}
                            </Label>
                            <Input
                              type="text"
                              className="form-control"
                              onChange={(e) => setInformation(e.target.value)}
                            />
                          </div>
                        </Col>
                        <Col xxl={3} md={6}>
                          <div>
                            <Label htmlFor="labelInput" className="form-label">
                              {TranslationList("The Goal")}
                            </Label>
                            <Input
                              type="text"
                              className="form-control"
                              onChange={(e) => setGoal(e.target.value)}
                            />
                          </div>
                        </Col>
                        <Col xxl={3} md={6}>
                          <div>
                            <Label htmlFor="labelInput" className="form-label">
                              {TranslationList("Location")}
                            </Label>
                            <Input
                              type="text"
                              className="form-control"
                              onChange={(e) => setLocation(e.target.value)}
                            />
                          </div>
                        </Col>
                        <Col xxl={3} md={6}>
                          <div>
                            <Label
                              htmlFor="placeholderInput"
                              className="form-label"
                            >
                              {TranslationList("Sub-project cost")}
                            </Label>
                            <Input
                              type="number"
                              className="form-control"
                              onChange={(e) =>
                                setSubProjectCost(e.target.value)
                              }
                            />
                          </div>
                        </Col>

                        <Col xxl={3} md={6}>
                          <div>
                            <Label htmlFor="valueInput" className="form-label">
                              {TranslationList("Of which IFAD sub-loan")}
                            </Label>
                            <Input
                              type="number"
                              className="form-control"
                              onChange={(e) => setSubLoan(e.target.value)}
                            />
                          </div>
                        </Col>
                        <Col xxl={3} md={6}>
                          <div>
                            <Label htmlFor="valueInput" className="form-label">
                              Собств.вклад
                            </Label>
                            <Input
                              type="number"
                              className="form-control"
                              onChange={(e) => setCost(e.target.value)}
                            />
                          </div>
                        </Col>
                        <Col xxl={3} md={6}>
                          <div>
                            <Label htmlFor="valueInput" className="form-label">
                              {TranslationList("Single window")}
                            </Label>
                            <Input
                              type="date"
                              className="form-control"
                              onChange={(e) => setSingleWindow(e.target.value)}
                            />
                          </div>
                        </Col>
                        <Col xxl={3} md={6}>
                          <div>
                            <Label htmlFor="valueInput" className="form-label">
                              {TranslationList("Order")}
                            </Label>
                            <Input
                              type="text"
                              className="form-control"
                              onChange={(e) => setOrder(e.target.value)}
                            />
                          </div>
                        </Col>
                        <Col xxl={3} md={6}>
                          <div>
                            <Label htmlFor="valueInput" className="form-label">
                              {TranslationList("Current Activity")}
                            </Label>
                            <Input
                              type="text"
                              className="form-control"
                              onChange={(e) =>
                                setCurrentActivity(e.target.value)
                              }
                            />
                          </div>
                        </Col>
                        <Col xxl={3} md={6}>
                          <div>
                            <Label htmlFor="valueInput" className="form-label">
                              {TranslationList("Appliciant")}
                            </Label>
                            <Input
                              type="text"
                              className="form-control"
                              onChange={(e) => setAppliciant(e.target.value)}
                            />
                          </div>
                        </Col>
                        <Col xxl={3} md={6}>
                          <div>
                            <Label htmlFor="valueInput" className="form-label">
                              {TranslationList("Postavchik")}
                            </Label>
                            <Input
                              type="text"
                              className="form-control"
                              onChange={(e) => setPostavshik(e.target.value)}
                            />
                          </div>
                        </Col>
                        <Col xxl={3} md={6}>
                          <div>
                            <Label htmlFor="valueInput" className="form-label">
                              {TranslationList("Product")}
                            </Label>
                            <Input
                              type="text"
                              className="form-control"
                              onChange={(e) => setProduct(e.target.value)}
                            />
                          </div>
                        </Col>
                        <Col xxl={3} md={6}>
                          <div>
                            <Label htmlFor="valueInput" className="form-label">
                              {TranslationList("Contract")}
                            </Label>
                            <Input
                              type="text"
                              className="form-control"
                              onChange={(e) => setContract(e.target.value)}
                            />
                          </div>
                        </Col>
                        <Col xxl={3} md={6}>
                          <div>
                            <Label htmlFor="valueInput" className="form-label">
                              {TranslationList("Data Contract")}
                            </Label>
                            <Input
                              type="text"
                              className="form-control"
                              onChange={(e) => setDateContract(e.target.value)}
                            />
                          </div>
                        </Col>
                        <Col xxl={3} md={6}>
                          <div>
                            <Label htmlFor="valueInput" className="form-label">
                              {TranslationList("Structure")}
                            </Label>
                            <Input
                              type="text"
                              className="form-control"
                              onChange={(e) =>
                                setSubloanStructure(e.target.value)
                              }
                            />
                          </div>
                        </Col>
                        <Col xxl={3} md={6}>
                          <div>
                            <Label htmlFor="valueInput" className="form-label">
                              {TranslationList("Project Implementation")}
                            </Label>
                            <Input
                              type="text"
                              className="form-control"
                              onChange={(e) =>
                                setSubprojectImplementation(e.target.value)
                              }
                            />
                          </div>
                        </Col>
                        <Col xxl={3} md={6}>
                          <div>
                            <Label htmlFor="valueInput" className="form-label">
                              {TranslationList("Structure")}
                            </Label>
                            <Input
                              type="text"
                              className="form-control"
                              onChange={(e) =>
                                setSubloanStructure(e.target.value)
                              }
                            />
                          </div>
                        </Col>
                        <Col xxl={3} md={6}>
                          <div>
                            <Label htmlFor="valueInput" className="form-label">
                              {TranslationList("New Workplace")}
                            </Label>
                            <Input
                              type="text"
                              className="form-control"
                              onChange={(e) => setNewWorkplaces(e.target.value)}
                            />
                          </div>
                        </Col>
                        <Col xxl={3} md={6}>
                          <div>
                            <Label htmlFor="valueInput" className="form-label">
                              {TranslationList("New Workplace")}
                            </Label>
                            <Input
                              type="text"
                              className="form-control"
                              onChange={(e) => setNewWorkplaces(e.target.value)}
                            />
                          </div>
                        </Col>
                        <Col xxl={3} md={6}>
                          <div>
                            <Label htmlFor="valueInput" className="form-label">
                              {TranslationList("Payback")}
                            </Label>
                            <Input
                              type="text"
                              className="form-control"
                              onChange={(e) => setPayback(e.target.value)}
                            />
                          </div>
                        </Col>
                        <Col xxl={3} md={6}>
                          <div>
                            <Label htmlFor="valueInput" className="form-label">
                              {TranslationList("Members")}
                            </Label>
                            <Input
                              type="text"
                              className="form-control"
                              onChange={(e) => setMembers(e.target.value)}
                            />
                          </div>
                        </Col>
                        <Col xxl={3} md={6}>
                          <div>
                            <Label htmlFor="valueInput" className="form-label">
                              {TranslationList("Automated?")}
                            </Label>
                            <Input
                              type="text"
                              className="form-control"
                              onChange={(e) => setAutomated(e.target.value)}
                            />
                          </div>
                        </Col>
                        <Col xxl={3} md={6}>
                          <div>
                            <Label htmlFor="valueInput" className="form-label">
                              {TranslationList("Date Bring")}
                            </Label>
                            <Input
                              type="text"
                              className="form-control"
                              onChange={(e) => setDatebring(e.target.value)}
                            />
                          </div>
                        </Col>
                        <Col xxl={3} md={6}>
                          <div>
                            <Label htmlFor="valueInput" className="form-label">
                              {TranslationList("Project Name")}
                            </Label>
                            <Input
                              type="text"
                              className="form-control"
                              onChange={(e) => setProjectName(e.target.value)}
                            />
                          </div>
                        </Col>
                        <Col xxl={3} md={6}>
                          <div>
                            <Label htmlFor="valueInput" className="form-label">
                              {TranslationList("Spetsialist")}
                            </Label>
                            <Input
                              type="text"
                              className="form-control"
                              onChange={(e) => setSpetsialist(e.target.value)}
                            />
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </CardBody>
                  <CardFooter className="d-flex justify-content-end">
                    <Button className="btn btn-light me-2">
                      {TranslationList("Cancel")}
                    </Button>
                    <Button className="btn btn-primary" onClick={handleSave}>
                      {TranslationList("OK")}
                    </Button>
                  </CardFooter>
                </Card>
              </Col>
            </Row>
          ) : (
            <Row>
              <Col lg={6}>
                <ForPDF data={data} />
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
}
