import React, { useCallback, useEffect, useMemo, useState } from "react";
import Select from "react-select";
import {
  Button,
  Card,
  CardBody,
  Container,
  FormFeedback,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  UncontrolledTooltip,
} from "reactstrap";
import { TranslationList } from "../../slices/settings/listtranslation";
import { useDispatch, useSelector } from "react-redux";
import classnames from "classnames";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {
  addprojectAsync,
  editprojectAsync,
  getprojectAsync,
  getprojectAsyncAuth,
  getprojectFilterAsync,
} from "../../slices/project";
import { getcurrencyAsync } from "../../slices/currency";
import { getsectorAsync } from "../../slices/sector";
import { getusersAsync } from "../../slices/users";
import { getRegionsAsync, getfoaAsync, getfullregion } from "../../slices/foa";
import { toast } from "react-toastify";
import { getorganizationAsync } from "../../slices/organization";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { DataService } from "../../helpers/dataService/dataService";
import endpoints from "../../endpoints";
export default function ProjectAdd() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [arrayList, setArrayList] = useState();
  const [current, setCurrent] = useState();
  const [error, setError] = useState();
  const currency = useSelector((state) => state.currency?.data?.results);
  const [customActiveTab, setcustomActiveTab] = useState("1");
  const [selectMulti, setselectMulti] = useState(null);
  const [sortbyMulti, setsortbyMulti] = useState();
  const [selectMultiRegion, setselectMultiRegion] = useState(null);
  const [sortbyMultiRegion, setsortbyMultiRegion] = useState();
  const [selectMonitoringDocument, setselectMonitoringDocument] =
    useState(null);
  const [sortbyMonitoringDocument, setsortbyMonitoringDocument] = useState();
  const [selectMultiSector, setselectMultiSector] = useState(null);
  const [sortbyMultiSector, setsortbyMultiSector] = useState();
  const [selectMultiFoa, setselectMultiFoa] = useState(null);
  const [sortbyMultiFoa, setsortbyMultiFoa] = useState();
  const [nameUZ, setNameUZ] = useState();
  const [nameRU, setNameRU] = useState();
  const [nameEN, setNameEN] = useState();
  const [period, setPeriod] = useState();
  const [projectNumber, setProjectNumber] = useState();
  const [govResolution, setGovResolution] = useState();
  const [descriptionUZ, setDescriptionUz] = useState();
  const [descriptionRU, setDescriptionRu] = useState();
  const [descriptionEN, setDescriptionEn] = useState();
  const [duration, setDuration] = useState();
  const [gracePeriod, setGracePeriod] = useState();
  const [procentUsdForm, setProcentUsdForm] = useState();
  const [procentUsdTo, setProcentUsdTo] = useState();
  const [age_from, setage_from] = useState();
  const [age_to, steage_to] = useState();
  const [age_ability_to_lend_from, setage_ability_to_lend_from] = useState();
  const [age_ability_to_lend_to, setage_ability_to_lend_to] = useState();
  const [overallProjectCost, setOverallProjectCost] = useState();
  const [remainingProjectCost, setRemainingProjectCost] = useState();
  const [department, setdepartment] = useState();
  const [position, setposition] = useState();
  const [user, setuser] = useState();
  const [checkAge, setcheckAge] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const sectors = useSelector((state) => state.sector.data?.results);
  const regions = useSelector((state) => state.foa.regions?.results);
  const foas = useSelector((state) => state.foa.data?.results);
  const [monitoringDocs, setMonitoringDocs] = useState(null);
  const params = useParams();
  const organizations = useSelector(
    (state) => state.organization.data?.results
  );
  const [ability_to_lend_currency, setAbility_to_lend_currency] = useState();
  const [age_ability_to_lend_currency, setAgeAbility_to_lend_currency] =
    useState();
  const lang = useSelector((state) => state.lang.data);
  const getProjectById = async () => {
    const response = await DataService.get(`projects/${params?.id}`);
    setCurrent(response);
    setcheckAge(response?.age_cheak);
    dispatch(
      getfoaAsync(
        `foa/?sector=${response?.sectors_detail?.map((res) => {
          return res.id;
        })}`
      )
    );
  };
  const getData = async () => {
    if (params?.id) {
      const response = await DataService.get(
        `project-specialist/?project=${params?.id}`
      );
      setArrayList(response?.results);
    } else {
      const response1 = await DataService.get("project-specialist/");
      setArrayList(response1?.results);
    }
    try {
      const resPeriod = await DataService.get(endpoints.monitoringPeriod);
      setPeriod(resPeriod?.results);
      const resMonitoringDoc = await DataService.get(
        endpoints.monitoringDocument
      );
      setMonitoringDocs(resMonitoringDoc?.results);
      const data = resMonitoringDoc?.results?.map((res) => {
        return { label: res.name, value: res.id };
      });
      setsortbyMonitoringDocument(data);
      const response = await DataService.get("department/");
      setdepartment(response?.results);
    } catch (error) {
      console.log(error);
    }
  };
  const SelectDepartment = async (id) => {
    try {
      const response = await DataService.get(`position/?department=${id}`);
      setposition(response?.results);
      setuser();
    } catch (error) {
      console.log(error);
    }
  };
  const SelectPosition = async (id) => {
    try {
      const response = await DataService.get(
        `user/?groups__name=Specialist&position=${id}`
      );
      setuser(response?.results);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (params?.id) {
      getProjectById();
    }
    getData();
  }, [params?.id]);
  useEffect(() => {
    getData();
    dispatch(getorganizationAsync("organization/?limit=100&type=Bank"));
    dispatch(getcurrencyAsync("currency/?limit=100"));
    dispatch(getsectorAsync("sector/?limit=100"));
    dispatch(getRegionsAsync("region/?limit=100"));
    dispatch(getusersAsync("user/?limit=100"));
  }, [lang]);
  useEffect(() => {
    const data = organizations?.map((res) => {
      return { label: res.name, value: res.id };
    });
    setsortbyMulti(data);
  }, [organizations]);
  useEffect(() => {
    const data = sectors?.map((res) => {
      return { label: res.name, value: res.id };
    });
    setsortbyMultiSector(data);
  }, [sectors]);
  useEffect(() => {
    const data = regions?.map((res) => {
      return { label: res.name, value: res.id };
    });
    setsortbyMultiRegion(data);
  }, [regions]);
  useEffect(() => {
    const data = foas?.map((res) => {
      return { label: res.name, value: res.id };
    });
    setsortbyMultiFoa(data);
  }, [foas]);

  const toggleCustom = (tab) => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };
  const handleAdd = async () => {
    let orgarr = [];
    orgarr = selectMulti?.map((res) => {
      return res.value;
    });
    let sectorarr = [];
    sectorarr = selectMultiSector?.map((res) => {
      return res.value;
    });
    let regionsarr = [];
    regionsarr = selectMultiRegion?.map((res) => {
      return res.value;
    });
    let monitoringDocsarr = [];
    monitoringDocsarr = selectMonitoringDocument?.map((res) => {
      return res.value;
    });
    let foaarr = [];
    foaarr = selectMultiFoa?.map((res) => {
      return res.value;
    });
    const data = {
      name_uz: nameUZ,
      name_ru: nameRU,
      name_en: nameEN,
      projectNumber: projectNumber,
      govResolution: govResolution,
      overallProjectCost: overallProjectCost,
      remainingProjectCost: remainingProjectCost,
      description_uz: descriptionUZ,
      description_ru: descriptionRU,
      description_en: descriptionEN,
      period_kredit: duration,
      grace_period: gracePeriod,
      procent_from: procentUsdForm,
      procent_to: procentUsdTo,
      age_cheak: document.getElementById("age_check").checked,
      priority: document.getElementById("priority").value,
      age_from: age_from,
      age_to: age_to,
      age_ability_to_lend_from: age_ability_to_lend_from,
      age_ability_to_lend_to: age_ability_to_lend_to,
      age_ability_to_lend_currency: age_ability_to_lend_currency,
      ability_to_lend_from: document.getElementById("ability_to_lend_from")
        .value,
      ability_to_lend_to: document.getElementById("ability_to_lend_to").value,
      ability_to_lend_currency: ability_to_lend_currency,
      overallProjectCostCurrency: parseInt(
        document.getElementById("project_cost_currency")?.value
      ),
      remainingProjectCostCurrency: parseInt(
        document.getElementById("remainingProjectCostCurrency")?.value
      ),
      organization: orgarr,
      sectors: sectorarr,
      field_of_activities: foaarr,
      regions: regionsarr,
      manitoring_period: document.getElementById("manitoring_period").value,
      manitoring_document: monitoringDocsarr,
    };

    const response = await addprojectAsync("projects/", data);
    if (response?.errorCode) {
      setError(response?.message);
      toast.error(TranslationList("An error has occurred!"));
    } else {
      searchParams.set("id", response?.id);
      setSearchParams(searchParams);
      toast.success(TranslationList("Done successfully!"));
      dispatch(getprojectAsyncAuth(`projects/`));
      setError();
      // navigate("/project");
    }
  };
  useEffect(() => {
    return () => {
      setselectMultiFoa();
      setselectMultiSector();
      setselectMulti();
      setselectMultiRegion();
    };
  }, []);
  const handleEdite = async () => {
    let orgarr = [];
    orgarr = selectMulti?.map((res) => {
      return res.value;
    });
    let sectorarr = [];
    sectorarr = selectMultiSector?.map((res) => {
      return res.value;
    });
    let monitoringDocsarr = [];
    monitoringDocsarr = selectMonitoringDocument?.map((res) => {
      return res.value;
    });
    let regionsarr = [];
    regionsarr = selectMultiRegion?.map((res) => {
      return res.value;
    });
    let foaarr = [];
    foaarr = selectMultiFoa?.map((res) => {
      return res.value;
    });
    const dataDocument = {
      name_uz: document.getElementById("name_uz").value,
      name_ru: document.getElementById("name_ru").value,
      name_en: document.getElementById("name_en").value,
      projectNumber: document.getElementById("project_number").value,
      govResolution: document.getElementById("gov_resolution").value,
      description_uz: descriptionUZ,
      description_ru: descriptionRU,
      description_en: descriptionEN,
      period_kredit: document.getElementById("period_kredit").value,
      grace_period: document.getElementById("grace_period").value,
      procent_from: document.getElementById("procent_from").value,
      procent_to: document.getElementById("procent_to").value,
      age_cheak: document.getElementById("age_check").checked,
      priority: document.getElementById("priority").value,
      age_from: age_from,
      age_to: age_to,
      age_ability_to_lend_from: age_ability_to_lend_from,
      age_ability_to_lend_to: age_ability_to_lend_to,
      age_ability_to_lend_currency: age_ability_to_lend_currency,
      ability_to_lend_from: document.getElementById("ability_to_lend_from")
        .value,
      ability_to_lend_to: document.getElementById("ability_to_lend_to").value,
      ability_to_lend_currency: ability_to_lend_currency,
      overallProjectCost: document.getElementById("overall_project_cost").value,
      overallProjectCostCurrency: document.getElementById(
        "project_cost_currency"
      ).value,
      remainingProjectCost: document.getElementById("remainingProjectCost")
        .value,

      remainingProjectCostCurrency: parseInt(
        document.getElementById("remainingProjectCostCurrency").value
      ),

      organization:
        orgarr ?? current?.organization_detail?.map((res) => res.id),
      sectors: sectorarr ?? current?.sectors_detail?.map((res) => res.id),
      regions: regionsarr ?? current?.region_detail?.map((res) => res.id),
      field_of_activities:
        foaarr ?? current?.field_of_activities_detail?.map((res) => res.id),
      manitoring_period: document.getElementById("manitoring_period").value,
      manitoring_document:
        monitoringDocsarr ??
        current?.manitoring_document_detail?.map((res) => res.id),
    };

    const response = await editprojectAsync(
      `projects/${params?.id}`,
      dataDocument
    );

    if (response?.errorCode) {
      setError(response?.message);
      toast.error(TranslationList("An error has occurred!"));
    } else {
      toast.success(TranslationList("Done successfully!"));
      dispatch(getprojectAsyncAuth(`projects/`));
      // window.location.href = '/project'
      navigate("/project");
    }
  };

  const handleAddUser = async () => {
    try {
      const data = {
        project: params?.id,
        user_project: document.getElementById("user").value,
        department: document.getElementById("department").value,
        position: document.getElementById("position").value,
      };
      if (data.department && data.position && data.user_project) {
        const response = await DataService.post("project-specialist/", data);
        if (response?.errorCode) {
          toast.error(TranslationList("An error has occurred!"));
        } else {
          toast.success(TranslationList("Done successfully!"));
          const response1 = await DataService.get(
            `project-specialist/?project=${params?.id}`
          );
          setArrayList(response1?.results);
          setposition();
          setuser();
        }
      } else {
        toast.error(TranslationList("Please fill all the fields!"));
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleDeleteUser = async (id) => {
    try {
      const response = await DataService.delete(`project-specialist/${id}`);
      if (response?.errorCode) {
        toast.error(TranslationList("An error has occurred!"));
      } else {
        toast.success(TranslationList("Done successfully!"));
        const response1 = await DataService.get(
          `project-specialist/?project=${params?.id}`
        );
        setArrayList(response1?.results);
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb
          title={
            params?.id
              ? TranslationList("Card_project")
              : TranslationList("Add")
          }
          pageTitle={TranslationList("Projects")}
          link="/project"
        />
        <Row>
          <Card>
            <CardBody>
              <div className="row">
                <div className="mb-3 col-4">
                  {" "}
                  <label htmlFor="name_uz" className="col-form-label">
                    {TranslationList("uz")} :
                  </label>
                  <Input
                    id="name_uz"
                    name="name_uz"
                    type="text"
                    className="form-control"
                    onChange={(e) => setNameUZ(e.target.value)}
                    defaultValue={current?.name_uz}
                    invalid={error?.name_uz ? true : false}
                  />
                  <FormFeedback type="invalid">{error?.name_uz}</FormFeedback>
                </div>
                <div className="mb-3 col-4">
                  {" "}
                  <label htmlFor="name_en" className="col-form-label">
                    {TranslationList("eng")}:
                  </label>
                  <Input
                    id="name_en"
                    name="name_en"
                    type="text"
                    className="form-control"
                    onChange={(e) => setNameEN(e.target.value)}
                    defaultValue={current?.name_en}
                    invalid={error?.name_en ? true : false}
                  />
                  <FormFeedback type="invalid">{error?.name_en}</FormFeedback>
                </div>
                <div className="mb-3 col-4">
                  {" "}
                  <label htmlFor="name_ru" className="col-form-label">
                    {TranslationList("ru")} :
                  </label>
                  <Input
                    id="name_ru"
                    name="name_ru"
                    type="text"
                    className="form-control"
                    onChange={(e) => setNameRU(e.target.value)}
                    defaultValue={current?.name_ru}
                    invalid={error?.name_ru ? true : false}
                  />
                  <FormFeedback type="invalid">{error?.name_ru}</FormFeedback>
                </div>
              </div>
              <div className="row">
                <div className="mb-3 col-4">
                  {" "}
                  <label htmlFor="projectNumber" className="col-form-label">
                    {TranslationList("Project number")}:
                  </label>
                  <Input
                    id="project_number"
                    name="projectNumber"
                    type="text"
                    className="form-control"
                    onChange={(e) => setProjectNumber(e.target.value)}
                    defaultValue={current?.projectNumber}
                    invalid={error?.projectNumber ? true : false}
                  />
                  <FormFeedback type="invalid">
                    {error?.projectNumber}
                  </FormFeedback>
                </div>
                <div className="col-4 d-flex justify-content-between">
                  <div className="mb-3 col-8">
                    {" "}
                    <label
                      htmlFor="overall_project_cost"
                      className="col-form-label"
                    >
                      {TranslationList("Remaining Project Cost")} :
                    </label>
                    <Input
                      id="remainingProjectCost"
                      name="remainingProjectCost"
                      className="form-control"
                      onChange={(e) => setRemainingProjectCost(e.target.value)}
                      defaultValue={current?.remainingProjectCost}
                      invalid={error?.remainingProjectCost ? true : false}
                    />
                    <FormFeedback type="invalid">
                      {error?.remainingProjectCost}
                    </FormFeedback>
                  </div>
                  <div className="mb-3 col-3">
                    {" "}
                    <label
                      htmlFor="project_cost_currency"
                      className="col-form-label"
                    >
                      {TranslationList("Сurrency")}
                    </label>
                    <Input
                      type="select"
                      name="remainingProjectCostCurrency"
                      className="form-select "
                      aria-label="Default select example"
                      id="remainingProjectCostCurrency"
                      defaultValue={current?.remainingProjectCostCurrency}
                    >
                      {currency?.map((res) => (
                        <option key={res.id} value={res.id}>
                          {res.symbol}
                        </option>
                      ))}
                    </Input>
                  </div>
                </div>
                <div className="col-4 d-flex justify-content-between">
                  <div className="mb-3 col-8">
                    {" "}
                    <label
                      htmlFor="overall_project_cost"
                      className="col-form-label"
                    >
                      {TranslationList("Overall Project Cost")} :
                    </label>
                    <Input
                      id="overall_project_cost"
                      name="overall_project_cost"
                      className="form-control"
                      onChange={(e) => setOverallProjectCost(e.target.value)}
                      defaultValue={current?.overallProjectCost}
                      invalid={error?.overallProjectCost ? true : false}
                    />
                    <FormFeedback type="invalid">
                      {error?.overallProjectCost}
                    </FormFeedback>
                  </div>
                  <div className="mb-3 col-3">
                    {" "}
                    <label
                      htmlFor="project_cost_currency"
                      className="col-form-label"
                    >
                      {TranslationList("Сurrency")}
                    </label>
                    <Input
                      type="select"
                      name="project_cost_currency"
                      className="form-select "
                      aria-label="Default select example"
                      id="project_cost_currency"
                      defaultValue={current?.overallProjectCostCurrency}
                    >
                      {currency?.map((res) => (
                        <option key={res.id} value={res.id}>
                          {res.symbol}
                        </option>
                      ))}
                    </Input>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="mb-3">
                  <Nav
                    tabs
                    className="nav nav-tabs nav-tabs-custom nav-success  mb-3"
                  >
                    <NavItem>
                      <NavLink
                        id="name_uz"
                        style={
                          error?.name_uz
                            ? { color: "#ed5e5e", cursor: "pointer" }
                            : { cursor: "pointer" }
                        }
                        className={classnames({
                          active: customActiveTab === "1",
                        })}
                        onClick={() => {
                          toggleCustom("1");
                        }}
                      >
                        <label htmlFor="description" className="col-form-label">
                          {TranslationList("Description_uz")} :
                        </label>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        id="name_ru"
                        style={
                          error?.name_ru
                            ? { color: "#ed5e5e", cursor: "pointer" }
                            : { cursor: "pointer" }
                        }
                        className={classnames({
                          active: customActiveTab === "2",
                        })}
                        onClick={() => {
                          toggleCustom("2");
                        }}
                      >
                        <label htmlFor="description" className="col-form-label">
                          {TranslationList("Description_ru")} :
                        </label>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        id="name_en"
                        style={
                          error?.name_en
                            ? { color: "#ed5e5e", cursor: "pointer" }
                            : { cursor: "pointer" }
                        }
                        className={classnames({
                          active: customActiveTab === "3",
                        })}
                        onClick={() => {
                          toggleCustom("3");
                        }}
                      >
                        <label htmlFor="description" className="col-form-label">
                          {TranslationList("Description_en")} :
                        </label>
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent
                    activeTab={customActiveTab}
                    className="text-muted"
                  >
                    <TabPane tabId="1" id="home1">
                      <CKEditor
                        editor={ClassicEditor}
                        data={current?.description_uz ?? ""}
                        onReady={(editor) => {
                          // You can store the "editor" and use when it is needed.
                        }}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          setDescriptionUz(data);
                        }}
                      />
                    </TabPane>
                    <TabPane tabId="2" id="home2">
                      <CKEditor
                        editor={ClassicEditor}
                        data={current?.description_ru ?? ""}
                        onReady={(editor) => {
                          // You can store the "editor" and use when it is needed.
                        }}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          setDescriptionRu(data);
                        }}
                      />
                    </TabPane>
                    <TabPane tabId="3" id="home3">
                      <CKEditor
                        editor={ClassicEditor}
                        data={current?.description_en ?? ""}
                        onReady={(editor) => {
                          // You can store the "editor" and use when it is needed.
                        }}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          setDescriptionEn(data);
                        }}
                      />
                    </TabPane>
                  </TabContent>
                </div>
                {error?.name_uz && (
                  <UncontrolledTooltip placement="top" target="name_uz">
                    {error?.name_uz}
                  </UncontrolledTooltip>
                )}
                {error?.name_ru && (
                  <UncontrolledTooltip placement="top" target="name_ru">
                    {error?.name_ru}
                  </UncontrolledTooltip>
                )}
                {error?.name_en && (
                  <UncontrolledTooltip placement="top" target="name_en">
                    {error?.name_en}
                  </UncontrolledTooltip>
                )}
              </div>
              <div className="row">
                <div className="mb-3 col-4">
                  <label htmlFor="gov_resolution" className="col-form-label">
                    {TranslationList("Gov resolution")}:
                  </label>
                  <Input
                    type="text"
                    id="gov_resolution"
                    name="gov_resolution"
                    className="form-control"
                    onChange={(e) => setGovResolution(e.target.value)}
                    defaultValue={current?.govResolution}
                    invalid={error?.govResolution ? true : false}
                  />
                  <FormFeedback type="invalid">
                    {error?.govResolution}
                  </FormFeedback>
                </div>
                <div className="mb-3 col-4">
                  <label className="col-form-label">
                    {TranslationList("Participating Bank")}
                  </label>
                  <Select
                    key={sortbyMulti?.length}
                    value={
                      selectMulti ??
                      current?.organization_detail?.map((res) => {
                        return { label: res.name, value: res.id };
                      })
                    }
                    isMulti={true}
                    onChange={(sortBy) => {
                      setselectMulti(sortBy);
                    }}
                    defaultValue={current?.organization_detail?.map((res) => {
                      return { label: res.name, value: res.id };
                    })}
                    options={sortbyMulti}
                    classNamePrefix="js-example-basic-multiple mb-0 col-4"
                  />
                  <div
                    style={{
                      marginTop: "0.25rem",
                      fontSize: "0.875em",
                      color: "#ed5e5e",
                    }}
                  >
                    {error?.organization}
                  </div>
                </div>
                <div className="mb-3 col-4">
                  <label className="col-form-label">
                    {TranslationList("Regions")}
                  </label>
                  <Select
                    key={sortbyMultiRegion?.length + "Regions"}
                    value={
                      selectMultiRegion ??
                      current?.region_detail?.map((res) => {
                        return { label: res.name, value: res.id };
                      })
                    }
                    isMulti={true}
                    onChange={(sortBy) => {
                      setselectMultiRegion(sortBy);
                    }}
                    defaultValue={current?.region_detail?.map((res) => {
                      return { label: res.name, value: res.id };
                    })}
                    options={sortbyMultiRegion}
                    classNamePrefix="js-example-basic-multiple mb-0 col-4"
                  />
                  <div
                    style={{
                      marginTop: "0.25rem",
                      fontSize: "0.875em",
                      color: "#ed5e5e",
                    }}
                  >
                    {error?.regions}
                  </div>
                </div>
                <div className="col-4 mb-3">
                  <label className="col-form-label">
                    {TranslationList("Sector")}
                  </label>
                  <Select
                    key={sortbyMultiSector?.length}
                    value={
                      selectMultiSector ??
                      current?.sectors_detail?.map((res) => {
                        return { label: res.name, value: res.id };
                      })
                    }
                    defaultValue={current?.sectors_detail?.map((res) => {
                      return { label: res.name, value: res.id };
                    })}
                    isMulti={true}
                    onChange={(sortBy) => {
                      setselectMultiSector(sortBy);
                      sortBy?.length > 0
                        ? dispatch(
                            getfoaAsync(
                              `foa/?sector=${sortBy?.map((res) => {
                                return res.value;
                              })}`
                            )
                          )
                        : dispatch(getfoaAsync(`foa/`));
                    }}
                    options={sortbyMultiSector}
                    classNamePrefix="js-example-basic-multiple mb-0"
                  />
                  <div
                    style={{
                      marginTop: "0.25rem",
                      fontSize: "0.875em",
                      color: "#ed5e5e",
                    }}
                  >
                    {error?.sectors}
                  </div>
                </div>
                <div className="mb-3 col-4">
                  <label className="col-form-label">
                    {TranslationList("Fields of Activity")}
                  </label>
                  <Select
                    key={sortbyMultiFoa?.length}
                    value={
                      selectMultiFoa ??
                      current?.field_of_activities_detail?.map((res) => {
                        return { label: res.name, value: res.id };
                      })
                    }
                    defaultValue={current?.field_of_activities_detail?.map(
                      (res) => {
                        return { label: res.name, value: res.id };
                      }
                    )}
                    isMulti={true}
                    onChange={(sortBy) => {
                      setselectMultiFoa(sortBy);
                    }}
                    options={sortbyMultiFoa}
                    classNamePrefix="js-example-basic-multiple"
                  />
                  <div
                    style={{
                      marginTop: "0.25rem",
                      fontSize: "0.875em",
                      color: "#ed5e5e",
                    }}
                  >
                    {error?.sectors}
                  </div>
                </div>
                <div className="mb-3 col-2">
                  <label htmlFor="period_kredit" className="col-form-label">
                    {TranslationList("Duration")}:
                  </label>
                  <Input
                    type="number"
                    id="period_kredit"
                    name="period_kredit"
                    className="form-control"
                    onChange={(e) => setDuration(e.target.value)}
                    defaultValue={current?.period_kredit}
                    invalid={error?.period_kredit ? true : false}
                  />
                  <FormFeedback type="invalid">
                    {error?.period_kredit}
                  </FormFeedback>
                </div>
                <div className="mb-3 col-2">
                  <label htmlFor="grace_period" className="col-form-label">
                    {TranslationList("Grace Period")}:
                  </label>
                  <Input
                    type="number"
                    id="grace_period"
                    name="grace_period"
                    className="form-control"
                    onChange={(e) => setGracePeriod(e.target.value)}
                    defaultValue={current?.grace_period}
                    invalid={error?.grace_period ? true : false}
                  />
                  <FormFeedback type="invalid">
                    {error?.grace_period}
                  </FormFeedback>
                </div>
                <div className="mb-3 col-2">
                  <label htmlFor="procent_from" className="col-form-label">
                    {TranslationList("Procent Form")}:
                  </label>
                  <Input
                    type="number"
                    id="procent_from"
                    name="procent_from"
                    className="form-control"
                    onChange={(e) => setProcentUsdForm(e.target.value)}
                    defaultValue={current?.procent_from}
                    invalid={error?.procent_from ? true : false}
                  />
                  <FormFeedback type="invalid">
                    {error?.procent_from}
                  </FormFeedback>
                </div>
                <div className="mb-3 col-2">
                  <label htmlFor="procent_to" className="col-form-label">
                    {TranslationList("To")}:
                  </label>
                  <Input
                    type="number"
                    id="procent_to"
                    name="procent_to"
                    className="form-control"
                    onChange={(e) => setProcentUsdTo(e.target.value)}
                    defaultValue={current?.procent_to}
                    invalid={error?.procent_to ? true : false}
                  />
                  <FormFeedback type="invalid">
                    {error?.procent_to}
                  </FormFeedback>
                </div>
                <div className="mb-3 col-2">
                  <label
                    htmlFor="ability_to_lend_from"
                    className="col-form-label"
                  >
                    {TranslationList("ability_to_lend_from")}:
                  </label>
                  <Input
                    type="number"
                    id="ability_to_lend_from"
                    name="ability_to_lend_from"
                    className="form-control"
                    defaultValue={current?.ability_to_lend_from}
                    invalid={error?.ability_to_lend_from ? true : false}
                  />
                  <FormFeedback type="invalid">
                    {error?.ability_to_lend_from}
                  </FormFeedback>
                </div>
                <div className="mb-3 col-2">
                  <label
                    htmlFor="ability_to_lend_to"
                    className="col-form-label"
                  >
                    {TranslationList("ability_to_lend_to")}:
                  </label>
                  <Input
                    type="number"
                    id="ability_to_lend_to"
                    name="ability_to_lend_to"
                    className="form-control"
                    defaultValue={current?.ability_to_lend_to}
                    invalid={error?.ability_to_lend_to ? true : false}
                  />
                  <FormFeedback type="invalid">
                    {error?.ability_to_lend_to}
                  </FormFeedback>
                </div>
                <div className="mb-3 col-2">
                  <label
                    htmlFor="ability_to_lend_currency"
                    className="col-form-label"
                  >
                    {TranslationList("Сurrency")}
                  </label>
                  <Input
                    key={
                      current?.ability_to_lend_currency +
                      "ability_to_lend_currency"
                    }
                    type="select"
                    name="ability_to_lend_currency"
                    className="form-select "
                    aria-label="Default select example"
                    id="ability_to_lend_currency"
                    defaultValue={current?.ability_to_lend_currency}
                    onChange={(e) =>
                      setAbility_to_lend_currency(e.target.value)
                    }
                  >
                    <option></option>
                    {currency?.map((res) => (
                      <option key={res.id} value={res.id}>
                        {res.symbol}
                      </option>
                    ))}
                  </Input>
                </div>

                <div className="mb-3 col-2">
                  <label htmlFor="priority" className="col-form-label">
                    {TranslationList("Priority")}:
                  </label>
                  <Input
                    type="number"
                    id="priority"
                    name="priority"
                    className="form-control"
                    defaultValue={current?.priority}
                    invalid={error?.priority ? true : false}
                  />
                  <FormFeedback type="invalid">{error?.priority}</FormFeedback>
                </div>
              </div>

              <div className="row">
                <div className=" col-4">
                  <div className="form-check form-switch mb-3">
                    <Input
                      key={current?.age_cheak}
                      className="form-check-input"
                      type="checkbox"
                      role="switch"
                      id="age_check"
                      defaultChecked={checkAge}
                      onChange={(e) => {
                        setcheckAge(e.target.checked);
                      }}
                    />
                    <Label className="form-check-label" htmlFor="age_check">
                      {TranslationList("Age check")}
                    </Label>
                  </div>
                </div>
              </div>
              {/* age */}
              <div
                className="row"
                style={checkAge ? { display: "flex" } : { display: "none" }}
              >
                <div className="mb-3 col-2">
                  <label htmlFor="age_from" className="col-form-label">
                    {TranslationList("From")}:
                  </label>
                  <Input
                    type="number"
                    id="age_from"
                    name="age_from"
                    className="form-control"
                    onChange={(e) => setage_from(e.target.value)}
                    defaultValue={current?.age_from}
                    invalid={error?.age_from ? true : false}
                  />
                  <FormFeedback type="invalid">{error?.age_from}</FormFeedback>
                </div>
                <div className="mb-3 col-2">
                  <label htmlFor="age_to" className="col-form-label">
                    {TranslationList("To")}:
                  </label>
                  <Input
                    type="number"
                    id="age_to"
                    name="age_to"
                    onChange={(e) => steage_to(e.target.value)}
                    className="form-control"
                    defaultValue={current?.age_to}
                    invalid={error?.age_to ? true : false}
                  />
                  <FormFeedback type="invalid">{error?.age_to}</FormFeedback>
                </div>

                <div className="mb-3 col-2">
                  <label
                    htmlFor="age_ability_to_lend_from"
                    className="col-form-label"
                  >
                    {TranslationList("age_ability_to_lend_from")}:
                  </label>
                  <Input
                    type="number"
                    id="age_ability_to_lend_from"
                    name="age_ability_to_lend_from"
                    className="form-control"
                    onChange={(e) =>
                      setage_ability_to_lend_from(e.target.value)
                    }
                    defaultValue={current?.age_ability_to_lend_from}
                    invalid={error?.age_ability_to_lend_from ? true : false}
                  />
                  <FormFeedback type="invalid">
                    {error?.age_ability_to_lend_from}
                  </FormFeedback>
                </div>
                <div className="mb-3 col-2">
                  <label
                    htmlFor="age_ability_to_lend_to"
                    className="col-form-label"
                  >
                    {TranslationList("age_ability_to_lend_to")}:
                  </label>
                  <Input
                    type="number"
                    id="age_ability_to_lend_to"
                    name="age_ability_to_lend_to"
                    className="form-control"
                    onChange={(e) => setage_ability_to_lend_to(e.target.value)}
                    defaultValue={current?.age_ability_to_lend_to}
                    invalid={error?.age_ability_to_lend_to ? true : false}
                  />
                  <FormFeedback type="invalid">
                    {error?.age_ability_to_lend_to}
                  </FormFeedback>
                </div>
                <div className="mb-3 col-2">
                  {" "}
                  <label
                    htmlFor="age_ability_to_lend_currency"
                    className="col-form-label"
                  >
                    {TranslationList("Сurrency")}
                  </label>
                  <Input
                    key={
                      current?.age_ability_to_lend_currency +
                      "age_ability_to_lend_currency"
                    }
                    onChange={(e) =>
                      setAgeAbility_to_lend_currency(e.target.value)
                    }
                    type="select"
                    name="age_ability_to_lend_currency"
                    className="form-select "
                    aria-label="Default select example"
                    id="age_ability_to_lend_currency"
                    defaultValue={current?.age_ability_to_lend_currency}
                  >
                    <option></option>
                    {currency?.map((res) => (
                      <option key={res.id} value={res.id}>
                        {res.symbol}
                      </option>
                    ))}
                  </Input>
                </div>
              </div>
              <div className="row">
                <div className="mb-3 col-2">
                  <label htmlFor="age_from" className="col-form-label">
                    {TranslationList("Monitoring_Period")}
                    {TranslationList("(month)")}:
                  </label>
                  <Input
                    key={current?.manitoring_period + period}
                    type="select"
                    name="manitoring_period"
                    className="form-select "
                    aria-label="Default select example"
                    id="manitoring_period"
                    defaultValue={current?.manitoring_period}
                  >
                    <option></option>
                    {period?.map((res) => (
                      <option key={res.id} value={res.id}>
                        {res.period}
                      </option>
                    ))}
                  </Input>
                  <FormFeedback type="invalid">
                    {error?.manitoring_period}
                  </FormFeedback>
                </div>
                <div className="mb-3 col-10">
                  <label className="col-form-label">
                    {TranslationList("Monitoring_Documents")}
                  </label>

                  <Select
                    key={sortbyMonitoringDocument?.length}
                    value={
                      selectMonitoringDocument ??
                      current?.manitoring_document_detail?.map((res) => {
                        return { label: res.name, value: res.id };
                      })
                    }
                    defaultValue={current?.manitoring_document_detail?.map(
                      (res) => {
                        return { label: res.name, value: res.id };
                      }
                    )}
                    isMulti={true}
                    onChange={(sortBy) => {
                      setselectMonitoringDocument(sortBy);
                    }}
                    options={sortbyMonitoringDocument}
                    classNamePrefix="js-example-basic-multiple"
                  />
                  <div
                    style={{
                      marginTop: "0.25rem",
                      fontSize: "0.875em",
                      color: "#ed5e5e",
                    }}
                  >
                    {error?.organization}
                  </div>
                </div>
              </div>
              <div className="row d-flex align-items-end  justify-content-end">
                <div className=" col-4 mb-3 d-flex align-items-end  justify-content-end ">
                  <Button
                    color="danger"
                    style={{ marginRight: "5px" }}
                    onClick={() => navigate(`/project`)}
                    className="align-items-end"
                  >
                    {TranslationList("Cancel")}
                  </Button>
                  {"   "}
                  {params?.id ? (
                    <Button
                      className="align-items-end"
                      color="primary"
                      onClick={handleEdite}
                    >
                      {TranslationList("Save")}
                    </Button>
                  ) : (
                    <Button
                      className="align-items-end"
                      color="primary"
                      onClick={handleAdd}
                    >
                      {TranslationList("Create")}
                    </Button>
                  )}
                </div>
              </div>
              {params?.id && (
                <React.Fragment>
                  <h5>{TranslationList("Users controlling the project")}</h5>
                  {arrayList ? (
                    <React.Fragment>
                      {arrayList.map((item) => (
                        <div className="row" key={item.id}>
                          <div className="mb-3 col-3">
                            <label
                              htmlFor="department"
                              className="col-form-label"
                            >
                              {TranslationList("Department")}{" "}
                            </label>
                            <Input
                              key={item.department}
                              type="text"
                              className="form-control"
                              defaultValue={item?.department_detail?.name}
                              disabled
                            />
                          </div>
                          <div className="mb-3 col-3">
                            <label
                              htmlFor="position"
                              className="col-form-label"
                            >
                              {TranslationList("Position")}
                            </label>
                            <Input
                              key={item.position}
                              type="text"
                              className="form-control"
                              defaultValue={item?.position_detail?.name}
                              disabled
                            />
                          </div>
                          <div className="mb-3 col-3">
                            <label htmlFor="users" className="col-form-label">
                              {TranslationList("Users")}
                            </label>
                            <Input
                              key={item.user}
                              type="text"
                              className="form-control"
                              defaultValue={
                                item?.user_detail?.last_name +
                                " " +
                                item?.user_detail?.first_name
                              }
                              disabled
                            />{" "}
                          </div>
                          <div className="col-3 d-flex mb-3 align-items-end">
                            <button
                              type="button"
                              className="btn btn-danger  align-items-end me-1"
                              id="create-btn"
                              onClick={() => handleDeleteUser(item?.id)}
                            >
                              <i className="ri-delete-bin-5-fill align-bottom "></i>
                            </button>
                          </div>
                        </div>
                      ))}
                    </React.Fragment>
                  ) : (
                    ""
                  )}
                  <React.Fragment>
                    <div className="row" id="currentuserItem">
                      <div className="mb-3 col-3">
                        <label htmlFor="department" className="col-form-label">
                          {TranslationList("Department")}
                        </label>
                        <select
                          id="department"
                          className="form-control  "
                          onChange={(e) => SelectDepartment(e.target.value)}
                        >
                          <option></option>
                          {department?.map((item) => (
                            <option key={item.id} value={item.id}>
                              {item.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="mb-3 col-3">
                        <label htmlFor="position" className="col-form-label">
                          {TranslationList("Position")}
                        </label>
                        <select
                          id="position"
                          className="form-control  "
                          onChange={(e) => SelectPosition(e.target.value)}
                        >
                          <option></option>
                          {position?.map((item) => (
                            <option key={item.id} value={item.id}>
                              {item.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="mb-3 col-3">
                        <label htmlFor="users" className="col-form-label">
                          {TranslationList("Users")}
                        </label>
                        <select id="user" className="form-control  ">
                          {user?.map((item) => (
                            <option key={item.id} value={item.id}>
                              {item.last_name} {item.first_name}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="col-3 d-flex mb-3 align-items-end">
                        <button
                          type="button"
                          className="btn btn-danger  align-items-end me-1"
                          id="create-btn"
                          onClick={() => {
                            document.getElementById("currentuserItem").remove();
                          }}
                        >
                          <i className="ri-close-line align-bottom "></i>
                        </button>
                        <button
                          type="button"
                          className="btn btn-success add-btn "
                          onClick={() => handleAddUser()}
                        >
                          <i className="ri-add-line align-bottom"></i>
                        </button>
                      </div>
                    </div>
                  </React.Fragment>
                </React.Fragment>
              )}
            </CardBody>
          </Card>
        </Row>
      </Container>
    </div>
  );
}
