import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const Navdata = () => {
  const history = useNavigate();
  //state data
  const [isDashboard, setIsDashboard] = useState(false);
  const [isDirectory, setIsDirectory] = useState(false);
  const [isSettings, setIsSettings] = useState(false);
  const [isAnalytics, setIsAnalytics] = useState(false);
  const [isApps, setIsApps] = useState(false);
  const [isAuth, setIsAuth] = useState(false);
  const [isPages, setIsPages] = useState(false);
  const [isBaseUi, setIsBaseUi] = useState(false);
  const [isAdvanceUi, setIsAdvanceUi] = useState(false);
  const [isForms, setIsForms] = useState(false);
  const [isTables, setIsTables] = useState(false);
  const [isCharts, setIsCharts] = useState(false);
  const [isIcons, setIsIcons] = useState(false);
  const [isMaps, setIsMaps] = useState(false);
  const [isMultiLevel, setIsMultiLevel] = useState(false);

  // Pages
  const [isLanding, setIsLanding] = useState(false);

  const [iscurrentState, setIscurrentState] = useState("Dashboard");

  function updateIconSidebar(e) {
    if (e && e.target && e.target.getAttribute("subitems")) {
      const ul = document.getElementById("two-column-menu");
      const iconItems = ul.querySelectorAll(".nav-icon.active");
      let activeIconItems = [...iconItems];
      activeIconItems.forEach((item) => {
        item.classList.remove("active");
        var id = item.getAttribute("subitems");
        if (document.getElementById(id))
          document.getElementById(id).classList.remove("show");
      });
    }
  }
  const profile = useSelector((state) => state.profile?.data);

  useEffect(() => {
    document.body.classList.remove("twocolumn-panel");
    if (iscurrentState !== "Dashboard") {
      setIsDashboard(false);
    }
    if (iscurrentState !== "Directory") {
      setIsDirectory(false);
    }
    if (iscurrentState !== "Settings") {
      setIsSettings(false);
    }
    if (iscurrentState !== "Analytics") {
      setIsAnalytics(false);
    }
    if (iscurrentState !== "Apps") {
      setIsApps(false);
    }
    if (iscurrentState !== "Auth") {
      setIsAuth(false);
    }
    if (iscurrentState !== "Pages") {
      setIsPages(false);
    }
    if (iscurrentState !== "BaseUi") {
      setIsBaseUi(false);
    }
    if (iscurrentState !== "AdvanceUi") {
      setIsAdvanceUi(false);
    }
    if (iscurrentState !== "Forms") {
      setIsForms(false);
    }
    if (iscurrentState !== "Tables") {
      setIsTables(false);
    }
    if (iscurrentState !== "Charts") {
      setIsCharts(false);
    }
    if (iscurrentState !== "Icons") {
      setIsIcons(false);
    }
    if (iscurrentState !== "Maps") {
      setIsMaps(false);
    }
    if (iscurrentState !== "MuliLevel") {
      setIsMultiLevel(false);
    }
    if (iscurrentState === "Widgets") {
      history("/widgets");
      document.body.classList.add("twocolumn-panel");
    }
    if (iscurrentState !== "Landing") {
      setIsLanding(false);
    }
  }, [
    history,
    iscurrentState,
    isDashboard,
    isApps,
    isAuth,
    isPages,
    isBaseUi,
    isAdvanceUi,
    isForms,
    isTables,
    isCharts,
    isIcons,
    isMaps,
    isMultiLevel,
    isAnalytics,
    isSettings,
  ]);

  // const menuItemss = [
  //   {
  //     id: "subproject",
  //     label: "Subproject",
  //     icon: "bx bx-file",
  //     link: "/subprojects",
  //     click: function (e) {
  //       e.preventDefault();
  //       setIscurrentState("Subprojects");
  //     },
  //   },
  //   {
  //     id: "analytics",
  //     label: "Analytics",
  //     icon: "bx bx-doughnut-chart",
  //     link: "/#",
  //     click: function (e) {
  //       e.preventDefault();
  //       setIsAnalytics(!isAnalytics);
  //       setIscurrentState("Analytics");
  //       updateIconSidebar(e);
  //     },
  //     stateVariables: isAnalytics,
  //     subItems: [
  //       {
  //         id: "statistics",
  //         label: "Statistics",
  //         link: "/statistics",
  //         parentId: "analytics",
  //       },
  //       {
  //         id: "analytics_map",
  //         label: "Map",
  //         link: "/map",
  //         parentId: "analytics",
  //       },
  //       {
  //         id: "analytics_reports",
  //         label: "Reports",
  //         link: "/analytics/reports",
  //         parentId: "analytics",
  //       },
  //     ],
  //   },
  //   {
  //     id: "directory",
  //     label: "Directories",
  //     icon: "bx bx-layer",
  //     link: "/#",
  //     click: function (e) {
  //       e.preventDefault();
  //       setIsDirectory(!isDirectory);
  //       setIscurrentState("Directory");
  //       updateIconSidebar(e);
  //     },
  //     stateVariables: isDirectory,
  //     subItems: [
  //       {
  //         id: "user",
  //         label: "Users",
  //         parentId: "directory",
  //         link: "/users",
  //       },
  //       {
  //         id: "region",
  //         label: "Regions and Districts",
  //         link: "/regions_and_districts",
  //         parentId: "directory",
  //       },
  //       {
  //         id: "position",
  //         label: "Position and Department",
  //         link: "/position_and_department",
  //         parentId: "directory",
  //       },
  //       {
  //         id: "currency",
  //         label: "Сurrency",
  //         link: "/currency",
  //         parentId: "directory",
  //       },
  //       {
  //         id: "monitoring",
  //         label: "Monitoring_Documents",
  //         link: "/monitoring",
  //         parentId: "directory",
  //       },
  //       {
  //         id: "monitoring_period",
  //         label: "Monitoring_Period",
  //         link: "/monitoring-period",
  //         parentId: "directory",
  //       },
  //       {
  //         id: "complaint",
  //         label: "Complaint",
  //         link: "/complaint",
  //         parentId: "directory",
  //       },
  //       {
  //         id: "project",
  //         label: "Project",
  //         link: "/project",
  //         parentId: "directory",
  //       },
  //       {
  //         id: "dedline",
  //         label: "Dedline",
  //         link: "/dedline",
  //         parentId: "directory",
  //       },
  //       {
  //         id: "activity_type",
  //         label: "Activity Type",
  //         link: "/activity_type",
  //         parentId: "directory",
  //       },
  //       {
  //         id: "sector_and_fields",
  //         label: "Sector and Fields",
  //         link: "/sector_and_fields",
  //         parentId: "directory",
  //       },
  //       {
  //         id: "organization",
  //         label: "Organization and Organization Branch",
  //         link: "/organization_and_branch",
  //         parentId: "directory",
  //       },
  //       {
  //         id: "entity_type",
  //         label: "Entity type",
  //         link: "/entity_type",
  //         parentId: "directory",
  //       },
  //       {
  //         id: "exchange_rates",
  //         label: "Exchange rates",
  //         link: "/exchange_rates",
  //         parentId: "directory",
  //       },
  //       {
  //         id: "document",
  //         label: "Document",
  //         link: "/document",
  //         parentId: "directory",
  //       },
  //     ],
  //   },
  //   {
  //     id: "settings",
  //     label: "Settings",
  //     icon: "bx bx-cog",
  //     link: "/#",
  //     click: function (e) {
  //       e.preventDefault();
  //       setIsSettings(!isSettings);
  //       setIscurrentState("Settings");
  //       updateIconSidebar(e);
  //     },
  //     stateVariables: isSettings,
  //     subItems: [
  //       {
  //         id: "translations",
  //         label: "Translation",
  //         link: "/translations",
  //         parentId: "settings",
  //       },
  //     ],
  //   },
  // ];
  const menuItems = () => {
    switch (profile?.roles[0]?.name) {
      case "User":
        return [
          {
            label: "Menu",
            isHeader: true,
          },

          {
            id: "applications",
            label: "Applications",
            icon: "bx bx-file",
            link: "/applications",
            click: function (e) {
              e.preventDefault();
              setIscurrentState("Applications");
            },
          },
        ];
      case "Administrator":
        return [
          {
            label: "Menu",
            isHeader: true,
          },
          {
            id: "settings",
            label: "Settings",
            icon: "bx bx-cog",
            link: "/#",
            click: function (e) {
              e.preventDefault();
              setIsSettings(!isSettings);
              setIscurrentState("Settings");
              updateIconSidebar(e);
            },
            stateVariables: isSettings,
            subItems: [
              {
                id: "translations",
                label: "Translation",
                link: "/translations",
                parentId: "settings",
              },
            ],
          },
          {
            id: "directory",
            label: "Directories",
            icon: "bx bx-layer",
            link: "/#",
            click: function (e) {
              e.preventDefault();
              setIsDirectory(!isDirectory);
              setIscurrentState("Directory");
              updateIconSidebar(e);
            },
            stateVariables: isDirectory,
            subItems: [
              {
                id: "user",
                label: "Users",
                parentId: "directory",
                link: "/users",
              },
              {
                id: "region",
                label: "Regions and Districts",
                link: "/regions_and_districts",
                parentId: "directory",
              },
              {
                id: "position",
                label: "Position and Department",
                link: "/position_and_department",
                parentId: "directory",
              },
              {
                id: "currency",
                label: "Сurrency",
                link: "/currency",
                parentId: "directory",
              },
              {
                id: "monitoring",
                label: "Monitoring_Documents",
                link: "/monitoring",
                parentId: "directory",
              },
              {
                id: "monitoring_period",
                label: "Monitoring_Period",
                link: "/monitoring-period",
                parentId: "directory",
              },
              {
                id: "complaint",
                label: "Complaint",
                link: "/complaint",
                parentId: "directory",
              },
              {
                id: "project",
                label: "Project",
                link: "/project",
                parentId: "directory",
              },
              {
                id: "dedline",
                label: "Dedline",
                link: "/dedline",
                parentId: "directory",
              },
              {
                id: "activity_type",
                label: "Activity Type",
                link: "/activity_type",
                parentId: "directory",
              },
              {
                id: "sector_and_fields",
                label: "Sector and Fields",
                link: "/sector_and_fields",
                parentId: "directory",
              },
              {
                id: "organization",
                label: "Organization and Organization Branch",
                link: "/organization_and_branch",
                parentId: "directory",
              },
              {
                id: "entity_type",
                label: "Entity type",
                link: "/entity_type",
                parentId: "directory",
              },
              {
                id: "exchange_rates",
                label: "Exchange rates",
                link: "/exchange_rates",
                parentId: "directory",
              },
              {
                id: "document",
                label: "Document",
                link: "/document",
                parentId: "directory",
              },
            ],
          },
        ];
      case "Coordinator":
        return [
          {
            label: "Menu",
            isHeader: true,
          },
          {
            id: "applications",
            label: "Applications",
            icon: "bx bx-file",
            link: "/applications",
            click: function (e) {
              e.preventDefault();
              setIscurrentState("Applications");
            },
          },
          {
            id: "subproject",
            label: "Subproject",
            icon: "bx bx-file",
            link: "/subprojects",
            click: function (e) {
              e.preventDefault();
              setIscurrentState("Subprojects");
            },
          },
          {
            id: "analytics",
            label: "Analytics",
            icon: "bx bx-doughnut-chart",
            link: "/#",
            click: function (e) {
              e.preventDefault();
              setIsAnalytics(!isAnalytics);
              setIscurrentState("Analytics");
              updateIconSidebar(e);
            },
            stateVariables: isAnalytics,
            subItems: [
              {
                id: "statistics",
                label: "Statistics",
                link: "/statistics",
                parentId: "analytics",
              },
              {
                id: "analytics_map",
                label: "Map",
                link: "/map",
                parentId: "analytics",
              },
              {
                id: "analytics_reports",
                label: "Reports",
                link: "/analytics/reports",
                parentId: "analytics",
              },
            ],
          },

          {
            id: "directory",
            label: "Directories",
            icon: "bx bx-layer",
            link: "/#",
            click: function (e) {
              e.preventDefault();
              setIsDirectory(!isDirectory);
              setIscurrentState("Directory");
              updateIconSidebar(e);
            },
            stateVariables: isDirectory,
            subItems: [
              {
                id: "user",
                label: "Users",
                parentId: "directory",
                link: "/users",
              },
              {
                id: "region",
                label: "Regions and Districts",
                link: "/regions_and_districts",
                parentId: "directory",
              },
              {
                id: "position",
                label: "Position and Department",
                link: "/position_and_department",
                parentId: "directory",
              },
              {
                id: "currency",
                label: "Сurrency",
                link: "/currency",
                parentId: "directory",
              },
              {
                id: "monitoring",
                label: "Monitoring_Documents",
                link: "/monitoring",
                parentId: "directory",
              },
              {
                id: "monitoring_period",
                label: "Monitoring_Period",
                link: "/monitoring-period",
                parentId: "directory",
              },
              {
                id: "complaint",
                label: "Complaint",
                link: "/complaint",
                parentId: "directory",
              },
              {
                id: "project",
                label: "Project",
                link: "/project",
                parentId: "directory",
              },
              {
                id: "dedline",
                label: "Dedline",
                link: "/dedline",
                parentId: "directory",
              },
              {
                id: "activity_type",
                label: "Activity Type",
                link: "/activity_type",
                parentId: "directory",
              },
              {
                id: "sector_and_fields",
                label: "Sector and Fields",
                link: "/sector_and_fields",
                parentId: "directory",
              },
              {
                id: "organization",
                label: "Organization and Organization Branch",
                link: "/organization_and_branch",
                parentId: "directory",
              },
              {
                id: "entity_type",
                label: "Entity type",
                link: "/entity_type",
                parentId: "directory",
              },
              {
                id: "exchange_rates",
                label: "Exchange rates",
                link: "/exchange_rates",
                parentId: "directory",
              },
              {
                id: "document",
                label: "Document",
                link: "/document",
                parentId: "directory",
              },
            ],
          },
        ];
      case "District Bank Manager":
        return [
          {
            label: "Menu",
            isHeader: true,
          },

          {
            id: "applications",
            label: "Applications",
            icon: "bx bx-file",
            link: "/applications",
            click: function (e) {
              e.preventDefault();
              setIscurrentState("Applications");
            },
          },
          {
            id: "subproject",
            label: "Subproject",
            icon: "bx bx-file",
            link: "/subprojects",
            click: function (e) {
              e.preventDefault();
              setIscurrentState("Subprojects");
            },
          },
          {
            id: "analytics",
            label: "Analytics",
            icon: "bx bx-doughnut-chart",
            link: "/#",
            click: function (e) {
              e.preventDefault();
              setIsAnalytics(!isAnalytics);
              setIscurrentState("Analytics");
              updateIconSidebar(e);
            },
            stateVariables: isAnalytics,
            subItems: [
              {
                id: "statistics",
                label: "Statistics",
                link: "/statistics",
                parentId: "analytics",
              },
              {
                id: "analytics_map",
                label: "Map",
                link: "/map",
                parentId: "analytics",
              },
              {
                id: "analytics_reports",
                label: "Reports",
                link: "/analytics/reports",
                parentId: "analytics",
              },
            ],
          },
        ];

      case "Manager of the Republic Bank":
        return [
          {
            label: "Menu",
            isHeader: true,
          },

          {
            id: "applications",
            label: "Applications",
            icon: "bx bx-file",
            link: "/applications",
            click: function (e) {
              e.preventDefault();
              setIscurrentState("Applications");
            },
          },
          {
            id: "subproject",
            label: "Subproject",
            icon: "bx bx-file",
            link: "/subprojects",
            click: function (e) {
              e.preventDefault();
              setIscurrentState("Subprojects");
            },
          },
          {
            id: "analytics",
            label: "Analytics",
            icon: "bx bx-doughnut-chart",
            link: "/#",
            click: function (e) {
              e.preventDefault();
              setIsAnalytics(!isAnalytics);
              setIscurrentState("Analytics");
              updateIconSidebar(e);
            },
            stateVariables: isAnalytics,
            subItems: [
              {
                id: "statistics",
                label: "Statistics",
                link: "/statistics",
                parentId: "analytics",
              },
              {
                id: "analytics_map",
                label: "Map",
                link: "/map",
                parentId: "analytics",
              },
              {
                id: "analytics_reports",
                label: "Reports",
                link: "/analytics/reports",
                parentId: "analytics",
              },
            ],
          },
        ];

      case "Specialist":
        return [
          {
            label: "Menu",
            isHeader: true,
          },

          {
            id: "applications",
            label: "Applications",
            icon: "bx bx-file",
            link: "/applications",
            click: function (e) {
              e.preventDefault();
              setIscurrentState("Applications");
            },
          },
          {
            id: "subproject",
            label: "Subproject",
            icon: "bx bx-file",
            link: "/subprojects",
            click: function (e) {
              e.preventDefault();
              setIscurrentState("Subprojects");
            },
          },
          {
            id: "analytics",
            label: "Analytics",
            icon: "bx bx-doughnut-chart",
            link: "/#",
            click: function (e) {
              e.preventDefault();
              setIsAnalytics(!isAnalytics);
              setIscurrentState("Analytics");
              updateIconSidebar(e);
            },
            stateVariables: isAnalytics,
            subItems: [
              {
                id: "statistics",
                label: "Statistics",
                link: "/statistics",
                parentId: "analytics",
              },
              {
                id: "analytics_map",
                label: "Map",
                link: "/map",
                parentId: "analytics",
              },
              {
                id: "analytics_reports",
                label: "Reports",
                link: "/analytics/reports",
                parentId: "analytics",
              },
            ],
          },
        ];
      default:
        return [
          {
            label: "Menu",
            isHeader: true,
          },

          {
            id: "applications",
            label: "Applications",
            icon: "bx bx-file",
            link: "/applications",
            click: function (e) {
              e.preventDefault();
              setIscurrentState("Applications");
            },
          },
          {
            id: "subproject",
            label: "Subproject",
            icon: "bx bx-file",
            link: "/subprojects",
            click: function (e) {
              e.preventDefault();
              setIscurrentState("Subprojects");
            },
          },
          {
            id: "analytics",
            label: "Analytics",
            icon: "bx bx-doughnut-chart",
            link: "/#",
            click: function (e) {
              e.preventDefault();
              setIsAnalytics(!isAnalytics);
              setIscurrentState("Analytics");
              updateIconSidebar(e);
            },
            stateVariables: isAnalytics,
            subItems: [
              {
                id: "statistics",
                label: "Statistics",
                link: "/statistics",
                parentId: "analytics",
              },
              {
                id: "analytics_map",
                label: "Map",
                link: "/map",
                parentId: "analytics",
              },
              {
                id: "analytics_reports",
                label: "Reports",
                link: "/analytics/reports",
                parentId: "analytics",
              },
            ],
          },
        ];
    }
  };
  return <React.Fragment>{menuItems()}</React.Fragment>;
};
export default Navdata;
