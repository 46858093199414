import { createSlice } from "@reduxjs/toolkit";
import { DataService } from "../helpers/dataService/dataService";

export const chronologySlide = createSlice({
  name: "chronology",
  initialState: {
    data: null,
    signatory: null,
  },
  reducers: {
    getchronology: (state, action) => {
      state.data = action.payload;
    },
    getsignatory: (state, action) => {
      state.signatory = action.payload;
    },
  },
});

export const getchronologyAsync = (data) => async (dispatch) => {
  try {
    const datas = await DataService.get(data);
    dispatch(getchronology(datas));
  } catch (error) {
    // //throw new Error(err);
  }
};
export const getsignatoryAsync = (data) => async (dispatch) => {
  try {
    const datas = await DataService.get(data);
    dispatch(getsignatory(datas));
  } catch (error) {
    // //throw new Error(err);
  }
};

export const { getchronology, getsignatory } = chronologySlide.actions;
export default chronologySlide.reducer;
