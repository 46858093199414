import { createSlice } from "@reduxjs/toolkit";
import { DataService } from "../../helpers/dataService/dataService";

export const organizationSlide = createSlice({
  name: "organization",
  initialState: {
    data: null,
    error: null,
    current: null,
    item: [],
  },
  reducers: {
    getorganization: (state, action) => {
      state.data = action.payload;
    },
    editorganization: (state, action) => {
      state.item = action.payload;
    },
    detailorganization: (state, action) => {
      state.current = action.payload;
    },
  },
});

export const getorganizationAsync = (data) => async (dispatch) => {
  try {
    const datas = await DataService.get(data);
    dispatch(getorganization(datas));
  } catch (error) {
    // //throw new Error(err);
  }
};
export const getdetailorganizationAsync = async (data) => {
  const datas = await DataService.get(data);
  return datas;
};

export const editorganizationAsync = (data) => async (dispatch) => {
  try {
    const datas = await DataService.get(data);
  } catch (error) {
    // //throw new Error(err);
  }
};

export const { getorganization, editorganization, detailorganization } =
  organizationSlide.actions;
export default organizationSlide.reducer;
