import PropTypes from "prop-types";
import React from "react";
import { useTranslation, withTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  Input,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { columnAsync } from "../../slices/application/reducer";
import { columnProjectAsync } from "../../slices/project/reducer";
import { columnOrganizationAsync } from "../../slices/organizations/reducer";
import { columnOrganizationBranchAsync } from "../../slices/organizationBranch/reducer";
import { columnUserAsync } from "../../slices/user/reducer";
import { TranslationList } from "../../slices/settings/listtranslation";

const ColumnModal = ({ show, onCloseClick, column }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const handleSave = async () => {
    let data = await column?.map((res, i) => {
      let obj = document.getElementById(res?.name).checked;
      return { name: res?.name, status: obj };
    });
    dispatch(columnAsync(data));
    dispatch(columnProjectAsync(data));
    dispatch(columnOrganizationAsync(data));
    dispatch(columnOrganizationBranchAsync(data));
    dispatch(columnUserAsync(data))
    onCloseClick();
  };
  return (
    <Modal isOpen={show} toggle={onCloseClick} centered={true}>
      <ModalHeader toggle={onCloseClick}>{TranslationList("Column selection")}</ModalHeader>
      <ModalBody className="py-3 ">
        <div className="live-preview">
          <ListGroup>
            {column?.map((res, i) => (
              <ListGroupItem key={i} tag="label">
                <Input
                  className="form-check-input me-1"
                  type="checkbox"
                  defaultChecked={res?.status}
                  id={res?.name}
                  value={res}
                />
                {res?.name}
              </ListGroupItem>
            ))}
          </ListGroup>
        </div>
      </ModalBody>
      <ModalFooter>
        <button
          type="button"
          className="btn w-sm btn-light"
          data-bs-dismiss="modal"
          onClick={onCloseClick}
        >
          {TranslationList("Close")}
        </button>
        <button
          type="button"
          className="btn w-sm btn-info"
          data-bs-dismiss="modal"
          onClick={handleSave}
        >
          Ok
        </button>
      </ModalFooter>
    </Modal>
  );
};

ColumnModal.propTypes = {
  onCloseClick: PropTypes.func,
  data: PropTypes.any,
  show: PropTypes.any,
};

export default withTranslation()(ColumnModal);
