import React, { useState, useEffect, useMemo } from "react";
import {
  Button,
  Container,
  Form,
  Input,
  Label,
  Modal,
  ModalHeader,
  Table,
} from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { TranslationList } from "../../slices/settings/listtranslation";
import LoaderContent from "../../Components/Spinner";
import Paginations from "../Tables/DataTables/Pagination";
import { DataService } from "../../helpers/dataService/dataService";
import endpoints from "../../endpoints";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/l10n/ru.js";
import "flatpickr/dist/l10n/uz.js";
import Select from "react-select";
import dateFormat from "dateformat";
import { toast } from "react-toastify";
export default function MonitoringWitoutPeriod({ status, onfetchData }) {
  const lang = useSelector((state) => state.lang.data);
  const [periods, setPeriods] = useState();
  const [current, setCurrent] = useState();
  const [modal_varying, setmodal_varying] = useState(false);
  const [loading, setLoading] = useState(false);
  const [apiData, setApiData] = useState();
  const [state, setState] = useState({
    date_start_manitoring: "",
    manitoring_period: "",
    status: "monitoring",
  });
  const tog_varying = () => {
    setmodal_varying(!modal_varying);
  };
  const fetchData = async () => {
    const response = await DataService.get(endpoints.investSubproject, {
      status: status,
    });
    setApiData(response);
    const resPeriod = await DataService.get(endpoints.monitoringPeriod);
    setPeriods(
      resPeriod?.results?.map((item) => {
        return { label: item?.period, value: item?.id };
      })
    );
  };
  const fetchDataParams = async (params) => {
    const response = await DataService.get(endpoints.investSubproject, {
      ...params,
      status: status,
    });
    setApiData(response);
  };
  useEffect(() => {
    fetchData();
  }, [lang]);
  const handleSearch = (e) => {
    fetchDataParams({ search: e });
  };
  const perpage = localStorage.getItem("perpage") ?? 10;
  const [perPage, setPerpage] = useState(perpage);
  const handleCurrentPage = (e) => {
    fetchDataParams({ page: e, limit: perPage });
  };
  const handlePerPageChange = (e) => {
    setPerpage(e);
    fetchDataParams({ limit: e });
  };
  const handleSave = async () => {
    try {
      await DataService.put(
        endpoints.investSubproject + `/${current?.id}`,
        state
      );
      toast.success(TranslationList("Done successfully!"));
      tog_varying();
      onfetchData();
      fetchData();
    } catch (err) {
      toast.error(err);
    }
  };

  return (
    <div className="border-0 shadow-none">
      <Modal
        isOpen={modal_varying}
        toggle={() => {
          tog_varying();
        }}
        id="exampleModal"
      >
        <ModalHeader
          toggle={() => {
            tog_varying();
          }}
        >
          {TranslationList("Add period for manitoring")}
        </ModalHeader>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            handleSave();
          }}
        >
          <div className="modal-body">
            <Container fluid>
              <div>
                <Label className="form-label mb-0">
                  {TranslationList("Monitoring start date")}
                </Label>
                <Flatpickr
                  className="form-control"
                  onChange={(e) =>
                    setState({
                      ...state,
                      date_start_manitoring: dateFormat(e, "yyyy-mm-dd"),
                    })
                  }
                  options={{
                    locale: localStorage.getItem("I18N_LANGUAGE_INVEST"),
                    dateFormat: "d M Y",
                  }}
                />
              </div>
              <div>
                <Label>{TranslationList("Monitoring_Period")}</Label>
                <Select
                  onChange={(e) => {
                    setState({
                      ...state,
                      manitoring_period: e.value,
                    });
                  }}
                  options={periods}
                  classNamePrefix="js-example-basic-multiple"
                />
              </div>
            </Container>
          </div>
          <div className="modal-footer">
            <Button
              color="light"
              onClick={() => {
                setmodal_varying(false);
              }}
            >
              {TranslationList("Cancel")}
            </Button>

            <Button color="primary" type="submit" disabled={loading}>
              {loading ? (
                <Spinner size="sm" className="me-2">
                  {" "}
                  Loading...{" "}
                </Spinner>
              ) : null}
              {TranslationList("Send")}
            </Button>
          </div>
        </Form>
      </Modal>
      <React.Fragment>
        <div className="border-0 p-2">
          <div className="d-flex ">
            <div className="p-2">
              <div className="search-box">
                <Input
                  type="text"
                  className="form-control search"
                  placeholder={TranslationList("List Search")}
                  onChange={(e) => handleSearch(e.target.value)}
                />
                <i className="ri-search-line search-icon"></i>
              </div>
            </div>
          </div>
        </div>
        {apiData?.results ? (
          <React.Fragment>
            <div className="live-preview p-3">
              <div className="text-muted table-responsive">
                <Table className="table-bordered align-middle table-nowrap mb-0">
                  <thead>
                    <tr>
                      <th scope="col">
                        {TranslationList("Application number")}
                      </th>
                      <th scope="col">{TranslationList("Project")}</th>
                      <th scope="col">
                        {TranslationList("Project description")}
                      </th>

                      <th scope="col">
                        {TranslationList("Monitoring_Period")}
                      </th>

                      <th scope="col">{TranslationList("Applicant")}</th>

                      <th scope="col">{TranslationList("Actions")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {apiData?.results?.map((res) => (
                      <tr key={res?.id}>
                        <td>
                          <Link
                            to={`/application-detail?show_id=${res.aplication_detail?.id}`}
                          >
                            {res.aplication_detail?.nomer}
                          </Link>
                        </td>

                        <td>
                          <div className="d-flex flex-wrap">
                            <div className="text-wrap">
                              {res?.project_detail?.name}
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="d-flex flex-wrap">
                            <div className="text-wrap">
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: res?.project_detail?.description,
                                }}
                              ></div>
                            </div>
                          </div>
                        </td>

                        <td>{res?.project_detail?.manitoring_period}</td>

                        <td>
                          {res?.aplication_detail?.created_by_info?.first_name +
                            "  "}
                          {res?.aplication_detail?.created_by_info?.last_name}
                        </td>
                        <td className="text-center">
                          <button
                            className="btn btn-sm btn-soft-success show-list"
                            onClick={() => {
                              tog_varying();
                              setCurrent(res);
                            }}
                          >
                            <i className="  ri-pencil-fill align-bottom" />
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </div>
          </React.Fragment>
        ) : (
          <LoaderContent />
        )}
        <Paginations
          totalItems={apiData?.pagination?.total}
          perPageChange={(e) => handlePerPageChange(e)}
          currentPage={(e) => handleCurrentPage(e)}
        />
      </React.Fragment>
    </div>
  );
}
