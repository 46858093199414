import axios from "axios";
import { toast } from "react-toastify";

export const getTokenOneId = async (code, redirect_uri) => {
  const data = {
    grant_type: "one_authorization_code",
    client_id: "invest_iscad_uz",
    redirect_uri: redirect_uri,
    client_secret: "JZWEM26e8Y4lT70wObP7gQRh",
    code: code,
  };
  try {
    const response = await axios.post(
      "https://sso.egov.uz/sso/oauth/Authorization.do",
      {},
      { params: { ...data } }
    );
    return getInfoOneId({ response, redirect_uri });
  } catch (e) {
    console.log(e);
    toast.error(e?.error);
  }
};
export const getTokenOneIdForBack = async (code, redirect_uri) => {
  const data = {
    grant_type: "one_authorization_code",
    client_id: "invest_iscad_uz",
    redirect_uri: redirect_uri,
    client_secret: "JZWEM26e8Y4lT70wObP7gQRh",
    code: code,
  };
  try {
    const response = await axios.post(
      "https://sso.egov.uz/sso/oauth/Authorization.do",
      {},
      { params: { ...data } }
    );
    return response;
  } catch (e) {
    console.log(e);
    toast.error(e?.error);
  }
};
export const getInfoOneId = async ({ response, redirect_uri }) => {
  const data = {
    grant_type: "one_access_token_identify",
    client_id: "invest_iscad_uz",
    redirect_uri: redirect_uri,
    client_secret: "JZWEM26e8Y4lT70wObP7gQRh",
    access_token: response.access_token,
    scope: response.scope,
  };
  try {
    const response = await axios.post(
      "https://sso.egov.uz/sso/oauth/Authorization.do",
      {},
      { params: { ...data } }
    );
    return response;
  } catch (e) {
    console.log(e);
    toast.error(e?.error);
  }
};

export const spliteAge = (pin) => {
  // Foydalanuvchi tug'ilgan sanasini olish
  var birthDate = new Date(pin);

  // Hozirgi sana va yilni olish
  var currentDate = new Date();
  var currentYear = currentDate.getFullYear();

  // Foydalanuvchi tug'ilgan yili va hozirgi yili orasidagi farqni hisoblash
  var age = currentYear - birthDate.getFullYear();

  // Agar hozirgi sana foydalanuvchi tug'ilgan sanasi oldin yoki keyin bo'lsa,
  // yoshini bir o'zgartirish kerak
  if (
    currentDate.getMonth() < birthDate.getMonth() ||
    (currentDate.getMonth() == birthDate.getMonth() &&
      currentDate.getDate() < birthDate.getDate())
  ) {
    age--;
  }

  // Natijani chiqarish
  return age;
};
