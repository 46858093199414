import React from "react";
import { Translation, withTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Form, Row, Col, Label, FormGroup, Table } from "reactstrap";
import { TranslationList } from "../../slices/settings/listtranslation";
import MaskedInput from "react-input-mask";
import CurrencyInput from "react-currency-input-field";

function PersonalDataShow() {
  const application = useSelector((state) => state.applic.current);

  return (
    <div>
      <Form className="needs-validation">
        {/* Заявитель */}
        <h5>{TranslationList("Applicant")}</h5>
        <React.Fragment>
          <Row>
            <Col md="6">
              <FormGroup className="mb-3">
                <Label className="fw-bold" htmlFor="numberapp">
                  {TranslationList("Application number")}
                </Label>
                <p>{application?.nomer}</p>
              </FormGroup>
            </Col>
            <Col md="6" className="d-flex align-items-center ">
              <FormGroup className="mb-3 me-4 ">
                <Label className="fw-bold" htmlFor="stirapp">
                  {TranslationList("TIN of the enterprise")}
                </Label>
                <p>{application?.organizationinfo?.inn}</p>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <FormGroup className="mb-3">
                <Label className="fw-bold" htmlFor="nameapp">
                  {TranslationList("Name of the organization")}
                </Label>
                <p>{application?.organizationinfo?.name}</p>
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup className="mb-3">
                <Label className="fw-bold" htmlFor="statusapp">
                  {TranslationList("Legal status")}
                </Label>
                <p>{application?.organizationinfo?.legal_status}</p>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <FormGroup className="mb-3">
                <Label className="fw-bold" htmlFor="addressapp">
                  {TranslationList("Organization address")}
                </Label>
                <p>{application?.organizationinfo?.address}</p>
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup className="mb-3">
                <Label className="fw-bold" htmlFor="statusapp">
                  {TranslationList("Region")}
                </Label>
                <p>{application?.organizationinfo?.region_detail?.name}</p>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <FormGroup className="mb-3">
                <Label className="fw-bold" htmlFor="nameapp">
                  {TranslationList("District")}
                </Label>
                <p>{application?.organizationinfo?.district_detail?.name}</p>
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup className="mb-3">
                <Label className="fw-bold" htmlFor="statusapp">
                  {TranslationList("Mahalla")}
                </Label>
                <p>{application?.organizationinfo?.mahalla}</p>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <FormGroup className="mb-3">
                <Label className="fw-bold" htmlFor="org">
                  {TranslationList("Head of the organization")}
                </Label>
                <p>{application?.organizationinfo?.supervisor}</p>
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup className="mb-3">
                <Label className="fw-bold" htmlFor="pnfl">
                  {TranslationList("PINFL Manager")}
                </Label>
                <p>{application?.organizationinfo?.pinfl}</p>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <FormGroup className="mb-3">
                <Label className="fw-bold" htmlFor="inn">
                  {TranslationList("TIN of the Head")}
                </Label>
                <p>{application?.organizationinfo?.inn}</p>
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup className="mb-3">
                <Label className="fw-bold" htmlFor="numberPhone">
                  {TranslationList("Manager's phone number")}
                </Label>
                <p>{application?.organizationinfo?.supervisor_phone}</p>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Label className="fw-bold form-label mb-0">
                {TranslationList("Gender of the Executive")}
              </Label>
              <Row>
                <Col md={6}>
                  <FormGroup className="mb-3">
                    <Label
                      className=" fw-bold form-check-label ml-3"
                      htmlFor="flexRadioDefault1"
                    >
                      <p>
                        {application?.organizationinfo?.supervisor_gender ==
                        "Male"
                          ? TranslationList("Male")
                          : TranslationList("Female")}
                      </p>
                    </Label>
                  </FormGroup>
                </Col>
              </Row>
            </Col>
            <Col md={6}>
              <Label className="fw-bold form-label mb-0">
                {TranslationList("Number of employees")}
              </Label>
              <Row>
                <Col md="6" className="d-flex align-items-center">
                  <FormGroup className="mb-3">
                    <Label
                      className="fw-bold form-check-label me-3"
                      htmlFor="full"
                    >
                      {TranslationList("overall_jobs")}
                    </Label>
                    <p>{application?.overall_jobs}</p>
                  </FormGroup>
                </Col>
                <Col md="6" className="d-flex align-items-center">
                  <FormGroup className="mb-3">
                    <Label
                      className="fw-bold form-check-label me-3"
                      htmlFor="full"
                    >
                      {TranslationList("Female")}
                    </Label>
                    <p>{application?.women}</p>
                  </FormGroup>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Label className="fw-bold form-label mb-0">
              {TranslationList("Organization founders")}
            </Label>
            <div className="p-3">
              <Table className="text-muted table-bordered align-middle form-label table-nowrap mb-0 ">
                <thead>
                  <tr>
                    <th style={{ fontWeight: 400 }} scope="col">
                      №
                    </th>
                    <th style={{ fontWeight: 400 }} scope="col">
                      {TranslationList("Founder")}
                    </th>
                    <th style={{ fontWeight: 400 }} scope="col">
                      {TranslationList("Share in the authorized capital")}
                    </th>
                  </tr>
                </thead>
                {/* <tbody>
                  <tr>
                    <td className="fw-medium">01</td>
                    <td>Implement new UX</td>
                    <td>
                      <span className="badge badge-soft-primary">Backlog</span>
                    </td>
                  </tr>
                </tbody> */}
              </Table>
            </div>
          </Row>
        </React.Fragment>

        {/* Проект */}
        <h6>{TranslationList("Project")}</h6>
        <React.Fragment>
          <Row>
            <Col md="6">
              <FormGroup className="mb-3">
                <Label className="fw-bold" htmlFor="addressapp">
                  {TranslationList(
                    "Area where the project will be implemented"
                  )}
                </Label>
                <p>{application?.project_region_info?.name}</p>
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup className="mb-3">
                <Label className="fw-bold" htmlFor="statusapp">
                  {TranslationList(
                    "The area where the project will be implemented"
                  )}
                </Label>
                <p>{application?.project_district_info?.name}</p>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <FormGroup className="mb-3">
                <Label className="fw-bold" htmlFor="addressapp">
                  {TranslationList("Project Implementation Sector")}{" "}
                </Label>
                <p>{application?.project_sector_info?.name}</p>
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup className="mb-3">
                <Label className="fw-bold" htmlFor="statusapp">
                  {TranslationList("Type of project activity")}{" "}
                </Label>
                <p>{application?.project_foa_info?.name}</p>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Label className="fw-bold form-label mb-0">
                {TranslationList("Number of jobs")}
              </Label>
              <Row>
                <Col md="6" className="d-flex align-items-center">
                  <FormGroup className="mb-3">
                    <Label className=" form-check-label me-3" htmlFor="full">
                      {TranslationList("General")}
                    </Label>
                    <p>{application?.overall_jobs_workplace}</p>
                  </FormGroup>
                </Col>
                <Col md="6" className="d-flex align-items-center">
                  <FormGroup className="mb-3">
                    <Label
                      className="fw-bold form-check-label me-3"
                      htmlFor="full"
                    >
                      {TranslationList("Female")}
                    </Label>
                    <p>{application?.women_workplace}</p>
                  </FormGroup>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <FormGroup className="mb-3 mt-3">
              <Label className="fw-bold" htmlFor="numberapp">
                {TranslationList("Brief description of the project")}
              </Label>
              <p>{application?.project_description}</p>
            </FormGroup>
          </Row>
        </React.Fragment>

        {/* Кредит */}
        <h6>{TranslationList("Credit")}</h6>
        <React.Fragment>
          <Row>
            <Col md="6">
              <FormGroup className="mb-3">
                <Label className="fw-bold" htmlFor="addressapp">
                  {TranslationList("Credit line")}
                </Label>
                <p>{application?.projectinfo?.name}</p>
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup className="mb-3">
                <Label className="fw-bold" htmlFor="statusapp">
                  {TranslationList("Participating Bank")}
                </Label>
                <p>{application?.pfi_info?.name}</p>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <FormGroup className="mb-3">
                <Label className="fw-bold" htmlFor="addressapp">
                  {TranslationList("Project cost")}{" "}
                </Label>
                <div className="d-flex align-items-center ">
                  <CurrencyInput
                    value={application?.project_cost?.number}
                    className="no-border form-control"
                  />

                  <span>
                    {application?.project_cost?.currency_detail?.symbol}
                  </span>
                </div>
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup className="mb-3">
                <Label className="fw-bold" htmlFor="statusapp">
                  {TranslationList("Borrower's contribution")}{" "}
                </Label>
                <div className="d-flex align-items-center ">
                  <CurrencyInput
                    value={application?.borrower_contribution?.number}
                    className="no-border form-control"
                  />
                  <span>
                    {
                      application?.borrower_contribution?.currency_detail
                        ?.symbol
                    }
                  </span>
                </div>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <FormGroup className="mb-3">
                <Label className="fw-bold" htmlFor="statusapp">
                  {TranslationList("Loan amount to receive")}{" "}
                </Label>
                <div className="d-flex align-items-center ">
                  <CurrencyInput
                    value={application?.loan_amount_to_receive?.number}
                    className="no-border form-control"
                  />

                  <span>
                    {
                      application?.loan_amount_to_receive?.currency_detail
                        ?.symbol
                    }
                  </span>
                </div>
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup className="mb-3">
                <Label className="fw-bold" htmlFor="pfi">
                  {TranslationList("Participating bank branch")}{" "}
                </Label>
                <p>{application?.pfi_branch_info?.name} </p>
              </FormGroup>
            </Col>
          </Row>
        </React.Fragment>
      </Form>
    </div>
  );
}
export default withTranslation()(PersonalDataShow);
