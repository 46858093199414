import { createSlice } from "@reduxjs/toolkit";
import { DataService } from "../helpers/dataService/dataService";

export const notificationSlide = createSlice({
  name: "notification",
  initialState: {
    data: null,
  },
  reducers: {
    getnotification: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const getnotificationAsync = (data) => async (dispatch) => {
  try {
    const datas = await DataService.get(data);
    dispatch(getnotification(datas));
  } catch (error) {
    // //throw new Error(err);
  }
};

export const { getnotification } = notificationSlide.actions;
export default notificationSlide.reducer;
