import flagus from "../assets/images/flags/us.svg";
import flagrussia from "../assets/images/flags/russia.svg";
import flaguzbek from "../assets/images/flags/uzbek.svg";

const languages = {
  uz: {
    label: "Oʻz",
    flag: flaguzbek,
  },

  ru: {
    label: "Ру",
    flag: flagrussia,
  },
  en: {
    label: "En",
    flag: flagus,
  },
};

export default languages;
