export const TranslationList = (data) => {
  const translationList = JSON.parse(localStorage.getItem("translate"));
  if (translationList && translationList.hasOwnProperty(data)) {
    return translationList[data];
  } else {
    const translationList1 =
      JSON.parse(localStorage.getItem("translateKey")) || [];
    translationList1.push(data);
    const set = new Set(translationList1);
    const array = Array.from(set);
    localStorage.setItem("translateKey", JSON.stringify(array));
    return data;
  }
};
