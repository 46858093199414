import React, { useEffect, useState } from "react";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { TranslationList } from "../../slices/settings/listtranslation";
import { DataService } from "../../helpers/dataService/dataService";
import endpoints from "../../endpoints";
import classnames from "classnames";
import MonitoringWitoutPeriod from "./MonitoringWitoutPeriod";
import MonitoringAll from "./MonitoringAll";
export default function TableSubprojects() {
  const [customActiveTab, setcustomActiveTab] = useState("1");
  const [apiData, setApiData] = useState();
  const toggleCustom = (tab) => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };
  const fetchData = async () => {
    const response = await DataService.get(endpoints.investSubprojectCount);
    setApiData(response);
  };
  useEffect(() => {
    fetchData();
  }, []);
  return (
    <div className="border-0 shadow-none">
      <React.Fragment>
        <Nav tabs className="nav nav-tabs nav-tabs-custom nav-success  mb-3">
          <NavItem>
            <NavLink
              style={{ cursor: "pointer" }}
              className={classnames({
                active: customActiveTab === "1",
              })}
              onClick={() => {
                toggleCustom("1");
              }}
            >
              {TranslationList("All")}
              {"  "}
              <span className=" badge bg-danger rounded-circle">
                {" "}
                {apiData?.all}
              </span>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              style={{ cursor: "pointer" }}
              className={classnames({
                active: customActiveTab === "5",
              })}
              onClick={() => {
                toggleCustom("5");
              }}
            >
              {TranslationList("Monitoring without periods")}{" "}
              <span className=" badge bg-danger rounded-circle">
                {apiData?.no_period_monitoring}
              </span>
            </NavLink>
          </NavItem>
          {/* <NavItem>
            <NavLink
              style={{ cursor: "pointer" }}
              className={classnames({
                active: customActiveTab === "2",
              })}
              onClick={() => {
                toggleCustom("2");
              }}
            >
              {TranslationList("Monitoring all")}{" "}
              <span className=" badge bg-danger rounded-circle">
                {" "}
                {apiData?.monitoring}
              </span>
            </NavLink>
          </NavItem> */}
          <NavItem>
            <NavLink
              style={{ cursor: "pointer" }}
              className={classnames({
                active: customActiveTab === "3",
              })}
              onClick={() => {
                toggleCustom("3");
              }}
            >
              {TranslationList("Monitoring start")}{" "}
              <span className=" badge bg-danger rounded-circle">
                {" "}
                {apiData?.start_monitoring}
              </span>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              style={{ cursor: "pointer" }}
              className={classnames({
                active: customActiveTab === "4",
              })}
              onClick={() => {
                toggleCustom("4");
              }}
            >
              {TranslationList("Monitoring finish")}{" "}
              <span className=" badge bg-danger rounded-circle">
                {" "}
                {apiData?.finish_monitoring}
              </span>
            </NavLink>
          </NavItem>
        </Nav>

        <TabContent activeTab={customActiveTab} className="text-muted">
          <TabPane tabId="1" id="all">
            <MonitoringAll countData={apiData} />
          </TabPane>
          <TabPane tabId="2" id="only_monitoring">
            <MonitoringAll status="monitoring" countData={apiData} />
          </TabPane>
          <TabPane tabId="3" id="monitoring">
            <MonitoringAll status="start_monitoring" countData={apiData} />
          </TabPane>
          <TabPane tabId="4" id="monitoring1">
            <MonitoringAll status="finish_monitoring" countData={apiData} />
          </TabPane>
          <TabPane tabId="5" id="witoutperiod">
            <MonitoringWitoutPeriod
              status="no_period_monitoring"
              onfetchData={fetchData}
            />
          </TabPane>
        </TabContent>
      </React.Fragment>
    </div>
  );
}
