import { createSlice } from "@reduxjs/toolkit";

export const landingSectorSlide = createSlice({
  name: "landingSector",
  initialState: {
    data: null,
  },
  reducers: {
    getlandingSector: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const { getlandingSector } = landingSectorSlide.actions;
export default landingSectorSlide.reducer;
