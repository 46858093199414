import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Container,
  Row,
  Table,
  CardHeader,
  Modal,
  ModalHeader,
  Input,
  Button,
  FormFeedback,
} from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import DeleteModal from "../../Components/Common/DeleteModal";
import { TranslationList } from "../../slices/settings/listtranslation";
import LoaderContent from "../../Components/Spinner";
import Paginations from "../Tables/DataTables/Pagination";
import { DataService } from "../../helpers/dataService/dataService";

export default function Dedline() {
  const [modal_varying1, setmodal_varying1] = useState(false);
  const [key, setkey] = useState();
  const [title, settitle] = useState();
  const [day, setday] = useState();
  const [error, setError] = useState();
  const [current, setCuttent] = useState();
  const [deleteModal, setDeleteModal] = useState(false);
  const dispatch = useDispatch();
  const perpage = localStorage.getItem("perpage") ?? 10;
  const [perPage, setPerpage] = useState(perpage);
  const lang = useSelector((state) => state.lang.data);
  const [data, setData] = useState();
  const getData = async () => {
    const response = await DataService.get(`deadline/`);
    setData(response);
  };
  useEffect(() => {
    getData();
  }, [lang]);
  const handleSearch = async (e) => {
    const response = await DataService.get(`deadline/?search=${e}`);
    setData(response);
  };
  function tog_varying1() {
    setmodal_varying1(!modal_varying1);
    setCuttent();
    setError();
  }
  const handleAdd = async () => {
    const dataDocument = {
      key: key,
      title: title,
      day: day,
    };
    const response = await DataService.post(`deadline/`, dataDocument);
    if (response?.errorCode) {
      setError(response?.message);
      toast.error(TranslationList("An error has occurred!"));
    } else {
      toast.success(TranslationList("Done successfully!"));
      getData();
      setmodal_varying1(false);
    }
  };
  const onClickEdite = (item) => {
    setmodal_varying1(true);
    setCuttent(item);
  };
  const handleEdite = async () => {
    const dataDocument = {
      key: document.getElementById("key").value,
      title: document.getElementById("title").value,
      day: document.getElementById("day").value,
    };

    const response = await DataService.put(
      `deadline/${current?.id}/`,
      dataDocument
    );

    if (response?.errorCode) {
      setmodal_varying1(false);
      toast.error(TranslationList("An error has occurred!"));
    } else {
      toast.success(TranslationList("Done successfully!"));
      getData();
      setmodal_varying1(false);
    }
  };
  const onClickTodoDelete = (item) => {
    setCuttent(item);
    setDeleteModal(true);
  };
  const handleDelete = async () => {
    const response = await DataService.delete(`deadline/${current?.id}/`);
    if (response?.errorCode) {
      toast.error(TranslationList("An error has occurred!"));
    } else {
      toast.success(TranslationList("Done successfully!"));
      getData();
    }
    setDeleteModal(false);
  };
  //Pagination begin
  const handleCurrentPage = async (e) => {
    const response = await DataService.get(
      `deadline/?page=${e}&limit=${perPage}`
    );
    setData(response);
  };
  const handlePerPageChange = async (e) => {
    const response = await DataService.get(`deadline/?limit=${e}`);
    setData(response);
    setPerpage(e);
  };
  //Pagination end
  document.title = `INVESTCONTROL | ${TranslationList("Dedline")}`;

  return (
    <div className="page-content">
      <DeleteModal
        show={deleteModal}
        data={current?.key}
        onDeleteClick={handleDelete}
        onCloseClick={() => setDeleteModal(false)}
      />
      <Modal
        isOpen={modal_varying1}
        toggle={() => {
          tog_varying1();
        }}
        id="exampleModal"
      >
        <ModalHeader
          toggle={() => {
            tog_varying1();
          }}
        >
          {current ? TranslationList("Edit") : TranslationList("Add")}{" "}
        </ModalHeader>
        <div className="modal-body">
          <form>
            <div className="mb-3">
              {" "}
              <label htmlFor="customer-name" className="col-form-label">
                {TranslationList("key")}:
              </label>
              <Input
                id="key"
                type="text"
                className="form-control"
                onChange={(e) => setkey(e.target.value)}
                defaultValue={current?.key}
                invalid={error?.key ? true : false}
              />
              <FormFeedback type="invalid">{error?.key}</FormFeedback>
            </div>
            <div className="mb-3">
              <label htmlFor="customer-name" className="col-form-label">
                {TranslationList("title")}:
              </label>
              <Input
                type="text"
                id="title"
                className="form-control"
                onChange={(e) => settitle(e.target.value)}
                defaultValue={current?.title}
                invalid={error?.title ? true : false}
              />
              <FormFeedback type="invalid">{error?.title}</FormFeedback>
            </div>
            <div className="mb-3">
              <label htmlFor="customer-name" className="col-form-label">
                {TranslationList("day")}:
              </label>
              <Input
                type="number"
                id="day"
                className="form-control"
                onChange={(e) => setday(e.target.value)}
                defaultValue={current?.day}
                invalid={error?.day ? true : false}
              />
              <FormFeedback type="invalid">{error?.day}</FormFeedback>
            </div>
          </form>
        </div>
        <div className="modal-footer">
          <Button
            color="light"
            onClick={() => {
              setmodal_varying1(false);
            }}
          >
            {TranslationList("Cancel")}
          </Button>
          {current ? (
            <Button color="primary" onClick={handleEdite}>
              {TranslationList("Save")}
            </Button>
          ) : (
            <Button color="primary" onClick={handleAdd}>
              {TranslationList("Create")}
            </Button>
          )}
        </div>
      </Modal>

      <Container fluid>
        <BreadCrumb
          title={TranslationList("Dedline")}
          pageTitle={TranslationList("home")}
        />
        <Row>
          <Card>
            <CardHeader>
              <Row className="align-items-center gy-3">
                <div className="col-sm">
                  <h5 className="card-title mb-0">
                    {" "}
                    {TranslationList("Dedline")}
                  </h5>
                </div>
                <div className="col-sm-auto d-flex justify-content-between">
                  <div className="px-2">
                    <div className="search-box">
                      <Input
                        type="text"
                        className="form-control search"
                        placeholder={TranslationList("List Search")}
                        onChange={(e) => handleSearch(e.target.value)}
                      />
                      <i className="ri-search-line search-icon"></i>
                    </div>
                  </div>
                  <div className="d-flex gap-1 flex-wrap">
                    <button
                      type="button"
                      className="btn btn-success add-btn"
                      id="create-btn"
                      onClick={() => tog_varying1()}
                    >
                      <i className="ri-add-line align-bottom me-1"></i>{" "}
                      {TranslationList("Add")}
                    </button>{" "}
                  </div>
                </div>
              </Row>
            </CardHeader>
            {data ? (
              <CardBody>
                <div className="live-preview p-3">
                  <div className="text-muted table-responsive">
                    <Table className="table-bordered align-middle table-nowrap mb-0">
                      <thead>
                        <tr>
                          <th scope="col" className="text-center">
                            {TranslationList("Number")}
                          </th>
                          <th scope="col" className="w-50  text-center">
                            {TranslationList("key")}
                          </th>
                          <th scope="col" className="w-50  text-center">
                            {TranslationList("title")}
                          </th>
                          <th scope="col" className="w-50  text-center">
                            {TranslationList("day")}
                          </th>
                          <th scope="col " className="text-center">
                            {TranslationList("Actions")}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {data?.results?.map((res, i) => (
                          <tr key={res?.id}>
                            <td className="text-center">{i + 1}</td>
                            <td>{res?.key}</td>
                            <td>{res?.title}</td>
                            <td>{res?.day}</td>

                            <td className="text-center">
                              <div className="hstack gap-2 justify-content-center">
                                <button
                                  className="btn btn-sm btn-soft-danger remove-list"
                                  onClick={() => onClickTodoDelete(res)}
                                >
                                  <i className="ri-delete-bin-5-fill align-bottom" />
                                </button>
                                <button
                                  className="btn btn-sm btn-soft-info edit-list"
                                  onClick={() => onClickEdite(res)}
                                >
                                  <i className="ri-pencil-fill align-bottom" />
                                </button>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </CardBody>
            ) : (
              <LoaderContent />
            )}
          </Card>
          <Paginations
            totalItems={data?.pagination?.total}
            perPageChange={(e) => handlePerPageChange(e)}
            currentPage={(e) => handleCurrentPage(e)}
          />
        </Row>
      </Container>
    </div>
  );
}
