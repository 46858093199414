import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
  Button,
  Form,
  FormFeedback,
  Alert,
  Spinner,
  FormGroup,
} from "reactstrap";
import OneID from "../../assets/images/oneid.svg";
import imzo from "../../assets/images/imzo.jpg";

//redux
import { useSelector, useDispatch } from "react-redux";

import { Link } from "react-router-dom";
import withRouter from "../../Components/Common/withRouter";
// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

//Social Media Imports
// import TwitterLogin from "react-twitter-auth"
// actions
import { loginUser } from "../../slices/thunks";

//Import config
import { TranslationList } from "../../slices/settings/listtranslation";
import { toast } from "react-toastify";
import { apiError, reset_login_flag } from "../../slices/auth/login/reducer";
import EImzoLogin from "./EImzoLogin";
export default function FormLogin(props) {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => ({
    user: state.Account.user,
  }));
  const [typeLogin, setTypeLogin] = useState("form_radio");
  const [userLogin, setUserLogin] = useState([]);
  const [passwordShow, setPasswordShow] = useState(false);

  useEffect(() => {
    if (user && user) {
      setUserLogin({
        username: user.user.username,
        password: user.user.confirm_password,
      });
    }
  }, [user]);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      username: userLogin.username,
      password: userLogin.password,
    },
    validationSchema: Yup.object({
      username: Yup.string().required("Please Enter Your Username"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: (values) => {
      dispatch(loginUser(values, props.router.navigate));
    },
  });

  const { error, errorFlag, loading } = useSelector((state) => ({
    error: state.Login.error,
    loading: state.Login.loading,
    errorFlag: state.Login.errorFlag,
  }));

  useEffect(() => {
    if (errorFlag) {
      setTimeout(() => {
        dispatch(reset_login_flag(error));
      }, 3000);
    }
  }, [dispatch, errorFlag]);
  return (
    <div>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          validation.handleSubmit();
          return false;
        }}
        action="#"
      >
        <div className="mb-3">
          <Label htmlFor="username" className="form-label">
            {TranslationList("Username")}
          </Label>
          <Input
            name="username"
            className="form-control"
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.username || ""}
            invalid={
              validation.touched.username && validation.errors.username
                ? true
                : false
            }
          />
          {validation.touched.username && validation.errors.username ? (
            <FormFeedback type="invalid">
              {validation.errors.username}
            </FormFeedback>
          ) : null}
        </div>

        <div className="mb-3">
          <Label className="form-label" htmlFor="password-input">
            {TranslationList("Password")}
          </Label>
          <div className="position-relative auth-pass-inputgroup mb-3">
            <Input
              name="password"
              value={validation.values.password || ""}
              type={passwordShow ? "text" : "password"}
              className="form-control pe-5"
              placeholder="Enter Password"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              invalid={
                validation.touched.password && validation.errors.password
                  ? true
                  : false
              }
            />
            {validation.touched.password && validation.errors.password ? (
              <FormFeedback type="invalid">
                {validation.errors.password}
              </FormFeedback>
            ) : null}
            <button
              className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
              type="button"
              onClick={() => setPasswordShow(!passwordShow)}
              id="password-addon"
            >
              <i className="ri-eye-fill align-middle"></i>
            </button>
          </div>
        </div>
        {/* 
        <div className="form-check">
          <Input
            className="form-check-input"
            type="checkbox"
            value=""
            id="auth-remember-check"
          />
          <Label className="form-check-label" htmlFor="auth-remember-check">
            {TranslationList("Remember me")}
          </Label>
          <div className="float-end">
            <Link to="/forgot-password" className="text-muted">
              {TranslationList("Forgot your password?")}{" "}
            </Link>
          </div>
        </div> */}

        <div className="mt-4">
          <Button
            color="success"
            disabled={error ? null : loading ? true : false}
            className="btn btn-success w-100"
            type="submit"
          >
            {loading ? (
              <Spinner size="sm" className="me-2">
                {" "}
                Loading...{" "}
              </Spinner>
            ) : null}
            {TranslationList("Login")}
          </Button>
        </div>
      </Form>
      {/* <div className="mt-4 text-center">
        <p className="mb-0">
          {TranslationList("Don't have an account?")}
          <Link
            to="/register"
            className="fw-semibold text-primary text-decoration-underline"
          >
            {TranslationList("Register")}
          </Link>{" "}
        </p>
      </div> */}
    </div>
  );
}
