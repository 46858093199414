import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Container,
  Row,
  Table,
  CardHeader,
  Input,
} from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteproject,
  deleteprojectAll,
  getprojectAsyncAuth,
} from "../../slices/project";
import { toast } from "react-toastify";
import DeleteModal from "../../Components/Common/DeleteModal";
import { TranslationList } from "../../slices/settings/listtranslation";
import LoaderContent from "../../Components/Spinner";
import Paginations from "../Tables/DataTables/Pagination";
import ColumnModal from "../../Components/Common/ColumnModal";
import { columnProjectAsync } from "../../slices/project/reducer";
import { Link, Navigate, useNavigate } from "react-router-dom";
import ExportCSVModal from "../../Components/Common/ExportCSVModal";
import ProjectSortable from "../Projects/ProjectSortable";
import reactDragula from "react-dragula";
export default function Project() {
  const [current, setCuttent] = useState();
  const [isExportCSV, setIsExportCSV] = useState(false);
  const [checkSelect, setCheckSelect] = useState(true);
  const [isColumn, setIsColumn] = useState(false);
  const [multiSelect, setMultiSelect] = useState();
  const [deleteModal, setDeleteModal] = useState(false);
  const perpage = localStorage.getItem("perpage") ?? 10;
  const [perPage, setPerpage] = useState(perpage);
  const dispatch = useDispatch();
  const columnsData = useSelector((state) => state.columnProjectTable);
  const projects = useSelector((state) => state.project.data);
  const lang = useSelector((state) => state.lang.data);
  const navigate = useNavigate();

  const toggleColumn = () => {
    setIsColumn(!isColumn);
  };
  useEffect(() => {
    dispatch(
      columnProjectAsync([
        { name: TranslationList("Name"), status: true },
        { name: TranslationList("Project number"), status: true },
        { name: TranslationList("Gov resolution"), status: true },
        { name: TranslationList("Overall Project Cost"), status: true },
        {
          name: TranslationList("Overall Project Cost Currency"),
          status: false,
        },
        { name: TranslationList("Status"), status: false },
        { name: TranslationList("Participating Bank"), status: false },
        { name: TranslationList("Duration"), status: false },
        { name: TranslationList("Grace Period"), status: false },
        { name: TranslationList("Procent Usd Form"), status: false },
        { name: TranslationList("Procent Usd To"), status: false },
        { name: TranslationList("Procent Smw From"), status: false },
        { name: TranslationList("Procent Smw To"), status: false },
        { name: TranslationList("Credeter"), status: false },
        { name: TranslationList("Ecologist"), status: false },
        { name: TranslationList("Moderator"), status: false },
        { name: TranslationList("Coordinator"), status: false },
      ])
    );
    dispatch(getprojectAsyncAuth(`projects/?page=${1}&limit=${perPage}`));
  }, [lang]);

  const handleSearch = (e) => {
    dispatch(getprojectAsyncAuth(`projects/?search=${e}`));
  };

  function tog_varying1() {
    setmodal_varying1(!modal_varying1);
    setCuttent();
    setError();
  }

  const onClickEdite = (item) => {
    setCuttent(item);
  };

  const onClickTodoDelete = (item) => {
    setMultiSelect();
    setCuttent(item);
    setDeleteModal(true);
  };
  const handleSave = (data) => {
    console.log("data", data);
  };
  const handleDelete = async () => {
    if (multiSelect) {
      const data = {
        ids: multiSelect?.map((res) => res.id),
      };
      const response = await deleteprojectAll(`projects/multidelete/`, data);
      if (response?.errorCode) {
        toast.error(TranslationList("An error has occurred!"));
      } else {
        toast.success(TranslationList("Done successfully!"));
        dispatch(getprojectAsyncAuth(`projects/`));
        setMultiSelect();
      }
    } else {
      const response = await deleteproject(`projects/${current?.id}`);
      if (response?.errorCode) {
        toast.error(TranslationList("An error has occurred!"));
      } else {
        toast.success(TranslationList("Done successfully!"));
        dispatch(getprojectAsyncAuth(`projects/`));
      }
    }
    setDeleteModal(false);
  };
  const handleCheck = (e, id) => {
    if (e.target.checked) {
      setCheckSelect(false);
    } else {
      const elementsWithName = document.getElementsByName("check");
      const elementsArray = Array.from(elementsWithName);
      const checkedValues = elementsArray.filter((e) => e.checked);

      if (checkedValues.length < 1) {
        setCheckSelect(true);
        let checkall = document.getElementById("checkAll");
        checkall.checked = false;
      }
    }
  };

  const handleCheckAll = (e) => {
    if (e.target.checked) {
      setCheckSelect(false);
    } else setCheckSelect(true);
    let check = document.getElementsByClassName("checkItem");
    let checkall = document.getElementById("checkAll");
    {
      for (var i = 0; i < check.length; i++) {
        check[i].checked = checkall.checked;
      }
    }
  };
  const handleStatus = (cell) => {
    switch (cell.status) {
      case "completed".toLocaleUpperCase():
        return <span className="badge badge-soft-info">{cell.status} </span>;
      case "draft".toLocaleUpperCase():
        return <span className="badge badge-soft-secondary">Черновик</span>;
      case "removed".toLocaleUpperCase():
        return (
          <span className="badge badge-soft-danger">
            {" "}
            Принята на обработку{" "}
          </span>
        );
      case "in_progress".toLocaleUpperCase():
        return (
          <span className="badge badge-soft-warning"> {cell.status} </span>
        );
      case "under_consideration".toLocaleUpperCase():
        return (
          <span className="badge badge-soft-primary"> {cell.status} </span>
        );
      case "new".toLocaleUpperCase():
        return (
          <span className="badge badge-soft-success"> {cell.status} </span>
        );
      default:
        return (
          <span className="badge badge-soft-success"> {cell.status} </span>
        );
    }
  };
  const handleMultiDelete = () => {
    const elementsWithName = document.getElementsByName("check");
    const elementsArray = Array.from(elementsWithName);
    const checkedValues = elementsArray
      .filter((e) => e.checked) // Filter checked elements
      .map((e) => JSON.parse(e.value)); // Map the checked elements to their values
    console.log(checkedValues);
    setMultiSelect(checkedValues);
    setDeleteModal(true);
  };

  //Pagination begin
  const handleCurrentPage = (e) => {
    dispatch(getprojectAsyncAuth(`projects/?page=${e}&limit=${perPage}`));
  };
  const handlePerPageChange = (e) => {
    setPerpage(e);
    dispatch(getprojectAsyncAuth(`projects/?limit=${e}`));
  };
  //Pagination end
  document.title = `INVESTCONTROL | ${TranslationList("Projects")}`;
  const dragulaDecorator = (componentBackingInstance) => {
    if (componentBackingInstance) {
      let options = {};
      reactDragula([componentBackingInstance], options);
    }
  };
  return (
    <div className="page-content">
      <DeleteModal
        show={deleteModal}
        data={current}
        multiSelect={multiSelect}
        onDeleteClick={handleDelete}
        onCloseClick={() => setDeleteModal(false)}
      />
      {projects?.results && (
        <ExportCSVModal
          show={isExportCSV}
          onCloseClick={() => setIsExportCSV(false)}
          data={projects?.results}
        />
      )}
      <ColumnModal
        show={isColumn}
        handleSaveColumn={handleSave}
        onCloseClick={() => setIsColumn(false)}
        column={columnsData?.columns}
      />

      {columnsData?.columns ? (
        <Container fluid>
          <BreadCrumb
            title={TranslationList("Projects")}
            pageTitle={TranslationList("home")}
          />
          <Row>
            <Card>
              <CardHeader>
                <Row className="align-items-center ">
                  <div className="col-sm">
                    <h5 className="card-title mb-0">
                      {TranslationList("Projects")}
                    </h5>
                  </div>
                  <div className="col-sm-auto d-flex justify-content-between">
                    <div className="d-flex gap-1 flex-wrap">
                      <Link to="/project_add_and_edit">
                        <button
                          type="button"
                          className="btn btn-success add-btn"
                          id="create-btn"
                        >
                          <i className="ri-add-line align-bottom me-1"></i>{" "}
                          {TranslationList("Add")}
                        </button>{" "}
                      </Link>
                    </div>
                  </div>
                </Row>
              </CardHeader>
              {projects?.results ? (
                <CardBody>
                  <div className="live-preview ">
                    <div className="text-muted table-responsive">
                      <div className="d-flex ">
                        <div className="px-1 mb-3">
                          <button
                            type="button"
                            className="btn btn-info"
                            onClick={() => setIsExportCSV(true)}
                          >
                            <i className="ri-file-download-line align-bottom me-1"></i>{" "}
                            {TranslationList("Export")}
                          </button>{" "}
                        </div>
                        <div className="me-auto px-1 mb-3">
                          <button
                            type="button"
                            className="btn btn-danger"
                            onClick={() => handleMultiDelete()}
                            disabled={checkSelect}
                          >
                            <i className="bx bx-trash  me-1"></i>{" "}
                            {TranslationList("Delete")}
                          </button>{" "}
                        </div>
                        <div className="px-1 mb-3">
                          <button
                            type="button"
                            className="btn btn-warning"
                            onClick={toggleColumn}
                          >
                            <i className=" ri-menu-add-line me-1 align-bottom"></i>{" "}
                            {TranslationList("Columns")}
                          </button>{" "}
                        </div>
                        <div className="px-1 mb-3">
                          <div className="search-box">
                            <Input
                              type="text"
                              className="form-control search"
                              placeholder={TranslationList("List Search")}
                              onChange={(e) => handleSearch(e.target.value)}
                            />
                            <i className="ri-search-line search-icon"></i>
                          </div>
                        </div>
                      </div>
                      <Table className="table-bordered align-middle table-nowrap mb-0">
                        <thead>
                          <tr>
                            <th scope="col">
                              <Input
                                className="form-check-input fs-15"
                                type="checkbox"
                                id="checkAll"
                                onChange={handleCheckAll}
                              />
                            </th>
                            {columnsData?.columns[0]?.status && (
                              <th scope="col" className="  text-center">
                                {TranslationList("Name")}
                              </th>
                            )}
                            {columnsData?.columns[1]?.status && (
                              <th scope="col" className="  text-center">
                                {TranslationList("Project number")}
                              </th>
                            )}
                            {columnsData?.columns[2]?.status && (
                              <th scope="col" className="  text-center">
                                {TranslationList("Gov resolution")}
                              </th>
                            )}

                            {columnsData?.columns[3]?.status && (
                              <th scope="col" className="  text-center">
                                {TranslationList("Overall Project Cost")}
                              </th>
                            )}
                            {columnsData?.columns[4]?.status && (
                              <th scope="col" className="  text-center">
                                {TranslationList(
                                  "Overall Project Cost Currency"
                                )}
                              </th>
                            )}
                            {columnsData?.columns[5]?.status && (
                              <th scope="col" className="  text-center">
                                {TranslationList("Status")}
                              </th>
                            )}
                            {columnsData?.columns[6]?.status && (
                              <th scope="col" className="  text-center">
                                {TranslationList("Participating Bank")}
                              </th>
                            )}
                            {columnsData?.columns[7]?.status && (
                              <th scope="col" className="  text-center">
                                {TranslationList("Duration")}
                              </th>
                            )}
                            {columnsData?.columns[8]?.status && (
                              <th scope="col" className="  text-center">
                                {TranslationList("Grace Period")}
                              </th>
                            )}
                            {columnsData?.columns[9]?.status && (
                              <th scope="col" className="  text-center">
                                {TranslationList("Procent Usd Form")}
                              </th>
                            )}
                            {columnsData?.columns[10]?.status && (
                              <th scope="col" className="  text-center">
                                {TranslationList("Procent Usd To")}
                              </th>
                            )}
                            {columnsData?.columns[11]?.status && (
                              <th scope="col" className="  text-center">
                                {TranslationList("Procent Smw From")}
                              </th>
                            )}
                            {columnsData?.columns[12]?.status && (
                              <th scope="col" className="  text-center">
                                {TranslationList("Procent Smw To")}
                              </th>
                            )}
                            {columnsData?.columns[13]?.status && (
                              <th scope="col" className="  text-center">
                                {TranslationList("Credeter")}
                              </th>
                            )}
                            {columnsData?.columns[14]?.status && (
                              <th scope="col" className="  text-center">
                                {TranslationList("Ecologist")}
                              </th>
                            )}
                            {columnsData?.columns[15]?.status && (
                              <th scope="col" className="  text-center">
                                {TranslationList("Moderator")}
                              </th>
                            )}
                            {columnsData?.columns[16]?.status && (
                              <th scope="col" className="  text-center">
                                {TranslationList("Coordinator")}
                              </th>
                            )}
                            <th scope="col " className="text-center">
                              {TranslationList("Actions")}
                            </th>
                          </tr>
                        </thead>
                        <tbody id="task-list" ref={dragulaDecorator}>
                          {projects?.results?.map((res, i) => (
                            <tr key={res?.id}>
                              <td className="fw-medium">
                                <input
                                  className="checkItem form-check-input fs-15"
                                  type="checkbox"
                                  name="check"
                                  value={JSON.stringify(res)}
                                  onChange={(e) => handleCheck(e, res?.id)}
                                />
                              </td>
                              {columnsData?.columns[0]?.status && (
                                <td>
                                  <div className="d-flex flex-wrap">
                                    <div className="text-wrap">{res?.name}</div>
                                  </div>
                                </td>
                              )}
                              {columnsData?.columns[1]?.status && (
                                <td>{res?.projectNumber}</td>
                              )}
                              {columnsData?.columns[2]?.status && (
                                <td>
                                  <div className="d-flex flex-wrap">
                                    <div className="text-wrap">
                                      {res?.govResolution}
                                    </div>
                                  </div>
                                </td>
                              )}

                              {columnsData?.columns[3]?.status && (
                                <td>{res?.overallProjectCost}</td>
                              )}
                              {columnsData?.columns[4]?.status && (
                                <td>
                                  {
                                    res?.overallProjectCostCurrency_detail
                                      ?.symbol
                                  }
                                </td>
                              )}
                              {columnsData?.columns[5]?.status && (
                                <td>{handleStatus(res)}</td>
                              )}
                              {columnsData?.columns[6]?.status && (
                                <td>
                                  {res?.organization_detail?.map((res, i) => (
                                    <div key={i}>{res.name}</div>
                                  ))}
                                </td>
                              )}
                              {columnsData?.columns[7]?.status && (
                                <td>{res?.duration}</td>
                              )}
                              {columnsData?.columns[8]?.status && (
                                <td>{res?.grace_period}</td>
                              )}
                              {columnsData?.columns[9]?.status && (
                                <td>{res?.procent_usd_from}</td>
                              )}
                              {columnsData?.columns[10]?.status && (
                                <td>{res?.procent_usd_to}</td>
                              )}
                              {columnsData?.columns[11]?.status && (
                                <td>{res?.procent_swm_from}</td>
                              )}
                              {columnsData?.columns[12]?.status && (
                                <td>{res?.procent_swm_to}</td>
                              )}
                              {columnsData?.columns[13]?.status && (
                                <td>{res?.specialist_creditor}</td>
                              )}
                              {columnsData?.columns[14]?.status && (
                                <td>{res?.specialist_ecolog}</td>
                              )}
                              {columnsData?.columns[15]?.status && (
                                <td>{res?.specialist_moderator}</td>
                              )}
                              {columnsData?.columns[16]?.status && (
                                <td>{res?.coordinator}</td>
                              )}
                              <td className="text-center">
                                <div className="hstack gap-2 justify-content-center">
                                  <button
                                    className="btn btn-sm btn-soft-danger remove-list"
                                    onClick={() => onClickTodoDelete(res)}
                                  >
                                    <i className="ri-delete-bin-5-fill align-bottom" />
                                  </button>
                                  <Link to={`/project/${res.id}`}>
                                    <button className="btn btn-sm btn-soft-info">
                                      <i className="ri-eye-fill align-bottom" />
                                    </button>
                                  </Link>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </CardBody>
              ) : (
                <LoaderContent />
              )}
            </Card>
            <Paginations
              totalItems={projects?.pagination?.total}
              perPageChange={(e) => handlePerPageChange(e)}
              currentPage={(e) => handleCurrentPage(e)}
            />
          </Row>
        </Container>
      ) : (
        <LoaderContent />
      )}
    </div>
  );
}
