import { createSlice } from "@reduxjs/toolkit";

const columnSlice = createSlice({
  name: "column",
  initialState: {
    columns: null,
    error: {},
  },
  reducers: {
    columnReducer: (state, action) => {
      state.columns = action.payload;
    },
  },
});
export const columnAsync = (data) => async (dispatch) => {
  try {
    dispatch(columnReducer(data));
  } catch (error) {
    // //throw new Error(err);
  }
};
export const { columnReducer } = columnSlice.actions;

export default columnSlice.reducer;
