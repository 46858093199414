import { useEffect, useRef } from "react";
import L from "leaflet";
import "leaflet.markercluster/dist/leaflet.markercluster";
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";
import { useMap } from "react-leaflet";

const customIcon = new L.Icon({
  iconUrl: "https://unpkg.com/leaflet@1.5.1/dist/images/marker-icon.png",
  iconSize: [25, 41],
});

const MarkerCluster = ({ markers, addMarkers }) => {
  const map = useMap();
  const markerClustersRef = useRef(L.markerClusterGroup());
  useEffect(() => {
    const markerClusters = markerClustersRef.current;
    markers.forEach(({ position }) =>
      L.marker(new L.LatLng(position.lat, position.lng), {
        icon: customIcon,
      }).addTo(markerClusters)
    );
    map.addLayer(markerClusters);
    return () => {
      map.removeLayer(markerClusters);
    };
  }, [markers, map, customIcon]);

  useEffect(() => {
    const handleMoveEnd = () => {
      const start = window.performance.now();
      addMarkers();
      const markersToAdd = [];
      markers.forEach(({ position }) => {
        const markerToAdd = L.marker(new L.LatLng(position.lat, position.lng), {
          icon: customIcon,
        });
        if (markerToAdd !== undefined) {
          markersToAdd.push(markerToAdd);
        }
      });
      markerClustersRef.current.clearLayers();
      markerClustersRef.current.addLayers(markersToAdd);
      const end = window.performance.now();
      console.log(`Time of adding markers and clusters: ${end - start}ms`);
    };
    map.on("moveend", handleMoveEnd);
    return () => {
      map.off("moveend", handleMoveEnd);
    };
  }, [markers, map, customIcon, addMarkers]);

  return null;
};

export default MarkerCluster;
