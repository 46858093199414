import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Form,
  Row,
  Col,
  Label,
  Input,
  FormFeedback,
  FormGroup,
  Button,
  Table,
} from "reactstrap";

import Select from "react-select";
import Cleave from "cleave.js/react";
import "cleave.js/dist/addons/cleave-phone.in";
import { useNavigate, useSearchParams } from "react-router-dom";
import Document from "./Document";
import { useDispatch, useSelector } from "react-redux";
import {
  getdetailorganizationAsync,
  getorganizationAsync,
} from "../../slices/organization/organization";
import { getregionAsync } from "../../slices/regions";
import {
  getdistrictAsync,
  getdistrictCurrentAsync,
} from "../../slices/district";
import { getsectorAsync } from "../../slices/sector";
// import { getactivityTypeAsync } from "../../slices/activity-type";
import { getprojectAsync } from "../../slices/project";
import { getpfiAsync, getpfifilialAsync } from "../../slices/pfi";
import {
  addapplicAsync,
  currentapplicAsync,
  editeapplicationAsync,
} from "../../slices/application";
import { toast } from "react-toastify";
import { getfoaAsync } from "../../slices/foa";
import { getcurrencyAsync } from "../../slices/currency";
import { DataService } from "../../helpers/dataService/dataService";
import MaskedInput from "react-input-mask";
import { TranslationList } from "../../slices/settings/listtranslation";
import LoaderContent from "../../Components/Spinner";
export default function PersonalDataEdite() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [state, setState] = useState();
  const [error, setError] = useState();
  const [data, setData] = useState();
  const [searchParams] = useSearchParams();
  const profile = useSelector((state) => state.profile?.data);

  const region = useSelector((state) => state.region?.data?.results);
  const sector = useSelector((state) => state.sector?.data?.results);
  const district = useSelector((state) => state.district?.data?.results);
  const districtCurrent = useSelector(
    (state) => state.district?.current?.results
  );
  const pfibranch = useSelector((state) => state.pfi?.current);

  const pfi = useSelector((state) => state.pfi?.data?.results);
  const foa = useSelector((state) => state.foa?.data?.results);
  const project = useSelector((state) => state.project?.data?.results);
  const currency = useSelector((state) => state.currency?.data?.results);
  const application = useSelector((state) => state.applic.current);
  const [borrowerContribution, setBorrowerContribution] = useState();
  const [loanAnmount, setLoanAnmount] = useState();
  const [projectCost, setprojectCost] = useState();

  useEffect(() => {
    dispatch(getorganizationAsync("organization/"));
    dispatch(getregionAsync("region/"));
    dispatch(getdistrictAsync("district/"));
    dispatch(getsectorAsync("sector/"));
    // dispatch(getactivityTypeAsync("activity-type/"));
    dispatch(getprojectAsync("projects/list"));
    dispatch(getpfiAsync("organization/?type=Bank"));
    dispatch(getfoaAsync("foa/"));
    dispatch(currentapplicAsync(`application/${searchParams.get("id")}`));
    getdata();
  }, []);
  const getdata = useCallback(async () => {
    const response = await DataService.get(
      `application/${searchParams.get("id")}`
    );
    setData(response);
    dispatch(
      getdistrictCurrentAsync(
        `district/?region=${response?.project_region}&limit=100`
      )
    );
  });
  useEffect(() => {
    setBorrowerContribution(parseInt(data?.borrower_contribution?.number));
  }, [data]);
  useEffect(() => {
    dispatch(getcurrencyAsync("currency/"));
    setState(application?.organizationinfo);
  }, [application]);

  const handleSearch = async () => {
    const stirapp = document.getElementById("stirapp").value;
    const response = await getdetailorganizationAsync(
      `organization?inn=${stirapp}`
    );
    setState(response?.results[0]);
  };

  const handledraft = async () => {
    const data = {
      stage: "stage1",
      status: "draft",
      organization: parseInt(document.getElementById("pfi").value) ?? 0,
      organization_branch:
        parseInt(document.getElementById("pfibranch").value) ?? 0,
      project: parseInt(document.getElementById("project").value) ?? 0,

      women: parseInt(document.getElementById("women").value) ?? 0,
      project_description: document.getElementById("project_description").value,
      project_region:
        parseInt(document.getElementById("project_region").value) ?? 0,
      project_district: document.getElementById("project_district").value,
      project_foa: document.getElementById("project_foa").value,

      project_sector: document.getElementById("project_sector").value,
      overall_jobs:
        parseInt(document.getElementById("overall_jobs").value) ?? 0,
      overall_jobs_workplace:
        parseInt(document.getElementById("overall_jobs_workplace").value) ?? 0,
      women_workplace:
        parseInt(document.getElementById("women_workplace").value) ?? 0,
      pfi: document.getElementById("pfi").value,
      project_cost: {
        number: parseInt(projectCost.split(" ").join("")) ?? 0,
        currency: document.getElementById("project_cost_currency").value,
      },
      borrower_contribution: {
        number: parseInt(borrowerContribution.split(" ").join("")),
        currency: document.getElementById("borrower_contribution_currency")
          .value,
      },
      loan_amount_to_receive: {
        number: parseInt(loanAnmount.split(" ").join("")) ?? 0,
        currency: document.getElementById("loan_amount_to_receive_currency")
          .value,
      },
    };
    const response = await editeapplicationAsync(
      `application/${searchParams.get("id")}`,
      data
    );
    if (!response?.errorCode) {
      toast.success(TranslationList("Application successfully created!"));
    } else {
      setError(response?.message);
    }
  };
  const SelectChange = (id) => {
    dispatch(getdistrictCurrentAsync(`district/?region=${id}&limit=100`));
  };
  const SelectChangeFilial = (id) => {
    dispatch(
      getpfifilialAsync(
        `organization-branch/?organization=${id}&limit=100&type=Bank`
      )
    );
  };
  return (
    <div>
      {data ? (
        <Form
          className="needs-validation"
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          {/* Заявитель */}
          <h6>{TranslationList("Applicant")}</h6>
          <React.Fragment>
            <Row>
              <Col md="6">
                <FormGroup className="mb-3">
                  <Label htmlFor="numberapp">
                    {TranslationList("Application number")}
                  </Label>
                  <Input
                    name="numberapp"
                    type="text"
                    className="form-control"
                    id="numberapp"
                    disabled
                    defaultValue={data?.nomer}
                  />

                  <FormFeedback type="invalid"></FormFeedback>
                </FormGroup>
              </Col>
              <Col md="6" className="d-flex align-items-center ">
                <FormGroup className="mb-3 me-4 ">
                  <Label htmlFor="stirapp">
                    {TranslationList("TIN of the enterprise")}
                  </Label>
                  <Input
                    name="stirapp"
                    type="text"
                    className="form-control"
                    id="stirapp"
                    disabled
                    defaultValue={profile?.organizationinfo?.inn}
                  />
                  <FormFeedback type="invalid"></FormFeedback>
                </FormGroup>
                <div className="mt-auto mb-3 ">
                  <button className=" btn btn-primary" onClick={handleSearch}>
                    {TranslationList("Check")}
                  </button>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <FormGroup className="mb-3">
                  <Label htmlFor="name">
                    {TranslationList("Name of the organization")}
                  </Label>
                  <Input
                    name="name"
                    type="text"
                    className="form-control"
                    defaultValue={profile?.organizationinfo?.name}
                    disabled
                    id="name"
                  />
                  <FormFeedback type="invalid"></FormFeedback>
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup className="mb-3">
                  <Label htmlFor="legal_status">
                    {TranslationList("Legal status")}
                  </Label>
                  <Input
                    name="legal_status"
                    id="legal_status"
                    type="text"
                    className="form-control"
                    defaultValue={profile?.organizationinfo?.legal_status}
                    disabled
                  />
                  <FormFeedback type="invalid"></FormFeedback>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <FormGroup className="mb-3">
                  <Label htmlFor="address">
                    {TranslationList("Organization address")}
                  </Label>
                  <Input
                    defaultValue={profile?.organizationinfo?.address}
                    disabled
                    name="address"
                    id="address"
                    type="text"
                    className="form-control"
                  />
                  <FormFeedback type="invalid"></FormFeedback>
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup className="mb-3">
                  <Label htmlFor="region_detail">
                    {TranslationList("Region")}
                  </Label>
                  <Input
                    defaultValue={
                      profile?.organizationinfo?.region_detail?.name
                    }
                    disabled
                    name="region_detail"
                    id="region_detail"
                    type="text"
                    className="form-control"
                  />
                  <FormFeedback type="invalid"></FormFeedback>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <FormGroup className="mb-3">
                  <Label htmlFor="district_detail">
                    {TranslationList("District")}
                  </Label>
                  <Input
                    defaultValue={
                      profile?.organizationinfo?.district_detail?.name
                    }
                    disabled
                    name="district_detail"
                    id="district_detail"
                    type="text"
                    className="form-control"
                  />
                  <FormFeedback type="invalid"></FormFeedback>
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup className="mb-3">
                  <Label htmlFor="mahalla">{TranslationList("Mahalla")}</Label>
                  <Input
                    defaultValue={profile?.organizationinfo?.mahalla}
                    disabled
                    name="mahalla"
                    id="mahalla"
                    type="text"
                    className="form-control"
                  />
                  <FormFeedback type="invalid"></FormFeedback>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <FormGroup className="mb-3">
                  <Label htmlFor="supervisor">
                    {TranslationList("Head of the organization")}
                  </Label>
                  <Input
                    name="supervisor"
                    id="supervisor"
                    type="text"
                    className="form-control"
                    defaultValue={profile?.organizationinfo?.supervisor}
                    disabled
                  />
                  <FormFeedback type="invalid"></FormFeedback>
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup className="mb-3">
                  <Label htmlFor="pinfl">
                    {TranslationList("PINFL Manager")}
                  </Label>
                  <Input
                    name="pinfl"
                    id="pinfl"
                    type="text"
                    className="form-control"
                    defaultValue={profile?.organizationinfo?.pinfl}
                    disabled
                  />
                  <FormFeedback type="invalid"></FormFeedback>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <FormGroup className="mb-3">
                  <Label htmlFor="inn">
                    {TranslationList("TIN of the Head")}
                  </Label>
                  <Input
                    name="inn"
                    id="inn"
                    type="text"
                    className="form-control"
                    defaultValue={profile?.organizationinfo?.inn}
                    disabled
                  />
                  <FormFeedback type="invalid"></FormFeedback>
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup className="mb-3">
                  <Label htmlFor="supervisor_phone">
                    {TranslationList("Manager's phone number")}
                  </Label>
                  <Input
                    name="supervisor_phone"
                    id="supervisor_phone"
                    type="text"
                    className="form-control"
                    defaultValue={profile?.organizationinfo?.supervisor_phone}
                    disabled
                  />
                  <FormFeedback type="invalid"></FormFeedback>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Label className="form-label mb-0">
                  {TranslationList("Gender of the Executive")}
                </Label>
                <Row>
                  <Col md={6}>
                    <FormGroup className="mb-3">
                      <Input
                        className="form-check-input me-2"
                        type="radio"
                        name="supervisor_gender"
                        id="flexRadioDefault1"
                        defaultChecked={
                          profile?.organizationinfo?.supervisor_gender ==
                            "Male" && true
                        }
                      />
                      <Label
                        className="form-check-label ml-3"
                        htmlFor="flexRadioDefault1"
                      >
                        {TranslationList("Male")}
                      </Label>
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup className="mb-3">
                      <Input
                        className="form-check-input me-2"
                        type="radio"
                        name="supervisor_gender"
                        id="flexRadioDefault2"
                        defaultChecked={
                          profile?.organizationinfo?.supervisor_gender ==
                            "Female" && true
                        }
                      />
                      <Label
                        className="form-check-label ml-3"
                        htmlFor="flexRadioDefault2"
                      >
                        {TranslationList("Female")}
                      </Label>
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
              <Col md={6}>
                <Label className="form-label mb-0">
                  {TranslationList("Number of employees")}
                </Label>
                <Row>
                  <Col md="6" className="d-flex align-items-center">
                    <Label
                      className="form-check-label me-3"
                      htmlFor="overall_jobs"
                    >
                      {TranslationList("General")}
                    </Label>
                    <div>
                      <Input
                        className="form-control"
                        type="number"
                        name="overall_jobs"
                        id="overall_jobs"
                        invalid={error?.overall_jobs && true}
                        defaultValue={data?.overall_jobs}
                      />
                      <FormFeedback type="invalid">
                        {error?.overall_jobs}
                      </FormFeedback>
                    </div>
                  </Col>
                  <Col md="6" className="d-flex align-items-center">
                    <Label className="form-check-label me-3" htmlFor="women">
                      {TranslationList("Female")}
                    </Label>
                    <div>
                      <Input
                        className="form-control"
                        type="number"
                        name="women"
                        id="women"
                        invalid={error?.women && true}
                        defaultValue={data?.women}
                      />
                      <FormFeedback type="invalid">{error?.women}</FormFeedback>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Label className="form-label mb-0">
                {TranslationList("Organization founders")}
              </Label>
              <div className="p-3">
                <Table className="text-muted table-bordered align-middle form-label table-nowrap mb-0 ">
                  <thead>
                    <tr>
                      <th style={{ fontWeight: 400 }} scope="col">
                        №
                      </th>
                      <th style={{ fontWeight: 400 }} scope="col">
                        {TranslationList("Founder")}
                      </th>
                      <th style={{ fontWeight: 400 }} scope="col">
                        {TranslationList("Share in the authorized capital")}
                      </th>
                    </tr>
                  </thead>
                  {/* <tbody>
                  <tr>
                    <td className="fw-medium">01</td>
                    <td>Implement new UX</td>
                    <td>
                      <span className="badge badge-soft-primary">Backlog</span>
                    </td>
                  </tr>
                </tbody> */}
                </Table>
              </div>
            </Row>
          </React.Fragment>
          <Row>
            <Col md="6">
              <FormGroup className="mb-3">
                <Label htmlFor="addressapp">
                  {TranslationList("Project Implementation Sector")}{" "}
                </Label>
                <Input
                  type="select"
                  className="form-select mb-3"
                  aria-label="Default select example"
                  id="project_sector"
                  defaultValue={data?.project_sector}
                  disabled
                >
                  {sector?.map((res) => (
                    <option key={res.id} value={res.id}>
                      {res.name}{" "}
                    </option>
                  ))}
                </Input>
                <FormFeedback type="invalid"></FormFeedback>
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup className="mb-3">
                <Label htmlFor="project_foa">
                  {TranslationList("Type of project activity")}{" "}
                </Label>
                <Input
                  type="select"
                  className="form-select mb-3"
                  aria-label="Default select example"
                  id="project_foa"
                  defaultValue={data?.project_foa}
                >
                  {foa?.map((res) => (
                    <option key={res.id} value={res.id}>
                      {res.name}{" "}
                    </option>
                  ))}
                </Input>
                <FormFeedback type="invalid"></FormFeedback>
              </FormGroup>
            </Col>
          </Row>
          {/* Проект */}
          <h6>{TranslationList("Project")}</h6>
          <React.Fragment>
            <Row>
              <FormGroup className="mb-3">
                <Label htmlFor="project">
                  {TranslationList("Credit line")}
                </Label>
                <Input
                  disabled
                  type="select"
                  className="form-select mb-3"
                  aria-label="Default select example"
                  id="project"
                  defaultValue={data?.project}
                >
                  {project?.map((res) => (
                    <option key={res.id} value={res.id}>
                      {res.projectNumber} - {res.name}
                    </option>
                  ))}
                </Input>
                <FormFeedback type="invalid"></FormFeedback>
              </FormGroup>
            </Row>
            <Row>
              <Col md="6">
                <FormGroup className="mb-3">
                  <Label htmlFor="pfi">
                    {TranslationList("Participating Bank")}
                  </Label>
                  <Input
                    type="select"
                    className="form-select mb-3"
                    aria-label="Default select example"
                    id="pfi"
                    onChange={(e) => SelectChangeFilial(e.target.value)}
                    defaultValue={data?.organization}
                  >
                    {pfi?.map((res) => (
                      <option key={res.id} value={res.id}>
                        {res.name}{" "}
                      </option>
                    ))}
                  </Input>
                  <FormFeedback type="invalid"></FormFeedback>
                </FormGroup>
              </Col>

              <Col md="6">
                <FormGroup className="mb-3">
                  <Label htmlFor="pfi">
                    {TranslationList("Participating bank branch")}{" "}
                  </Label>
                  <Input
                    type="select"
                    className="form-select mb-3"
                    aria-label="Default select example"
                    id="pfibranch"
                    defaultValue={data?.organization_branch}
                  >
                    {pfibranch?.results?.map((res) => (
                      <option key={res.id} value={res.id}>
                        {res.name}{" "}
                      </option>
                    ))}
                  </Input>
                  <FormFeedback type="invalid"></FormFeedback>
                </FormGroup>
              </Col>
            </Row>
          </React.Fragment>

          {/* Кредит */}
          <h6>{TranslationList("Credit")}</h6>
          <React.Fragment>
            <Row>
              <Col md="6">
                <FormGroup className="mb-3">
                  <Label htmlFor="region">
                    {TranslationList(
                      "Area where the project will be implemented"
                    )}
                  </Label>
                  <Input
                    type="select"
                    className="form-select mb-3"
                    aria-label="Default select example"
                    id="project_region"
                    defaultValue={data?.project_region}
                    onChange={(e) => SelectChange(e.target.value)}
                  >
                    {region?.map((res) => (
                      <option key={res.id} value={res.id}>
                        {res.name}{" "}
                      </option>
                    ))}
                  </Input>{" "}
                  <FormFeedback type="invalid"></FormFeedback>
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup className="mb-3">
                  <Label htmlFor="statusapp">
                    {TranslationList(
                      "The area where the project will be implemented"
                    )}
                  </Label>
                  <Input
                    type="select"
                    className="form-select mb-3"
                    aria-label="Default select example"
                    id="project_district"
                    defaultValue={data?.project_district}
                  >
                    {districtCurrent?.map((res) => (
                      <option key={res.id} value={res.id}>
                        {res.name}{" "}
                      </option>
                    ))}
                  </Input>
                  <FormFeedback type="invalid"></FormFeedback>
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col md="6">
                <FormGroup className="mb-3">
                  <Label htmlFor="borrower_contribution">
                    {TranslationList("Borrower's contribution")}{" "}
                  </Label>
                  <div className="d-flex w-100 justify-content-between">
                    <MaskedInput
                      mask="999 999 999 999 999 999 999 999"
                      className="form-control me-2 w-75"
                      onChange={(e) => setBorrowerContribution(e.target.value)}
                      maskChar=" "
                      value={borrowerContribution}
                      tag={<Input type="number" name="borrower_contribution" />}
                    />

                    <Input
                      type="select"
                      className="form-select w-25"
                      aria-label="Default select example"
                      defaultValue={data?.borrower_contribution?.currency}
                      id="borrower_contribution_currency"
                    >
                      {currency?.map((res) => (
                        <option key={res.id} value={res.id}>
                          {res.symbol}{" "}
                        </option>
                      ))}
                    </Input>
                  </div>
                  <FormFeedback type="invalid"></FormFeedback>
                </FormGroup>
              </Col>
              <Col md={6}>
                <Label className="form-label mb-0">
                  {TranslationList("Number of jobs")}
                </Label>
                <Row>
                  <Col md="6" className="d-flex align-items-center">
                    <Label
                      className="form-check-label me-3"
                      htmlFor="overall_jobs_workplace"
                    >
                      {TranslationList("General")}
                    </Label>
                    <div>
                      <Input
                        className="form-control"
                        type="number"
                        name="overall_jobs_workplace"
                        id="overall_jobs_workplace"
                        defaultValue={data?.overall_jobs_workplace}
                        invalid={error?.overall_jobs_workplace && true}
                      />
                      <FormFeedback type="invalid">
                        {error?.overall_jobs_workplace}
                      </FormFeedback>
                    </div>
                  </Col>
                  <Col md="6" className="d-flex align-items-center">
                    <Label
                      className="form-check-label me-3"
                      htmlFor="women_workplace"
                    >
                      {TranslationList("Female")}
                    </Label>
                    <div>
                      <Input
                        className="form-control"
                        type="number"
                        name="women_workplace"
                        id="women_workplace"
                        defaultValue={data?.women_workplace}
                        invalid={error?.women_workplace && true}
                      />
                      <FormFeedback type="invalid">
                        {error?.women_workplace}
                      </FormFeedback>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <FormGroup className="mb-3">
                  <Label htmlFor="loan_amount_to_receive">
                    {TranslationList("Loan amount to receive")}
                  </Label>
                  <div className="d-flex w-100 justify-content-between">
                    <MaskedInput
                      mask="999 999 999 999 999 999 999 999"
                      className="form-control me-2 w-75"
                      onChange={(e) => setLoanAnmount(e.target.value)}
                      defaultValue={data?.loan_amount_to_receive?.number}
                      maskChar=" "
                      tag={
                        <Input type="number" name="loan_amount_to_receive" />
                      }
                    />

                    <select
                      className="form-select w-25"
                      aria-label="Default select example"
                      id="loan_amount_to_receive_currency"
                      defaultValue={data?.loan_amount_to_receive?.currency}
                    >
                      {currency?.map((res) => (
                        <option key={res.id} value={res.id}>
                          {res.symbol}{" "}
                        </option>
                      ))}
                    </select>
                  </div>
                  <FormFeedback type="invalid"></FormFeedback>
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup className="mb-3">
                  <Label htmlFor="project_cost">
                    {TranslationList("Project cost")}{" "}
                  </Label>
                  <div className="d-flex w-100 justify-content-between">
                    <MaskedInput
                      onChange={(e) => setprojectCost(e.target.value)}
                      mask="999 999 999 999 999 999 999 999"
                      className="form-control me-2 w-75"
                      defaultValue={data?.project_cost?.number}
                      maskChar=" "
                      tag={
                        <Input type="number" name="loan_amount_to_receive" />
                      }
                    />

                    <Input
                      type="select"
                      className="form-select w-25"
                      aria-label="Default select example"
                      defaultValue={
                        data?.project_cost ? data?.project_cost?.currency : null
                      }
                      id="project_cost_currency"
                    >
                      {currency?.map((res) => (
                        <option key={res.id} value={res.id}>
                          {res.symbol}
                        </option>
                      ))}
                    </Input>
                  </div>
                  <FormFeedback type="invalid"></FormFeedback>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <FormGroup className="mb-3 mt-3">
                <Label htmlFor="project_description">
                  {TranslationList("Brief description of the project")}
                </Label>
                <Input
                  name="project_description"
                  type="textarea"
                  className="form-control"
                  id="project_description"
                  defaultValue={data?.project_description}
                  placeholder="Приобретение оборудования тепличного комплекса для выращивания овощей."
                />

                <FormFeedback type="invalid"></FormFeedback>
              </FormGroup>
            </Row>
          </React.Fragment>
          {/* <Document /> */}
          <div className=" d-flex justify-content-end mt-3">
            <button
              className="btn btn-danger me-4"
              onClick={() => navigate("/applications")}
            >
              {TranslationList("Close form")}
            </button>
            <button
              className="btn btn-primary"
              type="button"
              onClick={handledraft}
            >
              {TranslationList("Save")}
            </button>
          </div>
        </Form>
      ) : (
        <LoaderContent />
      )}
    </div>
  );
}
