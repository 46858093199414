import { createSlice } from "@reduxjs/toolkit";
import { DataService } from "../../helpers/dataService/dataService";

export const profileSlide = createSlice({
  name: "profile",
  initialState: {
    data: null,
  },
  reducers: {
    getprofile: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const getprofileAsync = (data) => async (dispatch) => {
  try {
    const datas = await DataService.get(data);
    dispatch(getprofile(datas));
  } catch (error) {
    // //throw new Error(err);
  }
};

export const { getprofile } = profileSlide.actions;
export default profileSlide.reducer;
