import React, { useEffect, useState } from "react";
import {
  Container,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
} from "reactstrap";
import classnames from "classnames";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { useSelector, useDispatch } from "react-redux";
import { withTranslation } from "react-i18next";
import Document from "./Document";
import Chronology from "./Chronology";
import Actions from "./Actions";
import PersonalDataShow from "./PersonalDataShow";
import { useSearchParams } from "react-router-dom";
import { currentapp } from "../../slices/application";
import ApplicationStep from "./ApplicationStep";
import { TranslationList } from "../../slices/settings/listtranslation";
import { DataService } from "../../helpers/dataService/dataService";
import { getnotificationAsync } from "../../slices/notification";
import LoaderContent from "../../Components/Spinner";
import PdfShow from "./PdfShow";
import Signs from "./Signs";

const ShowApplication = (props) => {
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.profile?.data);
  const [signer, setSigner] = useState();
  const [reports, setReports] = useState();
  const [searchParams] = useSearchParams();
  const [activeTab, setActiveTab] = useState("1");
  const [application, setApplication] = useState();
  const attachmentCount = useSelector(
    (state) => state.attachment.data?.pagination?.total
  );
  const getData = async () => {
    if (searchParams.get("show_id")) {
      const response = await DataService.get(
        `application/${searchParams.get("show_id")}`
      );
      setApplication(response);
      dispatch(currentapp(response));
      dispatch(getnotificationAsync("notification/?is_read=0"));

      const responseReport = await DataService.get(
        `reports/?application=${searchParams.get("show_id")}`
      );
      const lastRes =
        responseReport?.results[responseReport?.results?.length - 1];
      setReports(lastRes);
      if (lastRes?.id) {
        const responseSign = await DataService.get(
          `subscriber/?report=${lastRes?.id}`
        );
        setSigner(responseSign?.results);
      }
    }
  };
  useEffect(() => {
    getData();
  }, []);
  useEffect(() => {
    dispatch(getnotificationAsync("notification/?is_read=0"));
  }, [searchParams.get("show_id")]);
  const toggleTab = (tab, type) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };
  document.title = `INVESTCONTROL | ${TranslationList("Show_application")}`;

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb
          pageTitle={TranslationList("Applications")}
          title={TranslationList("Show_application")}
        />
        {application ? (
          <Row>
            <div className="col-xl-10 col-lg-9">
              <div>
                <div className="card">
                  <div className="card-header border-0">
                    <div className="row align-items-center">
                      <div className="col">
                        <Nav
                          className="nav-tabs-custom card-header-tabs border-bottom-0 nav-border-top nav-justified"
                          role="tablist"
                        >
                          <NavItem>
                            <NavLink
                              className={classnames(
                                { active: activeTab === "1" },
                                "fw-semibold"
                              )}
                              key={1}
                              onClick={() => {
                                toggleTab("1", "all");
                              }}
                              href="#"
                            >
                              {TranslationList("Application information")}
                            </NavLink>
                          </NavItem>
                          {application?.stage != "stageanketa" && (
                            <NavItem>
                              <NavLink
                                key={2}
                                className={classnames(
                                  { active: activeTab === "2" },
                                  "fw-semibold"
                                )}
                                onClick={() => {
                                  toggleTab("2", "published");
                                }}
                                href="#"
                              >
                                {TranslationList("Documents")}
                                <span className="badge badge-soft-danger align-middle rounded-pill ms-1">
                                  {attachmentCount}
                                </span>
                              </NavLink>
                            </NavItem>
                          )}
                          <NavItem>
                            <NavLink
                              className={classnames(
                                { active: activeTab === "3" },
                                "fw-semibold"
                              )}
                              key={3}
                              onClick={() => {
                                toggleTab("3", "draft");
                              }}
                              href="#"
                            >
                              {TranslationList("Chranology")}
                            </NavLink>
                          </NavItem>

                          <NavItem>
                            <NavLink
                              className={classnames(
                                { active: activeTab === "4" },
                                "fw-semibold"
                              )}
                              key={4}
                              onClick={() => {
                                toggleTab("4", "map");
                              }}
                              href="#"
                            >
                              {TranslationList("Application chranology map")}{" "}
                            </NavLink>
                          </NavItem>
                        </Nav>
                      </div>
                      <div className="col-auto"></div>
                    </div>
                  </div>
                  <div className="card-body pt-3">
                    <TabContent activeTab={activeTab}>
                      <TabPane tabId="1" id="home" key="1">
                        <PersonalDataShow />
                      </TabPane>

                      <TabPane tabId="2" id="product" key="2">
                        <Document />
                      </TabPane>

                      <TabPane tabId="3" id="messages" key="3">
                        <Container fluid>
                          <Chronology />
                        </Container>
                      </TabPane>
                      <TabPane tabId="4" id="map" key="4">
                        <Container fluid>
                          <ApplicationStep />
                        </Container>
                      </TabPane>
                    </TabContent>
                  </div>
                </div>
              </div>
            </div>
            <Col xl={2} lg={3}>
              <Actions />
            </Col>
          </Row>
        ) : (
          <LoaderContent />
        )}
      </Container>
    </div>
  );
};

export default withTranslation()(ShowApplication);
