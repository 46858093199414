import { createSlice } from "@reduxjs/toolkit";
import { DataService } from "../../helpers/dataService/dataService";
export const translationsSlide = createSlice({
  name: "translations",
  initialState: {
    list: null,
    data: null,
    error: null,
    current: null,
    item: [],
    load: false,
  },
  reducers: {
    gettranslationList: (state, action) => {
      state.list = action.payload;

      localStorage.setItem(`translate`, JSON.stringify(action.payload));
    },
    gettranslationListAll: (state, action) => {
      localStorage.setItem(
        `translate${action.payload?.lan}`,
        JSON.stringify(action.payload?.data)
      );
    },
    getload: (state, action) => {
      state.load = action.payload;
    },
    gettranslations: (state, action) => {
      state.data = action.payload;
    },
    edittranslations: (state, action) => {
      state.item = action.payload;
    },
  },
});
export const getDataTranslate = () => {};
export const addtranslationsAsync = async (url, data) => {
  try {
    const datas = await DataService.post(url, data);
    return datas;
  } catch (error) {
    return error;
  }
};
export const gettranslationsAsync = (data) => async (dispatch) => {
  try {
    dispatch(getload(true));

    const datas = await DataService.get(data);
    dispatch(gettranslations(datas));
    dispatch(getload(false));
  } catch (error) {
    // //throw new Error(err);
  }
};
export const gettranslationsListAsync = (data) => async (dispatch) => {
  try {
    const datas = await DataService.getNoAuth(data);
    dispatch(gettranslationList(datas));
  } catch (error) {
    // //throw new Error(err);
  }
};
export const gettranslationsListAll = (data, lan) => async (dispatch) => {
  try {
    const datas = await DataService.getNoAuth(data);
    dispatch(gettranslationListAll({ data: datas, lan: lan }));
  } catch (error) {
    // //throw new Error(err);
  }
};
export const edittranslationsAsync = async (url, data) => {
  try {
    const datas = await DataService.put(url, data);
    return datas;
  } catch (error) {
    return error;
  }
};
export const deletetranslations = async (data) => {
  try {
    const datas = await DataService.delete(data);
    return datas;
  } catch (error) {
    return error;
  }
};

export const {
  gettranslations,
  edittranslations,
  gettranslationList,
  gettranslationListAll,
  getload,
} = translationsSlide.actions;
export default translationsSlide.reducer;
