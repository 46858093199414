import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Container,
  Row,
  Table,
  CardHeader,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Input,
  Button,
  FormFeedback,
} from "reactstrap";
import { Link } from "react-router-dom";
import classnames from "classnames";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { useDispatch, useSelector } from "react-redux";
import {
  adddocumentAsync,
  deletedocument,
  editdocumentAsync,
  getdocumentAsync,
  getorganizationCurrentAsync,
} from "../../slices/document";
import { toast } from "react-toastify";
import DeleteModal from "../../Components/Common/DeleteModal";

import { TranslationList } from "../../slices/settings/listtranslation";
import { getorganizationAsync } from "../../slices/organization";
import { DataService } from "../../helpers/dataService/dataService";
import { getprojectAsync } from "../../slices/project";
import LoaderContent from "../../Components/Spinner";
import Paginations from "../Tables/DataTables/Pagination";

export default function Document() {
  const [modal_varying1, setmodal_varying1] = useState(false);
  const [nameUZ, setNameUZ] = useState();
  const [nameRU, setNameRU] = useState();
  const [nameEN, setNameEN] = useState();
  const [type, setType] = useState();
  const [error, setError] = useState();
  const [current, setCuttent] = useState();
  //   const [code, setCode] = useState();
  const [deleteModal, setDeleteModal] = useState(false);
  const [branchOrg, setBranchOrg] = useState();
  const perpage = localStorage.getItem("perpage") ?? 10;
  const [perPage, setPerpage] = useState(perpage);
  const dispatch = useDispatch();
  const documents = useSelector((state) => state.document?.data);
  const projectsfull = useSelector((state) => state.project?.data?.results);
  const lang = useSelector((state) => state.lang.data);
  useEffect(() => {
    dispatch(getdocumentAsync(`document/?page=${1}&limit=${perPage}`));
    dispatch(getorganizationAsync("organization/?limit=100"));
    dispatch(getprojectAsync("projects/list"));
  }, [lang]);
  const handleSearch = (e) => {
    dispatch(getdocumentAsync(`document/?search=${e}`));
  };
  const SelectChange = async (id) => {
    try {
      const response = await DataService.get(
        `organization-branch/?organization=${id}&limit=100`
      );
      setBranchOrg(response?.results);
    } catch (err) {
      toast.error(err);
    }
  };
  //   const SelectChangePro =async (id) => {
  //     try{
  //     const response= await DataService.get(`project=${id}&limit=100`);
  //           setProject(response?.results)
  //     }
  //     catch(err){
  //         toast.error(err)
  //     }
  //   };
  function tog_varying1() {
    setmodal_varying1(!modal_varying1);
    setCuttent();
    setError();
  }
  const handleAdd = async () => {
    const dataDocument = {
      name_uz: nameUZ,
      name_ru: nameRU,
      name_en: nameEN,
      type: type,
      application: document.getElementById("project").value,
      // organization: document.getElementById("organization").value,
      // organization_branch: document.getElementById("organization_branch").value,
    };
    const response = await adddocumentAsync("document/", dataDocument);
    if (response?.errorCode) {
      setError(response?.message);
      toast.error(TranslationList("An error has occurred!"));
    } else {
      toast.success(TranslationList("Done successfully!"));
      dispatch(getdocumentAsync(`document/?page=${1}&limit=${perPage}`));
      setmodal_varying1(false);
    }
  };
  const onClickEdite = (item) => {
    setmodal_varying1(true);
    setCuttent(item);
  };
  const handleEdite = async () => {
    const dataDocument = {
      name_uz: document.getElementById("name_uz").value,
      name_ru: document.getElementById("name_ru").value,
      name_en: document.getElementById("name_en").value,
      type: document.getElementById("type").value,
      application: document.getElementById("project").value,
      // organization: document.getElementById("organization").value,
      // organization_branch: document.getElementById("organization_branch").value,
    };

    const response = await editdocumentAsync(
      `document/${current?.id}`,
      dataDocument
    );
    if (response?.errorCode) {
      setError(response?.message);
      toast.error(TranslationList("An error has occurred!"));
    } else {
      toast.success(TranslationList("Done successfully!"));
      dispatch(getdocumentAsync(`document/?limit=100`));
      setmodal_varying1(false);
    }
  };
  const onClickTodoDelete = (item) => {
    setCuttent(item);
    setDeleteModal(true);
  };
  const handleDelete = async () => {
    const response = await deletedocument(`document/${current?.id}`);
    if (response?.errorCode) {
      toast.error(TranslationList("An error has occurred!"));
    } else {
      toast.success(TranslationList("Done successfully!"));
      dispatch(getdocumentAsync(`document/`));
    }
    setDeleteModal(false);
  };
  //Pagination begin

  const handleCurrentPage = (e) => {
    dispatch(getdocumentAsync(`document/?page=${e}&limit=${perPage}`));
  };
  const handlePerPageChange = (e) => {
    setPerpage(e);
    dispatch(getdocumentAsync(`document/?limit=${e}`));
  };
  //Pagination end
  document.title = `INVESTCONTROL | ${TranslationList("Documents")}`;

  return (
    <div className="page-content">
      <DeleteModal
        show={deleteModal}
        data={current?.name}
        onDeleteClick={handleDelete}
        onCloseClick={() => setDeleteModal(false)}
      />
      <Modal
        isOpen={modal_varying1}
        toggle={() => {
          tog_varying1();
        }}
        id="exampleModal"
      >
        <ModalHeader
          toggle={() => {
            tog_varying1();
          }}
        >
          {current ? TranslationList("Edit") : TranslationList("Add")}{" "}
        </ModalHeader>
        <div className="modal-body">
          <form>
            <div className="mb-3">
              <label htmlFor="customer-name" className="col-form-label">
                {TranslationList("uz")}:
              </label>
              <Input
                id="name_uz"
                type="text"
                className="form-control"
                onChange={(e) => setNameUZ(e.target.value)}
                defaultValue={current?.name_uz}
                invalid={error?.name_uz ? true : false}
              />
              <FormFeedback type="invalid">{error?.name_uz}</FormFeedback>
            </div>
            <div className="mb-3">
              <label htmlFor="customer-name" className="col-form-label">
                {TranslationList("ru")}:
              </label>
              <Input
                type="text"
                id="name_ru"
                className="form-control"
                onChange={(e) => setNameRU(e.target.value)}
                defaultValue={current?.name_ru}
                invalid={error?.name_ru ? true : false}
              />
              <FormFeedback type="invalid">{error?.name_ru}</FormFeedback>
            </div>
            <div className="mb-3">
              <label htmlFor="customer-name" className="col-form-label">
                {TranslationList("eng")}:
              </label>
              <Input
                type="text"
                id="name_en"
                className="form-control"
                onChange={(e) => setNameEN(e.target.value)}
                defaultValue={current?.name_en}
                invalid={error?.name_en ? true : false}
              />
              <FormFeedback type="invalid">{error?.name_en}</FormFeedback>
            </div>
            <div className="mb-3">
              <label htmlFor="department" className="col-form-label">
                {TranslationList("Project")} :
              </label>
              <select
                className="form-select mb-3 col-6"
                aria-label="Default select example"
                id="project"
                defaultValue={current?.application}
                onChange={(e) => SelectChange(e.target.value)}
                placeholder="assa"
              >
                {projectsfull?.map((res) => (
                  <option key={res.id} value={res.id} className="w-100">
                    <p>{res.name}</p>
                  </option>
                ))}
              </select>
            </div>
            <div className="mb-3">
              <label htmlFor="customer-name" className="col-form-label">
                {TranslationList("Type")} :
              </label>
              <select
                className="form-select mb-3 col-6"
                aria-label="Default select example"
                id="type"
                defaultValue={current?.type?.id}
                onChange={(e) => setType(e.target.value)}
              >
                <option value="Bank">{TranslationList("Bank")}</option>
                <option value="ISCAD">{TranslationList("ISCAD")}</option>
                <option value="Bank ISCAD">
                  {TranslationList("Bank ISCAD")}
                </option>
              </select>
            </div>
            {/* <div className="mb-3">
              <label htmlFor="department" className="col-form-label">
                Organization :
              </label>
              <select
                className="form-select mb-3 col-6"
                aria-label="Default select example"
                id="organization"
                defaultValue={current?.organization}
                onChange={(e) => SelectChange(e.target.value)}
                placeholder="assa"
              >
                {organizationsfull?.map((res) => (
                  <option key={res.id} value={res.id}>
                    {res.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="mb-3">
              <label htmlFor="department" className="col-form-label">
                Organization branch :
              </label>
              <select
                className="form-select mb-3 col-6"
                aria-label="Default select example"
                id="organization_branch"
                defaultValue={current?.organization_branch}
                onChange={(e) => SelectChange(e.target.value)}
              >
                {branchOrg?.map((res) => (
                  <option key={res.id} value={res.id}>
                    {res.name}
                  </option>
                ))}
              </select>
            </div> */}
          </form>
        </div>
        <div className="modal-footer">
          <Button
            color="light"
            onClick={() => {
              setmodal_varying1(false);
            }}
          >
            {TranslationList("Cancel")}
          </Button>
          {current ? (
            <Button color="primary" onClick={handleEdite}>
              {TranslationList("Save")}
            </Button>
          ) : (
            <Button color="primary" onClick={handleAdd}>
              {TranslationList("Create")}
            </Button>
          )}
        </div>
      </Modal>

      <Container fluid>
        <BreadCrumb
          title={TranslationList("Documents")}
          pageTitle={TranslationList("home")}
        />
        <Row>
          <Card>
            <CardHeader>
              <Row className="align-items-center gy-3">
                <div className="col-sm">
                  <h5 className="card-title mb-0">
                    {TranslationList("Documents")}
                  </h5>
                </div>
                <div className="col-sm-auto d-flex justify-content-between">
                  <div className="px-2">
                    <div className="search-box">
                      <Input
                        type="text"
                        className="form-control search"
                        placeholder={TranslationList("List Search")}
                        onChange={(e) => handleSearch(e.target.value)}
                      />
                      <i className="ri-search-line search-icon"></i>
                    </div>
                  </div>
                  <div className="d-flex gap-1 flex-wrap">
                    <button
                      type="button"
                      className="btn btn-success add-btn"
                      id="create-btn"
                      onClick={() => tog_varying1()}
                    >
                      <i className="ri-add-line align-bottom me-1"></i>{" "}
                      {TranslationList("Add")}
                    </button>{" "}
                  </div>
                </div>
              </Row>
            </CardHeader>
            {documents?.results ? (
              <CardBody>
                <div className="live-preview p-3">
                  <div className="text-muted table-responsive">
                    <Table className="table-bordered align-middle table-nowrap mb-0">
                      <thead>
                        <tr>
                          <th scope="col" className="text-center">
                            {TranslationList("Number")}
                          </th>
                          <th scope="col" className="w-50  text-center">
                            {TranslationList("Name")}
                          </th>
                          <th scope="col" className="w-50  text-center">
                            {TranslationList("Project")}
                          </th>
                          <th scope="col" className="w-50  text-center">
                            {TranslationList("Type")}
                          </th>
                          {/* <th scope="col" className="w-50  text-center">
                          Organization
                        </th>
                        <th scope="col" className="w-50  text-center">
                          Organization Branch
                        </th> */}
                          <th scope="col " className="text-center">
                            {TranslationList("Actions")}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {documents?.results?.map((res, i) => (
                          <tr key={res?.id}>
                            <td className="align-items-center">{i + 1}</td>
                            <td>{res?.name}</td>
                            <td>{res?.application_detail?.name}</td>
                            <td>{res?.type}</td>
                            {/* <td>{res?.organization_detail?.name}</td>
                          <td>{res?.organization_branch_detail?.name}</td> */}
                            <td className="text-center">
                              <div className="hstack gap-2 justify-content-center">
                                <button
                                  className="btn btn-sm btn-soft-danger remove-list"
                                  onClick={() => onClickTodoDelete(res)}
                                >
                                  <i className="ri-delete-bin-5-fill align-bottom" />
                                </button>
                                <button
                                  className="btn btn-sm btn-soft-info edit-list"
                                  onClick={() => onClickEdite(res)}
                                >
                                  <i className="ri-pencil-fill align-bottom" />
                                </button>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </CardBody>
            ) : (
              <LoaderContent />
            )}
          </Card>
          <Paginations
            totalItems={documents?.pagination?.total}
            perPageChange={(e) => handlePerPageChange(e)}
            currentPage={(e) => handleCurrentPage(e)}
          />
        </Row>
      </Container>
    </div>
  );
}
