import React, { useEffect, useState } from "react";
import {
  Container,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
} from "reactstrap";
import classnames from "classnames";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation, withTranslation } from "react-i18next";
import Document from "./Document";
import Chronology from "./Chronology";
import Actions from "./Actions";
import { useSearchParams } from "react-router-dom";
import { currentapplicAsync } from "../../slices/application";
import PersonalDataEdite from "./PersonalDataEdite";
import { TranslationList } from "../../slices/settings/listtranslation";

const AddApplication = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const [activeTab, setActiveTab] = useState("1");
  const attachmentCount = useSelector(
    (state) => state.attachment.data?.pagination?.total
  );
  const application = useSelector((state) => state.applic.current);

  useEffect(() => {
    dispatch(currentapplicAsync(`application/${searchParams.get("id")}`));
  }, [searchParams.get("id")]);
  const toggleTab = (tab, type) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };
  document.title = `INVESTCONTROL | ${t("Add_application")}`;

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb
          pageTitle={TranslationList("Applications")}
          title={TranslationList("NEW STATEMENT")}
        />
        <Row>
          <div className="col-xl-10 col-lg-9">
            <div>
              <div className="card">
                <div className="card-header border-0">
                  <div className="row align-items-center">
                    <div className="col">
                      <Nav
                        className="nav-tabs-custom card-header-tabs border-bottom-0 nav-border-top nav-justified"
                        role="tablist"
                      >
                        <NavItem>
                          <NavLink
                            className={classnames(
                              { active: activeTab === "1" },
                              "fw-semibold"
                            )}
                            onClick={() => {
                              toggleTab("1", "all");
                            }}
                            href="#"
                          >
                            {TranslationList("Personal data")}
                          </NavLink>
                        </NavItem>
                        {application?.stage != "stageanketa" && (
                          <React.Fragment>
                            <NavItem>
                              <NavLink
                                className={classnames(
                                  { active: activeTab === "2" },
                                  "fw-semibold"
                                )}
                                onClick={() => {
                                  toggleTab("2", "published");
                                }}
                                href="#"
                              >
                                {TranslationList("Documents")}
                                <span className="badge badge-soft-danger align-middle rounded-pill ms-1">
                                  {attachmentCount}
                                </span>
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                className={classnames(
                                  { active: activeTab === "3" },
                                  "fw-semibold"
                                )}
                                onClick={() => {
                                  toggleTab("3", "draft");
                                }}
                                href="#"
                              >
                                {TranslationList("Chranology")}
                              </NavLink>
                            </NavItem>
                          </React.Fragment>
                        )}
                      </Nav>
                    </div>
                    <div className="col-auto"></div>
                  </div>
                </div>
                <div className="card-body pt-3">
                  <TabContent activeTab={activeTab} className="text-muted ">
                    <TabPane tabId="1" id="home">
                      <PersonalDataEdite />
                    </TabPane>
                    <TabPane tabId="2" id="product">
                      <Document />
                    </TabPane>
                    <TabPane tabId="3" id="messages">
                      <Container fluid>
                        <Chronology />
                      </Container>
                    </TabPane>
                  </TabContent>
                </div>
              </div>
            </div>
          </div>
          <Col xl={2} lg={3}>
            <Actions />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default withTranslation()(AddApplication);
