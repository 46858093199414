import React from "react";
import { Col, Container, Row } from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { withTranslation } from "react-i18next";

const Projects = ({ t }) => {
  document.title = "Projects | Velzon - React Admin & Dashboard Template";
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Projects" pageTitle="Home" />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withTranslation()(Projects);
