import React from "react";
import ProjectList from "../pages/Projects/ProjectList";
import Settings from "../pages/Profile/Settings";
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import NFTLanding from "../pages/Landing/";
import Projects from "../pages/Projects/Projects";
import Applications from "../pages/Application/Applications";
import AddApplication from "../pages/Application/AddApplication";
import CreateApplication from "../pages/Application/ApplicationCreate/CreateApplication";
import ShowAplication from "../pages/Application/ShowAplication";
import RegionsAndDistricts from "../pages/Directory/Regions_and_districts";
import PositionAndDepartment from "../pages/Directory/Position_and_department";
import Translations from "../pages/Settings/Translations";
import Currency from "../pages/Directory/Currency";
import ParticipatingBank from "../pages/Directory/ParticipatingBank";
import Project from "../pages/Directory/Project";
import ActivityType from "../pages/Directory/ActivityType";
import Sector from "../pages/Directory/Sector_and_fields";
import Organization from "../pages/Directory/Organization";
import EntitiyType from "../pages/Directory/EntityType";
import ExchangeRates from "../pages/Directory/Exchangerates";
import ApplicationStep from "../pages/Application/ApplicationStep";
import Document from "../pages/Directory/Document";
import Users from "../pages/Directory/Users";
import ProjectAdd from "../pages/Directory/ProjectAdd";
import Reports from "../pages/Directory/Reports";
import ForPDF from "../pages/Directory/ForPDF";
import Dedline from "../pages/Directory/Dedline";
import ShowPdf from "../pages/Application/ShowPdf";
import SectorDetail from "../pages/Landing//SectorDetail";
import SendApplication from "../pages/Landing//SendApplication";
import Map from "../pages/Analytics/Map";
import AnalyticsReports from "../pages/Analytics/Report/Reports";
import Statistics from "../pages/Analytics/Statistics";
import Monitoring from "../pages/Directory/Monitoring";
import MonitoringPeriod from "../pages/Directory/MonitoringPeriod";
import Subprojects from "../pages/Subproject/Subprojects";
import SubProjectDetail from "../pages/Subproject/SubProjectDetail";
import AnalyticsReportsCreate from "../pages/Analytics/Report/AddReport";
import AnalyticsReportsEdite from "../pages/Analytics/Report/EditeReport";
import ReportShow from "../pages/Analytics/Report/ReportShow";
import Complaint from "../pages/Directory/Complaint";
import ApplicationShow from "../pages/Application/ApplicationShow";
import ApplicationReport from "../pages/Application/ApplicationReport";
const authProtectedRoutes = [
  { path: "/map", component: <Map /> },
  { path: "/analytics/reports", component: <AnalyticsReports /> },
  { path: "/analytics/reports/:id", component: <AnalyticsReportsEdite /> },
  { path: "/analytics/report/:id", component: <ReportShow /> },
  {
    path: "/analytics/reports/create/:type",
    component: <AnalyticsReportsCreate />,
  },
  { path: "/statistics", component: <Statistics /> },
  { path: "/projects", component: <Projects /> },
  { path: "/applications", component: <Applications /> },
  { path: "/subprojects", component: <Subprojects /> },
  { path: "/subprojects/:id", component: <SubProjectDetail /> },
  { path: "/add-application", component: <AddApplication /> },
  { path: "/create-application", component: <CreateApplication /> },
  { path: "/application-detail", component: <ShowAplication /> },
  { path: "/application-show/:id", component: <ApplicationShow /> },
  { path: "/regions_and_districts", component: <RegionsAndDistricts /> },
  { path: "/position_and_department", component: <PositionAndDepartment /> },
  { path: "/translations", component: <Translations /> },
  { path: "/currency", component: <Currency /> },
  { path: "/complaint", component: <Complaint /> },
  { path: "/pfi", component: <ParticipatingBank /> },
  { path: "/project", component: <Project /> },
  { path: "/activity_type", component: <ActivityType /> },
  { path: "/sector_and_fields", component: <Sector /> },
  { path: "/organization_and_branch", component: <Organization /> },
  { path: "/entity_type", component: <EntitiyType /> },
  { path: "/exchange_rates", component: <ExchangeRates /> },
  { path: "/application_step", component: <ApplicationStep /> },
  { path: "/document", component: <Document /> },
  { path: "/monitoring", component: <Monitoring /> },
  { path: "/monitoring-period", component: <MonitoringPeriod /> },
  { path: "/users/:id", component: <Settings /> },
  { path: "/users", component: <Users /> },
  { path: "project_add_and_edit", component: <ProjectAdd /> },
  { path: "/project/:id", component: <ProjectAdd /> },

  { path: "/reports", component: <Reports /> },
  { path: "/forpdf", component: <ForPDF /> },
  { path: "/application-report", component: <ApplicationReport /> },
  { path: "/showpdf", component: <ShowPdf /> },
  { path: "/dedline", component: <Dedline /> },
  { path: "/projectslist", component: <ProjectList /> },
];

const publicRoutes = [
  { path: "/", component: <NFTLanding /> },
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
];
const landingRouts = [
  { path: "/sectordetail", component: <SectorDetail /> },
  { path: "/send-application", component: <SendApplication /> },
];
export { authProtectedRoutes, publicRoutes, landingRouts };
