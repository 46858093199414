import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Modal,
  ModalHeader,
  Row,
  UncontrolledAlert,
} from "reactstrap";
import "cleave.js/dist/addons/cleave-phone.in";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { DataService } from "../../../helpers/dataService/dataService";
import MultiStepLanding from "../../../Components/MultiStepLanding";
import Stage1 from "./Step1";
import Stage2 from "./Step2";
import {
  addapplicAsyncNoAuth,
  editeapplicationAsync,
} from "../../../slices/application";
import { TranslationList } from "../../../slices/settings/listtranslation";
import Stage3 from "./Step3";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
export default function CreateApplication() {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const application = searchParams.get("id");
  const profile = useSelector((state) => state.profile?.data);
  const [loading, setLoading] = useState(false);
  const [currentData, setcurrentData] = useState();
  const [modal_large, setmodal_large] = useState(false);
  const [page, setPage] = useState(1);
  const [currentStage, setcurrentStage] = useState(1);
  const tog_large = () => {
    setmodal_large(!modal_large);
    searchParams.set("id", application);
    setSearchParams(searchParams);
  };
  const nextPageNumber = (pageNumber) => {
    setPage(pageNumber);
  };
  const nextStageSave1 = async (stage, data) => {
    setLoading(true);
    try {
      const response = await addapplicAsyncNoAuth("application/step1", {
        ...data,
        draft_stage: stage,
        created_by: profile?.id,
      });
      setLoading(false);

      if (response?.errorCode == 400) {
        toast.error(TranslationList("An error has occurred!"));
      } else {
        toast.success(TranslationList("Application successfully created!"));

        searchParams.set("id", response?.id);
        setSearchParams(searchParams);
      }
    } catch (error) {
      setLoading(false);
      toast.error(TranslationList("An error has occurred!"));
    }
    nextPageNumber(stage + 1);
    getApplication();
  };
  const nextStagePut1 = async (stage, data) => {
    setLoading(true);
    try {
      const response = await editeapplicationAsync(
        `application/${application}/step1`,
        {
          ...data,
          draft_stage: stage,
        }
      );
      setLoading(false);

      if (response?.errorCode == 400) {
        toast.error(TranslationList("An error has occurred!"));
      } else {
        toast.success(TranslationList("Application successfully updated!"));
        // searchParams.set("id", response?.id);
        // setSearchParams(searchParams);
      }
    } catch (error) {
      toast.error(TranslationList("An error has occurred!"));
    }
    nextPageNumber(stage + 1);
    getApplication();
  };
  const nextStagePut2 = async (stage, data) => {
    setLoading(true);

    try {
      const response = await editeapplicationAsync(
        `application/${application}/step2`,
        {
          ...data,
          draft_stage: stage,
        }
      );
      setLoading(false);

      if (response?.errorCode == 400) {
        toast.error(TranslationList("An error has occurred!"));
      } else {
        toast.success(TranslationList("Application successfully updated!"));
        // searchParams.set("id", response?.id);
        // setSearchParams(searchParams);
      }
    } catch (error) {
      toast.error(TranslationList("An error has occurred!"));
    }
    nextPageNumber(stage + 1);
    getApplication();
  };
  const saveApplication = async (data) => {
    setLoading(true);
    try {
      const response = await editeapplicationAsync(
        `application/${application}/step3`,
        {
          ...data,
          draft_stage: 3,
        }
      );
      setLoading(false);
      if (response?.errorCode == 400) {
        toast.error(TranslationList("An error has occurred!"));
      } else {
        getProjectSelect();
        tog_large();
      }
    } catch (error) {}
  };
  const handleCreateApp = async (id) => {
    try {
      await DataService.put(`application/${application}/step4`, {
        project: id,
        created_by: profile?.id,
        organization: profile?.organization,
        draft_stage: 4,
      });

      navigate(`/application-detail?show_id=${application}`);
      localStorage.removeItem("applicationId");
    } catch (error) {
      console.log(error);
    }
  };
  const getProjectSelect = async () => {
    try {
      const response = await DataService.getNoAuth(
        `projects/filter?aplication_id=${searchParams.get("id")}&user_age=${20}`
      );
      if (response) {
        if (response?.errors) setcurrentData(response);
        else setcurrentData({ data: response });
        tog_large();
        if (response?.errors?.length > 0) {
          localStorage.removeItem("applicationId");
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getApplication = async () => {
    const res = await DataService.get(
      `application/${searchParams.get("id")}/step2`
    );
    setcurrentStage(res?.draft_stage);
  };
  useEffect(() => {
    if (application) {
      getApplication();
    }
  }, []);
  return (
    <div>
      <Modal
        size="lg"
        isOpen={modal_large}
        toggle={() => {
          tog_large();
        }}
      >
        {currentData?.errors ? (
          <div className="modal-body">
            {currentData?.errors?.map((res, i) => (
              <UncontrolledAlert
                key={i}
                color="danger"
                className="alert-label-icon rounded-label"
              >
                <i className="ri-error-warning-line label-icon"></i>
                <strong>{res}</strong>
              </UncontrolledAlert>
            ))}
          </div>
        ) : (
          <React.Fragment>
            <ModalHeader
              id="myLargeModalLabel"
              toggle={() => {
                tog_large();
              }}
            >
              <h5 className="fw-bold">{currentData?.data[0]?.name}</h5>
            </ModalHeader>
            <div className="modal-body">
              <div className=" fs-14">
                {/* <p>Description</p> */}
                <div
                  className="text-wrap "
                  dangerouslySetInnerHTML={{
                    __html: currentData?.data[0]?.description,
                  }}
                />
              </div>
              <div className="py-3">
                <h5 className="mb-2 fs-14 fw-bold">
                  {TranslationList("Sectors")}:
                </h5>
                <div>
                  {currentData?.data[0]?.sectors_detail?.map((res) => (
                    <p className="" key={res?.id}>
                      {res?.name}
                    </p>
                  ))}
                </div>
              </div>
              <div className="py-3">
                <h5 className="mb-2 fs-14 fw-bold">
                  {TranslationList(
                    "Issued_through_the_following_commercial_banks"
                  )}
                  :
                </h5>
                <div>
                  {currentData?.data[0]?.organization_detail?.map((res) => (
                    <p className="" key={res?.id}>
                      {res?.name}
                    </p>
                  ))}
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                className="btn btn-success add-btn"
                onClick={() => {
                  handleCreateApp(currentData?.data[0]?.id);
                }}
              >
                {TranslationList("Send_application")}
              </button>
              <Button
                type="button"
                className="btn btn-danger  fw-medium"
                onClick={() => tog_large()}
              >
                <i className="ri-close-line me-1 align-middle"></i>{" "}
                {TranslationList("Close")}
              </Button>
            </div>
          </React.Fragment>
        )}
      </Modal>

      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            title={TranslationList("REGISTER OF APPLICATIONS")}
            pageTitle={TranslationList("Applications")}
          />
          <Row>
            <Col xs={12}>
              <Card>
                <CardBody>
                  <div className="needs-validation">
                    <MultiStepLanding
                      page={page}
                      onPageNumberClick={nextPageNumber}
                      currentStage={currentStage}
                    />
                    {
                      {
                        1: (
                          <Stage1
                            nextStagePut={nextStagePut1}
                            nextStageSave={nextStageSave1}
                            loading={loading}
                          />
                        ),
                        2: (
                          <Stage2
                            nextStagePut={nextStagePut2}
                            nextPageNumber={nextPageNumber}
                            loading={loading}
                          />
                        ),
                        3: (
                          <Stage3
                            saveApplication={saveApplication}
                            nextPageNumber={nextPageNumber}
                            loading={loading}
                          />
                        ),
                      }[page]
                    }
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}
