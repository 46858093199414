import React, { useState, useEffect, useMemo } from "react";
import {
  Button,
  Container,
  Form,
  Input,
  Label,
  Modal,
  ModalHeader,
  Table,
} from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { TranslationList } from "../../slices/settings/listtranslation";
import LoaderContent from "../../Components/Spinner";
import Paginations from "../Tables/DataTables/Pagination";
import { DataService } from "../../helpers/dataService/dataService";
import endpoints from "../../endpoints";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/l10n/ru.js";
import "flatpickr/dist/l10n/uz.js";
import Select from "react-select";
import dateFormat from "dateformat";
import { toast } from "react-toastify";
export default function MonitoringAll({ status, countData }) {
  const lang = useSelector((state) => state.lang.data);
  const navigate = useNavigate();
  const [current, setCurrent] = useState();
  const [periods, setPeriods] = useState();

  const [modal_varying, setmodal_varying] = useState(false);
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({
    date_start_manitoring: "",
    manitoring_period: "",
    status: "monitoring",
  });
  const tog_varying = () => {
    setmodal_varying(!modal_varying);
  };
  const [apiData, setApiData] = useState();

  const fetchData = async () => {
    const response = await DataService.get(endpoints.investSubproject, {
      status: status,
    });
    setApiData(response);
    const resPeriod = await DataService.get(endpoints.monitoringPeriod);
    setPeriods(
      resPeriod?.results?.map((item) => {
        return { label: item?.period, value: item?.id };
      })
    );
  };
  const fetchDataParams = async (params) => {
    const response = await DataService.get(endpoints.investSubproject, {
      ...params,
      status: status,
    });
    setApiData(response);
  };
  useEffect(() => {
    fetchData();
  }, [lang, countData]);
  const handleSearch = (e) => {
    fetchDataParams({ search: e });
  };
  const perpage = localStorage.getItem("perpage") ?? 10;
  const [perPage, setPerpage] = useState(perpage);
  const handleCurrentPage = (e) => {
    fetchDataParams({ page: e, limit: perPage });
  };
  const handlePerPageChange = (e) => {
    setPerpage(e);
    fetchDataParams({ limit: e });
  };
  const handleSave = async () => {
    try {
      await DataService.put(
        endpoints.investSubproject + `/${current?.id}`,
        state
      );
      toast.success(TranslationList("Done successfully!"));
      tog_varying();
      fetchData();
    } catch (err) {
      toast.error(err);
    }
  };
  return (
    <React.Fragment>
      <Modal
        isOpen={modal_varying}
        toggle={() => {
          tog_varying();
        }}
        id="exampleModal"
      >
        <ModalHeader
          toggle={() => {
            tog_varying();
          }}
        >
          {TranslationList("Add period for manitoring")}
        </ModalHeader>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            handleSave();
          }}
        >
          <div className="modal-body">
            <Container fluid>
              <div>
                <Label className="form-label mb-0">
                  {TranslationList("Monitoring start date")}
                </Label>
                <Flatpickr
                  className="form-control"
                  defaultValue={dateFormat(
                    current?.date_start_manitoring,
                    "d M Y"
                  )}
                  onChange={(e) =>
                    setState({
                      ...state,
                      date_start_manitoring: dateFormat(e, "yyyy-mm-dd"),
                    })
                  }
                  options={{
                    locale: localStorage.getItem("I18N_LANGUAGE_INVEST"),
                    dateFormat: "d M Y",
                  }}
                />
              </div>
              <div>
                <Label>{TranslationList("Monitoring_Period")}</Label>
                <Select
                  defaultValue={{
                    label: current?.manitoring_period_detail?.period,
                    value: current?.manitoring_period_detail?.id,
                  }}
                  onChange={(e) => {
                    setState({
                      ...state,
                      manitoring_period: e.value,
                    });
                  }}
                  options={periods}
                  classNamePrefix="js-example-basic-multiple"
                />
              </div>
            </Container>
          </div>
          <div className="modal-footer">
            <Button
              color="light"
              onClick={() => {
                setmodal_varying(false);
              }}
            >
              {TranslationList("Cancel")}
            </Button>

            <Button color="primary" type="submit" disabled={loading}>
              {loading ? (
                <Spinner size="sm" className="me-2">
                  {" "}
                  Loading...{" "}
                </Spinner>
              ) : null}
              {TranslationList("Send")}
            </Button>
          </div>
        </Form>
      </Modal>
      <div className="border-0 p-2">
        <div className="d-flex ">
          <div className="p-2">
            <div className="search-box">
              <Input
                type="text"
                className="form-control search"
                placeholder={TranslationList("List Search")}
                onChange={(e) => handleSearch(e.target.value)}
              />
              <i className="ri-search-line search-icon"></i>
            </div>
          </div>
        </div>
      </div>

      {apiData?.results ? (
        <React.Fragment>
          <div className="live-preview p-3">
            <div className="text-muted table-responsive">
              <Table className="table-bordered align-middle table-nowrap mb-0">
                <thead>
                  <tr>
                    <th scope="col">{TranslationList("Application number")}</th>
                    <th scope="col">{TranslationList("Project")}</th>
                    <th scope="col">
                      {TranslationList("Project description")}
                    </th>

                    <th scope="col">{TranslationList("Monitoring_Period")}</th>
                    {status == "start_monitoring" ||
                    status == "finish_monitoring" ? (
                      <th scope="col">{TranslationList("Monitoring_date")}</th>
                    ) : (
                      ""
                    )}

                    <th scope="col">{TranslationList("Applicant")}</th>

                    <th scope="col">{TranslationList("Actions")}</th>
                  </tr>
                </thead>
                <tbody>
                  {apiData?.results?.map((res) => (
                    <tr
                      style={{ cursor: "pointer" }}
                      key={res?.id}
                      onClick={() => navigate(`/subprojects/${res.id}`)}
                    >
                      <td>
                        {/* <Link
                          to={`/application-detail?show_id=${res.aplication_detail?.id}`}
                        > */}
                        {res.aplication_detail?.nomer}
                        {/* </Link> */}
                      </td>

                      <td>
                        <div className="d-flex flex-wrap">
                          <div className="text-wrap">
                            {res?.project_detail?.name}
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex flex-wrap">
                          <div className="text-wrap">
                            <div
                              dangerouslySetInnerHTML={{
                                __html: res?.project_detail?.description,
                              }}
                            ></div>
                          </div>
                        </div>
                      </td>

                      <td>{res?.project_detail?.manitoring_period}</td>
                      {status == "start_monitoring" ||
                      status == "finish_monitoring" ? (
                        <td>{res?.date_start_manitoring}</td>
                      ) : (
                        ""
                      )}

                      <td>
                        {res?.aplication_detail?.created_by_info?.first_name +
                          "  "}
                        {res?.aplication_detail?.created_by_info?.last_name}
                      </td>
                      <td className="text-center">
                        <div className="d-flex align-items-center justify-content-center gap-2">
                          <button
                            className="btn btn-sm btn-soft-success show-list"
                            onClick={() => navigate(`/subprojects/${res.id}`)}
                          >
                            <i className=" ri-line-chart-line align-bottom" />
                          </button>
                          {status == "monitoring" ? (
                            <button
                              className="btn btn-sm btn-soft-info show-list"
                              onClick={() => {
                                tog_varying();
                                setCurrent(res);
                              }}
                            >
                              <i className="  ri-pencil-fill align-bottom" />
                            </button>
                          ) : (
                            ""
                          )}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </div>
        </React.Fragment>
      ) : (
        <LoaderContent />
      )}
      <Paginations
        totalItems={apiData?.pagination?.total}
        perPageChange={(e) => handlePerPageChange(e)}
        currentPage={(e) => handleCurrentPage(e)}
      />
    </React.Fragment>
  );
}
