import { createSlice } from "@reduxjs/toolkit";

const columnOrganizationSlice = createSlice({
  name: "column",
  initialState: {
    columns: null,
    error: {},
  },
  reducers: {
    columnOrganizationReducer: (state, action) => {
      state.columns = action.payload;
    },
  },
});
export const columnOrganizationAsync = (data) => async (dispatch) => {
  try {
    dispatch(columnOrganizationReducer(data));
  } catch (error) {
    // //throw new Error(err);
  }
};
export const { columnOrganizationReducer } = columnOrganizationSlice.actions;

export default columnOrganizationSlice.reducer;
