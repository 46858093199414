import React, { useEffect } from "react";
import { Card, Container, Row, Col } from "reactstrap";
import { TranslationList } from "../../slices/settings/listtranslation";
import PersonalDataLanding from "./Application/Application";
import Aos from "aos";

export default function SendApplication() {
  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <React.Fragment>
      <section className="section bg-light" id="createapplication">
        <Container>
          <Row className="justify-content-center">
            <Col lg={8}>
              <div className="text-center mb-5">
                <h2 className="mb-3 fw-bold lh-base">
                  {TranslationList("Send_application")}
                </h2>
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg={12}>
              <Card className="p-3">
                <PersonalDataLanding />
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
}
