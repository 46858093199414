import { createSlice } from "@reduxjs/toolkit";

export const landingApplicationSlide = createSlice({
  name: "landingApplication",
  initialState: {
    data: null,
    check: true,
    stage: 1,
  },
  reducers: {
    getlandingApplication: (state, action) => {
      state.data = action.payload;
    },
    getlandingCheck: (state, action) => {
      state.check = action.payload;
    },
    getlandingStage: (state, action) => {
      state.stage = action.payload;
    },
  },
});

export const { getlandingApplication, getlandingCheck, getlandingStage } =
  landingApplicationSlide.actions;
export default landingApplicationSlide.reducer;
