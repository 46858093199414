import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Container,
  Row,
  Table,
  CardHeader,
  Input,
  Modal,
  ModalBody,
} from "reactstrap";
import { useSelector } from "react-redux";
import { TranslationList } from "../../../slices/settings/listtranslation";
import { toast } from "react-toastify";
import Paginations from "../../Tables/DataTables/Pagination";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { Link, useNavigate } from "react-router-dom";
import { DataService } from "../../../helpers/dataService/dataService";
import endpoints from "../../../endpoints";
import LoaderContent from "../../../Components/Spinner";
import dateFormat from "dateformat";
import DeleteModal from "../../../Components/Common/DeleteModal";

export default function AnalyticsReports() {
  const [deleteModal, setDeleteModal] = useState(false);
  const [modal_varying1, setmodal_varying1] = useState(false);
  const perpage = localStorage.getItem("perpage") ?? 10;
  const [perPage, setPerpage] = useState(perpage);
  const [current, setCurrent] = useState();
  const lang = useSelector((state) => state.lang.data);
  const navigate = useNavigate();
  const [apiData, setApiData] = useState();
  const fetchData = async () => {
    const response = await DataService.get(endpoints.analyticReport);
    setApiData(response);
  };
  useEffect(() => {
    fetchData();
  }, [lang]);

  const handleSearch = (e) => {};
  function tog_varying1() {
    setmodal_varying1(!modal_varying1);
  }

  const handleDelete = async () => {
    try {
      await DataService.delete(endpoints.analyticReport + `/${current?.id}`);
      toast.success(TranslationList("Done successfully!"));
      fetchData();
    } catch (err) {
      toast.error(TranslationList("An error has occurred!"));
    }

    setDeleteModal(false);
  };

  //Pagination begin

  const handleCurrentPage = (e) => {
    // dispatch(getcurrencyAsync(`currency/?page=${e}&limit=${perPage}`));
  };
  const handlePerPageChange = (e) => {
    setPerpage(e);
    // dispatch(getcurrencyAsync(`currency/?limit=${e}`));
  };
  document.title = `INVESTCONTROL | ${TranslationList("Reports")}`;

  return (
    <div className="page-content">
      <Modal isOpen={modal_varying1} toggle={tog_varying1} centered={true}>
        <ModalBody className="py-3 px-3">
          <div className="list-group list-group-fill-success">
            <li
              className="list-group-item list-group-item-action cursor-pointer "
              onClick={() => navigate(`/analytics/reports/create/custom`)}
            >
              1. Report test 1.
            </li>
            <li
              className="list-group-item list-group-item-action  cursor-pointer"
              onClick={() => navigate("/analytics/reports/create/custom")}
            >
              2. Report test 2.
            </li>
          </div>
        </ModalBody>
      </Modal>
      <DeleteModal
        show={deleteModal}
        data={current?.title}
        onDeleteClick={handleDelete}
        onCloseClick={() => setDeleteModal(false)}
      />

      <Container fluid>
        <BreadCrumb
          title={TranslationList("Reports")}
          pageTitle={TranslationList("home")}
        />
        <Row>
          <Card>
            <CardHeader>
              <Row className="align-items-center gy-3">
                <div className="col-sm">
                  <h5 className="card-title mb-0">
                    {TranslationList("Reports")}
                  </h5>
                </div>
                <div className="col-sm-auto d-flex justify-content-between">
                  <div className="px-2">
                    <div className="search-box">
                      <Input
                        type="text"
                        className="form-control search"
                        placeholder={TranslationList("List Search")}
                        onChange={(e) => handleSearch(e.target.value)}
                      />
                      <i className="ri-search-line search-icon"></i>
                    </div>
                  </div>
                  <div className="d-flex gap-1 flex-wrap">
                    <button
                      type="button"
                      className="btn btn-success add-btn"
                      id="create-btn"
                      onClick={() => tog_varying1()}
                    >
                      <i className="ri-add-line align-bottom me-1"></i>
                      {TranslationList("Add")}
                    </button>
                  </div>
                </div>
              </Row>
            </CardHeader>
            {apiData ? (
              <CardBody>
                <div className="live-preview p-3">
                  <div className="text-muted table-responsive">
                    <Table className="table-bordered align-middle table-nowrap mb-0">
                      <thead>
                        <tr>
                          <th scope="col" className="text-center">
                            {TranslationList("Number")}
                          </th>
                          <th scope="col" className="  text-center">
                            {TranslationList("Title")}
                          </th>

                          <th scope="col" className="  text-center">
                            {TranslationList("Created_by")}
                          </th>
                          <th scope="col" className="  text-center">
                            {TranslationList("Created_at")}
                          </th>

                          <th scope="col" className="  text-center">
                            {TranslationList("Updated_by")}
                          </th>
                          <th scope="col" className="  text-center">
                            {TranslationList("Updated_at")}
                          </th>
                          <th scope="col " className="text-center">
                            {TranslationList("Actions")}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {apiData?.results?.map((res, i) => (
                          <tr key={res?.id}>
                            <td className="text-center">{i + 1}</td>
                            <td>{res?.title}</td>
                            <td>
                              {res?.created_by_info?.first_name}{" "}
                              {res?.created_by_info?.surname}
                            </td>
                            <td>
                              {dateFormat(
                                res?.created_time,
                                "dd.mm.yyyy HH:MM"
                              )}
                            </td>
                            <td>
                              {res?.updated_by_info?.first_name}{" "}
                              {res?.updated_by_info?.surname}
                            </td>
                            <td>
                              {dateFormat(
                                res?.updated_time,
                                "dd.mm.yyyy HH:MM"
                              )}
                            </td>
                            <td className="text-center">
                              <div className="hstack gap-2 justify-content-center">
                                <button
                                  className="btn btn-sm btn-soft-success show-list"
                                  onClick={() =>
                                    navigate(`/analytics/report/${res?.id}`)
                                  }
                                >
                                  <i className="ri-eye-fill align-bottom" />
                                </button>
                                <button
                                  className="btn btn-sm btn-soft-danger remove-list"
                                  onClick={() => {
                                    setCurrent(res), setDeleteModal(true);
                                  }}
                                >
                                  <i className="ri-delete-bin-5-fill align-bottom" />
                                </button>
                                <button
                                  className="btn btn-sm btn-soft-info edit-list"
                                  onClick={() =>
                                    navigate(`/analytics/reports/${res?.id}`)
                                  }
                                >
                                  <i className="ri-pencil-fill align-bottom" />
                                </button>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </CardBody>
            ) : (
              <LoaderContent />
            )}
          </Card>
          <Paginations
            totalItems={apiData?.pagination?.total}
            perPageChange={(e) => handlePerPageChange(e)}
            currentPage={(e) => handleCurrentPage(e)}
          />
        </Row>
      </Container>
    </div>
  );
}
