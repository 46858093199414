import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Label,
  Input,
  FormFeedback,
  FormGroup,
  Form,
  Button,
} from "reactstrap";
import { DataService } from "../../../helpers/dataService/dataService";
import { TranslationList } from "../../../slices/settings/listtranslation";
import { useSearchParams } from "react-router-dom";
export default function Stage3({ saveApplication, nextPageNumber, loading }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const application = searchParams.get("id");
  const [data, setData] = useState();

  const getData = async () => {
    if (application) {
      const response = await DataService.getNoAuth(
        `application/${application}/step3`
      );
      setData(response);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const handledraft = async () => {
    {
      const data = {
        status: "draft",
        implementation_subproject: document.getElementById(
          "implementation_subproject"
        ).value,
        cumulative_profit: document.getElementById("cumulative_profit").value,
        cds_participant: document.getElementById("cds_participant").value,
        annual_production_volume: document.getElementById(
          "annual_production_volume"
        ).value,
        cadastral_number: document.getElementById("cadastral_number").value,
        document_number: document.getElementById("document_number").value,

        draft_stage: 2,
      };
      saveApplication(data);
    }
  };

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        handledraft();
      }}
    >
      <Row>
        <Col md="6">
          <FormGroup className="mb-3">
            <Label htmlFor="implementation_subproject">
              {TranslationList("implementation_subproject")}
            </Label>
            <Input
              key={data?.implementation_subproject}
              type="text"
              className="form-control"
              id="implementation_subproject"
              required
              defaultValue={data?.implementation_subproject}
            />

            <FormFeedback type="invalid">
              {TranslationList("Field is required")}
            </FormFeedback>
          </FormGroup>
        </Col>
        <Col md="6">
          <FormGroup className="mb-3">
            <Label htmlFor="annual_production_volume">
              {TranslationList("annual_production_volume")}
            </Label>
            <Input
              key={data?.annual_production_volume}
              required
              type="text"
              className="form-control"
              id="annual_production_volume"
              defaultValue={data?.annual_production_volume}
            />

            <FormFeedback type="invalid">
              {TranslationList("Field is required")}
            </FormFeedback>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md="6">
          <FormGroup className="mb-3">
            <Label htmlFor="cumulative_profit">
              {TranslationList("cumulative_profit")}
            </Label>
            <Input
              key={data?.cumulative_profit}
              type="text"
              className="form-control"
              id="cumulative_profit"
              required
              defaultValue={data?.cumulative_profit}
            />

            <FormFeedback type="invalid">
              {TranslationList("Field is required")}
            </FormFeedback>
          </FormGroup>
        </Col>
        <Col md="6">
          <FormGroup className="mb-3">
            <Label htmlFor="cds_participant">
              {TranslationList("cds_participant")}
            </Label>
            <Input
              key={data?.cds_participant}
              required
              type="text"
              className="form-control"
              id="cds_participant"
              defaultValue={data?.cds_participant}
            />

            <FormFeedback type="invalid">
              {TranslationList("Field is required")}
            </FormFeedback>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md="6">
          <FormGroup className="mb-3">
            <Label htmlFor="document_number">
              {TranslationList("document_number")}
            </Label>
            <Input
              key={data?.document_number}
              type="text"
              className="form-control"
              id="document_number"
              required
              defaultValue={data?.document_number}
            />

            <FormFeedback type="invalid">
              {TranslationList("Field is required")}
            </FormFeedback>
          </FormGroup>
        </Col>
        <Col md="6">
          <FormGroup className="mb-3">
            <Label htmlFor="cadastral_number">
              {TranslationList("cadastral_number")}
            </Label>
            <Input
              key={data?.cadastral_number}
              required
              type="text"
              className="form-control"
              id="cadastral_number"
              defaultValue={data?.cadastral_number}
            />

            <FormFeedback type="invalid">
              {TranslationList("Field is required")}
            </FormFeedback>
          </FormGroup>
        </Col>
      </Row>
      <div className=" d-flex justify-content-between mt-3">
        <Button color="primary" type="button" onClick={() => nextPageNumber(2)}>
          {TranslationList("Previous step")}
        </Button>
        <Button color="primary" type="submit" disabled={loading}>
          {TranslationList("Save")}
        </Button>
      </div>
    </Form>
  );
}
