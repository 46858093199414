import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Alert,
  Label,
  FormGroup,
  Input,
} from "reactstrap";
import OneID from "../../assets/images/oneid.svg";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import withRouter from "../../Components/Common/withRouter";
import * as Yup from "yup";
import { useFormik } from "formik";
import { loginUser } from "../../slices/thunks";
import { TranslationList } from "../../slices/settings/listtranslation";
import { reset_login_flag } from "../../slices/auth/login/reducer";
import EImzoLogin from "./EImzoLogin";
import FormLogin from "./formLogin";

const Login = (props) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => ({
    user: state.Account.user,
  }));
  const [typeLogin, setTypeLogin] = useState("form_radio");
  const [userLogin, setUserLogin] = useState([]);

  useEffect(() => {
    if (user && user) {
      setUserLogin({
        username: user.user.username,
        password: user.user.confirm_password,
      });
    }
  }, [user]);

  const { error, errorFlag, loading } = useSelector((state) => ({
    error: state.Login.error,
    loading: state.Login.loading,
    errorFlag: state.Login.errorFlag,
  }));

  useEffect(() => {
    if (errorFlag) {
      setTimeout(() => {
        dispatch(reset_login_flag(error));
      }, 3000);
    }
  }, [dispatch, errorFlag]);

  const LoginWith = () => {
    switch (typeLogin) {
      case "form_radio":
        return (
          <>
            <FormLogin {...props} />
          </>
        );
      case "oneIdRadio":
        return (
          <div className="d-flex w-100 ">
            <button
              className="btn btn-primary w-100 h-25"
              onClick={() => {
                window.location.href =
                  "https://sso.egov.uz/sso/oauth/Authorization.do?response_type=one_code&client_id=invest_iscad_uz&redirect_uri=https://fr-invest.samar.uz/applications&scope=myportal&state=testState";
              }}
            >
              <img src={OneID} width="100%" height={20} />
            </button>
          </div>
        );
      case "eImzoRadio":
        return (
          <div className="d-flex flex-column gap-5 w-100 ">
            <EImzoLogin />
          </div>
        );

      default:
        break;
    }
  };
  return (
    <React.Fragment>
      <div className="auth-page-wrapper auth-bg-cover py-5 d-flex justify-content-center align-items-center min-vh-100">
        <div className="auth-page-content overflow-hidden pt-lg-5">
          <Container>
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="mt-4">
                  <CardBody className="p-4">
                    <div className="text-center mt-2">
                      <Link to="/" className="text-primary">
                        {TranslationList("Backtosite")}
                      </Link>
                      <h5 className="text-primary mt-2">
                        {TranslationList("Login")}
                      </h5>
                    </div>
                    {error && error ? (
                      <Alert color="danger"> {error} </Alert>
                    ) : null}
                    <div className=" mt-4">
                      <LoginWith />
                    </div>

                    <Row className="mt-4">
                      <Col md={4}>
                        <FormGroup className="mb-3">
                          <Input
                            className="form-check-input me-2"
                            type="radio"
                            name="type_login"
                            id="form_radio"
                            defaultChecked={true}
                            onChange={(e) => setTypeLogin("form_radio")}
                          />
                          <Label
                            className="form-check-label ml-3"
                            htmlFor="form_radio"
                          >
                            {TranslationList("Login")}
                          </Label>
                        </FormGroup>
                      </Col>
                      <Col md={4}>
                        <FormGroup className="mb-3">
                          <Input
                            className="form-check-input me-2"
                            type="radio"
                            name="type_login"
                            id="oneIdRadio"
                            onChange={(e) => setTypeLogin("oneIdRadio")}
                          />
                          <Label
                            className="form-check-label ml-3"
                            htmlFor="oneIdRadio"
                          >
                            {TranslationList("OneID")}
                          </Label>
                        </FormGroup>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Login);
