import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Container,
  Row,
  Table,
  CardHeader,
} from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { useDispatch, useSelector } from "react-redux";
import {
  getexchangeRatesAsync,
} from "../../slices/exchangeRates";
import LoaderContent from "../../Components/Spinner";
import { TranslationList } from "../../slices/settings/listtranslation";
import Paginations from "../Tables/DataTables/Pagination";


export default function ExchangeRates() {
  const perpage = localStorage.getItem("perpage") ?? 10;
  const [perPage, setPerpage] = useState(perpage);
  const dispatch = useDispatch();
  const exchange_ratess = useSelector(
    (state) => state.exchangeRates.data
  );
  const lang = useSelector((state) => state.lang.data);

  useEffect(() => {
    dispatch(getexchangeRatesAsync(`exchange-rates/?page=${1}&limit=${perPage}`));
  }, [lang]);
  const handleSearch = (e) => {
    dispatch(getexchangeRatesAsync(`exchange-rates/?search=${e}`));
  };
//Pagination begin

const handleCurrentPage = (e) => {
  dispatch(
    getexchangeRatesAsync(`exchange-rates/?page=${e}&limit=${perPage}`)
  );
};
const handlePerPageChange = (e) => {
  setPerpage(e);
  dispatch(getexchangeRatesAsync(`exchange-rates/?limit=${e}`));
};
//Pagination end
document.title = `INVESTCONTROL | ${TranslationList("Exchange rates")}`;

  return (
    <div className="page-content">
      <Container fluid>
      <BreadCrumb title={TranslationList("Exchange rates")} pageTitle={TranslationList("home")} />
      <Row>
        <Card>
          <CardHeader>
            <Row className="align-items-center gy-3">
              <div className="col-sm">
                <h5 className="card-title mb-0">{TranslationList("Exchange rates")}</h5>
              </div>
            </Row>
          </CardHeader>
          {exchange_ratess?.results ? (
            <CardBody>
            <div className="live-preview p-3">
              <div className="text-muted table-responsive">
                <Table className="table-bordered align-middle table-nowrap mb-0">
                  <thead>
                    <tr>
                      <th scope="col" className="text-center">
                        {TranslationList("Number")}
                      </th>
                      <th scope="col" className="w-75  text-center">
                      {TranslationList("Сurrency")}
                      </th>
                      <th scope="col" className="w-75  text-center">
                        {TranslationList("Currency code")}
                      </th>
                      <th scope="col" className="w-75  text-center">
                        {TranslationList("Currency symbol")}
                      </th>
                      <th scope="col" className="w-75  text-center">
                        {TranslationList("Nominal")}
                      </th>
                      <th scope="col" className="w-75  text-center">
                        {TranslationList("Currency rate")}
                      </th>
                      <th scope="col" className="w-75  text-center">
                        {TranslationList("Different")}
                      </th>
                      <th scope="col" className="w-75  text-center">
                        {TranslationList("Date")}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {exchange_ratess?.results?.map((res, i) => (
                      <tr key={res?.id}>
                        <td className="text-center">{i + 1}</td>
                        <td>{res?.currency}</td>
                        <td>{res?.code}</td>

                        <td>{res?.symbol}</td>
                        <td>{res?.nominal}</td>
                        <td>{res?.rate}</td>
                        <td>{res?.diff}</td>
                        <td>{res?.date}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </div>
          </CardBody>
          ): (
            <LoaderContent />
          )}
        </Card>
        <Paginations
            totalItems={exchange_ratess?.pagination?.total}
            perPageChange={(e) => handlePerPageChange(e)}
            currentPage={(e) => handleCurrentPage(e)}
          />
      </Row>
    </Container>
    </div>
  );
}
