import React, { useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import Statisticsbar from "../../Components/Analytics/Statistics/Statisticsbar";
import classnames from "classnames";
import { TranslationList } from "../../slices/settings/listtranslation";
import Flatpickr from "react-flatpickr";
import StatisticsAll from "./StatisticsAll";

export default function Statistics() {
  const [customActiveTab, setcustomActiveTab] = useState("1");
  const toggleCustom = (tab) => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            title={TranslationList("Statistics")}
            pageTitle={TranslationList("Analytics")}
          />

          <Card>
            <CardBody>
              <Nav
                tabs
                className="nav nav-tabs nav-tabs-custom nav-success  mb-3"
              >
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: customActiveTab === "1",
                    })}
                    onClick={() => {
                      toggleCustom("1");
                    }}
                  >
                    {TranslationList("Aggregate stats")}
                  </NavLink>
                </NavItem>
                {/* <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: customActiveTab === "2",
                    })}
                    onClick={() => {
                      toggleCustom("2");
                    }}
                  >
                    {TranslationList("Comparative charts")}
                  </NavLink>
                </NavItem> */}
              </Nav>

              <TabContent activeTab={customActiveTab} className="text-muted">
                <TabPane tabId="1" id="home1">
                  <StatisticsAll />
                </TabPane>
                <TabPane tabId="2">
                  <div className="d-flex gap-5 flex-wrap">
                    <Statisticsbar />
                    <Statisticsbar />
                  </div>
                </TabPane>
              </TabContent>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
}
