import { Col, Container, CardHeader, Card, Row } from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { withTranslation } from "react-i18next";
import { TranslationList } from "../../slices/settings/listtranslation";
import TableSubprojects from "./TableSubprojects";
import React from "react";

const Subprojects = ({ t }) => {
  document.title = `INVESTCONTROL | ${TranslationList("Applications")}`;

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            title={TranslationList("REGISTER OF SUBPROJECT")}
            pageTitle={TranslationList("Subprojects")}
          />
          <Row>
            <Col xs={12}>
              <Card>
                <CardHeader>
                  <Row className="align-items-center gy-3">
                    <div className="col-sm">
                      <h5 className="card-title mb-0">
                        {TranslationList("My subprojects")}
                      </h5>
                    </div>
                  </Row>
                </CardHeader>
                <TableSubprojects />
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withTranslation()(Subprojects);
