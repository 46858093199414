import React, { useEffect, useState } from "react";
import {
  Table,
  Modal,
  ModalHeader,
  Input,
  Button,
  Spinner,
  Label,
  Container,
  Form,
  Row,
  Col,
  Card,
  CardHeader,
  Accordion,
  AccordionItem,
  AccordionHeader,
  AccordionBody,
  CardBody,
} from "reactstrap";
import DeleteModal from "../../Components/Common/DeleteModal";
import { registerPlugin } from "react-filepond";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteattachmentation,
  editattachmentAsync,
  getattachmentAsync,
} from "../../slices/attachment";
import { toast } from "react-toastify";
import "filepond/dist/filepond.min.css";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { DataService } from "../../helpers/dataService/dataService";
import { TranslationList } from "../../slices/settings/listtranslation";
import Loader from "../../Components/Spinner";
import { currentapplicAsync } from "../../slices/application";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import endpoints from "../../endpoints";
import classnames from "classnames";
import Empty from "../../Components/Empty";
export default function SubProjectDetail() {
  const [current, setCurrent] = useState();
  const [deleteModal, setDeleteModal] = useState(false);
  const [modal_varying1, setmodal_varying1] = useState(false);
  const [files, setFiles] = useState();
  const [title, setTitle] = useState();
  const [checkError, setCheckError] = useState(false);
  const [notes, setNotes] = useState();
  const profile = useSelector((state) => state.profile?.data);
  const lang = useSelector((state) => state.lang.data);
  registerPlugin(
    FilePondPluginFileValidateSize,
    FilePondPluginFileValidateType
  );
  const router = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [loadingFull, setLoadingFull] = useState(false);
  const [notice, setNotice] = useState();
  const [searchParams] = useSearchParams();
  const application = useSelector((state) => state.applic.current);
  const dispatch = useDispatch();
  const [apiData, setApiData] = useState();
  const [monitoringDocument, setMonitoringDocument] = useState();

  const [open, setOpen] = useState();
  const toggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };
  const fetchData = async () => {
    if (router?.id) {
      const resPeriods = await DataService.get(
        endpoints.investSubprojectManitoring,
        { invest_subproject: router?.id }
      );
      setMonitoringDocument(resPeriods);
    }
  };
  useEffect(() => {
    fetchData();
  }, [lang]);

  function tog_varying1() {
    setFiles();
    setmodal_varying1(!modal_varying1);
  }
  const handleDelete = async () => {
    const response = await deleteattachmentation(`attachment/${current?.id}`);
    if (response?.errorCode) {
      toast.error(TranslationList("An error has occurred!"));
    } else {
      toast.success(TranslationList("Done successfully!"));
      if (router?.id) {
        dispatch(getattachmentAsync(`attachment?application=${router?.id}`));
      } else {
        dispatch(
          getattachmentAsync(`attachment?application=${searchParams.get("id")}`)
        );
      }
    }
    setDeleteModal(false);
  };

  const handleCheckFile = async (res) => {
    switch (profile?.roles[0]?.name) {
      case "Specialist":
        {
          var data = {
            status: document.getElementsByName("results")[0].checked
              ? "center_confirmed"
              : document.getElementById("inlineRadio2").value,
            text: notes,
          };
        }
        break;

      case "District Bank Manager":
        {
          var data = {
            status: document.getElementsByName("results")[0].checked
              ? "center_confirmed"
              : document.getElementById("inlineRadio2").value,
            text: notes,
          };
        }
        break;
    }

    const response = await DataService.put(`attachment/${res?.id}`, data);
    if (response?.errorCode) {
      toast.error(TranslationList("An error has occurred!"));
    } else {
      toast.success(TranslationList("Done successfully!"));
      setLoading(false);
      fetchData();
    }
    setmodal_varying1(false);
  };
  const onSelectMonitoring = async (id) => {
    setLoadingFull(true);
    const response = await DataService.get(endpoints.attachment, {
      invest_subproject_manitoring: id,
    });

    setApiData(response);
    setLoadingFull(false);
  };
  const onSaveAll = async (id) => {
    setLoading(true);
    setCheckError(false);
    try {
      apiData?.results?.forEach(async (res) => {
        try {
          {
            if (document.getElementById(`file-${res?.id}`)?.files[0]) {
              const formdata = new FormData();
              formdata.append(
                "file",
                document.getElementById(`file-${res?.id}`)?.files[0]
              );
              formdata.append("manitoring_document", res?.manitoring_document);
              formdata.append(
                "invest_subproject",
                res?.invest_subproject_manitoring
              );
              if (document.getElementById(`comment-${res?.id}`)?.value) {
                formdata.append(
                  "text",
                  document.getElementById(`comment-${res?.id}`)?.value
                );
              }
              formdata.append("status", "monitoring_process");
              const response = await editattachmentAsync(
                endpoints.uploadManitoring(res?.id),
                formdata
              );
              lenStatus = lenStatus + 1;
            } else {
              setCheckError(true);
              toast.error(
                `${res?.manitoring_document_detail[0]?.name} ${TranslationList(
                  "File not found!"
                )}`
              );
            }
          }
        } catch (err) {
          console.log(err);
        }
      });
      if (!checkError) {
        await DataService.put(endpoints.investSubprojectManitoringById(id), {
          status: "finish_monitoring",
        });
        onSelectMonitoring(id);

        await DataService.put(endpoints.investSubprojectById(router?.id), {
          status: "finish_monitoring",
        });
        navigate("/subprojects");
      }
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };
  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        data={current?.documents_detail?.name}
        onDeleteClick={handleDelete}
        onCloseClick={() => setDeleteModal(false)}
      />
      <Modal
        isOpen={modal_varying1}
        toggle={() => {
          tog_varying1();
        }}
        id="exampleModal"
      >
        <ModalHeader
          toggle={() => {
            tog_varying1();
          }}
        >
          <h5> {title?.documents_detail[0]?.name}</h5>
        </ModalHeader>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            handleCheckFile(title);
          }}
        >
          <div className="modal-body">
            <Container fluid>
              <div className="mb-3  d-flex justify-content-between">
                <div className="form-check form-check-inline align-item-center">
                  <Input
                    required
                    className="form-check-input me-1 "
                    type="radio"
                    name="results"
                    style={{ width: 20, height: 20 }}
                    id="inlineRadio1"
                    value="confirmed"
                  />
                  <Label
                    className="form-check-label fs-18"
                    htmlFor="inlineRadio1"
                  >
                    {TranslationList("Corresponds")}
                  </Label>
                </div>
                <div className="form-check form-check-inline">
                  <Input
                    required
                    className="form-check-input"
                    type="radio"
                    name="results"
                    id="inlineRadio2"
                    value="not_confirmed"
                    style={{ width: 20, height: 20 }}
                  />
                  <Label
                    className="form-check-label fs-18"
                    htmlFor="inlineRadio2"
                  >
                    {TranslationList("does_not_fit")}
                  </Label>
                </div>
              </div>
              <div className="invalid-feedback">
                More example invalid feedback text
              </div>

              <div className="mb-3 ">
                <label htmlFor="notes" className="col-form-label">
                  {TranslationList("Notes")}
                </label>
                <Input
                  required
                  type="textarea"
                  id="notes"
                  name="notes"
                  className="form-control"
                  onChange={(e) => setNotes(e.target.value)}
                />
                <div className="invalid-feedback">
                  More example invalid feedback text
                </div>
              </div>
            </Container>
          </div>
          <div className="modal-footer">
            <Button
              color="light"
              onClick={() => {
                setmodal_varying1(false);
              }}
            >
              {TranslationList("Cancel")}
            </Button>

            <Button color="primary" type="submit" disabled={loading}>
              {TranslationList("Send")}
            </Button>
          </div>
        </Form>
      </Modal>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            title={TranslationList("REGISTER OF SUBPROJECT")}
            pageTitle={TranslationList("Subprojects")}
          />
          <Row>
            <Col xs={12}>
              <Card>
                <CardHeader>
                  <Row className="align-items-center gy-3">
                    <div className="col-sm">
                      <h5 className="card-title mb-0">
                        {TranslationList("Monitoring")}
                      </h5>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Accordion
                    open={open}
                    toggle={toggle}
                    className={
                      "custom-accordionwithicon custom-accordion-border accordion-border-box"
                    }
                    id="accordionBordered"
                  >
                    {monitoringDocument ? (
                      monitoringDocument?.results?.length > 0 ? (
                        monitoringDocument?.results?.map((res) => (
                          <div
                            className={
                              res?.status == "finish_monitoring"
                                ? "accordion-warning mb-2"
                                : "accordion-success mb-2"
                            }
                            key={res?.id.toString()}
                          >
                            <AccordionItem>
                              <AccordionHeader
                                targetId={res?.id.toString()}
                                onClick={() => onSelectMonitoring(res?.id)}
                              >
                                {res?.date_start_manitoring}
                              </AccordionHeader>
                              <AccordionBody accordionId={res?.id.toString()}>
                                {!loading && apiData ? (
                                  <div className="live-preview">
                                    <div className="text-muted table-responsive">
                                      <Table className="text-muted table-bordered align-middle  mb-2 ">
                                        <thead>
                                          <tr>
                                            <th scope="col">№</th>
                                            <th scope="col">
                                              {TranslationList(
                                                "Monitoring_Documents"
                                              )}
                                            </th>

                                            <th scope="col">
                                              {TranslationList("Creator")}
                                            </th>

                                            <th scope="col">
                                              {TranslationList("File name")}
                                            </th>

                                            <th>{TranslationList("Notes")}</th>
                                            <th
                                              scope="col"
                                              className="text-center"
                                            >
                                              {TranslationList("Actions")}
                                            </th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {apiData?.results?.map((item, i) => (
                                            <tr key={item?.id}>
                                              <td className="fw-medium">
                                                {i + 1}
                                              </td>
                                              <td
                                                className="col-md-4"
                                                style={{
                                                  wordWrap: "break-word",
                                                }}
                                              >
                                                <div
                                                  style={{
                                                    wordWrap: "break-word",
                                                  }}
                                                >
                                                  {
                                                    item
                                                      ?.manitoring_document_detail[0]
                                                      ?.name
                                                  }
                                                </div>
                                              </td>
                                              <td>
                                                {item?.created_by_info?.map(
                                                  (item) => (
                                                    <div key={item?.id}>
                                                      <span>
                                                        {item?.last_name}{" "}
                                                      </span>
                                                      <span>
                                                        {item?.first_name}
                                                      </span>
                                                    </div>
                                                  )
                                                )}
                                              </td>

                                              <td className="col-md-4">
                                                {!item?.file ? (
                                                  <Input
                                                    id={`file-${item?.id}`}
                                                    type="file"
                                                  />
                                                ) : (
                                                  <div
                                                    style={{
                                                      wordWrap: "break-word",
                                                    }}
                                                  >
                                                    {item?.file_name}
                                                  </div>
                                                )}
                                              </td>
                                              <td className="col-md-4">
                                                <div className="d-flex justify-content-between gap-2 h-75 align-items-center">
                                                  {!item?.file ? (
                                                    <Input
                                                      id={`comment-${item?.id}`}
                                                      defaultValue={item?.text}
                                                      type="textarea"
                                                      // onChange={(e) => {
                                                      //   setNotice(e.target.value);
                                                      // }}
                                                    />
                                                  ) : (
                                                    <div
                                                      style={{
                                                        wordWrap: "break-word",
                                                      }}
                                                    >
                                                      {item?.text}
                                                    </div>
                                                  )}
                                                  {/* <button
                                                  className="btn btn-primary"
                                                  type="button"
                                                  onClick={() =>
                                                    handleSaveNotice(item)
                                                  }
                                                  disabled={loading}
                                                >
                                                  {loading ? (
                                                    <Spinner
                                                      size="sm"
                                                      className="me-2"
                                                    >
                                                      {" "}
                                                      Loading...{" "}
                                                    </Spinner>
                                                  ) : null}
                                                  {TranslationList("Save")}
                                                </button> */}
                                                </div>
                                              </td>

                                              <td>
                                                <div className="hstack gap-2 d-flex justify-content-center">
                                                  {profile?.roles[0]?.name ==
                                                    "District Bank Manager" &&
                                                    application?.stage ==
                                                      "stagetumbank" &&
                                                    item?.status ==
                                                      "confirmed" &&
                                                    item.documents_detail[0]
                                                      .type != "ISCAD" && (
                                                      <button
                                                        className="btn  btn-soft-success remove-list"
                                                        onClick={() => {
                                                          setTitle(item);
                                                          tog_varying1();
                                                        }}
                                                      >
                                                        {TranslationList(
                                                          "Confirmation"
                                                        )}
                                                      </button>
                                                    )}

                                                  {item?.file && (
                                                    <a
                                                      className="btn  btn-soft-info edit-list"
                                                      href={item.file}
                                                      target="_blank"
                                                    >
                                                      <i className="ri-download-fill align-bottom" />
                                                    </a>
                                                  )}
                                                  {item?.status ==
                                                    "not_confirmed" && (
                                                    <div className="btn  btn-soft-danger show-list">
                                                      <i className="ri-close-circle-fill align-bottom" />
                                                    </div>
                                                  )}
                                                  {item?.status ==
                                                    "monitoring_process" && (
                                                    <div className="btn  btn-soft-success show-list">
                                                      <i className="ri-check-fill align-bottom" />
                                                    </div>
                                                  )}
                                                </div>
                                              </td>
                                            </tr>
                                          ))}
                                        </tbody>
                                      </Table>
                                      {res?.status != "finish_monitoring" ? (
                                        <div className="d-flex w-100 mt-3 justify-content-end align-items-center">
                                          <button
                                            className="btn btn-primary"
                                            type="button"
                                            onClick={() => onSaveAll(res?.id)}
                                            disabled={loading}
                                          >
                                            {loading ? (
                                              <Spinner
                                                size="sm"
                                                className="me-2"
                                              >
                                                {" "}
                                                Loading...{" "}
                                              </Spinner>
                                            ) : null}
                                            {TranslationList("Save")}
                                          </button>
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </div>
                                ) : (
                                  <Loader />
                                )}
                              </AccordionBody>
                            </AccordionItem>
                          </div>
                        ))
                      ) : (
                        <Empty />
                      )
                    ) : (
                      <Loader />
                    )}
                  </Accordion>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}
