import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
} from "reactstrap";
import dateFormat from "dateformat";
import progileBg from "../../assets/images/profile-bg.jpg";
import avatar1 from "../../assets/images/users/avatar-1.jpg";
import { useDispatch, useSelector } from "react-redux";
import {
  addusersAsync,
  editusersAsync,
  getuserFilterAsync,
  getusersAsync,
} from "../../slices/users";
import {
  getdistrictCurrentAsync,
  getfullregionsAsync,
} from "../../slices/district";
import { getroleAsync } from "../../slices/role";
import { TranslationList } from "../../slices/settings/listtranslation";
import Select from "react-select";
import { getorganizationAsync } from "../../slices/organization";
import { toast } from "react-toastify";
import { api } from "../../config";
import { DataService } from "../../helpers/dataService/dataService";
const Settings = () => {
  const dispatch = useDispatch();
  let [searchParams] = useSearchParams();
  const [current, setCurrent] = useState();
  const [userName, setUserName] = useState();
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const organizations = useSelector(
    (state) => state.organization?.data?.results
  );
  const regions = useSelector((state) => state.district.regions?.results);
  const [districts, setDistrict] = useState();
  const roles = useSelector((state) => state.roles?.data?.results);
  const users = useSelector((state) => state.users?.data);
  const [surName, setSurName] = useState();
  const [gender, setGender] = useState();
  const [JSHSHIR, setJSHSHIR] = useState();
  const [givenBy, setGivenBy] = useState();
  const [givenDate, setGivenDate] = useState();
  const [pasportSeria, setPasportSeria] = useState();
  const [pasportNumber, setPasportNumber] = useState();
  const [inn, setInn] = useState();
  const [email, setEmail] = useState();
  const [dateBirthday, setDateBirthday] = useState();
  const [dateJoined, setDateJoined] = useState();
  const [selectMulti, setSelectMulti] = useState(null);
  const [sortbyMulti, setSortbyMulti] = useState();
  const [error, setError] = useState();
  const perpage = localStorage.getItem("perpage") ?? 10;
  const [perPage, setPerpage] = useState(perpage);
  const lang = useSelector((state) => state.lang.data);
  const [department, setDepartment] = useState();
  const [position, setPosition] = useState();
  const navigate = useNavigate();
  const params = useParams();
  const getUserById = async () => {
    const response = await getuserFilterAsync(`user/${params?.id}`);
    setCurrent(response);
    if (response?.department) {
      const responseposition = await DataService.get(
        `position/?department=${response?.department}`
      );
      setPosition(responseposition?.results);
    }
    if (response?.district) {
      const responsedistrict = await DataService.get(
        `district/?region=${response?.region}`
      );
      setDistrict(responsedistrict?.results);
    }
  };
  const getData = async () => {
    try {
      const response = await DataService.get("department/");
      setDepartment(response?.results);
    } catch (error) {
      console.log(error);
    }
  };
  const SelectDepartment = async (id) => {
    try {
      const response = await DataService.get(`position/?department=${id}`);
      setPosition(response?.results);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getData();
    getUserById();
  }, [params?.id]);
  useEffect(() => {
    dispatch(getusersAsync(`user/?page=${1}&limit=${perPage}`));
    dispatch(getorganizationAsync(`organization/?limit=100`));
    dispatch(getfullregionsAsync("region/?limit=100"));
    dispatch(getroleAsync(`role/?limit=100`));
  }, [lang]);

  const SelectChange = async (id) => {
    const responsedistrict = await DataService.get(`district/?region=${id}`);
    setDistrict(responsedistrict?.results);
  };
  useEffect(() => {
    const data = roles?.map((res) => {
      return { label: TranslationList(res.name), value: res.id };
    });
    setSortbyMulti(data);
  }, [roles]);

  const handleAdd = async () => {
    let rolarr = [];
    rolarr = selectMulti?.map((res) => {
      return res.value;
    });
    const dataDocument = {
      username: userName,
      first_name: firstName,
      last_name: lastName,
      surname: surName,
      gender: gender,
      given_by: givenBy,
      given_date: givenDate,
      pasport_serie: pasportSeria,
      password_number: pasportNumber,
      jshshir: JSHSHIR,
      region: document.getElementById("region").value,
      district: document.getElementById("district").value,
      inn: inn,
      date_of_birthday: dateBirthday,
      email: email,
      date_joined: dateJoined,
      organization: document.getElementById("organization").value,
      roles: rolarr,
      is_active: "True",
    };
    var file;
    if (document.getElementById("profile-img-file-input").files[0]) {
      file = document.getElementById("profile-img-file-input").files[0];
    }
    if (document.getElementById("password").value) {
      dataDocument.password = document.getElementById("password").value;
    }

    let formdata = new FormData();
    if (file) {
      formdata.append("avatar", file);
    }
    for (let key in dataDocument) {
      if (dataDocument.hasOwnProperty(key)) {
        formdata.append(key, dataDocument[key]);
      }
    }
    const response = await addusersAsync("user/", formdata, {
      "Content-Type": "multipart/form-data",
    });
    if (response?.errorCode) {
      setError(response?.message);
      toast.error(TranslationList("An error has occurred!"));
    } else {
      toast.success(TranslationList("Done successfully!"));
      dispatch(getusersAsync(`user/?page=${1}&limit=${perPage}`));
      navigate("/users");
    }
  };

  const handleEdite = async () => {
    let rolarr = [];
    rolarr = selectMulti?.map((res) => {
      return res.value;
    });
    const dataDocument = {
      organization: document.getElementById("oragnization").value,

      username: document.getElementById("username").value,
      first_name: document.getElementById("first_name").value,
      last_name: document.getElementById("last_name").value,
      surname: document.getElementById("surname").value,
      gender: document.getElementById("gender").value,
      given_by: document.getElementById("given_by").value,
      given_date: document.getElementById("given_date").value,
      pasport_serie: document.getElementById("pasport_serie").value,
      password_number: document.getElementById("pasport_number").value,
      jshshir: document.getElementById("jshshir").value,
      inn: document.getElementById("inn").value,
      date_of_birthday: document.getElementById("date_of_birthday").value,
      email: document.getElementById("email").value,
      date_joined: document.getElementById("date_joined").value,
      department: document.getElementById("department").value,
      position: document.getElementById("position").value,
      region: document.getElementById("region").value,
      district: document.getElementById("district").value,
      roles: rolarr,
      is_active: "True",
    };
    var file;
    if (document.getElementById("profile-img-file-input").files[0]) {
      file = document.getElementById("profile-img-file-input").files[0];
    }
    if (document.getElementById("password").value) {
      dataDocument.password = document.getElementById("password").value;
    }

    let formdata = new FormData();
    if (file) {
      formdata.append("avatar", file);
    }
    for (let key in dataDocument) {
      if (dataDocument.hasOwnProperty(key)) {
        formdata.append(key, dataDocument[key]);
      }
    }
    const response = await editusersAsync(`user/${params?.id}`, formdata, {
      "Content-Type": "multipart/form-data",
    });
    if (response?.errorCode) {
      toast.error(TranslationList("An error has occurred!"));
    } else {
      toast.success(TranslationList("Done successfully!"));
      dispatch(getusersAsync(`user/?limit=100`));
      navigate("/users");
    }
  };

  document.title = `${TranslationList("Profile")} | INVESTCONTROL`;

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="position-relative mx-n4 mt-n4">
            <div className="profile-wid-bg profile-setting-img">
              <img src={progileBg} className="profile-wid-img" alt="" />
            </div>
          </div>
          <Row>
            <Col xxl={3}>
              <Card className="mt-n5">
                <CardBody className="p-4">
                  <div className="text-center">
                    <div className="profile-user position-relative d-inline-block mx-auto  mb-4">
                      <img
                        src={
                          current?.avatar
                            ? api.FILE_URL + current?.avatar
                            : avatar1
                        }
                        className="rounded-circle avatar-xl img-thumbnail user-profile-image"
                        alt="user-profile"
                        id="avatar"
                      />
                      <div className="avatar-xs p-0 rounded-circle profile-photo-edit">
                        <Input
                          id="profile-img-file-input"
                          type="file"
                          accept="image/*"
                          className="profile-img-file-input"
                          onChange={(e) => {
                            document.getElementById("avatar").src =
                              URL.createObjectURL(e.target.files[0]);
                          }}
                        />
                        <Label
                          htmlFor="profile-img-file-input"
                          className="profile-photo-edit avatar-xs"
                        >
                          <span className="avatar-title rounded-circle bg-light text-body">
                            <i className="ri-camera-fill"></i>
                          </span>
                        </Label>
                      </div>
                    </div>
                    <h5 className="fs-16 mb-1">
                      {current?.last_name} {current?.first_name}
                    </h5>
                    {current?.roles?.map((res, i) => (
                      <p className="text-muted mb-0" key={i}>
                        {res?.name} /
                      </p>
                    ))}
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col xxl={9}>
              <Card className="mt-xxl-n5">
                <CardBody className="p-4">
                  <Form>
                    <Row>
                      <Col lg={6}>
                        <div className="mb-3">
                          <Label htmlFor="lastnameInput" className="form-label">
                            {TranslationList("Last name")}
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="last_name"
                            onChange={(e) => setLastName(e.target.value)}
                            defaultValue={current?.last_name}
                            invalid={error?.last_name ? true : false}
                          />
                          <FormFeedback type="invalid">
                            {error?.last_name}
                          </FormFeedback>
                        </div>
                      </Col>

                      <Col lg={6}>
                        <div className="mb-3">
                          <Label
                            htmlFor="firstnameInput"
                            className="form-label"
                          >
                            {TranslationList("First name")}
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="first_name"
                            onChange={(e) => setFirstName(e.target.value)}
                            defaultValue={current?.first_name}
                            invalid={error?.first_name ? true : false}
                          />
                          <FormFeedback type="invalid">
                            {error?.first_name}
                          </FormFeedback>
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="mb-3">
                          <Label
                            htmlFor="phonenumberInput"
                            className="form-label"
                          >
                            {TranslationList("Surname")}
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="surname"
                            onChange={(e) => setSurName(e.target.value)}
                            defaultValue={current?.surname}
                            invalid={error?.surname ? true : false}
                          />
                          <FormFeedback type="invalid">
                            {error?.surname}
                          </FormFeedback>
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="mb-3">
                          <Label
                            htmlFor="firstnameInput"
                            className="form-label"
                          >
                            {TranslationList("Username")}
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="username"
                            onChange={(e) => setUserName(e.target.value)}
                            defaultValue={current?.username}
                            invalid={error?.username ? true : false}
                          />
                          <FormFeedback type="invalid">
                            {error?.username}
                          </FormFeedback>
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="mb-3">
                          <Label
                            htmlFor="firstnameInput"
                            className="form-label"
                          >
                            {TranslationList("Password")}
                          </Label>
                          <Input
                            type="password"
                            className="form-control"
                            id="password"
                            invalid={error?.password ? true : false}
                          />
                          <FormFeedback type="invalid">
                            {error?.password}
                          </FormFeedback>
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="mb-3">
                          <Label htmlFor="lastnameInput" className="form-label">
                            {TranslationList("Department")}
                          </Label>
                          <select
                            key={current?.department}
                            className="form-select mb-3 col-6"
                            aria-label="Default select example"
                            id="department"
                            defaultValue={current?.department}
                            onChange={(e) => SelectDepartment(e.target.value)}
                          >
                            <option></option>
                            {department?.map((res) => (
                              <option key={res.id} value={res.id}>
                                {res.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="mb-3">
                          <Label htmlFor="lastnameInput" className="form-label">
                            {TranslationList("Position")}
                          </Label>
                          <select
                            key={current?.position + position?.length}
                            className="form-select mb-3 col-6"
                            aria-label="Default select example"
                            id="position"
                            defaultValue={current?.position}
                          >
                            <option></option>
                            {position?.map((res) => (
                              <option key={res.id} value={res.id}>
                                {res.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="mb-3">
                          <Label htmlFor="lastnameInput" className="form-label">
                            {TranslationList("Region")}
                          </Label>
                          <select
                            key={current?.region + regions?.length}
                            className="form-select mb-3 col-6"
                            aria-label="Default select example"
                            id="region"
                            defaultValue={current?.region}
                            onChange={(e) => SelectChange(e.target.value)}
                          >
                            <option></option>
                            {regions?.map((res) => (
                              <option key={res.id} value={res.id}>
                                {res.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="mb-3">
                          <Label htmlFor="lastnameInput" className="form-label">
                            {TranslationList("District")}
                          </Label>
                          <select
                            key={current?.district + districts?.length}
                            className="form-select mb-3 col-6"
                            aria-label="Default select example"
                            id="district"
                            defaultValue={current?.district}
                          >
                            <option></option>
                            {districts?.map((res) => (
                              <option key={res.id} value={res.id}>
                                {res.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="mb-3">
                          <Label htmlFor="lastnameInput" className="form-label">
                            {TranslationList("Organization")}
                          </Label>
                          <select
                            key={current?.organization}
                            className="form-select mb-3 col-6"
                            aria-label="Default select example"
                            id="oragnization"
                            defaultValue={current?.organization}
                          >
                            <option></option>
                            {organizations?.map((res) => (
                              <option key={res.id} value={res.id}>
                                {res.name}{" "}
                              </option>
                            ))}
                          </select>
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="mb-3">
                          <Label htmlFor="lastnameInput" className="form-label">
                            {TranslationList("Gender")}
                          </Label>
                          <select
                            className="form-select mb-3 col-6"
                            aria-label="Default select example"
                            id="gender"
                            key={current?.gender}
                            defaultValue={current?.gender}
                            onChange={(e) => setGender(e.target.value)}
                          >
                            <option></option>
                            <option value="male">
                              {TranslationList("Male")}
                            </option>
                            <option value="female">
                              {TranslationList("Female")}
                            </option>
                          </select>
                          <FormFeedback type="invalid">
                            {error?.gender}
                          </FormFeedback>
                        </div>
                      </Col>

                      <Col lg={6}>
                        <div className="mb-3">
                          <Label
                            htmlFor="phonenumberInput"
                            className="form-label"
                          >
                            {TranslationList("Pasport Seria")}
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="pasport_serie"
                            onChange={(e) => setPasportSeria(e.target.value)}
                            defaultValue={current?.pasport_serie}
                            invalid={error?.pasport_serie ? true : false}
                          />
                          <FormFeedback type="invalid">
                            {error?.pasport_serie}
                          </FormFeedback>
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="mb-3">
                          <Label
                            htmlFor="phonenumberInput"
                            className="form-label"
                          >
                            {TranslationList("Pasport Number")}
                          </Label>
                          <Input
                            type="number"
                            className="form-control"
                            id="pasport_number"
                            onChange={(e) => setPasportNumber(e.target.value)}
                            defaultValue={current?.password_number}
                            invalid={error?.password_number ? true : false}
                          />
                          <FormFeedback type="invalid">
                            {error?.password_number}
                          </FormFeedback>
                        </div>
                      </Col>

                      <Col lg={6}>
                        <div className="mb-3">
                          <Label
                            htmlFor="phonenumberInput"
                            className="form-label"
                          >
                            {TranslationList("Given By")}
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="given_by"
                            onChange={(e) => setGivenBy(e.target.value)}
                            defaultValue={current?.given_by}
                            invalid={error?.given_by ? true : false}
                          />
                          <FormFeedback type="invalid">
                            {error?.given_by}
                          </FormFeedback>
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="mb-3">
                          <Label
                            htmlFor="JoiningdatInput"
                            className="form-label"
                          >
                            {TranslationList("Given Data")}
                          </Label>
                          <Input
                            type="date"
                            className="form-control"
                            id="given_date"
                            options={{
                              dateFormat: "YYYY-MM-DD",
                            }}
                            defaultValue={current?.given_date}
                            onChange={(data) =>
                              setGivenDate(
                                dateFormat(data.target.value, "yyyy-mm-dd")
                              )
                            }
                          />
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="mb-3">
                          <Label
                            htmlFor="phonenumberInput"
                            className="form-label"
                          >
                            {TranslationList("JSHSHIR")}
                          </Label>
                          <Input
                            type="number"
                            className="form-control"
                            id="jshshir"
                            placeholder="Enter your JSHSHIR"
                            onChange={(e) => setJSHSHIR(e.target.value)}
                            defaultValue={current?.jshshir}
                            invalid={error?.jshshir ? true : false}
                          />
                          <FormFeedback type="invalid">
                            {error?.jshshir}
                          </FormFeedback>
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="mb-3">
                          <Label
                            htmlFor="phonenumberInput"
                            className="form-label"
                          >
                            {TranslationList("Inn")}
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="inn"
                            placeholder="Enter your INN"
                            onChange={(e) => setInn(e.target.value)}
                            defaultValue={current?.inn}
                            invalid={error?.inn ? true : false}
                          />
                          <FormFeedback type="invalid">
                            {error?.inn}
                          </FormFeedback>
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="mb-3">
                          <Label
                            htmlFor="phonenumberInput"
                            className="form-label"
                          >
                            {TranslationList("Data Of Birthday")}
                          </Label>
                          <Input
                            className="form-control"
                            id="date_of_birthday"
                            type="date"
                            options={{
                              dateFormat: "YYYY-MM-DD",
                            }}
                            defaultValue={current?.date_of_birthday}
                            onChange={(data) =>
                              setDateBirthday(
                                dateFormat(data.target.value, "yyyy-mm-dd")
                              )
                            }
                          />
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="mb-3">
                          <Label
                            htmlFor="JoiningdatInput"
                            className="form-label"
                          >
                            {TranslationList("Joining Date")}
                          </Label>
                          <Input
                            className="form-control"
                            id="date_joined"
                            disabled
                            type="date"
                            options={{
                              dateFormat: "YYYY-MM-DD",
                            }}
                            defaultValue={dateFormat(
                              current?.date_joined,
                              "yyyy-mm-dd"
                            )}
                            onChange={(data) =>
                              setDateJoined(
                                dateFormat(data.target.value, "yyyy-mm-dd")
                              )
                            }
                          />
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="mb-3">
                          <Label
                            htmlFor="phonenumberInput"
                            className="form-label"
                          >
                            {TranslationList("Email")}
                          </Label>
                          <Input
                            type="email"
                            className="form-control"
                            id="email"
                            placeholder="Enter your phone Email"
                            onChange={(e) => setEmail(e.target.value)}
                            defaultValue={current?.email}
                            invalid={error?.email ? true : false}
                          />
                          <FormFeedback type="invalid">
                            {error?.email}
                          </FormFeedback>
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="mb-3">
                          <Label htmlFor="emailInput" className="form-label">
                            {TranslationList("Role")}
                          </Label>
                          <Select
                            style={{ backgroundColor: "white" }}
                            key={sortbyMulti?.length}
                            value={
                              selectMulti ??
                              current?.roles?.map((res) => {
                                return {
                                  label: TranslationList(res.name),
                                  value: res.id,
                                };
                              })
                            }
                            isMulti={true}
                            onChange={(sortBy) => {
                              setSelectMulti(sortBy);
                            }}
                            // defaultValue={current?.organization_detail?.map((res) => {
                            //   return { label: res.name, value: res.id };
                            // })}
                            options={sortbyMulti}
                            classNamePrefix="js-example-basic-multiple mb-0 col-6"
                          />
                        </div>
                      </Col>

                      <Col lg={12}>
                        <div className="modal-footer">
                          {params?.id ? (
                            <Button color="primary" onClick={handleEdite}>
                              {TranslationList("Save")}
                            </Button>
                          ) : (
                            <Button color="primary" onClick={handleAdd}>
                              {TranslationList("Create")}
                            </Button>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Settings;
