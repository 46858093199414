import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Container,
  Row,
  Table,
  CardHeader,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Input,
  Button,
} from "reactstrap";
import { Link } from "react-router-dom";
import classnames from "classnames";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { useDispatch, useSelector } from "react-redux";
import {
  addpfiAsync,
  deletepfi,
  editpfiAsync,
  getpfiAsync,
} from "../../slices/pfi";
import { toast } from "react-toastify";
import DeleteModal from "../../Components/Common/DeleteModal";

import { TranslationList } from "../../slices/settings/listtranslation";
import {
  getdistrictCurrentAsync,
  getfullregionsAsync,
} from "../../slices/district";

export default function ParticipatingBank() {
  const regionsfull = useSelector((state) => state.district.regions?.results);
  const [modal_varying1, setmodal_varying1] = useState(false);
  const [nameUZ, setNameUZ] = useState();
  const [nameRU, setNameRU] = useState();
  const [nameEN, setNameEN] = useState();
  const [level, setLevel] = useState();
  const [address, setAddresss] = useState();
  const [phoneNumber, setPhoneNumber] = useState();
  const [financingVolume, setFinancingVolume] = useState();
  const [current, setCuttent] = useState();
  //   const [code, setCode] = useState();
  const [deleteModal, setDeleteModal] = useState(false);

  const dispatch = useDispatch();
  const pfis = useSelector((state) => state.pfi.data?.results);
  const districtCurrent = useSelector(
    (state) => state.district?.current?.results
  );
  useEffect(() => {
    dispatch(getpfiAsync("pfi/"));
    dispatch(getfullregionsAsync("region/?limit=100"));
    if (regionsfull)
      dispatch(
        getdistrictCurrentAsync(
          `district/?region=${regionsfull[0]?.id}&limit=100`
        )
      );
  }, []);

  const SelectChange = (id) => {
    dispatch(getdistrictCurrentAsync(`district/?region=${id}&limit=100`));
  };
  function tog_varying1() {
    setmodal_varying1(!modal_varying1);
    setCuttent();
  }
  const handleAdd = async () => {
    const dataDocument = {
      region: document.getElementById("region").value,
      district: document.getElementById("district").value,
      name_uz: nameUZ,
      name_ru: nameRU,
      name_en: nameEN,
      level: level,
      address: address,
      phone_number: phoneNumber,
      financing_volume: financingVolume,
    };
    const response = await addpfiAsync("pfi/", dataDocument);
    if (response?.errorCode) {
      toast.error(TranslationList("An error has occurred!"));
    } else {
      toast.success(TranslationList("Done successfully!"));
      dispatch(getpfiAsync(`pfi/`));
    }

    setmodal_varying1(false);
  };
  const onClickEdite = (item) => {
    setmodal_varying1(true);
    setCuttent(item);
  };
  const handleEdite = async () => {
    const dataDocument = {
      name_uz: document.getElementById("name_uz").value,
      name_ru: document.getElementById("name_ru").value,
      name_en: document.getElementById("name_en").value,
      level: document.getElementById("level").value,
    };

    const response = await editpfiAsync(`pfi/${current?.id}`, dataDocument);
    if (response?.errorCode) {
      toast.error(TranslationList("An error has occurred!"));
    } else {
      toast.success(TranslationList("Done successfully!"));
      dispatch(getpfiAsync(`pfi/`));
    }

    setmodal_varying1(false);
  };
  const onClickTodoDelete = (item) => {
    setCuttent(item);
    setDeleteModal(true);
  };
  const handleDelete = async () => {
    const response = await deletepfi(`pfi/${current?.id}`);
    if (response?.errorCode) {
      toast.error(TranslationList("An error has occurred!"));
    } else {
      toast.success(TranslationList("Done successfully!"));
      dispatch(getpfiAsync(`pfi/`));
    }
    setDeleteModal(false);
  };
  document.title = "pfi | Velzon - React Admin & Dashboard Template";

  return (
    <div className="page-content">
      <DeleteModal
        show={deleteModal}
        data={current?.name}
        onDeleteClick={handleDelete}
        onCloseClick={() => setDeleteModal(false)}
      />
      <Modal
        isOpen={modal_varying1}
        toggle={() => {
          tog_varying1();
        }}
        id="exampleModal"
      >
        <ModalHeader
          toggle={() => {
            tog_varying1();
          }}
        >
          {current ? "Edite" : "Add"}{" "}
        </ModalHeader>
        <div className="modal-body">
          <form>
            <div className="mb-3">
              {" "}
              <label htmlFor="customer-name" className="col-form-label">
                Name UZ:
              </label>
              <Input
                id="name_uz"
                type="text"
                className="form-control"
                onChange={(e) => setNameUZ(e.target.value)}
                defaultValue={current?.name_uz}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="customer-name" className="col-form-label">
                Name RU:
              </label>
              <Input
                type="text"
                id="name_ru"
                className="form-control"
                onChange={(e) => setNameRU(e.target.value)}
                defaultValue={current?.name_ru}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="customer-name" className="col-form-label">
                Name EN:
              </label>
              <Input
                type="text"
                id="name_en"
                className="form-control"
                onChange={(e) => setNameEN(e.target.value)}
                defaultValue={current?.name_en}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="customer-name" className="col-form-label">
                Level :
              </label>
              <Input
                type="text"
                id="level"
                className="form-control"
                onChange={(e) => setLevel(e.target.value)}
                defaultValue={current?.level}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="department" className="col-form-label">
                Region :
              </label>
              <select
                className="form-select mb-3"
                aria-label="Default select example"
                id="region"
                defaultValue={current?.region?.id}
                onChange={(e) => SelectChange(e.target.value)}
              >
                {regionsfull?.map((res) => (
                  <option key={res.id} value={res.id}>
                    {res.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="mb-3">
              <label htmlFor="district" className="col-form-label">
                District :
              </label>
              <select
                className="form-select mb-3"
                aria-label="Default select example"
                id="district"
                defaultValue={current?.district?.id}
              >
                {districtCurrent?.map((res) => (
                  <option key={res.id} value={res.id}>
                    {res.name}{" "}
                  </option>
                ))}
              </select>
            </div>
            <div className="mb-3">
              {" "}
              <label htmlFor="customer-name" className="col-form-label">
                Address :
              </label>
              <Input
                id="address"
                type="text"
                className="form-control"
                onChange={(e) => setAddresss(e.target.value)}
                defaultValue={current?.address}
              />
            </div>
            <div className="mb-3">
              {" "}
              <label htmlFor="customer-name" className="col-form-label">
                Phone number :
              </label>
              <Input
                id="phone_number"
                type="text"
                className="form-control"
                onChange={(e) => setPhoneNumber(e.target.value)}
                defaultValue={current?.phone_number}
              />
            </div>
            <div className="mb-3">
              {" "}
              <label htmlFor="customer-name" className="col-form-label">
                Financing volume:
              </label>
              <Input
                id="financing_volume"
                type="text"
                className="form-control"
                onChange={(e) => setFinancingVolume(e.target.value)}
                defaultValue={current?.financing_volume}
              />
            </div>
          </form>
        </div>
        <div className="modal-footer">
          <Button
            color="light"
            onClick={() => {
              setmodal_varying1(false);
            }}
          >
            Отменить
          </Button>
          {current ? (
            <Button color="primary" onClick={handleEdite}>
              Сохранить
            </Button>
          ) : (
            <Button color="primary" onClick={handleAdd}>
              Создать
            </Button>
          )}
        </div>
      </Modal>

      <Container fluid>
        <BreadCrumb title="Participating Bank" pageTitle="Home" />
        <Row>
          <Card>
            <CardHeader>
              <Row className="align-items-center gy-3">
                <div className="col-sm">
                  <h5 className="card-title mb-0"> Participating Bank</h5>
                </div>
                <div className="col-sm-auto">
                  <div className="d-flex gap-1 flex-wrap">
                    <button
                      type="button"
                      className="btn btn-success add-btn"
                      id="create-btn"
                      onClick={() => tog_varying1()}
                    >
                      <i className="ri-add-line align-bottom me-1"></i>{" "}
                      {TranslationList("Participating Bank")}
                    </button>{" "}
                  </div>
                </div>
              </Row>
            </CardHeader>
            <CardBody>
              <div className="live-preview p-3">
                <div className="text-muted table-responsive">
                  <Table className="table-bordered align-middle table-nowrap mb-0">
                    <thead>
                      <tr>
                        <th scope="col" className="text-center">
                          Номер
                        </th>
                        <th scope="col" className="w-50  text-center">
                          Название
                        </th>
                        <th scope="col" className="w-50  text-center">
                          Левел
                        </th>
                        <th scope="col" className="w-50  text-center">
                          Region
                        </th>
                        <th scope="col" className="w-50  text-center">
                          District
                        </th>
                        <th scope="col" className="w-50  text-center">
                          Address
                        </th>
                        <th scope="col" className="w-50  text-center">
                          Phone number
                        </th>
                        <th scope="col" className="w-50  text-center">
                          Financing volume
                        </th>
                        <th scope="col " className="text-center">
                          Действия
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {pfis?.map((res, i) => (
                        <tr key={res?.id}>
                          <td className="align-items-center">{i + 1}</td>
                          <td>{res?.name}</td>
                          <td>{res?.level}</td>
                          <td>{res?.region_detail?.name}</td>
                          <td>{res?.district_detail?.name}</td>
                          <td>{res?.address}</td>
                          <td>{res?.phone_number}</td>
                          <td>{res?.financing_volume}</td>
                          <td className="text-center">
                            <div className="hstack gap-2 justify-content-center">
                              <button
                                className="btn btn-sm btn-soft-danger remove-list"
                                onClick={() => onClickTodoDelete(res)}
                              >
                                <i className="ri-delete-bin-5-fill align-bottom" />
                              </button>
                              <button
                                className="btn btn-sm btn-soft-info edit-list"
                                onClick={() => onClickEdite(res)}
                              >
                                <i className="ri-pencil-fill align-bottom" />
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </div>
            </CardBody>
          </Card>
        </Row>
      </Container>
    </div>
  );
}
