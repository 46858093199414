import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import {
  Card,
  CardBody,
  Modal,
  ModalBody,
  ModalHeader,
  Input,
  Container,
  Button,
  Spinner,
  Label,
  Form,
} from "reactstrap";
import { TranslationList } from "../../slices/settings/listtranslation";
import { useSelector } from "react-redux";
import dateFormat from "dateformat";
import { DataService } from "../../helpers/dataService/dataService";
import { toast } from "react-toastify";
import { useNavigate, useSearchParams } from "react-router-dom";
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import {
  addattachmentAsync,
  editattachmentAsync,
} from "../../slices/attachment";
import ForPDF from "./ForPDF";
import endpoints from "../../endpoints";
function Actions() {
  const [countRow, setCountRow] = useState();
  const [modal_varyingLarge, setmodal_varyingLarge] = useState(false);
  const [modal_varying1, setmodal_varying1] = useState(false);
  const [modal_varying2, setmodal_varying2] = useState(false);
  const [modal_varying3, setmodal_varying3] = useState(false);
  const [modal_varying4, setmodal_varying4] = useState(false);
  const application = useSelector((state) => state.applic.current);
  const navigate = useNavigate();
  const profile = useSelector((state) => state.profile?.data);
  const [files, setFiles] = useState();
  const [notes, setNotes] = useState();
  const [currentstatus, setCurrentStatus] = useState();
  const [data, setData] = useState();
  const [loading, setLoading] = useState();
  const [reports, setReports] = useState();
  const [searchParams] = useSearchParams();
  const [apiData, setApiData] = useState();
  registerPlugin(
    FilePondPluginFileValidateSize,
    FilePondPluginFileValidateType
  );
  const dataFunck = async () => {
    if (application && profile) {
      const signatur = await DataService.get(
        `signatory?application=${application?.id}&user=${profile?.id}`
      );
      setData(signatur?.results[0]);
    }
  };

  useEffect(() => {
    dataFunck();
  }, [application, profile]);
  const getData = async () => {
    const responseReport = await DataService.get(
      `reports/?application=${searchParams.get("show_id")}`
    );
    const lastRes =
      responseReport?.results[responseReport?.results?.length - 1];
    setReports(lastRes);
    const response = await DataService.get(endpoints.complaintFilter, {
      project: application?.project,
      application: application?.id,
    });
    setApiData(response);
  };
  useEffect(() => {
    getData();
  }, []);
  const handleSendFinish = async () => {
    const dataDocumentatt = new FormData();
    if (files) {
      dataDocumentatt.append("file", files[0].file);
      dataDocumentatt.append("file_name", files[0].file?.name);
      dataDocumentatt.append("application", application?.id);
      dataDocumentatt.append("stage", "center_stage3");

      await addattachmentAsync("attachment/", dataDocumentatt);
    }
    const data = {
      status_center_stage_3: currentstatus,
      results_center_stage_3: document.getElementsByName("results")[0].checked
        ? document.getElementById("inlineRadio1").value
        : document.getElementById("inlineRadio2").value,
      notes_center_stage_3: notes,
    };
    const appID = application?.id;

    const response = await DataService.put(
      `application/${appID}/center-stage3`,
      data
    );
    if (response?.errorCode) {
      toast.error(TranslationList("An error has occurred!"));
    } else {
      toast.success(TranslationList("Done successfully!"));
      navigate("/applications");
    }
    setmodal_varying2(false);
  };
  const handleSend = async () => {
    const appID = application?.id;
    setLoading(true);
    if (application?.stage == "stagefile") {
      const data = {
        draf_stage: 4,
        project_id: application?.project,
        pfi_branch: application?.pfi_branch,
        status_stage_file: "status3",
      };
      try {
        const response = await DataService.put(
          `application/${appID}/${application?.stage}`,
          data
        );
        setLoading(false);
        toast.success(TranslationList("Done successfully!"));
        navigate("/applications");
      } catch (error) {
        if (error?.errorCode == 500) {
          toast.error(error?.message);
        }
      }
    } else {
      const signatur = await DataService.get(
        `signatory?application=${application?.id}&user=${profile?.id}`
      );
      if (files) {
        const dataDocumentatt = new FormData();
        dataDocumentatt.append("file", files[0].file);
        dataDocumentatt.append("file_name", files[0].file?.name);
        dataDocumentatt.append("application", application?.id);
        dataDocumentatt.append("stage", "center_stage2");
        dataDocumentatt.append("signatory", signatur?.results[0]?.id);
        const responseat = await addattachmentAsync(
          "attachment/",
          dataDocumentatt
        );
      }
      const data = {
        results: document.getElementsByName("results")[0].checked
          ? document.getElementById("inlineRadio1").value
          : document.getElementById("inlineRadio2").value,
        notes: notes,
      };
      const response = await DataService.put(
        `signatory/${signatur?.results[0]?.id}`,
        data
      );
      if (response?.errorCode) {
        toast.error(TranslationList("An error has occurred!"));
      } else {
        toast.success(TranslationList("Done successfully!"));
        setLoading(false);

        navigate("/applications");
      }
      setmodal_varying2(false);
    }
  };
  function tog_varyingLarge() {
    setmodal_varyingLarge(!modal_varyingLarge);
  }
  function tog_varying1() {
    setmodal_varying1(!modal_varying1);
  }
  function tog_varying2() {
    setmodal_varying2(!modal_varying2);
  }
  function tog_varying3() {
    setmodal_varying3(!modal_varying3);
  }
  function tog_varying4() {
    setmodal_varying4(!modal_varying4);
  }
  const handleAdd = async () => {
    setLoading(true);
    if (files) {
      for (let item of files) {
        const dataDocumentatt = new FormData();
        dataDocumentatt.append("file", item?.file);
        dataDocumentatt.append("text", notes ?? "Completed");

        dataDocumentatt.append("file_name", item?.file?.name);
        dataDocumentatt.append("application", application?.id);
        switch (profile?.roles[0]?.name) {
          case "District Bank Manager":
            {
              dataDocumentatt.append("stage", "stagetumbank");
            }
            break;
          case "Coordinator":
            {
              dataDocumentatt.append("stage", "stagecordinator");
            }
            break;
          case "Manager of the Republic Bank":
            {
              dataDocumentatt.append("stage", "stageresbank");
            }
            break;
          case "Director":
            {
              dataDocumentatt.append("stage", "stagedirector");
            }
            break;
          case "Deputy Director":
            {
              dataDocumentatt.append("stage", "stagedirector");
            }
            break;
          case "Finansist":
            {
              dataDocumentatt.append("stage", "stageekonomist");
            }
            break;
          case "Specialist":
            {
              dataDocumentatt.append("stage", "stageiscad");
            }
            break;
        }
        const responseat = await addattachmentAsync(
          "attachment/",
          dataDocumentatt
        );
      }
    }

    switch (profile?.roles[0]?.name) {
      case "Manager of the Republic Bank":
        {
          const dataDocument = {
            status_stage_5: document.getElementsByName("results")[0].checked
              ? document.getElementById("inlineRadio1").value
              : document.getElementById("inlineRadio2").value,

            notes_stage_5: notes ?? "Completed",
          };
          const response = await editattachmentAsync(
            `application/${application?.id}/stageresbank`,
            dataDocument
          );
          if (response?.errorCode) {
            toast.error(TranslationList("An error has occurred!"));
          } else {
            toast.success(TranslationList("Done successfully!"));
            navigate("/applications");
            setLoading(false);
          }
        }
        break;
      case "Coordinator":
        {
          const dataDocument = {
            status_stage_cordinator: "status4",
            description_cordinator: notes ?? "Completed",
          };
          const response = await DataService.put(
            `application/${application?.id}/stagecordinator`,
            dataDocument
          );
          if (response?.errorCode) {
            toast.error(TranslationList("An error has occurred!"));
          } else {
            toast.success(TranslationList("Done successfully!"));
            navigate("/applications");
            setLoading(false);
          }
        }
        break;
      case "Specialist":
        {
          const dataDocument = {
            status_center_stage_3: document.getElementsByName("results")[0]
              .checked
              ? document.getElementById("inlineRadio1").value
              : document.getElementById("inlineRadio2").value,
            notes: notes ?? "Completed",
            specialist: profile?.id,
          };
          const response = await DataService.put(
            `application/${application?.id}/stagecenter`,
            dataDocument
          );
          if (response?.errorCode) {
            toast.error(TranslationList("An error has occurred!"));
          } else {
            toast.success(TranslationList("Done successfully!"));
            navigate("/applications");
            setLoading(false);
          }
        }
        break;
      case "District Bank Manager":
        {
          const dataDocument = {
            results_stage_3: "corresponds",
            status_stage_3: document.getElementsByName("results")[0].checked
              ? document.getElementById("inlineRadio1").value
              : document.getElementById("inlineRadio2").value,
            notes_stage_3: notes ?? "Completed",
          };
          const response = await DataService.put(
            `application/${application?.id}/stagetumbank`,
            dataDocument
          );
          if (response?.errorCode) {
            toast.error(TranslationList("An error has occurred!"));
          } else {
            toast.success(TranslationList("Done successfully!"));
            navigate("/applications");
            setLoading(false);
          }
        }
        break;
      case "Director":
        {
          const dataDocument = {
            status_stage_direktor: document.getElementsByName("results")[0]
              .checked
              ? document.getElementById("inlineRadio1").value
              : document.getElementById("inlineRadio2").value,
            description_direktor: notes ?? "Completed",
          };
          const response = await DataService.put(
            `application/${application?.id}/stagedirektor`,
            dataDocument
          );
          if (response?.errorCode) {
            toast.error(TranslationList("An error has occurred!"));
          } else {
            toast.success(TranslationList("Done successfully!"));
            navigate("/applications");
            setLoading(false);
          }
        }
        break;
      case "Deputy Director":
        {
          const dataDocument = {
            status_stage_direktor: document.getElementsByName("results")[0]
              .checked
              ? document.getElementById("inlineRadio1").value
              : document.getElementById("inlineRadio2").value,
            description_direktor: notes ?? "Completed",
          };
          const response = await DataService.put(
            `application/${application?.id}/stagedirektor`,
            dataDocument
          );
          if (response?.errorCode) {
            toast.error(TranslationList("An error has occurred!"));
          } else {
            toast.success(TranslationList("Done successfully!"));
            navigate("/applications");
            setLoading(false);
          }
        }
        break;
      case "Finansist":
        {
          const dataDocument = {
            status_stage_ekonomist: document.getElementsByName("results")[0]
              .checked
              ? document.getElementById("inlineRadio1").value
              : document.getElementById("inlineRadio2").value,
            description_ekonomist: notes ?? "Completed",
          };
          const response = await DataService.put(
            `application/${application?.id}/stageekonomist`,
            dataDocument
          );
          if (response?.errorCode) {
            toast.error(TranslationList("An error has occurred!"));
          } else {
            toast.success(TranslationList("Done successfully!"));
            navigate("/applications");
            setLoading(false);
          }
        }
        break;
    }
    setmodal_varying4(false);
    setmodal_varying1(false);
  };
  const handleStatus = (cell) => {
    switch (cell) {
      case "refusal":
        return (
          <span className=" fs-14 badge badge-soft-danger">
            {" "}
            {TranslationList("refusal")}{" "}
          </span>
        );
      case "completed":
        return (
          <span className="fs-14 badge badge-soft-info">
            {TranslationList("Fulfilled")}{" "}
          </span>
        );
      case "draft":
        return (
          <span className="fs-14 badge badge-soft-secondary">
            {TranslationList("Draft")}
          </span>
        );
      case "removed":
        return (
          <span className="fs-14 badge badge-soft-danger">
            {" "}
            {TranslationList("Accepted for processing	")}{" "}
          </span>
        );
      case "in_progress":
        return (
          <span className="fs-14 badge badge-soft-warning">
            {" "}
            {TranslationList("In consideration")}{" "}
          </span>
        );
      case "under_consideration":
        return (
          <span className="fs-14 badge badge-soft-primary">
            {TranslationList("In consideration")}{" "}
          </span>
        );
      case "new":
        return (
          <span className="fs-14 badge badge-soft-info">
            {" "}
            {TranslationList("New")}{" "}
          </span>
        );
      case "done":
        return (
          <span className="fs-14 badge badge-soft-success">
            {" "}
            {TranslationList("Approved")}
          </span>
        );
      default:
        return (
          <span className="fs-14 badge badge-soft-success">
            {" "}
            {TranslationList("On completion")}
          </span>
        );
    }
  };

  return (
    <React.Fragment>
      <Modal
        isOpen={modal_varying1}
        toggle={() => {
          tog_varying1();
        }}
        id="exampleModal"
      >
        <ModalHeader
          toggle={() => {
            tog_varying1();
          }}
        >
          {application?.nomer}
        </ModalHeader>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            handleAdd();
          }}
        >
          <div className="modal-body">
            <Container fluid>
              <div className="mb-3  d-flex justify-content-between">
                <div className="form-check form-check-inline">
                  <Input
                    className="form-check-input"
                    type="radio"
                    name="results"
                    id="inlineRadio1"
                    style={{ width: 20, height: 20 }}
                    value="status3"
                    defaultChecked
                    required
                  />
                  <Label
                    className="form-check-label fs-18"
                    htmlFor="inlineRadio1"
                  >
                    {TranslationList("Corresponds")}
                  </Label>
                </div>
                <div className="form-check form-check-inline">
                  <Input
                    className="form-check-input"
                    type="radio"
                    name="results"
                    required
                    id="inlineRadio2"
                    value="status4"
                    style={{ width: 20, height: 20 }}
                  />
                  <Label
                    className="form-check-label fs-18"
                    htmlFor="inlineRadio2"
                  >
                    {TranslationList("needs_to_be_finalized")}
                  </Label>
                </div>
              </div>
              <div className="mb-3 ">
                <label htmlFor="notes" className="col-form-label">
                  {TranslationList("Notes")}
                </label>
                <Input
                  type="textarea"
                  id="notes"
                  required
                  name="notes"
                  className="form-control"
                  onChange={(e) => setNotes(e.target.value)}
                />
                <span>{}</span>
              </div>
              <div className="mb-3">
                <label htmlFor="message-text" className="col-form-label">
                  {TranslationList("File")}:
                </label>

                <FilePond
                  labelIdle="Файл загружен"
                  files={files}
                  onupdatefiles={(file) => setFiles(file)}
                  allowMultiple={true}
                  maxFiles={4}
                  name="files"
                  className="filepond filepond-input-multiple"
                />
              </div>
            </Container>
          </div>
          <div className="modal-footer">
            <Button
              color="light"
              onClick={() => {
                setmodal_varying1(false);
              }}
            >
              {TranslationList("Cancel")}
            </Button>

            <Button color="primary" type="submit" disabled={loading}>
              {loading ? (
                <Spinner size="sm" className="me-2">
                  {" "}
                  Loading...{" "}
                </Spinner>
              ) : null}
              {TranslationList("Send")}
            </Button>
          </div>
        </Form>
      </Modal>
      <Modal
        isOpen={modal_varying4}
        toggle={() => {
          tog_varying4();
        }}
        id="exampleModal"
      >
        <ModalHeader
          toggle={() => {
            tog_varying4();
          }}
        >
          {application?.nomer}
        </ModalHeader>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            handleAdd();
          }}
        >
          <div className="modal-body">
            <Container fluid>
              <div className="mb-3 ">
                <label htmlFor="notes" className="col-form-label">
                  {TranslationList("Notes")}
                </label>
                <Input
                  type="textarea"
                  id="notes"
                  required
                  name="notes"
                  className="form-control"
                  onChange={(e) => setNotes(e.target.value)}
                />
                <span>{}</span>
              </div>
              <div className="mb-3">
                <label htmlFor="message-text" className="col-form-label">
                  {TranslationList("File")}:
                </label>

                <FilePond
                  labelIdle="Файл загружен"
                  files={files}
                  onupdatefiles={(file) => setFiles(file)}
                  allowMultiple={true}
                  maxFiles={4}
                  name="files"
                  className="filepond filepond-input-multiple"
                />
              </div>
            </Container>
          </div>
          <div className="modal-footer">
            <Button
              color="light"
              onClick={() => {
                setmodal_varying4(false);
              }}
            >
              {TranslationList("Cancel")}
            </Button>

            <Button color="primary" type="submit" disabled={loading}>
              {loading ? (
                <Spinner size="sm" className="me-2">
                  {" "}
                  Loading...{" "}
                </Spinner>
              ) : null}
              {TranslationList("Send")}
            </Button>
          </div>
        </Form>
      </Modal>
      <Modal
        isOpen={modal_varying2}
        toggle={() => {
          tog_varying2();
        }}
        id="exampleModal"
      >
        <ModalHeader
          toggle={() => {
            tog_varying2();
          }}
        >
          {application?.nomer}
        </ModalHeader>
        <div className="modal-body">
          <Container fluid>
            <div className="mb-3  d-flex justify-content-between">
              <div className="form-check form-check-inline">
                <Input
                  className="form-check-input"
                  type="radio"
                  name="results"
                  id="inlineRadio1"
                  value="corresponds"
                  defaultChecked
                />
                <Label className="form-check-label" htmlFor="inlineRadio1">
                  {TranslationList("Corresponds")}
                </Label>
              </div>
              <div className="form-check form-check-inline">
                <Input
                  className="form-check-input"
                  type="radio"
                  name="results"
                  id="inlineRadio2"
                  value="needs_to_be_finalized"
                />
                <Label className="form-check-label" htmlFor="inlineRadio2">
                  {TranslationList("needs_to_be_finalized")}
                </Label>
              </div>
            </div>

            <div className="mb-3 ">
              <label htmlFor="notes" className="col-form-label">
                {TranslationList("Notes")}
              </label>
              <Input
                type="textarea"
                id="notes"
                name="notes"
                className="form-control"
                onChange={(e) => setNotes(e.target.value)}
              />
            </div>
          </Container>
        </div>
        <div className="modal-footer">
          <Button
            color="light"
            onClick={() => {
              setmodal_varying2(false);
            }}
          >
            {TranslationList("Cancel")}
          </Button>

          <Button color="primary" onClick={handleSend} disabled={loading}>
            {loading ? (
              <Spinner size="sm" className="me-2">
                {" "}
                Loading...{" "}
              </Spinner>
            ) : null}
            {TranslationList("Send")}
          </Button>
        </div>
      </Modal>
      <Modal
        isOpen={modal_varying3}
        toggle={() => {
          tog_varying3();
        }}
        id="exampleModal"
      >
        <ModalHeader
          toggle={() => {
            tog_varying3();
          }}
        >
          {application?.nomer}
        </ModalHeader>
        <div className="modal-body">
          <Container fluid>
            <div className="mb-3  d-flex justify-content-between">
              <div className="form-check form-check-inline">
                <Input
                  className="form-check-input"
                  type="radio"
                  name="results"
                  id="inlineRadio1"
                  value="corresponds"
                  defaultChecked
                />
                <Label className="form-check-label" htmlFor="inlineRadio1">
                  {TranslationList("Corresponds")}
                </Label>
              </div>
              <div className="form-check form-check-inline">
                <Input
                  className="form-check-input"
                  type="radio"
                  name="results"
                  id="inlineRadio2"
                  value="needs_to_be_finalized"
                />
                <Label className="form-check-label" htmlFor="inlineRadio2">
                  {TranslationList("needs_to_be_finalized")}
                </Label>
              </div>
            </div>

            <div className="mb-3 ">
              <label htmlFor="notes" className="col-form-label">
                {TranslationList("Notes")}
              </label>
              <Input
                type="textarea"
                id="notes"
                name="notes"
                className="form-control"
                onChange={(e) => setNotes(e.target.value)}
              />
            </div>

            {/* <div className="mb-3">
              <label htmlFor="message-text" className="col-form-label">
                {TranslationList("File")}:
              </label>
            
              <FilePond
                labelIdle="Файл загружен"
                files={files}
                onupdatefiles={(file) => setFiles(file)}
                allowMultiple={false}
                maxFiles={1}
                name="files"
                className="filepond filepond-input-multiple"
              />
            </div> */}
          </Container>
        </div>
        <div className="modal-footer">
          <Button
            color="light"
            onClick={() => {
              setmodal_varying3(false);
            }}
          >
            {TranslationList("Cancel")}
          </Button>

          <Button color="primary" onClick={handleSendFinish} disabled={loading}>
            {loading ? (
              <Spinner size="sm" className="me-2">
                {" "}
                Loading...{" "}
              </Spinner>
            ) : null}
            {TranslationList("Send")}
          </Button>
        </div>
      </Modal>
      <Modal
        className="modal-fullscreen"
        scrollable={true}
        isOpen={modal_varyingLarge}
        toggle={() => {
          tog_varyingLarge();
        }}
        id="exampleModal"
      >
        <ModalBody>
          <ForPDF data={application} report={reports} />
        </ModalBody>
      </Modal>
      <Card>
        <div className="d-flex justify-content-center p-4">
          <h5 className="fs-16">{TranslationList("Requisites")}</h5>
        </div>
        <CardBody className="text-muted">
          <div>
            <h6>{TranslationList("Status")}:</h6>
            <p>{handleStatus(application?.status)}</p>
          </div>

          <h6>{TranslationList("Last_modified")}:</h6>
          <div>{dateFormat(application?.updated_time, "dd.mm.yyyy HH:MM")}</div>
          <div>
            {application?.updated_by_info?.last_name}
            {"  "}
            {application?.updated_by_info?.first_name}
          </div>
          <div>{application?.updated_by_info?.roles[0]?.name}</div>
          {application?.stage_center == "completed" && (
            <div className="mt-2">
              <h6>{TranslationList("Center's result")}:</h6>
              <p>{handleStatus("done")}</p>
            </div>
          )}
          {application?.status_stage_5 == "status3" && (
            <div className="mt-2">
              <h6>{TranslationList("Bank_result")}:</h6>
              <p>{handleStatus("done")}</p>
            </div>
          )}
        </CardBody>

        {profile?.roles[0]?.name == "Specialist" && data?.results == "" && (
          <div>
            <div className="d-flex justify-content-center pt-4">
              <h5 className="fs-16">{TranslationList("Actions")}</h5>
            </div>
            <div className="d-flex flex-column justify-content-center m-3">
              <button
                className="btn btn-success mb-3"
                onClick={() => {
                  setCurrentStatus("status3");
                  tog_varying1();
                }}
                disabled={loading}
              >
                {loading ? (
                  <Spinner size="sm" className="me-2">
                    {" "}
                    Loading...{" "}
                  </Spinner>
                ) : null}
                {TranslationList("Apply")}
              </button>

              <button
                className="btn btn-light mb-3"
                onClick={() => navigate("/applications")}
              >
                {TranslationList("Close form")}
              </button>
            </div>
          </div>
        )}
        {profile?.roles[0]?.name == "District Bank Manager" &&
          application?.stage == "stagetumbank" && (
            <div>
              <div className="d-flex justify-content-center pt-4">
                <h5 className="fs-16">{TranslationList("Actions")}</h5>
              </div>
              <div className="d-flex flex-column justify-content-center m-3">
                <button
                  className="btn btn-success mb-3"
                  onClick={() => {
                    setCurrentStatus("status3");
                    tog_varying1();
                  }}
                  disabled={loading}
                >
                  {loading ? (
                    <Spinner size="sm" className="me-2">
                      {" "}
                      Loading...{" "}
                    </Spinner>
                  ) : null}
                  {TranslationList(
                    "Approve_and_send_a_package_of_documents_for_obtaining_a_loan"
                  )}
                </button>

                <button
                  className="btn btn-light mb-3"
                  onClick={() => navigate("/applications")}
                >
                  {TranslationList("Close_form")}{" "}
                </button>
              </div>
            </div>
          )}
        {profile?.roles[0]?.name == "Manager of the Republic Bank" &&
          application?.stage == "stageresbank" && (
            <div>
              <div className="d-flex justify-content-center pt-4">
                <h5 className="fs-16">{TranslationList("Actions")}</h5>
              </div>
              <div className="d-flex flex-column justify-content-center m-3">
                <button
                  className="btn btn-success mb-3"
                  onClick={() => {
                    setCurrentStatus("status3");
                    tog_varying1();
                  }}
                  disabled={loading}
                >
                  {loading ? (
                    <Spinner size="sm" className="me-2">
                      {" "}
                      Loading...{" "}
                    </Spinner>
                  ) : null}
                  {TranslationList("Making decisions")}
                </button>
                {/* <button
                  disabled={loading}
                  className="btn btn-danger mb-3"
                  onClick={() => {
                    setCurrentStatus("status4");
                    tog_varying1();
                  }}
                >
                  {TranslationList("Send_back_for_revision")}
                </button> */}
                <button
                  className="btn btn-light mb-3"
                  onClick={() => navigate("/applications")}
                >
                  {TranslationList("Close form")}
                </button>
              </div>
            </div>
          )}
        {profile?.roles[0]?.name == "Coordinator" &&
          application?.stage == "stagecordinator" && (
            <div>
              <div className="d-flex justify-content-center pt-4">
                <h5 className="fs-16">{TranslationList("Actions")}</h5>
              </div>
              <div className="d-flex flex-column justify-content-center m-3">
                <button
                  className="btn btn-success mb-3"
                  onClick={() => {
                    // tog_varyingLarge();
                    navigate(
                      `/application-report?application=${application?.id}`
                    );
                  }}
                >
                  {TranslationList("Apply")}
                </button>

                <button
                  className="btn btn-danger mb-3"
                  onClick={() => {
                    setCurrentStatus("status4");
                    tog_varying4();
                  }}
                >
                  {TranslationList("Refused")}
                </button>
              </div>
            </div>
          )}
        {(profile?.roles[0]?.name == "Director" ||
          profile?.roles[0]?.name == "Deputy Director") &&
          application?.stage == "stagedirektor" && (
            <div>
              <div className="d-flex justify-content-center pt-4">
                <h5 className="fs-16">{TranslationList("Actions")}</h5>
              </div>
              <div className="d-flex flex-column justify-content-center m-3">
                <button
                  className="btn btn-success mb-3"
                  onClick={() => {
                    tog_varying1();
                  }}
                >
                  {loading ? (
                    <Spinner size="sm" className="me-2">
                      {" "}
                      Loading...{" "}
                    </Spinner>
                  ) : null}
                  {TranslationList("Apply")}
                </button>
                {/* <button
                  className="btn btn-danger mb-3"
                  onClick={() => {
                    handleBack("status4");
                  }}
                >
                  {TranslationList("Send_back")}
                </button> */}
                <button
                  className="btn btn-light mb-3"
                  onClick={() => navigate("/applications")}
                >
                  {TranslationList("Close form")}
                </button>
              </div>
            </div>
          )}
        {profile?.roles[0]?.name == "Finansist" &&
          application?.stage == "stageekonomist" && (
            <div>
              <div className="d-flex justify-content-center pt-4">
                <h5 className="fs-16">{TranslationList("Actions")}</h5>
              </div>
              <div className="d-flex flex-column justify-content-center m-3">
                <button
                  className="btn btn-success mb-3"
                  onClick={() => {
                    tog_varying1();
                  }}
                >
                  {loading ? (
                    <Spinner size="sm" className="me-2">
                      {" "}
                      Loading...{" "}
                    </Spinner>
                  ) : null}
                  {TranslationList("Apply")}
                </button>
                {/* <button
                  className="btn btn-danger mb-3"
                  onClick={() => {
                    handleBack("status4");
                  }}
                >
                  {TranslationList("Send_back")}
                </button> */}
                <button
                  className="btn btn-light mb-3"
                  onClick={() => navigate("/applications")}
                >
                  {TranslationList("Close form")}
                </button>
              </div>
            </div>
          )}

        {profile?.roles[0]?.name == "Manager" &&
          application?.stage == "stagedirektor" &&
          application?.stage_center == "stage4" && (
            <div>
              <div className="d-flex justify-content-center pt-4">
                <h5 className="fs-16">{TranslationList("Actions")}</h5>
              </div>
              <div className="d-flex flex-column justify-content-center m-3">
                <button
                  className="btn btn-success mb-3"
                  onClick={() => {
                    tog_varying3();
                    setCurrentStatus("status3");
                  }}
                  disabled={loading}
                >
                  {loading ? (
                    <Spinner size="sm" className="me-2">
                      {" "}
                      Loading...{" "}
                    </Spinner>
                  ) : null}
                  {TranslationList("Apply")}
                </button>
                <button
                  className="btn btn-danger mb-3"
                  onClick={() => {
                    setCurrentStatus("status4");
                    tog_varying3();
                  }}
                  disabled={loading}
                >
                  {TranslationList("Send_back_for_revision")}
                </button>{" "}
                <button
                  className="btn btn-danger mb-3"
                  onClick={() => navigate("/applications")}
                >
                  {TranslationList("Close form")}
                </button>
              </div>
            </div>
          )}
      </Card>
      {profile?.roles[0]?.name == "User" && apiData?.length > 0 ? (
        <Card>
          <div className="d-flex justify-content-center px-4 mt-3">
            <h5 className="fs-16">{TranslationList("Contact us")}</h5>
          </div>
          <CardBody className="text-muted">
            {apiData?.map((item) => (
              <div key={item?.id} className="d-flex flex-column gap-2">
                <h6>
                  {TranslationList(item?.employee_detail?.roles[0]?.name)}:
                </h6>
                <div>
                  {item?.employee_detail?.last_name} {"  "}
                  {item?.employee_detail?.first_name}
                </div>
                <div className="d-flex  flex-column gap-1 mb-2">
                  <a
                    href={`tel:${item?.phone}`}
                    className="d-flex align-item-center gap-3 mb-2 color-black "
                  >
                    <span>{item?.phone}</span>
                    <i
                      className="mdi mdi-phone-sync
"
                    ></i>
                  </a>
                  <a
                    href={`mailto:${item?.email}`}
                    className="d-flex align-item-center  gap-3 mb-2 color-black "
                  >
                    {TranslationList("Email")}:{/* {item?.email} */}
                    <i className="mdi mdi-email"></i>
                  </a>
                </div>
              </div>
            ))}
          </CardBody>
        </Card>
      ) : (
        ""
      )}
      {(application?.stage == "stagedirektor" ||
        application?.stage == "stageekonomist" ||
        application?.stage == "stagecordinator" ||
        application?.stage == "stagefinish") &&
        reports && (
          <button
            className="btn btn-primary w-100 mb-3"
            onClick={() => navigate(`/application-show/${application?.id}`)}
          >
            {TranslationList("application_sign")}
          </button>
        )}
    </React.Fragment>
  );
}
export default withTranslation()(Actions);
