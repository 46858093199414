import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Container,
  FormFeedback,
  Input,
  Modal,
  ModalHeader,
  Row,
} from "reactstrap";
import tableToExcel from "@linways/table-to-excel";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import React, { useEffect, useState } from "react";
import PivotTableUI from "react-pivottable/PivotTableUI";
import "react-pivottable/pivottable.css";
import "./pivot.css";
import TableRenderers from "react-pivottable/TableRenderers";
import Plot from "react-plotly.js";
import createPlotlyRenderers from "react-pivottable/PlotlyRenderers";
// import tips from "../data/tips";
import { TranslationList } from "../../../slices/settings/listtranslation";
import { DataService } from "../../../helpers/dataService/dataService";
import endpoints from "../../../endpoints";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import ReactPivottableUI from "react-pivottable";

const PlotlyRenderers = createPlotlyRenderers(Plot);

export default function AnalyticsReportsCreate() {
  const params = useParams();
  const navigate = useNavigate();
  const [names, setNames] = useState({
    title_uz: "",
    title_ru: "",
    title_en: "",
  });
  const [tips, setTips] = useState();
  const [error, setError] = useState();
  const [current, setCurrent] = useState();
  const [modal_varying1, setmodal_varying1] = useState(false);
  const tog_varying1 = () => {
    setmodal_varying1(!modal_varying1);
  };
  const [state, setState] = useState();
  const lang = useSelector((state) => state.lang.data);

  useEffect(() => {
    fetchData();
  }, [lang, params]);
  const fetchData = async () => {
    const typeresponse = await DataService.get(
      endpoints.analyticReportById(params?.type)
    );
    const customData = typeresponse?.results?.map((data) => {
      let newObj = {};
      for (let key in data) {
        if (data.hasOwnProperty(key)) {
          let value = data[key];
          newObj[TranslationList(key)] = value;
        }
      }
      return newObj;
    });
    setTips(customData);
  };
  const handleAddTitles = async () => {
    try {
      if (!params?.id) {
        const res = await DataService.post(endpoints.analyticReport, names);
        toast.success(TranslationList("Done successfully!"));
        fetchData();
        navigate(`/analytics/reports/${res?.id}`);
        tog_varying1();
      } else {
        const res = await DataService.put(
          endpoints.analyticReport + `/${params?.id}`,
          names
        );
        toast.success(TranslationList("Done successfully!"));
        fetchData();
        navigate(`/analytics/reports/${res?.id}`);
        tog_varying1();
      }
    } catch (err) {
      toast.error(err?.message);
    }
  };
  const handleAddTable = async () => {
    try {
      if (params?.type) {
        const res = await DataService.post(endpoints.analyticReport, {
          text_json: JSON.stringify(state),
          api_url: params?.type,
        });
        toast.success(TranslationList("Done successfully!"));
        fetchData();
        navigate(`/analytics/reports/${res?.id}`);
      }
    } catch (err) {
      toast.error(err?.message);
    }
  };
  const onCleare = () => {
    if (!current?.text_json) {
      setState({
        // aggregatorName: "",
        rendererName: "",
        rows: [],
        cols: [],
        vals: [],
      });
    } else {
      const s = JSON.parse(current?.text_json);
      setState({
        aggregatorName: s?.aggregatorName,
        rendererName: s?.rendererName,
        rows: s.rows,
        cols: s.cols,
        vals: s.vals,
      });
    }
  };
  const handleExportClick = () => {
    var htmlTable = document.querySelector(".pvtTable")?.cloneNode(true);
    htmlTable.setAttribute("data-cols-width", "15,15,10");
    if (htmlTable) {
      const htmlTableHead = htmlTable.querySelector("thead");
      const htmlHeadRows = htmlTableHead.querySelectorAll("tr");
      htmlHeadRows.forEach((headRow) => {
        const htmlHeadCells = headRow.querySelectorAll("th");
        htmlHeadCells.forEach((htmlCell) => {
          const isAxisLabel = htmlCell.classList.contains("pvtAxisLabel");
          const isColLabel = htmlCell.classList.contains("pvtColLabel");
          const isTotalLabel = htmlCell.classList.contains("pvtTotalLabel");

          if (isAxisLabel) {
            htmlCell.setAttribute("data-a-h", "justify");
            htmlCell.setAttribute("data-a-v", "middle");
            htmlCell.setAttribute("data-f-bold", "true");
            htmlCell.setAttribute("data-b-a-c", "00000000");
            htmlCell.setAttribute("data-b-a-s", "thin");
          }
          if (isColLabel) {
            htmlCell.setAttribute("data-a-h", "center");
            htmlCell.setAttribute("data-a-v", "middle");
            htmlCell.setAttribute("data-b-a-c", "00000000");
            htmlCell.setAttribute("data-b-a-s", "thin");
            htmlCell.style.border = "thin"; // Add border style
          }
          if (isTotalLabel) {
            htmlCell.setAttribute("data-exclude", "true");
          }
        });
      });

      const htmlTableBody = htmlTable.querySelector("tbody");
      const htmlBodyRows = htmlTableBody.querySelectorAll("tr");
      htmlBodyRows.forEach((bodyRow) => {
        const htmlBodyCells = bodyRow.querySelectorAll("th, td");
        htmlBodyCells.forEach((htmlCell) => {
          const isRowLabel = htmlCell.classList.contains("pvtRowLabel");
          const isValue = htmlCell.classList.contains("pvtVal");
          const isTotal = htmlCell.classList.contains("pvtTotal");
          const isTotalLabel = htmlCell.classList.contains("pvtTotalLabel");
          const isGrandTotal = htmlCell.classList.contains("pvtGrandTotal");

          if (isRowLabel) {
            htmlCell.setAttribute("data-a-h", "justify");
            htmlCell.setAttribute("data-a-v", "middle");
            htmlCell.setAttribute("data-b-a-c", "00000000");
            htmlCell.setAttribute("data-b-a-s", "thin");
            htmlCell.style.border = "thin"; // Add border style
          }
          if (isValue) {
            htmlCell.setAttribute("data-a-h", "right");
            htmlCell.setAttribute("data-a-v", "middle");
            htmlCell.setAttribute("data-t", "n");
            htmlCell.setAttribute("data-b-a-c", "00000000");
            htmlCell.setAttribute("data-b-a-s", "thin");
            htmlCell.style.border = "thin"; // Add border style
          }
          if (isTotal || isTotalLabel || isGrandTotal) {
            htmlCell.setAttribute("data-exclude", "true");
          }
        });
      });

      tableToExcel.convert(htmlTable, { name: "report.xlsx" });
    }
  };

  return (
    <React.Fragment>
      <Modal
        isOpen={modal_varying1}
        toggle={() => {
          tog_varying1();
        }}
        id="exampleModal"
      >
        <ModalHeader
          toggle={() => {
            tog_varying1();
          }}
        >
          {current ? TranslationList("Edit") : TranslationList("Add")}{" "}
        </ModalHeader>
        <div className="modal-body">
          <form>
            <div className="mb-3">
              <label htmlFor="customer-name" className="col-form-label">
                {TranslationList("uz")}:
              </label>
              <Input
                id="title_uz"
                type="text"
                className="form-control"
                onChange={(e) =>
                  setNames({ ...names, title_uz: e.target.value })
                }
                defaultValue={current?.title_uz}
                invalid={error?.title_uz ? true : false}
              />
              <FormFeedback type="invalid">{error?.title_uz}</FormFeedback>
            </div>
            <div className="mb-3">
              <label htmlFor="customer-name" className="col-form-label">
                {TranslationList("ru")}:
              </label>
              <Input
                type="text"
                id="title_ru"
                className="form-control"
                onChange={(e) =>
                  setNames({ ...names, title_ru: e.target.value })
                }
                defaultValue={current?.title_ru}
                invalid={error?.title_ru ? true : false}
              />
              <FormFeedback type="invalid">{error?.title_ru}</FormFeedback>
            </div>
            <div className="mb-3">
              <label htmlFor="customer-name" className="col-form-label">
                {TranslationList("eng")}:
              </label>
              <Input
                type="text"
                id="title_en"
                className="form-control"
                onChange={(e) =>
                  setNames({ ...names, title_en: e.target.value })
                }
                defaultValue={current?.title_en}
                invalid={error?.title_en ? true : false}
              />
              <FormFeedback type="invalid">{error?.title_en}</FormFeedback>
            </div>
          </form>
        </div>
        <div className="modal-footer">
          <Button
            color="light"
            onClick={() => {
              setmodal_varying1(false);
            }}
          >
            {TranslationList("Cancel")}
          </Button>
          {current ? (
            <Button color="primary" onClick={handleAddTitles}>
              {TranslationList("Save")}
            </Button>
          ) : (
            <Button color="primary" onClick={handleAddTitles}>
              {TranslationList("Create")}
            </Button>
          )}
        </div>
      </Modal>

      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            title="Reports"
            link="/analytics/reports"
            pageTitle="Analytics"
          />

          <div className="d-flex align-items-center justify-content-end mb-2 gap-1 flex-wrap">
            <h5>{current?.title}</h5>
            {!current?.title && (
              <button
                type="button"
                className="btn btn-success add-btn"
                id="create-btn"
                onClick={() => tog_varying1()}
              >
                <i className="ri-add-line align-bottom me-1"></i>
                {TranslationList("Add title report")}
              </button>
            )}
          </div>
          <Card>
            <CardHeader>
              <div className="border-0 ">
                <div className="d-flex gap-2 ">
                  <div className="">
                    {state?.rendererName ==
                      ("Table" ||
                        "Table Heatmap" ||
                        "Table Col Heatmap" ||
                        "Table Row Heatmap" ||
                        "Exportable TSV") && (
                      <button
                        type="button"
                        className="btn btn-info"
                        onClick={handleExportClick}
                      >
                        <i className="ri-file-download-line align-bottom me-1"></i>{" "}
                        {TranslationList("Export")}
                      </button>
                    )}
                  </div>
                  <div className="me-auto ">
                    <button
                      type="button"
                      className="btn btn-danger"
                      onClick={onCleare}
                    >
                      <i className="bx bx-trash  me-1"></i>{" "}
                      {TranslationList("Cleare")}
                    </button>{" "}
                  </div>
                  <div className="">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={handleAddTable}
                    >
                      {TranslationList("Save")}
                    </button>{" "}
                  </div>
                </div>
              </div>
            </CardHeader>
            <CardBody>
              <Row className="w-100 overflow-auto">
                <ReactPivottableUI
                  data={tips}
                  onChange={(s) => {
                    setState(s);
                  }}
                  renderers={Object.assign({}, TableRenderers, PlotlyRenderers)}
                  {...state}
                />
              </Row>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
}
