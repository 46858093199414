import React, { useState, useEffect, useRef } from "react";
import L from "leaflet";
import "leaflet.markercluster";
import { get, forEach, map } from "lodash";
import {
  CENTER,
  info,
  backControl,
  VIEW_MODE_REGIONS,
  VIEW_MODE_DISTRICTS,
  styleRegion,
  styleDistrict,
  highlightFeature,
  tryParseJson,
  isNotEqualChild,
  markerIcon, // Assuming this is imported from './utils'
} from "./utils";

const Map1 = ({ regions, subprojects, districts, filter, setFilters }) => {
  const [currentView, setCurrentView] = useState(VIEW_MODE_REGIONS);
  const mapRef = useRef(null);
  const regionLayerRef = useRef(null);
  const districtLayerRef = useRef(null);
  const markersRef = useRef(null);

  // Initialize the map
  useEffect(() => {
    const map = L.map("map", {
      maxBoundsViscosity: 1.0,
      maxZoom: 15,
    }).setView(CENTER, 13);

    L.tileLayer(
      "https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png",
      {}
    ).addTo(map);

    mapRef.current = map;

    // Add controls and fit bounds once regions are set
    if (regions) {
      setRegions(regions);
    }

    // Cleanup on component unmount
    return () => {
      map.remove();
    };
  }, []); // This useEffect acts as componentDidMount

  useEffect(() => {
    if (regions) {
      setRegions(regions);
    }
  }, [regions]);
  useEffect(() => {
    if (districts) {
      setDistricts(districts);
    }
  }, [districts]);
  useEffect(() => {
    setMarkers(subprojects);
  }, [subprojects]);

  // useEffect(() => {
  //   handleFilterChange(filter);
  // }, [filter]);

  const setMarkers = (coordinates) => {
    if (markersRef.current) mapRef.current.removeLayer(markersRef.current);

    const markers = L.markerClusterGroup({ showCoverageOnHover: false });

    coordinates?.forEach((item) => {
      const name = get(item, "aplication_detail.nomer", "");
      const project_detail = get(item, "project_detail.name", "");
      const status = get(item, "status", "");
      const pfiName = get(item, "aplication_detail.pfi", "");
      // Assuming that "aplication_detail.coordinate_x" is latitude and "aplication_detail.coordinate_y" is longitude
      const lat = get(item, "aplication_detail.coordinate_x", "");
      const lng = get(item, "aplication_detail.coordinate_y", ""); // Corrected to use 'coordinate_y' for longitude
      const marker = L.marker([lat, lng], { icon: markerIcon }) // Corrected to pass coordinates as an array
        .bindTooltip(name)
        .bindPopup(
          `<h5 className="gx-text-center">${name}</h5>
          <table className="gx-table">
          <tr><td>Project Name</td>
          <td className="gx-text-right">${project_detail}</td></tr>
          <tr><td>Status </td><td className="gx-text-right">${status}</td></tr>
          <tr><td>PFI</td><td className="gx-text-right">${pfiName}</td></tr>
          </table>`
        );
      markers.addLayer(marker);
    });

    markersRef.current = markers;
    mapRef.current.addLayer(markers);
  };

  const setRegions = (data) => {
    if (regionLayerRef.current) {
      mapRef.current.removeLayer(regionLayerRef.current);
    }

    const regionFeatures = map(data, ({ geo_json, ...properties }) => ({
      type: "Feature",
      properties,
      geometry: {
        type: "MultiPolygon",
        coordinates: tryParseJson(geo_json),
      },
    }));

    const resetHighlight = (e) => {
      regionLayerRef.current.resetStyle(e.target);
      info.update();
    };

    const layer = L.geoJSON(regionFeatures, {
      style: styleRegion,
      onEachFeature: (feature, layer) => {
        layer.on({
          mouseover: highlightFeature,
          mouseout: resetHighlight,
          click: handleClickRegion,
        });
      },
    }).addTo(mapRef.current);

    regionLayerRef.current = layer;

    const bounds = layer?.getBounds();
    mapRef.current.fitBounds(bounds);
    mapRef.current.addControl(info);
  };

  const setDistricts = (districts) => {
    if (districtLayerRef.current) {
      mapRef.current.removeLayer(districtLayerRef.current);
    }
    const features = districts?.map((item) => {
      return {
        type: "Feature",
        item,
        geometry: {
          type: "MultiPolygon",
          coordinates: tryParseJson(item?.geo_json),
        },
      };
    });

    if (!features?.length) return;
    const layer = L.geoJSON(features, {
      style: styleDistrict,
      onEachFeature: (feature, layer) => {
        const name = get(feature, "properties.name");
        layer.bindTooltip(name);
        layer.on({
          click: handleClickDistrict,
        });
      },
    }).addTo(mapRef.current);

    districtLayerRef.current = layer;
    mapRef.current.fitBounds(layer?.getBounds());
    setCurrentView(VIEW_MODE_DISTRICTS);
    mapRef?.current?.addControl(backControl);
    // backControl?.setCallback(backToRegion);
  };

  const clearDistricts = () => {
    if (districtLayerRef.current) {
      mapRef.current.removeLayer(districtLayerRef.current);
      districtLayerRef.current = null;
    }
  };

  const backToRegion = () => {
    setCurrentView(VIEW_MODE_REGIONS);
    clearDistricts();
    if (regionLayerRef?.current) {
      mapRef.current?.addLayer(regionLayerRef.current);
    }
    mapRef.current?.fitBounds(regionLayerRef.current?.getBounds());
    mapRef.current?.removeControl(backControl);
    // setFilters({
    //   regions: "",
    //   region_id: null,
    // });
  };

  const handleClickRegion = (e) => {
    const region = get(e, "target.feature.properties");
    setFilters({ regions: "", region_id: region.id });
    setCurrentView(VIEW_MODE_DISTRICTS);
    // setDistricts(region.id);
  };

  const handleClickDistrict = (e) => {
    const district = get(e, "target.feature.properties");
    // setFilters({ regions: "", district_id: district.id });
  };

  return (
    <div id="map" style={{ width: "100%", height: 500, background: "#fff" }} />
  );
};

export default Map1;
