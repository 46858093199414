import React, { useEffect, useState } from "react";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { get, map } from "lodash";

//i18n
import i18n from "../../i18n";
import languages from "../../common/languages";
import { useDispatch } from "react-redux";
import { getlang } from "../../slices/lang";
import { gettranslationsListAsync } from "../../slices/settings/translations";

const LanguageDropdown = () => {
  const [selectedLang, setSelectedLang] = useState("");
  const dispatch = useDispatch();
  useEffect(() => {
    const currentLanguage = localStorage.getItem("I18N_LANGUAGE_INVEST");
    setSelectedLang(currentLanguage);
  }, []);

  const changeLanguageAction = (lang) => {
    dispatch(gettranslationsListAsync(`translations?lang=${lang}`));
    i18n.changeLanguage(lang);
    localStorage.setItem("I18N_LANGUAGE_INVEST", lang);
    setSelectedLang(lang);
    dispatch(getlang(lang));
  };

  const [isLanguageDropdown, setIsLanguageDropdown] = useState(false);
  const toggleLanguageDropdown = () => {
    setIsLanguageDropdown(!isLanguageDropdown);
  };
  return (
    <Dropdown
      isOpen={isLanguageDropdown}
      toggle={toggleLanguageDropdown}
      className="ms-1 topbar-head-dropdown header-item me-10 nav-item "
    >
      <DropdownToggle
        className="me-10 border-0   nav-link fs-14 fw-semibold "
        style={{ marginRight: 10, backgroundColor: "transparent" }}
      >
        {/* <img
            src={get(languages, `${selectedLang}.flag`)}
            alt="Header Language"
            height="20"
            className="rounded"
          /> */}
        <span className="align-middle  ">
          {get(languages, `${selectedLang}.label`)}
        </span>
      </DropdownToggle>
      <DropdownMenu className="notify-item language py-2">
        {map(Object.keys(languages), (key) => (
          <DropdownItem
            key={key}
            onClick={() => changeLanguageAction(key)}
            className={`notify-item ${
              selectedLang === key ? "active" : "none"
            }`}
          >
            {/* <img
                src={get(languages, `${key}.flag`)}
                alt="Skote"
                className="me-2 rounded"
                height="18"
              /> */}
            <span className="align-middle ">
              {get(languages, `${key}.label`)}
            </span>
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
};

export default LanguageDropdown;
